import request$1, { request } from "@lateinos/request";
import { request as request2 } from "@lateinos/request";
import moment from "moment";
import { DateParse, EventDispatcher, ruleExecAsync, DataProcess } from "@lcos-kernel/chicken-library";
import { globalStorage } from "@lateinos/global-provider";
import { cloneDeep, uniq } from "lodash";
import { extend } from "umi-request";
import { ControlType } from "@lcos-kernel/controls-core";
class Context {
}
var DomainTypeEnum = /* @__PURE__ */ ((DomainTypeEnum2) => {
  DomainTypeEnum2["User"] = "user";
  DomainTypeEnum2["Engine"] = "engine";
  DomainTypeEnum2["App"] = "app";
  DomainTypeEnum2["Schema"] = "schema";
  DomainTypeEnum2["Workflow"] = "workflow";
  DomainTypeEnum2["Common"] = "common";
  DomainTypeEnum2["View"] = "view";
  return DomainTypeEnum2;
})(DomainTypeEnum || {});
var EngineEntryType = /* @__PURE__ */ ((EngineEntryType2) => {
  EngineEntryType2["DINGDING"] = "DINGDING";
  EngineEntryType2["DINGDINGMANUAL"] = "DINGDING_MANUAL";
  EngineEntryType2["OWN"] = "OWN";
  EngineEntryType2["WECOME"] = "WE_COM";
  EngineEntryType2["WECOMECOMCUSTOMIZED"] = "WE_COM_CUSTOMIZED";
  EngineEntryType2["UNKNOWN"] = "UNKNOWN";
  EngineEntryType2["WECOMESTANDARD"] = "WE_COM_STANDARD";
  EngineEntryType2["FEISHUSTORE"] = "FEISHU_STORE";
  EngineEntryType2["CLOUD_PIVOT"] = "CLOUD_PIVOT";
  return EngineEntryType2;
})(EngineEntryType || {});
var EngineState = /* @__PURE__ */ ((EngineState2) => {
  EngineState2["INITIALIZING"] = "INITIALIZING";
  EngineState2["RUNNINE"] = "RUNNINE";
  EngineState2["FORBIDDEN"] = "FORBIDDEN";
  EngineState2["ARCHIVED"] = "ARCHIVED";
  return EngineState2;
})(EngineState || {});
var AppRoleEnum = /* @__PURE__ */ ((AppRoleEnum2) => {
  AppRoleEnum2["OWNER"] = "OWNER";
  AppRoleEnum2["MANAGER"] = "MANAGER";
  AppRoleEnum2["COLLABORATORS"] = "COLLABORATORS";
  AppRoleEnum2["VISITOR"] = "VISITOR";
  AppRoleEnum2["GUEST"] = "GUEST";
  return AppRoleEnum2;
})(AppRoleEnum || {});
var RoleType = /* @__PURE__ */ ((RoleType2) => {
  RoleType2[RoleType2["DEFAULT"] = 1] = "DEFAULT";
  RoleType2[RoleType2["CUSTOM"] = 2] = "CUSTOM";
  RoleType2[RoleType2["DYNAMIC"] = 3] = "DYNAMIC";
  return RoleType2;
})(RoleType || {});
var ObjectType = /* @__PURE__ */ ((ObjectType2) => {
  ObjectType2[ObjectType2["MEMBER"] = 1] = "MEMBER";
  ObjectType2[ObjectType2["DEPARTMENT"] = 2] = "DEPARTMENT";
  return ObjectType2;
})(ObjectType || {});
var FunctionNodeType = /* @__PURE__ */ ((FunctionNodeType2) => {
  FunctionNodeType2[FunctionNodeType2["App"] = 1] = "App";
  FunctionNodeType2[FunctionNodeType2["Group"] = 2] = "Group";
  FunctionNodeType2[FunctionNodeType2["Schema"] = 3] = "Schema";
  FunctionNodeType2[FunctionNodeType2["Workflow"] = 4] = "Workflow";
  FunctionNodeType2[FunctionNodeType2["Dashboard"] = 5] = "Dashboard";
  FunctionNodeType2[FunctionNodeType2["SubTable"] = 6] = "SubTable";
  FunctionNodeType2[FunctionNodeType2["AdvancedDataSources"] = 7] = "AdvancedDataSources";
  FunctionNodeType2[FunctionNodeType2["UserModule"] = 8] = "UserModule";
  return FunctionNodeType2;
})(FunctionNodeType || {});
var SchemaTag = /* @__PURE__ */ ((SchemaTag2) => {
  SchemaTag2[SchemaTag2["Normal"] = 0] = "Normal";
  SchemaTag2[SchemaTag2["SharedSchema"] = 1] = "SharedSchema";
  SchemaTag2[SchemaTag2["SyncSchema"] = 2] = "SyncSchema";
  return SchemaTag2;
})(SchemaTag || {});
var ExportState = /* @__PURE__ */ ((ExportState2) => {
  ExportState2[ExportState2["Waiting"] = 0] = "Waiting";
  ExportState2[ExportState2["Exporting"] = 1] = "Exporting";
  ExportState2[ExportState2["GetObjectIdArray"] = 2] = "GetObjectIdArray";
  ExportState2[ExportState2["GetObjectArray"] = 3] = "GetObjectArray";
  ExportState2[ExportState2["HandleVirtualObject"] = 5] = "HandleVirtualObject";
  ExportState2[ExportState2["BuildWorkBook"] = 6] = "BuildWorkBook";
  ExportState2[ExportState2["Complete"] = 7] = "Complete";
  ExportState2[ExportState2["Error"] = -1] = "Error";
  return ExportState2;
})(ExportState || {});
var ImportState = /* @__PURE__ */ ((ImportState2) => {
  ImportState2[ImportState2["Waiting"] = 0] = "Waiting";
  ImportState2[ImportState2["Importing"] = 1] = "Importing";
  ImportState2[ImportState2["CheckedData"] = 2] = "CheckedData";
  ImportState2[ImportState2["CreateSchema"] = 3] = "CreateSchema";
  ImportState2[ImportState2["ImportData"] = 4] = "ImportData";
  ImportState2[ImportState2["Complete"] = 5] = "Complete";
  ImportState2[ImportState2["AbortImport"] = 6] = "AbortImport";
  ImportState2[ImportState2["Error"] = -1] = "Error";
  return ImportState2;
})(ImportState || {});
var FieldPermissionType = /* @__PURE__ */ ((FieldPermissionType2) => {
  FieldPermissionType2["Editable"] = "Editable";
  FieldPermissionType2["Visible"] = "Visible";
  return FieldPermissionType2;
})(FieldPermissionType || {});
var SharedState = /* @__PURE__ */ ((SharedState2) => {
  SharedState2[SharedState2["Closed"] = 0] = "Closed";
  SharedState2[SharedState2["Open"] = 1] = "Open";
  return SharedState2;
})(SharedState || {});
var SyncState = /* @__PURE__ */ ((SyncState2) => {
  SyncState2[SyncState2["Closed"] = 0] = "Closed";
  SyncState2[SyncState2["Open"] = 1] = "Open";
  return SyncState2;
})(SyncState || {});
var RecordLevel = /* @__PURE__ */ ((RecordLevel2) => {
  RecordLevel2[RecordLevel2["Success"] = 1] = "Success";
  RecordLevel2[RecordLevel2["Warning"] = 2] = "Warning";
  RecordLevel2[RecordLevel2["Error"] = 3] = "Error";
  return RecordLevel2;
})(RecordLevel || {});
class BaseMetaData {
  constructor() {
    this._cached = false;
  }
  get cached() {
    return this._cached;
  }
  set cached(value) {
    this._cached = value;
  }
}
class ContextMetaData {
  constructor() {
    this._contextMap = /* @__PURE__ */ new Map();
    this._engineMap = /* @__PURE__ */ new Map();
  }
  addContext(contextKey, context) {
    this._contextMap.set(contextKey, context);
  }
  getContext(contextKey) {
    return this._contextMap.get(contextKey);
  }
  cleanAppContexts() {
    this._contextMap.forEach((value, key) => {
      if (key.startsWith("app:")) {
        const appCode = key.split(":")[1];
        this.cleanSchemaContexts(appCode);
        this._contextMap.delete(key);
      }
    });
  }
  cleanSchemaContexts(appCode) {
    this._contextMap.forEach((value, key) => {
      if (key.startsWith("schema:" + appCode)) {
        this._contextMap.delete(key);
      }
    });
  }
  addEngine(engine) {
    this._engineMap.set(engine.engineCode, engine);
  }
  removeEngine(engineCode) {
    return this._engineMap.delete(engineCode);
  }
  getEngine(engineCode) {
    const engine = this._engineMap.get(engineCode);
    if (!engine) {
      return null;
    }
    return engine;
  }
  getEngines() {
    return Array.from(this._engineMap.values());
  }
  clearEngines() {
    this._engineMap.clear();
  }
  updateEngine(engine) {
    this._engineMap[engine.engineCode] = engine;
  }
}
class EngineMetaData {
  constructor() {
    this._appMap = /* @__PURE__ */ new Map();
    this._memberMap = /* @__PURE__ */ new Map();
  }
  addApp(app) {
    this._appMap.set(app.code, app);
  }
  removeApp(appCode) {
    return this._appMap.delete(appCode);
  }
  updateApp(app) {
    this._appMap.set(app.code, {
      ...this._appMap.get(app.code),
      ...app
    });
  }
  getApp(appCode) {
    if (!this._appMap.has(appCode)) {
      return null;
    }
    return this._appMap.get(appCode);
  }
  clearApps() {
    this._appMap.clear();
  }
  getApps() {
    return Array.from(this._appMap.values());
  }
  addMember(member) {
    this._memberMap.set(member.objectId, member);
  }
  removeMember(objectId) {
    this._memberMap.delete(objectId);
  }
  getMember(objectId) {
    if (!this._memberMap.has(objectId)) {
      return null;
    }
    return this._memberMap.get(objectId);
  }
}
class AppMetaData extends BaseMetaData {
  constructor() {
    super();
    this._functionNodeMap = /* @__PURE__ */ new Map();
    this._appRoleMap = /* @__PURE__ */ new Map();
    this._allViewMap = /* @__PURE__ */ new Map();
  }
  addFunctionNode(appCode, functionNode) {
    if (functionNode.parentCode !== "") {
      const parentFunctionNode = this.recursiveFindFunctionNode(this.getFunctionNodes(), functionNode.parentCode);
      if (parentFunctionNode) {
        if (parentFunctionNode.children) {
          parentFunctionNode.children.push(functionNode);
        } else {
          parentFunctionNode.children = [functionNode];
        }
      } else {
        this._functionNodeMap.set(functionNode.code, functionNode);
      }
    } else {
      this._functionNodeMap.set(functionNode.code, functionNode);
    }
  }
  removeFunctionNode(appCode, functionNodeCode) {
    const currentFunctionNode = this.recursiveFindFunctionNode(this.getFunctionNodes(), functionNodeCode);
    if (currentFunctionNode.parentCode !== appCode) {
      if (currentFunctionNode.type === FunctionNodeType.Group) {
        if (currentFunctionNode.children) {
          currentFunctionNode.children.forEach((f) => {
            f.parentCode = currentFunctionNode.parentCode;
            this.addFunctionNode(appCode, f);
          });
          currentFunctionNode.children = null;
        }
        const parentFunctionNode = this.recursiveFindFunctionNode(this.getFunctionNodes(), currentFunctionNode.parentCode);
        parentFunctionNode.children = parentFunctionNode.children.filter((item) => item.code !== functionNodeCode);
      }
    } else {
      return this._functionNodeMap.delete(functionNodeCode);
    }
  }
  updateFunctionNode(functionNode) {
    this._functionNodeMap.set(functionNode.code, functionNode);
  }
  getFunctionNode(functionNodeCode) {
    return this.recursiveFindFunctionNode(this.getFunctionNodes(), functionNodeCode);
  }
  getFunctionNodes() {
    const nodes = Array.from(this._functionNodeMap.values());
    return nodes.filter((node) => !node.hidden);
  }
  getAllFunctinoNodes() {
    return Array.from(this._functionNodeMap.values());
  }
  clearFunctionNodes() {
    this._functionNodeMap.clear();
  }
  getAppRole(memberId) {
    return this._appRoleMap.get(memberId);
  }
  setAppRole(memberId, roles) {
    this._appRoleMap.set(memberId, roles);
  }
  clearAppSuperRole() {
    this._appSuperRole = null;
    this._appRoleMap.clear();
  }
  recursiveFindFunctionNode(functionNodes, schemaCode) {
    if (functionNodes || functionNodes.length > 0) {
      for (let i = 0; i < functionNodes.length; i++) {
        let functionNode = functionNodes[i];
        if (functionNode.code === schemaCode) {
          return functionNode;
        } else {
          if (functionNode.children && functionNode.children.length > 0) {
            functionNode = this.recursiveFindFunctionNode(functionNode.children, schemaCode);
            if (functionNode) {
              return functionNode;
            }
          }
        }
      }
    }
    return null;
  }
  addViewList(schemaCode, views) {
    this._allViewMap.set(schemaCode, views);
  }
  getViewList(schemaCode) {
    return this._allViewMap.get(schemaCode);
  }
  addView(schemaCode, view) {
    let views = this._allViewMap.get(schemaCode);
    if (!views) {
      views = new Array();
    }
    views.push(view);
    this._allViewMap.set(schemaCode, views);
  }
  getAllViewMap() {
    return this._allViewMap;
  }
  get appSuperRole() {
    return this._appSuperRole;
  }
  set appSuperRole(value) {
    this._appSuperRole = value;
  }
}
class SchemaMetaData extends BaseMetaData {
  constructor() {
    super();
    this._contolMap = /* @__PURE__ */ new Map();
    this._viewMap = /* @__PURE__ */ new Map();
    this._submitRules = new Array();
  }
  addControl(control) {
    this._contolMap.set(control.code, control);
    if (this._schemaPermission) {
      this._schemaPermission.fieldAddPermission = {
        ...this._schemaPermission.fieldAddPermission,
        ...this.addFieldPermission(control.code)
      };
      this._schemaPermission.fieldEditPermissions[0].fieldEditPermission = {
        ...this._schemaPermission.fieldEditPermissions[0].fieldEditPermission,
        ...this.addFieldPermission(control.code)
      };
    }
  }
  removeControl(controlCode) {
    this._contolMap.delete(controlCode);
    if (this._schemaPermission) {
      delete this._schemaPermission.fieldAddPermission[controlCode];
      delete this._schemaPermission.fieldEditPermissions[0].fieldEditPermission[controlCode];
    }
  }
  updateControl(control) {
    if (this._contolMap.size === 0) {
      return;
    }
    this._contolMap.set(control.code, {
      ...this._contolMap.get(control.code),
      ...control
    });
  }
  getControls() {
    return Array.from(this._contolMap.values());
  }
  setControls(controls) {
    controls.forEach((controlDto) => {
      const control = {
        code: controlDto.code,
        displayName: controlDto.displayName,
        displayRule: controlDto.displayRule,
        prevCode: controlDto.prevCode,
        required: controlDto.required,
        status: controlDto.status,
        tips: controlDto.status,
        type: controlDto.type,
        properties: controlDto.properties,
        childrenControls: controlDto.childrenControls,
        titleFlag: controlDto.titleFlag
      };
      this._contolMap.set(control.code, control);
    });
  }
  clearControls() {
    this._contolMap.clear();
  }
  addView(view) {
    this._viewMap.set(view.viewCode, view);
  }
  removeView(viewCode) {
    return this._viewMap.delete(viewCode);
  }
  updateView(view) {
    this._viewMap.set(view.viewCode, view);
  }
  getView(viewCode) {
    return this._viewMap.get(viewCode);
  }
  getViews() {
    return Array.from(this._viewMap.values());
  }
  clearViews() {
    this._viewMap.clear();
  }
  updateViewPreference(viewCode, newPreference) {
    const cacheView = this._viewMap.get(viewCode);
    if (cacheView) {
      const preference = {
        ...cacheView.preference,
        ...newPreference
      };
      cacheView.preference = preference;
    } else {
      cacheView.preference = newPreference;
    }
  }
  addSubmitRule(submitRule) {
    this._submitRules.push(submitRule);
  }
  addFieldPermission(controlCode) {
    return {
      [controlCode]: {
        Editable: true,
        Visible: true
      }
    };
  }
  get submitRules() {
    return this._submitRules;
  }
  set submitRules(value) {
    this._submitRules = value;
  }
  get schemaPermission() {
    return this._schemaPermission;
  }
  set schemaPermission(value) {
    this._schemaPermission = value;
  }
  get schemaSharedSetting() {
    return this._schemaSharedSetting;
  }
  set schemaSharedSetting(value) {
    this._schemaSharedSetting = value;
  }
  get schemaPreference() {
    return this._schemaPreference;
  }
  set schemaPreference(value) {
    this._schemaPreference = value;
  }
}
class ControlCacheManager {
  constructor() {
    this._controlMap = /* @__PURE__ */ new Map();
  }
  addControl(control) {
    if (this._controlMap.size === 0) {
      return;
    }
    this._controlMap.set(control.code, control);
  }
  removeControl(controlCode) {
    this._controlMap.delete(controlCode);
  }
  updateControl(control) {
    if (this._controlMap.size === 0) {
      return;
    }
    this._controlMap.set(control.code, {
      ...this._controlMap.get(control.code),
      ...control
    });
  }
  getControls() {
    return Array.from(this._controlMap.values());
  }
  setControls(controls) {
    controls.forEach((controlDto) => {
      const control = {
        code: controlDto.code,
        displayName: controlDto.displayName,
        displayRule: controlDto.displayRule,
        prevCode: controlDto.prevCode,
        required: controlDto.required,
        status: controlDto.status,
        tips: controlDto.status,
        type: controlDto.type,
        properties: controlDto.properties,
        childrenControls: controlDto.childrenControls,
        titleFlag: controlDto.titleFlag
      };
      this._controlMap.set(control.code, control);
    });
  }
  clearControls() {
    this._controlMap.clear();
  }
}
function checkByAccount$2(code, email, mobile) {
  const path = "/v1/user/check/account";
  return request(path, {
    method: "get",
    params: {
      code,
      email,
      mobile
    }
  });
}
function userNameQuery() {
  const path = "/v1/user/information/basic";
  return request(path, {
    method: "get"
  });
}
function userNameReset(option) {
  const path = "/v1/user/information/basic/reset";
  return request(path, {
    method: "post",
    data: option
  });
}
function userBindemail(option) {
  const path = "/v1/user/information/email";
  return request(path, {
    method: "post",
    data: option
  });
}
function userBindPhone(option) {
  const path = "/v1/user/information/mobile";
  return request(path, {
    method: "post",
    data: option
  });
}
function userPasswordModify(passwordResetOption) {
  const path = "/v1/user/information/modify/password";
  return request(path, {
    method: "post",
    data: passwordResetOption
  });
}
function passwordReset(passwordResetOption) {
  const path = "/v1/user/information/password";
  return request(path, {
    method: "post",
    data: passwordResetOption
  });
}
function userUpdateemail(option) {
  const path = "/v1/user/information/update/email";
  return request(path, {
    method: "post",
    data: option
  });
}
function userUpdatePhone(option) {
  const path = "/v1/user/information/update/mobile";
  return request(path, {
    method: "post",
    data: option
  });
}
function unbindWechat$2() {
  const path = "/v1/user/information/wechat/unbind";
  return request(path, {
    method: "delete"
  });
}
const UserApi = {
  checkByAccount: checkByAccount$2,
  userNameQuery,
  userNameReset,
  userBindemail,
  userBindPhone,
  userPasswordModify,
  passwordReset,
  userUpdateemail,
  userUpdatePhone,
  unbindWechat: unbindWechat$2
};
const proxyService = (service2) => {
  return service2;
};
function getUserBasicInfo$1() {
  return UserApi.userNameQuery();
}
function checkByAccount$1(code, email, mobile) {
  return UserApi.checkByAccount(code, email, mobile);
}
function updateUserBasicInfo$1(option) {
  return UserApi.userNameReset(option);
}
function bindUserPhone$1(option) {
  return UserApi.userBindPhone(option);
}
function bindUserEmail$1(option) {
  return UserApi.userBindemail(option);
}
function modifyPassword$1(passwordResetOption) {
  return UserApi.userPasswordModify(passwordResetOption);
}
function resetPassword$1(passwordResetOption) {
  return UserApi.passwordReset(passwordResetOption);
}
function updateUserEmail$1(option) {
  return UserApi.userUpdateemail(option);
}
function updateUserPhone$1(option) {
  return UserApi.userUpdatePhone(option);
}
function unbindWechat$1() {
  return UserApi.unbindWechat();
}
const User = {
  getUserBasicInfo: getUserBasicInfo$1,
  checkByAccount: checkByAccount$1,
  bindUserEmail: bindUserEmail$1,
  bindUserPhone: bindUserPhone$1,
  unbindWechat: unbindWechat$1,
  updateUserPhone: updateUserPhone$1,
  updateUserEmail: updateUserEmail$1,
  modifyPassword: modifyPassword$1,
  resetPassword: resetPassword$1,
  updateUserBasicInfo: updateUserBasicInfo$1
};
const service$j = proxyService(User);
function queryLatestPct() {
  const path = "/v1/pct/latest";
  return request(path, {
    method: "get"
  });
}
function attachmentPresubmission(data) {
  const path = "/v1/attachment/presubmission";
  return request(path, {
    method: "post",
    data
  });
}
function attachementQueryExternalinfo$1(data) {
  const path = "/v1/attachment/query/externalinfo";
  return request(path, {
    method: "post",
    data
  });
}
const Common = {
  queryLatestPct,
  attachmentPresubmission,
  attachementQueryExternalinfo: attachementQueryExternalinfo$1
};
const service$i = proxyService(Common);
function changeCorp$2(option) {
  const path = "/v1/corpintegrate/corp/change";
  return request(path, {
    method: "post",
    data: option
  });
}
function appCross$2(engineCode, token) {
  const path = "/integrate/app/cross";
  return request(path, {
    method: "post",
    data: {
      "engineCode": engineCode,
      "token": token
    }
  });
}
function getCurrentCorp() {
  const path = "/v1/corpintegrate/corpinfo/current";
  return request(path, {
    method: "get"
  });
}
function moveCorpInfo(moveModel) {
  const path = "/v1/corpintegrate/corpinfo/move";
  return request(path, {
    method: "post",
    data: moveModel
  });
}
function getCorpInfo(corpId) {
  const path = `/v1/corpintegrate/corpinfo/${corpId}`;
  return request(path, {
    method: "get"
  });
}
function getBindBaseInfo$1(corpId) {
  const path = "/v1/corpintegrate/dingtalk/getBindBaseInfo";
  return request(path, {
    method: "get",
    params: {
      corpId
    }
  });
}
function getThirdPartyBinding() {
  const path = "/v1/corpintegrate/dingtalk/getThirdPartyBinding";
  return request(path, {
    method: "get"
  });
}
function unBindingDingtalk() {
  const path = "/v1/corpintegrate/dingtalk/unBinding";
  return request(path, {
    method: "post"
  });
}
function join(invitationId) {
  const path = `/v1/corpintegrate/invitation/join/${invitationId}`;
  return request(path, {
    method: "put"
  });
}
function getLastOneInvitation(id) {
  const path = "/v1/corpintegrate/invitation/last";
  return request(path, {
    method: "get",
    params: {
      id
    }
  });
}
function refuseInvitation(invitationId) {
  const path = `/v1/corpintegrate/invitation/refuse/${invitationId}`;
  return request(path, {
    method: "put"
  });
}
function getUserIntegrateEngine$2() {
  const path = "/v1/corpintegrate/userengine";
  return request(path, {
    method: "get"
  });
}
function getQrCode() {
  const path = "/v1/corpintegrate/wechat/getQrCode";
  return request(path, {
    method: "get"
  });
}
function handleEvent(model) {
  const path = "/v1/corpintegrate/wechat/handleEvent";
  return request(path, {
    method: "post",
    data: model
  });
}
function integrateInner(model) {
  const path = "/v1/corpintegrate/wecom/inner";
  return request(path, {
    method: "post",
    data: model
  });
}
function unBindingWecom() {
  const path = "/v1/corpintegrate/wecom/unBinding";
  return request(path, {
    method: "post"
  });
}
function checkTerminal() {
  const path = "/v1/token/terminal/check";
  return request(path, {
    method: "get",
    disableBizError: true,
    disableSysError: true
  });
}
function getFeatures$2(corpId) {
  const path = `/v1/corpintegrate/featuregates/${corpId}`;
  return request(path, {
    method: "get"
  });
}
function saveCorpAdmin$2(memberIds) {
  return request("/v1/corpintegrate/admin", {
    method: "post",
    data: memberIds
  });
}
function getCorpAdminList$2() {
  return request("/v1/corpintegrate/admin/list", {
    method: "get"
  });
}
function getCorpOwner$2() {
  return request("/v1/corpintegrate/owner", {
    method: "get"
  });
}
const CorpIntegrateApi = {
  changeCorp: changeCorp$2,
  appCross: appCross$2,
  getUserIntegrateEngine: getUserIntegrateEngine$2,
  getCurrentCorp,
  moveCorpInfo,
  getCorpInfo,
  getFeatures: getFeatures$2,
  getBindBaseInfo: getBindBaseInfo$1,
  getThirdPartyBinding,
  integrateInner,
  unBindingDingtalk,
  join,
  getLastOneInvitation,
  refuseInvitation,
  getQrCode,
  handleEvent,
  unBindingWecom,
  checkTerminal,
  saveCorpAdmin: saveCorpAdmin$2,
  getCorpAdminList: getCorpAdminList$2,
  getCorpOwner: getCorpOwner$2
};
function changeCorp$1(option) {
  return CorpIntegrateApi.changeCorp(option);
}
function appCross$1(engineCode, token) {
  return CorpIntegrateApi.appCross(engineCode, token);
}
function loginTerminalCheck$1() {
  return CorpIntegrateApi.checkTerminal();
}
function getBindBaseInfo(corpId) {
  return CorpIntegrateApi.getBindBaseInfo(corpId);
}
function getUserIntegrateEngine$1() {
  return CorpIntegrateApi.getUserIntegrateEngine();
}
function getFeatures$1(corpId) {
  return CorpIntegrateApi.getFeatures(corpId);
}
function saveCorpAdmin$1(memberIds) {
  return CorpIntegrateApi.saveCorpAdmin(memberIds);
}
function getCorpAdminList$1() {
  return CorpIntegrateApi.getCorpAdminList();
}
function getCorpOwner$1() {
  return CorpIntegrateApi.getCorpOwner();
}
const Corp = {
  changeCorp: changeCorp$1,
  appCross: appCross$1,
  getFeatures: getFeatures$1,
  getBindBaseInfo,
  getUserIntegrateEngine: getUserIntegrateEngine$1,
  loginTerminalCheck: loginTerminalCheck$1,
  saveCorpAdmin: saveCorpAdmin$1,
  getCorpAdminList: getCorpAdminList$1,
  getCorpOwner: getCorpOwner$1
};
const service$h = proxyService(Corp);
function queryDefaultView$1(schemaCode) {
  const path = `/v1/view/default/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getAllControlsV2(schemaCode) {
  const path = `/v2/control/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getAllControls$1(schemaCode) {
  const path = `/v1/view/${schemaCode}/control`;
  return request(path, {
    method: "get"
  });
}
function addControl(schemaCode, dto) {
  const path = `/v1/view/${schemaCode}/control`;
  return request(path, {
    method: "post",
    data: dto
  });
}
function updateControl$1(schemaCode, dto) {
  const path = `/v1/view/${schemaCode}/control`;
  return request(path, {
    method: "put",
    data: dto
  });
}
function getAccessibleControls(appCode, schemaCode) {
  const path = `/v1/view/${schemaCode}/control/accessible/${appCode}`;
  return request(path, {
    method: "get"
  });
}
function deleteControl$1(schemaCode, code) {
  const path = `/v1/view/${schemaCode}/control/${code}`;
  return request(path, {
    method: "delete"
  });
}
function getDefaultProperty(schemaCode, code, controlType) {
  const path = `/v1/view/${schemaCode}/control/${code}/${controlType}`;
  return request(path, {
    method: "get"
  });
}
function getTypeConversionProgress(schemaCode, actionId) {
  const path = `/v1/view/${schemaCode}/control/typeconversion/${actionId}`;
  return request(path, {
    method: "get"
  });
}
function getAllControlsV1(schemaCode) {
  const path = `/v1/view/control/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function addControlV1(schemaCode, dto) {
  const path = `/v1/view/control/${schemaCode}`;
  return request(path, {
    method: "post",
    data: dto
  });
}
function updateControlV1(schemaCode, dto) {
  const path = `/v2/schema/${schemaCode}/control`;
  return request(path, {
    method: "put",
    data: dto
  });
}
function getAccessibleControlsV1(appCode, schemaCode) {
  const path = `/v1/view/control/accessible/${appCode}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function deleteControlV1(schemaCode, controlCode) {
  const path = `/v1/view/control/${schemaCode}/${controlCode}`;
  return request(path, {
    method: "delete"
  });
}
function getDefaultPropertyV1(schemaCode, controlCode, controlType) {
  const path = `/v1/view/control/${schemaCode}/${controlCode}/${controlType}`;
  return request(path, {
    method: "get"
  });
}
function getTypeConversionProgressV1(schemaCode, actionId) {
  const path = `/v1/view/control/typeconversion/${schemaCode}/${actionId}`;
  return request(path, {
    method: "get"
  });
}
function addViewControlV2(schemaCode, dto) {
  const path = `/v2/view/control/${schemaCode}`;
  return request(path, {
    method: "post",
    data: dto
  });
}
function addControlV2$1(schemaCode, dto) {
  const path = `/v2/schema/${schemaCode}/control`;
  return request(path, {
    method: "post",
    data: dto
  });
}
function updateControlV2(schemaCode, dto) {
  const path = `/v2/control/${schemaCode}`;
  return request(path, {
    method: "put",
    data: dto
  });
}
function getAccessibleControlsV2(appCode, schemaCode) {
  const path = `/v2/control/accessible/${appCode}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getBatchSchemaAccessibleControls(appCode, schemaCodes) {
  const path = `/v2/control/accessible/${appCode}`;
  return request(path, {
    method: "post",
    data: schemaCodes
  });
}
function deleteControlV2(schemaCode, controlCode) {
  const path = `/v2/schema/${schemaCode}/control/${controlCode}`;
  return request(path, {
    method: "delete"
  });
}
function getDefaultPropertyV2(schemaCode, controlCode, controlType) {
  const path = `/v2/control/${schemaCode}/${controlCode}/${controlType}`;
  return request(path, {
    method: "get"
  });
}
function getTypeConversionProgressV2(actionId) {
  const path = `/v1/progress/${actionId}`;
  return request(path, {
    method: "get"
  });
}
function getSubControls$1(appCode, schemaCode) {
  const path = `/v3/control/sub/${appCode}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getAccessibleControlsV3(schemaCode, dto) {
  const path = `/v3/schema/${schemaCode}/querycontrols`;
  return request(path, {
    method: "post",
    data: dto
  });
}
function checkControlStatusInvalid(appCode, schemaCode) {
  const path = `/v3/control/status/${appCode}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getSubControlsWithStatus(appCode, schemaCode) {
  const path = `/v3/control/sub/${appCode}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getAuthStatusControls(dto) {
  const path = "/v3/control/auth/status";
  return request(path, {
    method: "post",
    data: dto
  });
}
function getBySchemaCode(schemaCode) {
  const path = `/v1/schema/datasyncrecord/byschema/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getByAppCode(appCode) {
  const path = `/v1/schema/datasyncrecord/byapp/${appCode}`;
  return request(path, {
    method: "get"
  });
}
function getSchemaReferences(schemaCode) {
  const path = `/v1/schema/referenced/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getControlReferences(schemaCode, controlCode) {
  const path = `/v1/schema/referenced/${schemaCode}/control/${controlCode}`;
  return request(path, {
    method: "get"
  });
}
function addSchema(dto) {
  const path = "/v1/schema";
  return request(path, {
    method: "post",
    data: dto
  });
}
function updateSchema(dto) {
  const path = "/v1/schema";
  return request(path, {
    method: "put",
    data: dto
  });
}
function addSyncSchema$1(dto) {
  const path = "/v1/schema/syncschema";
  return request(path, {
    method: "post",
    data: dto
  });
}
function copySchema(schemaCode, displayName, req) {
  const path = `/v1/schema/copy/${schemaCode}/${displayName}`;
  return request(path, {
    method: "post",
    data: req
  });
}
function deleteSchema(schemaCode) {
  const path = `/v1/schema/${schemaCode}`;
  return request(path, {
    method: "delete"
  });
}
function deleteSchemas(schemaCodes) {
  const path = "/v1/schema/delete";
  return request(path, {
    method: "post",
    data: schemaCodes
  });
}
function checkReferenced(schemaCode) {
  const path = `/v1/schema/${schemaCode}/reference`;
  return request(path, {
    method: "get"
  });
}
function getSubmitRule$1(appCode, schemaCode) {
  const path = `/v1/schema/submitrule/${appCode}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function addSubmitRule$1(dtoList, appCode, schemaCode) {
  const path = `/v1/schema/submitrule/${appCode}/${schemaCode}`;
  return request(path, {
    method: "post",
    data: dtoList
  });
}
function updateSubmitRule$1(dtoList, appCode, schemaCode) {
  const path = `/v1/schema/submitrule/${appCode}/${schemaCode}`;
  return request(path, {
    method: "put",
    data: dtoList
  });
}
function getSharedschemasetting(schemaCode) {
  const path = `/v1/schema/sharedschemasetting/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function deleteSharedschemasetting(schemaCode) {
  const path = `/v1/schema/sharedschemasetting/${schemaCode}`;
  return request(path, {
    method: "delete"
  });
}
function addSharedschemasetting(model) {
  const path = "/v1/schema/sharedschemasetting";
  return request(path, {
    method: "post",
    data: model
  });
}
function updateSharedschemasetting$1(model) {
  const path = "/v1/schema/sharedschemasetting";
  return request(path, {
    method: "put",
    data: model
  });
}
function updateState(schemaCode, state) {
  const path = `/v1/schema/sharedschemasetting/${schemaCode}/${state}`;
  return request(path, {
    method: "patch"
  });
}
function getSyncschemasetting(schemaCode) {
  const path = `/v1/schema/syncschemasetting/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function updateSyncschemasetting(model) {
  const path = "/v1/schema/syncschemasetting";
  return request(path, {
    method: "put",
    data: model
  });
}
function updateSchemaTitle$1(model) {
  const path = `/v1/schema/${model.schemaCode}/title`;
  return request(path, {
    method: "put",
    params: {
      appCode: model.appCode,
      titleCode: model.titleCode
    }
  });
}
function getView$1(schemaCode, mode) {
  const path = `/v1/view/${schemaCode}/${mode}`;
  return request(path, {
    method: "get"
  });
}
function getViews$1(schemaCode) {
  const path = `/v1/view/list/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function addView$1(dto) {
  const path = "/v1/view";
  return request(path, {
    method: "post",
    data: dto
  });
}
function updateView$1(dto) {
  const path = "/v1/view";
  return request(path, {
    method: "put",
    data: dto
  });
}
function updateViewInfo(dto) {
  const path = "/v1/view/info";
  return request(path, {
    method: "put",
    data: dto
  });
}
function updateViewSetting$1(viewCode, settings) {
  const path = `/v1/view/${viewCode}/setting`;
  return request(path, {
    method: "put",
    data: settings
  });
}
function deleteView$1(schemaCode, viewCode) {
  const path = `/v1/view/${schemaCode}/${viewCode}`;
  return request(path, {
    method: "delete"
  });
}
function getView2(viewCode) {
  const path = `/v1/view/${viewCode}`;
  return request(path, {
    method: "get"
  });
}
function getViewV2(viewCode) {
  const path = `/v2/view?viewCode=${viewCode}`;
  return request(path, {
    method: "get"
  });
}
function copyView(viewCode) {
  const path = `/v1/view/${viewCode}/copy`;
  return request(path, {
    method: "post"
  });
}
function getShared$1(data) {
  const path = "/v3/schema/shared-setting/query";
  return request(path, {
    method: "post",
    data
  });
}
function addSyncSchemaV3$1(data) {
  const path = "/v3/schema/sync-schema";
  return request(path, {
    method: "post",
    data
  });
}
function querySyncSchema$1(data) {
  const path = "/v3/schema/sync-setting/query";
  return request(path, {
    method: "post",
    data
  });
}
function addShareSet$1(schemaCode, data) {
  const path = `/v3/schema/${schemaCode}/shared-setting`;
  return request(path, {
    method: "post",
    data
  });
}
function editShareSet$1(schemaCode, data) {
  const path = `/v3/schema/${schemaCode}/shared-setting`;
  return request(path, {
    method: "put",
    data
  });
}
function deleteShareSet$1(schemaCode, id) {
  const path = `/v3/schema/${schemaCode}/shared-setting/${id}`;
  return request(path, {
    method: "delete"
  });
}
function deleteSyncSchema$1(schemaCode) {
  const path = `/v3/schema/${schemaCode}/shared-schema`;
  return request(path, {
    method: "delete"
  });
}
function editSyncSet$1(schemaCode, data) {
  const path = `/v3/schema/${schemaCode}/sync-setting`;
  return request(path, {
    method: "put",
    data
  });
}
function deleteSyncSet$1(schemaCode, id) {
  const path = `/v3/schema/${schemaCode}/shared-setting/${id}`;
  return request(path, {
    method: "delete"
  });
}
function getSyncStatus$1(schemaCode) {
  const path = `/v3/schema/${schemaCode}/sync-status`;
  return request(path, {
    method: "get"
  });
}
function queryIntegrationConfig$1(data) {
  const path = "/v3/integration/configs/query";
  return request(path, {
    method: "post",
    data
  });
}
function integrationOriginColumn$1(integrationConnection, tableName) {
  const path = `/v3/integration/columns/${tableName}`;
  return request(path, {
    method: "post",
    data: integrationConnection
  });
}
function addIntegrationOrigin$1(integrationConfig) {
  const path = "/v3/integration/config";
  return request(path, {
    method: "post",
    data: integrationConfig
  });
}
function updateIntegrationOrigin$1(integrationConfig) {
  const path = `/v3/integration/config/${integrationConfig.id}`;
  return request(path, {
    method: "put",
    data: integrationConfig
  });
}
function deleteIntegrationOrigin$1(id) {
  const path = `/v3/integration/config/${id}`;
  return request(path, {
    method: "delete"
  });
}
function integrationDbtypes$1() {
  const path = "/v3/integration/db-types";
  return request(path, {
    method: "get"
  });
}
function integrationDbtypesField$1(dbType) {
  const path = `/v3/integration/field-type-maps/${dbType}`;
  return request(path, {
    method: "get"
  });
}
function integrationHandelSync$1(id) {
  const path = `/v3/integration/sync/${id}`;
  return request(path, {
    method: "post"
  });
}
function integrationDbTables$1(integrationConnection) {
  const path = "/v3/integration/tables";
  return request(path, {
    method: "post",
    data: integrationConnection
  });
}
function integrationUpdateName$1(newName, id, appCode) {
  const path = `/v3/integration/config/${id}/rename`;
  return request(path, {
    method: "put",
    data: {
      appCode,
      newName
    }
  });
}
function getReportDataSourceByAppCode$1(appCode) {
  const path = `/v3/integration/report/datasource/${appCode}`;
  return request(path, {
    method: "get"
  });
}
function getReportDataSourceMetadata$1(dataSourceId) {
  const path = `/v3/integration/report/datasource/metadata/${dataSourceId}`;
  return request(path, {
    method: "get"
  });
}
const ViewApi = {
  getAllControls: getAllControls$1,
  addControl,
  updateControl: updateControl$1,
  getAccessibleControls,
  deleteControl: deleteControl$1,
  getDefaultProperty,
  getTypeConversionProgress,
  getAllControlsV1,
  addControlV1,
  updateControlV1,
  getAccessibleControlsV1,
  deleteControlV1,
  getDefaultPropertyV1,
  getTypeConversionProgressV1,
  addControlV2: addControlV2$1,
  getAllControlsV2,
  addViewControlV2,
  updateControlV2,
  getAccessibleControlsV2,
  getBatchSchemaAccessibleControls,
  deleteControlV2,
  getDefaultPropertyV2,
  getTypeConversionProgressV2,
  getSubControls: getSubControls$1,
  getAccessibleControlsV3,
  checkControlStatusInvalid,
  getSubControlsWithStatus,
  getAuthStatusControls,
  getBySchemaCode,
  getByAppCode,
  getSchemaReferences,
  getControlReferences,
  addSchema,
  updateSchema,
  addSyncSchema: addSyncSchema$1,
  copySchema,
  deleteSchema,
  deleteSchemas,
  checkReferenced,
  getSubmitRule: getSubmitRule$1,
  addSubmitRule: addSubmitRule$1,
  updateSubmitRule: updateSubmitRule$1,
  getSharedschemasetting,
  deleteSharedschemasetting,
  addSharedschemasetting,
  updateSharedschemasetting: updateSharedschemasetting$1,
  updateState,
  getSyncschemasetting,
  updateSyncschemasetting,
  getView: getView$1,
  getViews: getViews$1,
  addView: addView$1,
  updateView: updateView$1,
  updateViewInfo,
  updateViewSetting: updateViewSetting$1,
  deleteView: deleteView$1,
  getView2,
  queryDefaultView: queryDefaultView$1,
  copyView,
  getViewV2,
  updateSchemaTitle: updateSchemaTitle$1,
  getShared: getShared$1,
  addSyncSchemaV3: addSyncSchemaV3$1,
  querySyncSchema: querySyncSchema$1,
  addShareSet: addShareSet$1,
  editShareSet: editShareSet$1,
  deleteShareSet: deleteShareSet$1,
  deleteSyncSchema: deleteSyncSchema$1,
  editSyncSet: editSyncSet$1,
  deleteSyncSet: deleteSyncSet$1,
  getSyncStatus: getSyncStatus$1,
  queryIntegrationConfig: queryIntegrationConfig$1,
  integrationOriginColumn: integrationOriginColumn$1,
  addIntegrationOrigin: addIntegrationOrigin$1,
  updateIntegrationOrigin: updateIntegrationOrigin$1,
  deleteIntegrationOrigin: deleteIntegrationOrigin$1,
  integrationDbtypes: integrationDbtypes$1,
  integrationDbtypesField: integrationDbtypesField$1,
  integrationHandelSync: integrationHandelSync$1,
  integrationDbTables: integrationDbTables$1,
  integrationUpdateName: integrationUpdateName$1,
  getReportDataSourceByAppCode: getReportDataSourceByAppCode$1,
  getReportDataSourceMetadata: getReportDataSourceMetadata$1
};
function importTest(actionId) {
  const path = "/v1/import/test";
  return request(path, {
    method: "get",
    params: {
      actionId
    }
  });
}
function exportDataBySection$1(exportRequest) {
  const path = "/v1/export/section";
  return request(path, {
    method: "post",
    data: exportRequest
  });
}
function exportSectionData$1(exportRequest) {
  const path = "/v3/export/task";
  return request(path, {
    method: "post",
    data: exportRequest
  });
}
function getExportProgress$1(actionId) {
  const path = `/v1/export/progress/${actionId}`;
  return request(path, {
    method: "get"
  });
}
function start$1(importRequest) {
  const path = "/v1/import/start";
  return request(path, {
    method: "post",
    data: importRequest
  });
}
function update$1(importRequest) {
  const path = "/v1/import/update";
  return request(path, {
    method: "post",
    data: importRequest
  });
}
function getImportProgress$1(actionId) {
  const path = `/v1/import/progress/${actionId}`;
  return request(path, {
    method: "get"
  });
}
function queryImportExportProgress$1(actionId) {
  const path = `/v3/importexport/progress/${actionId}`;
  return request(path, {
    method: "get"
  });
}
function checkSchemaCompleteState(schemaCode) {
  const path = `/v1/import/check/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getTemplate$1(schemaCode, importOption) {
  const path = `/v1/import/template/${schemaCode}/${importOption}`;
  return request(path, {
    method: "get"
  });
}
function previewData(viewRowCount) {
  const path = `/v1/import/preview/${viewRowCount}`;
  return request(path, {
    method: "post"
  });
}
function getImportColumnsOld$1(schemaCode, importOption) {
  const path = `/v1/import/columns/${schemaCode}/${importOption}`;
  return request(path, {
    method: "get"
  });
}
function getImportColumns$1(schemaCode) {
  const path = `/v3/import/supportcolumns/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function aborImport(actionId) {
  const path = `/v1/import/abort/${actionId}`;
  return request(path, {
    method: "post"
  });
}
function createPreviewData$1(viewRowCount, formData) {
  const path = `/v1/import/create/preview/${viewRowCount}`;
  return request(path, {
    method: "post",
    body: formData
  });
}
function createSchemaStart(request$12) {
  const path = "/v1/import/create/start";
  return request(path, {
    method: "post",
    data: request$12
  });
}
function createSchemaProgress(actionId) {
  const path = `/v1/import/create/progress/${actionId}`;
  return request(path, {
    method: "get"
  });
}
function abortCreateSchema(actionId) {
  const path = `/v1/import/create/abort/${actionId}`;
  return request(path, {
    method: "post"
  });
}
function downloadTemplate$1(data) {
  const path = "/v3/import/template";
  return request(path, {
    method: "post",
    data
  });
}
function createImportTask$1(data) {
  const path = "/v3/import/task";
  return request(path, {
    method: "post",
    data
  });
}
function getImportTemplate$1(schemaCode, viewCode, importOption) {
  const path = `/v2/import/template/${schemaCode}/${viewCode}/${importOption}`;
  return request(path, {
    method: "get"
  });
}
const ImportExportApi = {
  importTest,
  downloadTemplate: downloadTemplate$1,
  createImportTask: createImportTask$1,
  exportDataBySection: exportDataBySection$1,
  exportSectionData: exportSectionData$1,
  getExportProgress: getExportProgress$1,
  start: start$1,
  update: update$1,
  getImportProgress: getImportProgress$1,
  checkSchemaCompleteState,
  getTemplate: getTemplate$1,
  previewData,
  getImportColumnsOld: getImportColumnsOld$1,
  getImportColumns: getImportColumns$1,
  aborImport,
  createPreviewData: createPreviewData$1,
  createSchemaStart,
  createSchemaProgress,
  abortCreateSchema,
  queryImportExportProgress: queryImportExportProgress$1,
  getImportTemplate: getImportTemplate$1
};
function queryAppIntegrateInfo(appCode) {
  const path = "/v1/appaggregation/app";
  return request(path, {
    method: "get",
    params: {
      appCode
    }
  });
}
function querySchemaIntegrateInfo(appCode, schemaCode) {
  const path = "/v1/appaggregation/schema";
  return request(path, {
    method: "get",
    params: {
      appCode,
      schemaCode
    }
  });
}
function queryTaskProgress$1(taskId) {
  const path = `/v2/progress/${taskId}`;
  return request(path, {
    method: "get"
  });
}
function getUnFinishedTaskProgress$1(params) {
  const path = "/v2/progress/query";
  return request(path, {
    method: "post",
    data: params
  });
}
function queryConversionPreview$1(schemaCode, dto) {
  const path = `/v2/schema/${schemaCode}/control/conversionpreview`;
  return request(path, {
    method: "post",
    data: dto
  });
}
function addDashboardNode$1(dashboardNodes) {
  const path = "/v1/dashboard/nodes";
  return request(path, {
    method: "post",
    data: dashboardNodes
  });
}
function deleteDashboardNode$1(code) {
  const path = `/v1/dashboard/node/${code}`;
  return request(path, {
    method: "delete"
  });
}
function updateDashboardNode$1(dashboardNodes) {
  const path = "/v1/dashboard/nodes";
  return request(path, {
    method: "put",
    data: dashboardNodes
  });
}
function queryDashboardNodeList$1(params) {
  const path = "/v1/dashboard/nodelist";
  return request(path, {
    method: "post",
    data: params
  });
}
function countTodo$1(participantId) {
  const path = "/v2/workflow/todos/unfinishedcount";
  return request(path, {
    method: "get",
    params: {
      participantId
    }
  });
}
function queryTodosInfo$1(params) {
  const path = "/v2/workflow/todos/query";
  return request(path, {
    method: "post",
    data: params
  });
}
function queryOriginalTodos$1(params) {
  const path = "/v2/workflow/instances/query";
  return request(path, {
    method: "post",
    data: params
  });
}
function batchUpdateBizData$1(data) {
  const path = "/v2/bizdatas/condition-update";
  return request(path, {
    method: "post",
    data
  });
}
function batchDeleteBizData$1(data) {
  const path = "/v2/bizdatas/condition-delete";
  return request(path, {
    method: "post",
    data
  });
}
function dataConvert$1(data) {
  const path = "/v1/bizdata/dataconvert";
  return request(path, {
    method: "post",
    data
  });
}
function expressionEvaluate$1(data) {
  const path = "/v1/bizdata/expression/relationship/evaluate";
  return request(path, {
    method: "post",
    data
  });
}
function expireExportTask$1(data) {
  const path = "/v3/export/bulk/task";
  return request(path, {
    method: "post",
    data
  });
}
function expireExportTaskProgress$1(actionId) {
  const path = `/v3/importexport/progress/${actionId}`;
  return request(path, {
    method: "get"
  });
}
function querySchemaReferenced$1(data) {
  const {
    appCode,
    schemaCode,
    valid,
    controlCode
  } = data;
  const path = `/v2/schema/${schemaCode}/referenced`;
  return request(path, {
    method: "post",
    data: {
      appCode,
      valid,
      controlCode
    }
  });
}
const IntegrateApi = {
  queryAppIntegrateInfo,
  querySchemaIntegrateInfo,
  queryTaskProgress: queryTaskProgress$1,
  getUnFinishedTaskProgress: getUnFinishedTaskProgress$1,
  queryConversionPreview: queryConversionPreview$1,
  addDashboardNode: addDashboardNode$1,
  deleteDashboardNode: deleteDashboardNode$1,
  updateDashboardNode: updateDashboardNode$1,
  queryDashboardNodeList: queryDashboardNodeList$1,
  countTodo: countTodo$1,
  queryTodosInfo: queryTodosInfo$1,
  queryOriginalTodos: queryOriginalTodos$1,
  batchUpdateBizData: batchUpdateBizData$1,
  batchDeleteBizData: batchDeleteBizData$1,
  dataConvert: dataConvert$1,
  expressionEvaluate: expressionEvaluate$1,
  expireExportTask: expireExportTask$1,
  expireExportTaskProgress: expireExportTaskProgress$1,
  querySchemaReferenced: querySchemaReferenced$1
};
function addSyncSchema(dto) {
  return ViewApi.addSyncSchema(dto);
}
function updateSyncSetting(model) {
  return ViewApi.updateSyncschemasetting(model);
}
function getSyncSetting(schemaCode) {
  return ViewApi.getSyncschemasetting(schemaCode);
}
function getSyncRecord(schemaCode) {
  return ViewApi.getBySchemaCode(schemaCode);
}
function addShardSchema(model) {
  return ViewApi.addSharedschemasetting(model);
}
function updateSharedschemasetting(model) {
  return ViewApi.updateSharedschemasetting(model);
}
function getSharedSetting(schemaCode) {
  return ViewApi.getSharedschemasetting(schemaCode);
}
function addSubmitRule(dtoList, appCode, schemaCode) {
  return ViewApi.addSubmitRule(dtoList, appCode, schemaCode);
}
function updateSubmitRule(dtoList, appCode, schemaCode) {
  return ViewApi.updateSubmitRule(dtoList, appCode, schemaCode);
}
function getSubmitRule(appCode, schemaCode) {
  return ViewApi.getSubmitRule(appCode, schemaCode);
}
const addControlV2 = (schemaCode, dto) => {
  return ViewApi.addControlV2(schemaCode, dto);
};
const deleteControl = (schemaCode, controlCode) => {
  return ViewApi.deleteControlV2(schemaCode, controlCode);
};
const updateControl = (schemaCode, dto) => {
  return ViewApi.updateControlV1(schemaCode, dto);
};
const getControlProperty = (schemaCode, controlCode, controlType) => {
  return ViewApi.getDefaultPropertyV2(schemaCode, controlCode, controlType);
};
const getAccssibleControls = (schemaCode, dto) => {
  return ViewApi.getAccessibleControlsV3(schemaCode, dto);
};
const getSubControls = (appCode, schemaCode) => {
  return ViewApi.getSubControls(appCode, schemaCode);
};
const getTypeConvetProgress = (actionId) => {
  return ViewApi.getTypeConversionProgressV2(actionId);
};
const queryConversionPreview = (schemaCode, dto) => {
  return IntegrateApi.queryConversionPreview(schemaCode, dto);
};
const getAllControls = (schemaCode) => {
  return ViewApi.getAllControlsV2(schemaCode);
};
function updateSchemaTitle(model) {
  return ViewApi.updateSchemaTitle(model);
}
function downloadTemplate(data) {
  return ImportExportApi.downloadTemplate(data);
}
function createImportTask(data) {
  return ImportExportApi.createImportTask(data);
}
function querySchemaReferenced(data) {
  return IntegrateApi.querySchemaReferenced(data);
}
function getShared(data) {
  return ViewApi.getShared(data);
}
function addSyncSchemaV3(data) {
  return ViewApi.addSyncSchemaV3(data);
}
function querySyncSchema(data) {
  return ViewApi.querySyncSchema(data);
}
function addShareSet(schemaCode, data) {
  return ViewApi.addShareSet(schemaCode, data);
}
function editShareSet(schemaCode, data) {
  return ViewApi.editShareSet(schemaCode, data);
}
function deleteShareSet(schemaCode, id) {
  return ViewApi.deleteShareSet(schemaCode, id);
}
function deleteSyncSchema(schemaCode) {
  return ViewApi.deleteSyncSchema(schemaCode);
}
function editSyncSet(schemaCode, data) {
  return ViewApi.editSyncSet(schemaCode, data);
}
function deleteSyncSet(schemaCode, id) {
  return ViewApi.deleteSyncSet(schemaCode, id);
}
function getSyncStatus(schemaCode) {
  return ViewApi.getSyncStatus(schemaCode);
}
function queryIntegrationConfig(appCode, schemaCode) {
  return ViewApi.queryIntegrationConfig({
    appCode,
    schemaCode
  });
}
function integrationOriginColumn(integrationConnection, tableName) {
  return ViewApi.integrationOriginColumn(integrationConnection, tableName);
}
function addIntegrationOrigin(integrationConfig) {
  return ViewApi.addIntegrationOrigin(integrationConfig);
}
function updateIntegrationOrigin(integrationConfig) {
  return ViewApi.updateIntegrationOrigin(integrationConfig);
}
function deleteIntegrationOrigin(id) {
  return ViewApi.deleteIntegrationOrigin(id);
}
function integrationDbtypes() {
  return ViewApi.integrationDbtypes();
}
function integrationDbtypesField(dbType) {
  return ViewApi.integrationDbtypesField(dbType);
}
function integrationHandelSync(id) {
  return ViewApi.integrationHandelSync(id);
}
function integrationDbTables(integrationConnection) {
  return ViewApi.integrationDbTables(integrationConnection);
}
function integrationUpdateName(newName, id, appCode) {
  return ViewApi.integrationUpdateName(newName, id, appCode);
}
function getReportDataSourceByAppCode(appCode) {
  return ViewApi.getReportDataSourceByAppCode(appCode);
}
function getReportDataSourceMetadata(dataSourceId) {
  return ViewApi.getReportDataSourceMetadata(dataSourceId);
}
const Schema = {
  addShardSchema,
  addSyncSchema,
  updateSyncSetting,
  getSyncRecord,
  getSyncSetting,
  getSharedSetting,
  updateSharedschemasetting,
  getSubmitRule,
  addSubmitRule,
  updateSubmitRule,
  getAllControls,
  getSubControls,
  getControlProperty,
  getAccssibleControls,
  addControlV2,
  deleteControl,
  updateControl,
  getTypeConvetProgress,
  queryConversionPreview,
  downloadTemplate,
  createImportTask,
  updateSchemaTitle,
  querySchemaReferenced,
  getShared,
  addSyncSchemaV3,
  querySyncSchema,
  addShareSet,
  editShareSet,
  deleteShareSet,
  deleteSyncSchema,
  editSyncSet,
  deleteSyncSet,
  getSyncStatus,
  queryIntegrationConfig,
  integrationOriginColumn,
  addIntegrationOrigin,
  updateIntegrationOrigin,
  deleteIntegrationOrigin,
  integrationDbtypes,
  integrationDbtypesField,
  integrationHandelSync,
  integrationDbTables,
  integrationUpdateName,
  getReportDataSourceByAppCode,
  getReportDataSourceMetadata
};
const service$g = proxyService(Schema);
function addApp$1(app) {
  const path = "/v1/app";
  return request(path, {
    method: "post",
    data: app
  });
}
function updateApp$1(app) {
  const path = "/v1/app";
  return request(path, {
    method: "put",
    data: app
  });
}
function getApps$1() {
  const path = "/v1/app";
  return request(path, {
    method: "get"
  });
}
function getAppsByPage$1(params) {
  const path = "/v1/app/page";
  return request(path, {
    method: "get",
    params
  });
}
function getApp$1(appCode) {
  const path = `/v1/app/${appCode}`;
  return request(path, {
    method: "get"
  });
}
function removeApp(appCode) {
  const path = `/v1/app/${appCode}`;
  return request(path, {
    method: "delete"
  });
}
function getUserVisitAppLastTime(appCode) {
  const path = `/v1/app/${appCode}/lasttime`;
  return request(path, {
    method: "get"
  });
}
function getAppFunctionNodes(appCode, scene) {
  const path = `/v1/functionnode/list/${appCode}`;
  return request(path, {
    method: "get",
    params: {
      scene
    }
  });
}
function getAppFunctionNode$1(code) {
  const path = `/v1/app/singlenode/${code}`;
  return request(path, {
    method: "get"
  });
}
function updateNodeSort$1(sortedCodes) {
  const path = "/v1/app/sort";
  return request(path, {
    method: "post",
    data: sortedCodes
  });
}
function getAllNodes() {
  const path = "/v1/app/node";
  return request(path, {
    method: "get"
  });
}
function getAllAppNodesByTag(tag) {
  const path = `/v1/app/node/tag/${tag}`;
  return request(path, {
    method: "get"
  });
}
function getAllAppSchemaNodes(dto) {
  const path = "/v1/app/schema";
  return request(path, {
    method: "post",
    data: dto
  });
}
function checkTemplateState(label) {
  const path = "/v1/app/template/state";
  return request(path, {
    method: "post",
    data: label
  });
}
function checkPublish() {
  const path = "/v1/app/template/verfication";
  return request(path, {
    method: "get"
  });
}
function publish$2(appCode) {
  const path = "/v1/app/template/publish";
  return request(path, {
    method: "post",
    params: {
      appCode
    }
  });
}
function install$1(externalLabel, installData) {
  const path = "/v1/app/template/install";
  return request(path, {
    method: "post",
    params: {
      externalLabel,
      installData
    }
  });
}
function init() {
  const path = "/v1/app/template/init";
  return request(path, {
    method: "post"
  });
}
function unPack(externalLabel) {
  const path = `/v1/app/template/unpack/${externalLabel}`;
  return request(path, {
    method: "get"
  });
}
function test(templateName) {
  const path = "/v1/app/template/test";
  return request(path, {
    method: "post",
    params: {
      templateName
    }
  });
}
function getFunctionNodes(appCode, scene) {
  const path = `/v2/functionnode/${appCode}`;
  return request(path, {
    method: "get",
    params: {
      scene
    }
  });
}
function getFunctionNode(code) {
  const path = `/v1/functionnode/single/${code}`;
  return request(path, {
    method: "get"
  });
}
function getAllNodesByTag(tag) {
  const path = `/v1/functionnode/tag/${tag}`;
  return request(path, {
    method: "get"
  });
}
function createFunctionNode$1(model) {
  const path = "/v1/functionnode";
  return request(path, {
    method: "post",
    data: model
  });
}
function updateFunctionNode$1(model) {
  const path = "/v1/functionnode";
  return request(path, {
    method: "put",
    data: model
  });
}
function deleteFunctionnode(code) {
  const path = `/v2/functionnode/${code}`;
  return request(path, {
    method: "delete"
  });
}
function unGroupFunctionNode$1(groupCode) {
  const path = `/v1/functionnode/ungroup/${groupCode}`;
  return request(path, {
    method: "post"
  });
}
function moveFunctionNodeToNewGroup$1(groupCode, data) {
  const path = `/v1/functionnode/movetogroup/${groupCode}`;
  return request(path, {
    method: "post",
    data
  });
}
function delRecycleApp$2(appCode) {
  const path = `/v1/recycleBin/app/${appCode}`;
  return request(path, {
    method: "delete"
  });
}
function queryRecycleApps$2(params) {
  const path = "/v1/recycleBin/query/apps";
  return request(path, {
    method: "post",
    data: params
  });
}
function restoreRecycleApp$2(appCode) {
  const path = `/v1/recycleBin/restore/app/${appCode}`;
  return request(path, {
    method: "post"
  });
}
function recycleApp$1(appCode) {
  const path = `/v1/recycleBin/recycle/app/${appCode}`;
  return request(path, {
    method: "post"
  });
}
function queryExtentionnodes$1(params) {
  const path = "/v1/extensionnode/page";
  return request(path, {
    method: "get",
    params
  });
}
function addExtentionnode$1(params) {
  const path = "/v1/extensionnode";
  return request(path, {
    method: "post",
    data: params
  });
}
function updateExtentionnode$1(params) {
  const path = `/v1/extensionnode?code=${params.code}`;
  return request(path, {
    method: "put",
    data: {
      entryPath: params.entryPath,
      extServiceName: params.extServiceName,
      name: params.name,
      sortKey: params.sortKey
    }
  });
}
function delExtentionnode$1(code) {
  const path = "/v1/extensionnode";
  return request(path, {
    method: "delete",
    params: {
      code
    }
  });
}
const AppApi = {
  addApp: addApp$1,
  updateApp: updateApp$1,
  getApps: getApps$1,
  getAppsByPage: getAppsByPage$1,
  getApp: getApp$1,
  removeApp,
  getUserVisitAppLastTime,
  getAppFunctionNodes,
  getAppFunctionNode: getAppFunctionNode$1,
  updateNodeSort: updateNodeSort$1,
  getAllNodes,
  getAllAppNodesByTag,
  getAllAppSchemaNodes,
  checkTemplateState,
  checkPublish,
  publish: publish$2,
  install: install$1,
  init,
  unPack,
  test,
  getFunctionNodes,
  getFunctionNode,
  getAllNodesByTag,
  createFunctionNode: createFunctionNode$1,
  updateFunctionNode: updateFunctionNode$1,
  deleteFunctionnode,
  unGroupFunctionNode: unGroupFunctionNode$1,
  moveFunctionNodeToNewGroup: moveFunctionNodeToNewGroup$1,
  delRecycleApp: delRecycleApp$2,
  queryRecycleApps: queryRecycleApps$2,
  restoreRecycleApp: restoreRecycleApp$2,
  recycleApp: recycleApp$1,
  queryExtentionnodes: queryExtentionnodes$1,
  addExtentionnode: addExtentionnode$1,
  updateExtentionnode: updateExtentionnode$1,
  delExtentionnode: delExtentionnode$1
};
function listCategories(displayFlag, name) {
  const path = "/v1/template/category/listCategories";
  return request(path, {
    method: "get",
    params: {
      displayFlag,
      name
    }
  });
}
function download$1(externalLabel) {
  const path = `/v1/template/download/${externalLabel}`;
  return request(path, {
    method: "get"
  });
}
function getDetail$1(id) {
  const path = "/v1/template/getDetail";
  return request(path, {
    method: "get",
    params: {
      id
    }
  });
}
function increaseInstallNum$1(templateId) {
  const path = `/v1/template/increaseInstallNum/${templateId}`;
  return request(path, {
    method: "post"
  });
}
function listByCategoryIds() {
  const path = "/v1/template/listByCategoryIds";
  return request(path, {
    method: "get"
  });
}
function pageTemplates(categoryId, name, pageIndex, pageSize) {
  const path = "/v1/template/pageTemplates";
  return request(path, {
    method: "get",
    params: {
      categoryId,
      name,
      pageIndex,
      pageSize
    }
  });
}
const TemplateApi = {
  listCategories,
  download: download$1,
  getDetail: getDetail$1,
  increaseInstallNum: increaseInstallNum$1,
  listByCategoryIds,
  pageTemplates
};
function install(externalLabel, installData) {
  return AppApi.install(externalLabel, installData);
}
function increaseInstallNum(templateId) {
  return TemplateApi.increaseInstallNum(templateId);
}
const Template = {
  install,
  increaseInstallNum
};
const service$f = proxyService(Template);
function addView(dto) {
  return ViewApi.addView(dto);
}
function deleteView(schemaCode, viewCode) {
  return ViewApi.deleteView(schemaCode, viewCode);
}
function updateView(dto) {
  return ViewApi.updateViewInfo(dto);
}
function getView(viewCode) {
  return ViewApi.getViewV2(viewCode);
}
function queryDefaultView(schemaCode) {
  return ViewApi.queryDefaultView(schemaCode);
}
function getViews(schemaCode) {
  return ViewApi.getViews(schemaCode);
}
function updateViewSetting(viewCode, settings) {
  return ViewApi.updateViewSetting(viewCode, settings);
}
const View = {
  getView,
  queryDefaultView,
  getViews,
  addView,
  deleteView,
  updateView,
  updateViewSetting
};
const service$e = proxyService(View);
function getSingleDataV2(schemaCode, objectId, appCode) {
  const path = `/v3/bizdata/query/${appCode}/${schemaCode}/${objectId}`;
  return request(path, {
    method: "get"
  });
}
function getColumnData(req) {
  const path = "/v1/bizdata/query/column";
  return request(path, {
    method: "post",
    data: req
  });
}
function getList$1(appCode, schemaCode, req) {
  const path = "/v3/bizdata/query";
  return request(path, {
    method: "post",
    data: {
      ...req
    }
  });
}
function getBizDataHistory$1(req) {
  const path = "/v4/bizdata/history/summaries";
  return request(path, {
    method: "post",
    data: req
  });
}
function getBizDataHistoryDetails$1(req) {
  const path = "/v4/bizdata/history/details";
  return request(path, {
    method: "post",
    data: req
  });
}
function dataExistFilter$1(schemaCode, objectId, filter) {
  const path = `/v1/bizdata/query/data/filter/${schemaCode}/${objectId}`;
  return request(path, {
    method: "post",
    data: filter
  });
}
function queryRelationValues(req) {
  const path = "/v1/bizdata/query/relation/values";
  return request(path, {
    method: "post",
    data: req
  });
}
function queryRelationStatus(req) {
  const path = "/v1/bizdata/query/relation/status";
  return request(path, {
    method: "post",
    data: req
  });
}
function queryRelationSubTableValues$1(req) {
  const path = "/v2/bizdatas/query/relation/subtable/values";
  return request(path, {
    method: "post",
    data: req
  });
}
function queryRelationSubTableStatus$1(req) {
  const path = "/v2/bizdatas/query/relation/subtable/status";
  return request(path, {
    method: "post",
    data: req
  });
}
function datalink$1(req) {
  const path = "/v1/bizdata/query/datalink";
  return request(path, {
    method: "post",
    data: req
  });
}
function datalinkNext(req) {
  const path = "/v2/bizdata/query/datalink";
  return request(path, {
    method: "post",
    data: req
  });
}
function getStatistics$1(req) {
  const path = "/v1/bizdata/query/statistics";
  return request(path, {
    method: "post",
    data: req
  });
}
function queryScanData$1(req) {
  const path = "/v1/bizdata/query/relation/shortlinkcode";
  return request(path, {
    method: "post",
    data: req
  });
}
function upload(schemaCode, propertyCode) {
  const path = "/v1/attachment";
  return request(path, {
    method: "post",
    params: {
      schemaCode,
      propertyCode
    }
  });
}
function preview(fileId) {
  const path = `/v1/attachment/${fileId}`;
  return request(path, {
    method: "get"
  });
}
function download(fileId) {
  const path = `/v1/attachment/download/${fileId}`;
  return request(path, {
    method: "get"
  });
}
function saveBizObject4(appCode, schemaCode, dto) {
  const path = "/v4/bizdata";
  return request(path, {
    method: "post",
    data: dto
  });
}
function batchDeleteBizObject$1(schemaCode, batchDeleteBODto) {
  const path = `/v1/bizdata/batch/${schemaCode}`;
  return request(path, {
    method: "delete",
    data: batchDeleteBODto
  });
}
function batchUpdateBizObject(dto) {
  const path = "/v1/bizdata/batch";
  return request(path, {
    method: "put",
    data: dto
  });
}
function batchSubmitBizObject$1(dto) {
  const path = "/v2/bizdatas";
  return request(path, {
    method: "post",
    data: dto
  });
}
function checkPropertyValueExist(dto) {
  const path = "/v1/bizdata/exist";
  return request(path, {
    method: "post",
    data: dto
  });
}
function moveRow(dto) {
  const path = "/v1/bizdata/sort";
  return request(path, {
    method: "post",
    data: dto
  });
}
function sortSubTable(dto) {
  const path = "/v1/bizdata/sort/subtable";
  return request(path, {
    method: "post",
    data: dto
  });
}
function getBizDataQrCode$1(appCode, schemaCode, bizObjectId) {
  const path = `/v3/bizdata/qrcode/${appCode}/${schemaCode}/${bizObjectId}`;
  return request(path, {
    method: "post",
    data: {}
  });
}
function getColumnDistinct$1(req) {
  const path = "/v1/bizdata/query/column/distinct";
  return request(path, {
    method: "post",
    data: req
  });
}
const BizDataApi = {
  getSingleDataV2,
  getColumnData,
  getList: getList$1,
  getBizDataHistory: getBizDataHistory$1,
  getBizDataHistoryDetails: getBizDataHistoryDetails$1,
  queryRelationValues,
  queryRelationStatus,
  queryRelationSubTableValues: queryRelationSubTableValues$1,
  queryRelationSubTableStatus: queryRelationSubTableStatus$1,
  dataExistFilter: dataExistFilter$1,
  datalink: datalink$1,
  datalinkNext,
  upload,
  preview,
  download,
  saveBizObject4,
  batchSubmitBizObject: batchSubmitBizObject$1,
  batchDeleteBizObject: batchDeleteBizObject$1,
  batchUpdateBizObject,
  checkPropertyValueExist,
  moveRow,
  sortSubTable,
  getStatistics: getStatistics$1,
  queryScanData: queryScanData$1,
  getBizDataQrCode: getBizDataQrCode$1,
  getColumnDistinct: getColumnDistinct$1
};
function startBulkEdit(bulkEditRequest) {
  const path = "/v1/bulkedit";
  return request(path, {
    method: "post",
    data: bulkEditRequest
  });
}
function bulkEditAll(bulkEditRequest) {
  const path = "/v1/bulkedit/start";
  return request(path, {
    method: "post",
    data: bulkEditRequest
  });
}
function progress(actionId) {
  const path = `/v1/bulkedit/progress/${actionId}`;
  return request(path, {
    method: "get"
  });
}
function progress2(memberId, schemaCode) {
  const path = `/v1/progress/unfinishedtasks/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function abort(actionId) {
  const path = `/v1/bulkedit/abort/${actionId}`;
  return request(path, {
    method: "post"
  });
}
function start(task) {
  const path = "/v1/task/start";
  return request(path, {
    method: "post",
    data: task
  });
}
const BizDataBulkApi = {
  startBulkEdit,
  bulkEditAll,
  progress,
  progress2,
  abort,
  start
};
function saveBizObjectV4(appCode, schemaCode, dto) {
  return BizDataApi.saveBizObject4(appCode, schemaCode, dto);
}
function batchDeleteBizObject(schemaCode, batchDeleteBODto) {
  return BizDataApi.batchDeleteBizObject(schemaCode, batchDeleteBODto);
}
function batchSubmitBizObject(dto) {
  return BizDataApi.batchSubmitBizObject(dto);
}
function getSingleData(schemaCode, objectId, appCode) {
  return BizDataApi.getSingleDataV2(schemaCode, objectId, appCode);
}
function getColumnDatas(req) {
  return BizDataApi.getColumnData(req);
}
function getList(appCode, schemaCode, req) {
  return BizDataApi.getList(appCode, schemaCode, req);
}
function bulkEditByFilter(bulkEditAllRequest) {
  return BizDataBulkApi.bulkEditAll(bulkEditAllRequest);
}
function bulkEditByIds(bulkEditRequest) {
  return BizDataBulkApi.startBulkEdit(bulkEditRequest);
}
function getBulkEditProgress(taskId) {
  return BizDataBulkApi.progress(taskId);
}
function abortBulkEdit(taskId) {
  return BizDataBulkApi.abort(taskId);
}
function queryUnFinishedTask(memberId, schemaCode) {
  return BizDataBulkApi.progress2(memberId, schemaCode);
}
function getBizDataHistory(req) {
  return BizDataApi.getBizDataHistory(req);
}
function getBizDataHistoryDetails(req) {
  return BizDataApi.getBizDataHistoryDetails(req);
}
function queryRelationDataValues(req) {
  return BizDataApi.queryRelationValues(req);
}
function queryRelationDataStatus(req) {
  return BizDataApi.queryRelationStatus(req);
}
function queryRelationSubTableValues(req) {
  return BizDataApi.queryRelationSubTableValues(req);
}
function queryRelationSubTableStatus(req) {
  return BizDataApi.queryRelationSubTableStatus(req);
}
function datalink(req) {
  return BizDataApi.datalinkNext(req);
}
function datalinkV1(req) {
  return BizDataApi.datalink(req);
}
function dataExistFilter(schemaCode, objectId, filter) {
  return BizDataApi.dataExistFilter(schemaCode, objectId, filter);
}
function getStatistics(req) {
  return BizDataApi.getStatistics(req);
}
function queryScanData(req) {
  return BizDataApi.queryScanData(req);
}
function getBizDataQrCode(appCode, schemaCode, bizObjectId) {
  return BizDataApi.getBizDataQrCode(appCode, schemaCode, bizObjectId);
}
function getColumnDistinct(req) {
  return BizDataApi.getColumnDistinct(req);
}
const BIZ = {
  getBizDataHistory,
  getBizDataHistoryDetails,
  saveBizObjectV4,
  batchDeleteBizObject,
  batchSubmitBizObject,
  bulkEditByIds,
  bulkEditByFilter,
  getBulkEditProgress,
  abortBulkEdit,
  queryUnFinishedTask,
  getSingleData,
  getList,
  getColumnDatas,
  getStatistics,
  queryRelationDataValues,
  queryRelationDataStatus,
  queryRelationSubTableValues,
  queryRelationSubTableStatus,
  datalink,
  datalinkV1,
  dataExistFilter,
  queryScanData,
  getBizDataQrCode,
  getColumnDistinct
};
const service$d = proxyService(BIZ);
async function getModulesByAppCode(appCode, scene) {
  return AppApi.getFunctionNodes(appCode, scene);
}
async function getSchemas(appCode, scene) {
  return AppApi.getAppFunctionNodes(appCode, scene);
}
function addApp(app) {
  return AppApi.addApp(app);
}
function deleteApp(appCode) {
  return AppApi.removeApp(appCode);
}
function updateApp(app) {
  return AppApi.updateApp(app);
}
function getApp(appCode) {
  return AppApi.getApp(appCode);
}
function getAppsByPage(params) {
  return AppApi.getAppsByPage(params);
}
function getApps() {
  return AppApi.getApps();
}
function createFunctionNode(model) {
  return AppApi.createFunctionNode(model);
}
function deleteFunctionNode(code) {
  return AppApi.deleteFunctionnode(code);
}
function updateFunctionNode(model) {
  return AppApi.updateFunctionNode(model);
}
function getAppFunctionNode(code) {
  return AppApi.getAppFunctionNode(code);
}
function copyFunctionNode(sourceCode, displayName, req) {
  return ViewApi.copySchema(sourceCode, displayName, req);
}
function getAppSyncRecords(appCode) {
  return ViewApi.getByAppCode(appCode);
}
function unGroupFunctionNode(groupCode) {
  return AppApi.unGroupFunctionNode(groupCode);
}
function moveFunctionNodeToNewGroup(groupCode, data) {
  return AppApi.moveFunctionNodeToNewGroup(groupCode, data);
}
function updateNodeSort(sortedCodes) {
  return AppApi.updateNodeSort(sortedCodes);
}
function queryFunctionNode(code) {
  return AppApi.getFunctionNode(code);
}
function getAllModulesByTag(tag) {
  return AppApi.getAllNodesByTag(tag);
}
function getAllTableModules(dto) {
  return AppApi.getAllAppSchemaNodes(dto);
}
function queryExtentionnodes(params) {
  return AppApi.queryExtentionnodes(params);
}
function addExtentionnode(params) {
  return AppApi.addExtentionnode(params);
}
function updateExtentionnode(params) {
  return AppApi.updateExtentionnode(params);
}
function delExtentionnode(code) {
  return AppApi.delExtentionnode(code);
}
const App = {
  getSchemas,
  getApp,
  getApps,
  getAppsByPage,
  addApp,
  updateApp,
  deleteApp,
  createFunctionNode,
  updateFunctionNode,
  deleteFunctionNode,
  unGroupFunctionNode,
  moveFunctionNodeToNewGroup,
  copyFunctionNode,
  getAppFunctionNode,
  queryFunctionNode,
  getModulesByAppCode,
  getAppSyncRecords,
  getAllModulesByTag,
  getAllTableModules,
  updateNodeSort,
  queryExtentionnodes,
  addExtentionnode,
  updateExtentionnode,
  delExtentionnode
};
const proxyAppService = proxyService(App);
function getViewAccessPermissionSetting$1(appCode, viewCode, subSchemaCode) {
  const path = "/v2/rolepermission/viewpermission/access";
  return request(path, {
    method: "get",
    params: {
      appCode,
      viewCode,
      subSchemaCode
    }
  });
}
function getUserViewFieldPermissions(appCode, schemaCode, viewCode, subSchemaCode) {
  const path = "/v2/rolepermission/viewpermission/user/fieldpermission";
  return request(path, {
    method: "get",
    params: {
      appCode,
      schemaCode,
      viewCode,
      subSchemaCode
    }
  });
}
function getViewFieldPermissionSetting(appCode, viewCode, subSchemaCode) {
  const path = "/v2/rolepermission/viewpermission/field";
  return request(path, {
    method: "get",
    params: {
      appCode,
      viewCode,
      subSchemaCode
    }
  });
}
function queryUserAndDeptRoles$1(query) {
  const path = "/v1/role/userdepartmentroles";
  return request(path, {
    method: "post",
    data: query
  });
}
function addViewPermissionSetting$1(model) {
  const path = "/v2/rolepermission/viewpermission";
  return request(path, {
    method: "post",
    data: model
  });
}
function updateViewPermissionSetting$1(model) {
  const path = "/v2/rolepermission/viewpermission";
  return request(path, {
    method: "put",
    data: model
  });
}
function getViewPermissionSetting$1(appCode, schemaCode, viewCode) {
  const path = "/v2/rolepermission/viewpermissionsetting";
  return request(path, {
    method: "get",
    params: {
      appCode,
      schemaCode,
      viewCode
    }
  });
}
function addRole$1(input) {
  const path = "/v1/role";
  return request(path, {
    method: "post",
    data: input
  });
}
function deleteRole$1(id) {
  const path = `/v1/role/${id}`;
  return request(path, {
    method: "delete"
  });
}
function updateRole$1(input) {
  const path = "/v1/role";
  return request(path, {
    method: "put",
    data: input
  });
}
function getRoles$1(appCode) {
  const path = `/v1/role/${appCode}`;
  return request(path, {
    method: "get"
  });
}
function queryRoleFieldPermissions$1(roleId, schemaCode) {
  const path = `/v3/rolepermission/fieldpermission/${roleId}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function addRoleAllPermissionsV3(inputList) {
  const path = "/v3/rolepermission/all";
  return request(path, {
    method: "post",
    data: inputList
  });
}
function updateRoleAllPermissionsV3(inputList) {
  const path = "/v3/rolepermission/all";
  return request(path, {
    method: "put",
    data: inputList
  });
}
function updatePermissionScene$1(id, flag) {
  const path = `/v3/rolepermission/scene/${id}/${flag}`;
  return request(path, {
    method: "put"
  });
}
function roleUserCount(appCode) {
  const path = `/v1/role/user/count/${appCode}`;
  return request(path, {
    method: "get"
  });
}
function getUserByRole(query) {
  const path = "/v1/role/user";
  return request(path, {
    method: "post",
    data: query
  });
}
function getUserAllRoles(memberId, appCode) {
  const path = "/v1/role/user/all";
  return request(path, {
    method: "get",
    params: {
      memberId,
      appCode
    }
  });
}
function getUserRole(appCode, userId, roleId, sorts) {
  const path = "/v1/role/userrole";
  return request(path, {
    method: "get",
    params: {
      appCode,
      userId,
      roleId,
      sorts
    }
  });
}
function addUserRoleRelation(input) {
  const path = "/v1/role/userrole";
  return request(path, {
    method: "post",
    data: input
  });
}
function getListUserRole(queryList) {
  const path = "/v1/role/userroleuser/role/list";
  return request(path, {
    method: "post",
    data: queryList
  });
}
function addUserDifferentRoleRelation(model) {
  const path = "/v1/role/userrole/differentroles";
  return request(path, {
    method: "post",
    data: model
  });
}
function deleteUserRoleRelation(input) {
  const path = "/v1/role/userrole/delete";
  return request(path, {
    method: "post",
    data: input
  });
}
function deleteUserAllRoles(input) {
  const path = "/v1/role/userrole/delete/all";
  return request(path, {
    method: "post",
    data: input
  });
}
function deleteListUserAllRoles(inputList) {
  const path = "/v1/role/userrole/delete/list";
  return request(path, {
    method: "post",
    data: inputList
  });
}
function getDepartmentRoles(appCode, departmentIds) {
  const path = "/v1/role/departmentrole/list";
  return request(path, {
    method: "post",
    data: departmentIds,
    params: {
      appCode
    }
  });
}
function addDepartmentRoleRelation(input) {
  const path = "/v1/role/departmentrole";
  return request(path, {
    method: "post",
    data: input
  });
}
function addDepartmentDifferentRoleRelation(model) {
  const path = "/v1/role/departmentrole/differentroles";
  return request(path, {
    method: "post",
    data: model
  });
}
function deleteDepartmentRoleRelation(input) {
  const path = "/v1/role/departmentrole/delete";
  return request(path, {
    method: "post",
    data: input
  });
}
function deleteDepartmentAllRoles(input) {
  const path = "/v1/role/departmentrole/delete/all";
  return request(path, {
    method: "post",
    data: input
  });
}
function getCurrentUserManagerApp() {
  const path = "/v1/role/userrole/currentuser/managerapp";
  return request(path, {
    method: "get"
  });
}
function userHasOwnerApp(userId) {
  const path = `/v1/role/checkownerapp/${userId}`;
  return request(path, {
    method: "get"
  });
}
function getUserOwnerAppList(memberId) {
  const path = `/v1/role/userownerapps/${memberId}`;
  return request(path, {
    method: "get"
  });
}
function assignUserOwnerRole(list) {
  const path = "/v1/role/assignuserownerrole";
  return request(path, {
    method: "post",
    data: list
  });
}
function changeAppOwner$1(changeOwnerModel) {
  const path = "/v1/role/changeappowner";
  return request(path, {
    method: "post",
    data: changeOwnerModel
  });
}
function removeGroup(changeOwnerModel) {
  const path = "/v1/role/removegroup";
  return request(path, {
    method: "post",
    data: changeOwnerModel
  });
}
function getUserAndDeptAvatars(appCode, topNum) {
  const path = `/v1/role/userdepartmentroles/avatars/${appCode}/${topNum}`;
  return request(path, {
    method: "get"
  });
}
function getRoleSchemaRelationList(schemaCode, roleId) {
  const path = "/v1/role/schemarole/list";
  return request(path, {
    method: "get",
    params: {
      schemaCode,
      roleId
    }
  });
}
function addRoleSchemaRelation(dto) {
  const path = "/v1/role/schemarole";
  return request(path, {
    method: "post",
    data: dto
  });
}
function deleteRoleSchemaRelation(schemaCode, roleId) {
  const path = "/v1/role/schemarole";
  return request(path, {
    method: "delete",
    params: {
      schemaCode,
      roleId
    }
  });
}
function getAccessPermissions(roleId, schemaCode) {
  const path = `/v1/rolepermission/accesspermission/${roleId}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getSystemAccessPermissions() {
  const path = "/v1/rolepermission/systemaccess/all";
  return request(path, {
    method: "get"
  });
}
function getUserAccessPermission$1(appCode, schemaCode) {
  const path = `/v1/rolepermission/accesspermission/user/${appCode}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function addAccessPermissions(input) {
  const path = "/v1/rolepermission/accesspermission";
  return request(path, {
    method: "post",
    data: input
  });
}
function updateAccessPermissions(input) {
  const path = "/v1/rolepermission/accesspermission";
  return request(path, {
    method: "put",
    data: input
  });
}
function getFieldPermissions(roleId, schemaCode) {
  const path = `/v1/rolepermission/fieldpermission/${roleId}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getUserFieldPermission(appCode, schemaCode) {
  const path = `/v1/rolepermission/fieldpermission/user/${appCode}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getUserFieldPermissionV3$1(appCode, schemaCode) {
  const path = `/v3/rolepermission/fieldpermission/user/${appCode}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function addFieldPermissions(input) {
  const path = "/v1/rolepermission/fieldpermission";
  return request(path, {
    method: "post",
    data: input
  });
}
function updateFieldPermissions(input) {
  const path = "/v1/rolepermission/fieldpermission";
  return request(path, {
    method: "put",
    data: input
  });
}
function getDataPermission$1(roleId, schemaCode) {
  const path = `/v1/rolepermission/datapermission/${roleId}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getDataPermissionNew(roleId, schemaCode) {
  const path = `/v2/rolepermission/datapermission/${roleId}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function updateDataPermission(dataPermission) {
  const path = "/v1/rolepermission/datapermission";
  return request(path, {
    method: "put",
    data: dataPermission
  });
}
function getSystemDataPermissions() {
  const path = "/v1/rolepermission/datapermission/system";
  return request(path, {
    method: "get"
  });
}
function getSystemFieldPermissions() {
  const path = "/v1/rolepermission/systemfield/all";
  return request(path, {
    method: "get"
  });
}
function addRoleAllPermissions$1(inputList) {
  const path = "/v1/rolepermission/all";
  return request(path, {
    method: "post",
    data: inputList
  });
}
function addRoleAllPermissionsNew(inputList) {
  const path = "/v2/rolepermission/all";
  return request(path, {
    method: "post",
    data: inputList
  });
}
function updateRoleAllPermissionsNew(inputList) {
  const path = "/v2/rolepermission/all";
  return request(path, {
    method: "put",
    data: inputList
  });
}
function getRoleAllPermissions(roleId) {
  const path = `/v1/rolepermission/role/schema/permission/all/${roleId}`;
  return request(path, {
    method: "get"
  });
}
function getRoleAllPermissionsNew(roleId) {
  const path = `/v2/rolepermission/role/schema/permission/all/${roleId}`;
  return request(path, {
    method: "get"
  });
}
function getRoleAllPermissionsV3(roleId) {
  const path = `/v3/rolepermission/role/schema/permission/all/${roleId}`;
  return request(path, {
    method: "get"
  });
}
function generateRoleAndPermission4Template(model) {
  const path = "/v2/rolepermission/roleandpermission4template";
  return request(path, {
    method: "post",
    data: model
  });
}
function getUserViewAccessPermissions(appCode, schemaCode, viewCode, subSchemaCode) {
  const path = "/v2/rolepermission/viewpermission/user/access";
  return request(path, {
    method: "get",
    params: {
      appCode,
      schemaCode,
      viewCode,
      subSchemaCode
    }
  });
}
const AuthApi = {
  addRole: addRole$1,
  updateRole: updateRole$1,
  getRoles: getRoles$1,
  deleteRole: deleteRole$1,
  roleUserCount,
  getUserByRole,
  getUserAllRoles,
  getUserRole,
  addUserRoleRelation,
  getListUserRole,
  addUserDifferentRoleRelation,
  deleteUserRoleRelation,
  deleteUserAllRoles,
  deleteListUserAllRoles,
  getDepartmentRoles,
  addDepartmentRoleRelation,
  addDepartmentDifferentRoleRelation,
  deleteDepartmentRoleRelation,
  deleteDepartmentAllRoles,
  getCurrentUserManagerApp,
  userHasOwnerApp,
  getUserOwnerAppList,
  assignUserOwnerRole,
  changeAppOwner: changeAppOwner$1,
  removeGroup,
  queryUserAndDeptRoles: queryUserAndDeptRoles$1,
  getUserAndDeptAvatars,
  getRoleSchemaRelationList,
  addRoleSchemaRelation,
  deleteRoleSchemaRelation,
  getAccessPermissions,
  getSystemAccessPermissions,
  getUserAccessPermission: getUserAccessPermission$1,
  addAccessPermissions,
  updateAccessPermissions,
  getFieldPermissions,
  queryRoleFieldPermissions: queryRoleFieldPermissions$1,
  getUserFieldPermission,
  getUserFieldPermissionV3: getUserFieldPermissionV3$1,
  addFieldPermissions,
  updateFieldPermissions,
  getDataPermission: getDataPermission$1,
  getDataPermissionNew,
  updateDataPermission,
  getSystemDataPermissions,
  getSystemFieldPermissions,
  addRoleAllPermissions: addRoleAllPermissions$1,
  addRoleAllPermissionsNew,
  updateRoleAllPermissionsNew,
  addRoleAllPermissionsV3,
  updateRoleAllPermissionsV3,
  getRoleAllPermissions,
  getRoleAllPermissionsNew,
  getRoleAllPermissionsV3,
  generateRoleAndPermission4Template,
  getViewPermissionSetting: getViewPermissionSetting$1,
  getViewFieldPermissionSetting,
  getViewAccessPermissionSetting: getViewAccessPermissionSetting$1,
  addViewPermissionSetting: addViewPermissionSetting$1,
  updateViewPermissionSetting: updateViewPermissionSetting$1,
  getUserViewFieldPermissions,
  getUserViewAccessPermissions,
  updatePermissionScene: updatePermissionScene$1
};
function changeAppOwner(changeOwnerModel) {
  return AuthApi.changeAppOwner(changeOwnerModel);
}
function getUserAccessPermission(appCode, schemaCode) {
  return AuthApi.getUserAccessPermission(appCode, schemaCode);
}
function getUserFieldPermissionV3(appCode, schemaCode) {
  return AuthApi.getUserFieldPermissionV3(appCode, schemaCode);
}
function deleteAllRolesOfUser(input) {
  return AuthApi.deleteUserAllRoles(input);
}
function getAllRolesOfUser(memberId, appCode) {
  return AuthApi.getUserAllRoles(memberId, appCode);
}
function queryUserAndDeptRoles(query) {
  return AuthApi.queryUserAndDeptRoles(query);
}
function addViewPermissionSetting(model) {
  return AuthApi.addViewPermissionSetting(model);
}
function updateViewPermissionSetting(model) {
  return AuthApi.updateViewPermissionSetting(model);
}
function getViewPermissionSetting(appCode, schemaCode, viewCode) {
  return AuthApi.getViewPermissionSetting(appCode, schemaCode, viewCode);
}
function queryViewFieldPermissionSetting(appCode, viewCode, subSchemaCode) {
  return AuthApi.getViewFieldPermissionSetting(appCode, viewCode, subSchemaCode);
}
function queryUserViewFieldPermissions(appCode, schemaCode, viewCode, subSchemaCode) {
  return AuthApi.getUserViewFieldPermissions(appCode, schemaCode, viewCode, subSchemaCode);
}
function getViewAccessPermissionSetting(appCode, viewCode, subSchemaCode) {
  return AuthApi.getViewAccessPermissionSetting(appCode, viewCode, subSchemaCode);
}
function addRole(input) {
  return AuthApi.addRole(input);
}
function deleteRole(id) {
  return AuthApi.deleteRole(id);
}
function updateRole(input) {
  return AuthApi.updateRole(input);
}
function getRoles(appCode) {
  return AuthApi.getRoles(appCode);
}
function addRoleAllPermissions(inputList) {
  return AuthApi.addRoleAllPermissionsV3(inputList);
}
function updateRoleAllPermissions(inputList) {
  return AuthApi.updateRoleAllPermissionsV3(inputList);
}
function queryRoleFieldPermissions(roleId, schemaCode) {
  return AuthApi.queryRoleFieldPermissions(roleId, schemaCode);
}
function updatePermissionScene(id, flag) {
  return AuthApi.updatePermissionScene(id, flag);
}
const Acl = {
  addRole,
  deleteRole,
  updateRole,
  getRoles,
  getAllRolesOfUser,
  deleteAllRolesOfUser,
  changeAppOwner,
  queryUserAndDeptRoles,
  getUserAccessPermission,
  getUserFieldPermissionV3,
  queryViewFieldPermissionSetting,
  getViewAccessPermissionSetting,
  addViewPermissionSetting,
  getViewPermissionSetting,
  queryUserViewFieldPermissions,
  updateViewPermissionSetting,
  addRoleAllPermissions,
  updateRoleAllPermissions,
  queryRoleFieldPermissions,
  updatePermissionScene
};
const service$c = proxyService(Acl);
function getExternalInfo$2(shortLinkCode) {
  return request(`/v1/externallink/${shortLinkCode}`, {
    method: "get",
    data: {}
  });
}
function generateShortLink(appCode, schemaCode, viewCode) {
  return request(`/v1/externallink/${appCode}/${schemaCode}/${viewCode}`, {
    method: "get",
    data: {}
  });
}
function getSubTableControl$1(shortLinkCode, schemaCode) {
  return request(`/v1/externallink/control/${shortLinkCode}/${schemaCode}`, {
    method: "get",
    data: {}
  });
}
const getExternalBizData$2 = (shortLinkCode, sign, charset) => {
  return request.get("/v1/externaldata/bizdata", {
    params: {
      shortLinkCode,
      sign,
      c: charset
    }
  });
};
function postSharedFormData$2(shortLinkCode, data) {
  return request.post(`/v1/externallink/${shortLinkCode}`, {
    data
  });
}
const ExternalLinkApi$1 = {
  getExternalInfo: getExternalInfo$2,
  generateShortLink,
  getSubTableControl: getSubTableControl$1,
  getExternalBizData: getExternalBizData$2,
  postSharedFormData: postSharedFormData$2
};
function getShortLink(appCode, schemaCode, viewCode) {
  return ExternalLinkApi$1.generateShortLink(appCode, schemaCode, viewCode);
}
function getExternalInfo$1(shortLinkCode) {
  return ExternalLinkApi$1.getExternalInfo(shortLinkCode);
}
function getSubTableControl(shortLinkCode, schemaCode) {
  return ExternalLinkApi$1.getSubTableControl(shortLinkCode, schemaCode);
}
const getExternalBizData$1 = (shortLinkCode, sign, charset) => {
  return ExternalLinkApi$1.getExternalBizData(shortLinkCode, sign, charset);
};
function postSharedFormData$1(shortLinkCode, data) {
  return ExternalLinkApi$1.postSharedFormData(shortLinkCode, data);
}
const ExternalLink = {
  getShortLink,
  getExternalInfo: getExternalInfo$1,
  getSubTableControl,
  getExternalBizData: getExternalBizData$1,
  postSharedFormData: postSharedFormData$1
};
const service$b = proxyService(ExternalLink);
function exportDataBySection(exportRequest) {
  return ImportExportApi.exportDataBySection(exportRequest);
}
function exportSectionData(exportRequest) {
  return ImportExportApi.exportSectionData(exportRequest);
}
function getExportProgress(actionId) {
  return ImportExportApi.getExportProgress(actionId);
}
function getImportTemplate(schemaCode, viewCode, importOption) {
  return ImportExportApi.getImportTemplate(schemaCode, viewCode, importOption);
}
function getImportColumnsOld(schemaCode, importOption) {
  return ImportExportApi.getImportColumnsOld(schemaCode, importOption);
}
function getImportColumns(schemaCode) {
  return ImportExportApi.getImportColumns(schemaCode);
}
function createPreviewData(viewRowCount, formData) {
  return ImportExportApi.createPreviewData(viewRowCount, formData);
}
function startImport(importRequest) {
  return ImportExportApi.start(importRequest);
}
function updateImport(importRequest) {
  return ImportExportApi.update(importRequest);
}
function startCreateSchema(req) {
  return ImportExportApi.createSchemaStart(req);
}
function getSchemaCreateProgress(actionId) {
  return ImportExportApi.createSchemaProgress(actionId);
}
function getImportProgress(actionId) {
  return ImportExportApi.getImportProgress(actionId);
}
function queryImportExportProgress(actionId) {
  return ImportExportApi.queryImportExportProgress(actionId);
}
const ImpExp = {
  exportDataBySection,
  exportSectionData,
  getImportProgress,
  startImport,
  updateImport,
  getExportProgress,
  getImportColumnsOld,
  getImportColumns,
  createPreviewData,
  startCreateSchema,
  getSchemaCreateProgress,
  queryImportExportProgress,
  getImportTemplate
};
const service$a = proxyService(ImpExp);
function getDepartment(departmentId) {
  const path = `/v1/org/department/${departmentId}`;
  return request(path, {
    method: "get"
  });
}
function getChildMembers$2(departmentId) {
  const path = `/v1/org/department/${departmentId}/members`;
  return request(path, {
    method: "get"
  });
}
function search(queryOption) {
  const path = "/v1/org/department/page";
  return request(path, {
    method: "post",
    data: queryOption
  });
}
function getChildDepartments(departmentId) {
  const path = `/v1/org/department/${departmentId}/departments`;
  return request(path, {
    method: "get"
  });
}
function getChildUnits$2(departmentId) {
  const path = `/v1/org/department/${departmentId}/units`;
  return request(path, {
    method: "get"
  });
}
function getDetail(memberId) {
  const path = `/v1/org/member/detail/${memberId}`;
  return request(path, {
    method: "get"
  });
}
function queryCorpOwners$2(corpId) {
  const path = "//v1/org/member/listCorpOwners";
  return request(path, {
    method: "get",
    params: {
      corpId
    }
  });
}
function getMember(memberId) {
  const path = `/v1/org/member/${memberId}`;
  return request(path, {
    method: "get"
  });
}
function errorCode$1() {
  const path = "/v1/heartbeat/errorCode";
  return request(path, {
    method: "get"
  });
}
function addDepartment(addOption) {
  const path = "/v1/org/department";
  return request(path, {
    method: "post",
    data: addOption
  });
}
function update(updateOption) {
  const path = "/v1/org/department";
  return request(path, {
    method: "put",
    data: updateOption
  });
}
function initTreePath() {
  const path = "/v1/org/department/initTreePath";
  return request(path, {
    method: "post"
  });
}
function listChildUnits(option) {
  const path = "/v1/org/department/listChildUnits";
  return request(path, {
    method: "post",
    data: option
  });
}
function getRoot() {
  const path = "/v1/org/department/root";
  return request(path, {
    method: "get"
  });
}
function createOrg(departmentOption) {
  const path = "/v1/org/department/root";
  return request(path, {
    method: "post",
    data: departmentOption
  });
}
function resetSortKey(option) {
  const path = "/v1/org/department/sortKey/reset";
  return request(path, {
    method: "put",
    data: option
  });
}
function deleteDepartment(departmentId) {
  const path = `/v1/org/department/${departmentId}`;
  return request(path, {
    method: "delete"
  });
}
function addMembers(departmentId, memberIds) {
  const path = `/v1/org/department/${departmentId}/members`;
  return request(path, {
    method: "put",
    data: memberIds
  });
}
function changeDepartment$2(option) {
  const path = "/v1/org/member/departments";
  return request(path, {
    method: "put",
    data: option
  });
}
function getMemberPrivacy() {
  const path = "/v1/org/member/getMemberPrivacy";
  return request(path, {
    method: "get"
  });
}
function listByIds(ids) {
  const path = "/v1/org/member/listByIds";
  return request(path, {
    method: "post",
    data: ids
  });
}
function listDepartmentMember(departmentId, onlyDept) {
  const path = "/v1/org/member/listDepartmentMember";
  return request(path, {
    method: "get",
    params: {
      departmentId,
      onlyDept
    }
  });
}
function listInactiveMembers(departmentId) {
  const path = `/v1/org/member/listInactiveMembers/${departmentId}`;
  return request(path, {
    method: "get"
  });
}
function listMyDepartmentMember(option) {
  const path = "/v1/org/member/listMyDepartmentMember";
  return request(path, {
    method: "post",
    data: option
  });
}
function changeOwner(option) {
  const path = "/v1/org/member/operate/changeOwner";
  return request(path, {
    method: "put",
    data: option
  });
}
function deleteHandoverRecords(memberId) {
  const path = "/v1/org/member/operate/deleteHandoverRecords";
  return request(path, {
    method: "put",
    params: {
      memberId
    }
  });
}
function listHandoverRecords(memberId) {
  const path = "/v1/org/member/operate/listHandoverRecords";
  return request(path, {
    method: "get",
    params: {
      memberId
    }
  });
}
function updateHandoverRecords(options) {
  const path = "/v1/org/member/operate/updateHandoverRecords";
  return request(path, {
    method: "put",
    data: options
  });
}
function pageMembers(queryOption) {
  const path = "/v1/org/member/page";
  return request(path, {
    method: "post",
    data: queryOption
  });
}
function setMemberPrivacy(option) {
  const path = "/v1/org/member/setMemberPrivacy";
  return request(path, {
    method: "post",
    data: option
  });
}
function getDepartmentsByIds$2(ids) {
  const path = "/v1/org/department/listByIds";
  return request(path, {
    method: "post",
    data: ids
  });
}
function getDepartmentsByName$2(option) {
  const path = "/v1/org/department/listByName";
  return request(path, {
    method: "post",
    data: option
  });
}
function getMembersByIds$2(ids) {
  const path = "/v1/org/member/listByIds";
  return request(path, {
    method: "post",
    data: ids
  });
}
function getMembersByName$2(option) {
  const path = "/v1/org/member/listByName";
  return request(path, {
    method: "post",
    data: option
  });
}
function getMembersMainDept$2(ids) {
  const path = "/v1/org/member/mainDepartment/batch";
  return request(path, {
    method: "post",
    data: ids
  });
}
function getMemberEntryLog$2(params) {
  return request("/v1/org/memberEntryLog/query/page", {
    method: "post",
    data: params
  });
}
function getDepartmentManager$2(id, recursively = false) {
  const path = "/v1/org/department/manager";
  return request(path, {
    method: "get",
    params: {
      recursively,
      departmentId: id
    }
  });
}
function getMemberManager$2(id, recursively = false) {
  const path = "/v1/org/member/manager";
  return request(path, {
    method: "get",
    params: {
      recursively,
      memberId: id
    }
  });
}
const OrgApi$1 = {
  errorCode: errorCode$1,
  addDepartment,
  update,
  initTreePath,
  listChildUnits,
  search,
  getRoot,
  createOrg,
  resetSortKey,
  getDepartment,
  deleteDepartment,
  getChildDepartments,
  getChildMembers: getChildMembers$2,
  addMembers,
  getChildUnits: getChildUnits$2,
  changeDepartment: changeDepartment$2,
  getDetail,
  getMemberPrivacy,
  listByIds,
  listDepartmentMember,
  listInactiveMembers,
  listMyDepartmentMember,
  changeOwner,
  deleteHandoverRecords,
  listHandoverRecords,
  updateHandoverRecords,
  pageMembers,
  setMemberPrivacy,
  getMember,
  queryCorpOwners: queryCorpOwners$2,
  getDepartmentsByIds: getDepartmentsByIds$2,
  getDepartmentsByName: getDepartmentsByName$2,
  getMembersByIds: getMembersByIds$2,
  getMembersByName: getMembersByName$2,
  getMembersMainDept: getMembersMainDept$2,
  getMemberEntryLog: getMemberEntryLog$2,
  getDepartmentManager: getDepartmentManager$2,
  getMemberManager: getMemberManager$2
};
function inviteMembers$2(members) {
  const path = "/v1/contacts/members";
  return request(path, {
    method: "put",
    data: members
  });
}
function autoInvite(userInfo) {
  const path = "/v1/contacts/member/autoInvite";
  return request(path, {
    method: "put",
    data: userInfo
  });
}
function handoverMembers(memberId) {
  const path = `/v1/contacts/member/handover/${memberId}`;
  return request(path, {
    method: "put"
  });
}
function leaveCorp$2(corpId, memberId) {
  const path = "/v1/contacts/member/leave";
  return request(path, {
    method: "post",
    params: {
      corpId,
      memberId
    }
  });
}
function kickMembers(membersPkList) {
  const path = "/v1/contacts/member/remove";
  return request(path, {
    method: "post",
    data: membersPkList
  });
}
function checkKickMembers(membersPkList) {
  const path = "/v1/contacts/member/remove/check";
  return request(path, {
    method: "post",
    data: membersPkList
  });
}
function getCorpMembers$2(option) {
  const path = "/v1/contacts/members/list";
  return request(path, {
    method: "post",
    data: option
  });
}
function reactiveMembers(membersPkList) {
  const path = "/v1/contacts/members/reactive";
  return request(path, {
    method: "post",
    data: membersPkList
  });
}
function errorCode() {
  const path = "/v1/heartbeat/errorCode";
  return request(path, {
    method: "get"
  });
}
const ContactApi = {
  autoInvite,
  handoverMembers,
  leaveCorp: leaveCorp$2,
  kickMembers,
  checkKickMembers,
  inviteMembers: inviteMembers$2,
  getCorpMembers: getCorpMembers$2,
  reactiveMembers,
  errorCode
};
function getMemberInfo$1(memberId) {
  return OrgApi$1.getDetail(memberId);
}
function getOrgRoot$1() {
  return OrgApi$1.getRoot();
}
function queryChildUnits$1(option) {
  return OrgApi$1.listChildUnits(option);
}
function getMyDepartmentMembers$1(option) {
  return OrgApi$1.listMyDepartmentMember(option);
}
function getChildDepts$1(departmentId) {
  return OrgApi$1.getChildDepartments(departmentId);
}
function queryMembers$1(queryOption) {
  return OrgApi$1.pageMembers(queryOption);
}
function getDeptInfo$1(departmentId) {
  return OrgApi$1.getDepartment(departmentId);
}
function queryDepts$1(queryOption) {
  return OrgApi$1.search(queryOption);
}
function getChildUnits$1(departmentId) {
  return OrgApi$1.getChildUnits(departmentId);
}
function getChildMembers$1(departmentId) {
  return OrgApi$1.getChildMembers(departmentId);
}
function getCorpMembers$1(option) {
  return ContactApi.getCorpMembers(option);
}
function leaveCorp$1(corpId, memberId) {
  return ContactApi.leaveCorp(corpId, memberId);
}
function inviteMembers$1(members) {
  return ContactApi.inviteMembers(members);
}
function changeDepartment$1(option) {
  return OrgApi$1.changeDepartment(option);
}
function queryCorpOwners$1(corpId) {
  return OrgApi$1.queryCorpOwners(corpId);
}
function getDepartmentsByIds$1(ids) {
  return OrgApi$1.getDepartmentsByIds(ids);
}
function getDepartmentsByName$1(option) {
  return OrgApi$1.getDepartmentsByName(option);
}
function getMembersByIds$1(ids) {
  return OrgApi$1.getMembersByIds(ids);
}
function getMembersByName$1(option) {
  return OrgApi$1.getMembersByName(option);
}
function getMembersMainDept$1(ids) {
  return OrgApi$1.getMembersMainDept(ids);
}
function getMemberEntryLog$1(params) {
  return OrgApi$1.getMemberEntryLog(params);
}
function getDepartmentManager$1(id, recursively) {
  return OrgApi$1.getDepartmentManager(id, recursively);
}
function getMemberManager$1(id, recursively) {
  return OrgApi$1.getMemberManager(id, recursively);
}
const Org = {
  leaveCorp: leaveCorp$1,
  inviteMembers: inviteMembers$1,
  getCorpMembers: getCorpMembers$1,
  queryChildUnits: queryChildUnits$1,
  queryDepts: queryDepts$1,
  getOrgRoot: getOrgRoot$1,
  getDeptInfo: getDeptInfo$1,
  getMemberInfo: getMemberInfo$1,
  getChildDepts: getChildDepts$1,
  getChildMembers: getChildMembers$1,
  getChildUnits: getChildUnits$1,
  changeDepartment: changeDepartment$1,
  getMyDepartmentMembers: getMyDepartmentMembers$1,
  queryMembers: queryMembers$1,
  queryCorpOwners: queryCorpOwners$1,
  getDepartmentsByIds: getDepartmentsByIds$1,
  getDepartmentsByName: getDepartmentsByName$1,
  getMembersByIds: getMembersByIds$1,
  getMembersByName: getMembersByName$1,
  getMembersMainDept: getMembersMainDept$1,
  getMemberEntryLog: getMemberEntryLog$1,
  getDepartmentManager: getDepartmentManager$1,
  getMemberManager: getMemberManager$1
};
const service$9 = proxyService(Org);
function getPreference$1(target, scope) {
  const path = `/v1/preference/${target}/${scope}`;
  return request(path, {
    method: "get"
  });
}
function removePreference$1(target, scope) {
  const path = `/v1/preference/${target}/${scope}`;
  return request(path, {
    method: "delete"
  });
}
function savePreferenceItem(target, preferenceItemModel) {
  const path = `/v1/preference/${target}`;
  return request(path, {
    method: "post",
    data: preferenceItemModel
  });
}
function removePreference2(target) {
  const path = `/v1/preference/${target}`;
  return request(path, {
    method: "delete"
  });
}
function savePreferenceItems(target, dto) {
  const path = `/v1/preference/list/${target}`;
  return request(path, {
    method: "post",
    data: dto
  });
}
function removePreferenceItem(target, scope, key) {
  const path = `/v1/preference/${target}/${scope}/${key}`;
  return request(path, {
    method: "delete"
  });
}
const PreferenceApi = {
  getPreference: getPreference$1,
  removePreference: removePreference$1,
  savePreferenceItem,
  removePreference2,
  savePreferenceItems,
  removePreferenceItem
};
function getPreference(target, scope) {
  return PreferenceApi.getPreference(target, scope);
}
function updatePreference(target, preferenceItemModel) {
  return PreferenceApi.savePreferenceItem(target, preferenceItemModel);
}
function removePreference(target, memberId) {
  return PreferenceApi.removePreference(target, memberId);
}
const Preference = {
  getPreference,
  updatePreference,
  removePreference
};
const service$8 = proxyService(Preference);
function submitWorkflowBizData$1(request$12) {
  const path = "/v2/workflow/todos/approval";
  return request(path, {
    method: "post",
    data: request$12
  });
}
function forward$1(request$12) {
  const path = "/v1/workflow/forward";
  return request(path, {
    method: "post",
    data: request$12
  });
}
function submit$1(request$12) {
  const path = "/v2/workflow/submission";
  return request(path, {
    method: "post",
    data: request$12
  });
}
function getWorkflowInstanceSteps$1(request$12) {
  const path = "/v2/workflow/steps/query";
  return request(path, {
    method: "post",
    data: request$12
  });
}
function getControlVOs$1(schemaCode, showAll) {
  const path = `/v1/workflow/designer/properties/${schemaCode}`;
  return request(path, {
    method: "get",
    params: {
      showAll
    }
  });
}
function getWorkflowClauses$1(request$12) {
  const path = "/v1/workflow/clauses";
  return request(path, {
    method: "post",
    data: request$12
  });
}
function validateCreateWorkflow$1(request$12) {
  const path = "/v1/workflow/validation";
  return request(path, {
    method: "post",
    data: request$12
  });
}
function open$1(workflowCode) {
  const path = `/v1/workflow/open/${workflowCode}`;
  return request(path, {
    method: "put"
  });
}
function close$1(workflowCode) {
  const path = `/v1/workflow/close/${workflowCode}`;
  return request(path, {
    method: "put"
  });
}
function remove$1(workflowCode) {
  const path = `/v1/workflow/${workflowCode}`;
  return request(path, {
    method: "delete"
  });
}
function getRelatedInfomationOfSchema$1(appCode, schemaCode) {
  const path = `/v1/workflow/designer/schema/${appCode}/${schemaCode}`;
  return request(path, {
    method: "get"
  });
}
function getActivityInfoByWorkItemId$1(workItemId) {
  const path = "/v1/workflow/view/context";
  return request(path, {
    method: "get",
    params: {
      workitemId: workItemId
    }
  });
}
function getBizData(workItemId) {
  const path = `/v1/workflow/bizdata/query/${workItemId}`;
  return request(path, {
    method: "get"
  });
}
function getWorkflowDesignerInfo$1(workflowCode, trigger) {
  const path = `/v1/workflow/designer/${workflowCode}/${trigger}`;
  return request(path, {
    method: "get"
  });
}
function getVisibleControls(request$12) {
  const path = "/v1/workflow/accessible/controls";
  return request(path, {
    method: "post",
    data: request$12
  });
}
function getFieldPermission(request$12) {
  const path = "/v1/workflow/fieldpermission";
  return request(path, {
    method: "post",
    data: request$12
  });
}
function getDataPermission(instanceId, workItemId, memberId) {
  const path = "/v1/workflow/datapermission";
  return request(path, {
    method: "get",
    params: {
      instanceId,
      workItemId,
      memberId
    }
  });
}
function getAccessPermission(schemaCode, subSchemaCode, workItemId, memberId) {
  const path = "/v1/workflow/accesspermission";
  return request(path, {
    method: "get",
    params: {
      schemaCode,
      subSchemaCode,
      workItemId,
      memberId
    }
  });
}
function getActivityInfo(workitemId) {
  const path = "/v1/workflow/view/context";
  return request(path, {
    method: "get",
    params: {
      workitemId
    }
  });
}
function getWorklfowLog(schemaCode, bizObjectId) {
  const path = `/v1/workflow/mobile/steps/${schemaCode}/${bizObjectId}`;
  return request(path, {
    method: "get"
  });
}
function addWorkflow(template) {
  const path = "/v1/workflow";
  return request(path, {
    method: "post",
    data: template
  });
}
function publish$1(template) {
  const path = "/v1/workflow/published";
  return request(path, {
    method: "put",
    data: template
  });
}
function getTemplate(workflowCode, workflowVersion) {
  const path = `/v1/workflow/template/${workflowCode}/${workflowVersion})`;
  return request(path, {
    method: "get"
  });
}
function getWorkflowControls$1(propertyCode, workItemId, subSchemaCode) {
  const path = "/v2/workflow/accessible/controls";
  return request(path, {
    method: "get",
    params: {
      propertyCode,
      workItemId,
      subSchemaCode
    }
  });
}
function getWorkflowFieldPermission$1(propertyCode, workItemId, subSchemaCode) {
  const path = "/v2/workflow/fieldpermission";
  return request(path, {
    method: "get",
    params: {
      propertyCode,
      workItemId,
      subSchemaCode
    }
  });
}
function getWorkflowAccessPermission$1(propertyCode, workItemId, memberId) {
  const path = "/v2/workflow/accesspermission";
  return request(path, {
    method: "get",
    params: {
      propertyCode,
      workItemId,
      memberId
    }
  });
}
function getWorkflowRecords$1(params) {
  const data = params;
  const path = "/v2/workflow/bizdata/relation";
  return request(path, {
    method: "post",
    data
  });
}
function getWorkflowRecordById$1(workItemId) {
  const path = `/v2/workflow/bizdata/query/${workItemId}`;
  return request(path, {
    method: "get"
  });
}
function saveWorkflowSubBizObject$1(dto, workitemId) {
  const path = `/v2/workflow/sub/bizdata/${workitemId}`;
  return request(path, {
    method: "post",
    data: dto
  });
}
function updateWorkflowSubBizObject$1(dto, workitemId) {
  const path = `/v2/workflow/sub/bizdata/${workitemId}`;
  return request(path, {
    method: "put",
    data: dto
  });
}
function deleteWorkflowSubBizObject$1(workitemId, schemaCode, bizObjectId, uowId) {
  const path = `/v2/workflow/sub/bizdata/${workitemId}`;
  return request(path, {
    method: "delete",
    params: {
      schemaCode,
      bizObjectId,
      uowId
    }
  });
}
function manageWorkflow$1(params) {
  const path = "/v2/workflow/manager";
  return request(path, {
    method: "post",
    data: params
  });
}
function managerWorkflowParticipants$1(params) {
  const path = "/v2/workflow/activity/participants";
  return request(path, {
    method: "put",
    data: params
  });
}
function getActivityTemplateInfo$1(params) {
  const path = `/v1/workflow/activity/${params.workflowCode}/${params.version}/${params.activityCode}`;
  return request(path, {
    method: "get"
  });
}
function bulkTerminate$1(params) {
  const path = "/v1/workflow/bulk/terminate";
  return request(path, {
    method: "post",
    data: params
  });
}
function bulkUpdateParticipants$1(params) {
  const path = "/v1/workflow/bulk/update/participants";
  return request(path, {
    method: "post",
    data: params
  });
}
const WorkflowApi = {
  getBizData,
  getControlVOs: getControlVOs$1,
  getRelatedInfomationOfSchema: getRelatedInfomationOfSchema$1,
  getWorkflowClauses: getWorkflowClauses$1,
  remove: remove$1,
  open: open$1,
  close: close$1,
  getWorkflowDesignerInfo: getWorkflowDesignerInfo$1,
  getVisibleControls,
  getFieldPermission,
  getDataPermission,
  getAccessPermission,
  submit: submit$1,
  forward: forward$1,
  submitWorkflowBizData: submitWorkflowBizData$1,
  getActivityInfo,
  getActivityInfoByWorkItemId: getActivityInfoByWorkItemId$1,
  getWorklfowLog,
  validateCreateWorkflow: validateCreateWorkflow$1,
  addWorkflow,
  publish: publish$1,
  getTemplate,
  getWorkflowControls: getWorkflowControls$1,
  getWorkflowFieldPermission: getWorkflowFieldPermission$1,
  getWorkflowAccessPermission: getWorkflowAccessPermission$1,
  getWorkflowRecords: getWorkflowRecords$1,
  getWorkflowRecordById: getWorkflowRecordById$1,
  saveWorkflowSubBizObject: saveWorkflowSubBizObject$1,
  updateWorkflowSubBizObject: updateWorkflowSubBizObject$1,
  deleteWorkflowSubBizObject: deleteWorkflowSubBizObject$1,
  getWorkflowInstanceSteps: getWorkflowInstanceSteps$1,
  manageWorkflow: manageWorkflow$1,
  managerWorkflowParticipants: managerWorkflowParticipants$1,
  getActivityTemplateInfo: getActivityTemplateInfo$1,
  bulkTerminate: bulkTerminate$1,
  bulkUpdateParticipants: bulkUpdateParticipants$1
};
function saveWorkflowSubBizObject(dto, workitemId) {
  return WorkflowApi.saveWorkflowSubBizObject(dto, workitemId);
}
function submitWorkflowBizData(request3) {
  return WorkflowApi.submitWorkflowBizData(request3);
}
function deleteWorkflowSubBizObject(workitemId, schemaCode, bizObjectId, uowId) {
  return WorkflowApi.deleteWorkflowSubBizObject(workitemId, schemaCode, bizObjectId, uowId);
}
function updateWorkflowSubBizObject(dto, workitemId) {
  return WorkflowApi.updateWorkflowSubBizObject(dto, workitemId);
}
function getWorkflowRecordById(workItemId) {
  return WorkflowApi.getWorkflowRecordById(workItemId);
}
function getWorkflowRecords(params) {
  return WorkflowApi.getWorkflowRecords(params);
}
function getWorkflowControls(propertyCode, workItemId, subSchemaCode) {
  return WorkflowApi.getWorkflowControls(propertyCode, workItemId, subSchemaCode);
}
function getWorkflowAccessPermission(propertyCode, workItemId, memberId) {
  return WorkflowApi.getWorkflowAccessPermission(propertyCode, workItemId, memberId);
}
function getWorkflowFieldPermission(propertyCode, workItemId, subSchemaCode) {
  return WorkflowApi.getWorkflowFieldPermission(propertyCode, workItemId, subSchemaCode);
}
function publish(template) {
  return WorkflowApi.publish(template);
}
function forward(req) {
  return WorkflowApi.forward(req);
}
function submit(req) {
  return WorkflowApi.submit(req);
}
function getWorkflowInstanceSteps(req) {
  return WorkflowApi.getWorkflowInstanceSteps(req);
}
function getWorkflowClauses(req) {
  return WorkflowApi.getWorkflowClauses(req);
}
function validateCreateWorkflow(req) {
  return WorkflowApi.validateCreateWorkflow(req);
}
function open(workflowCode) {
  return WorkflowApi.open(workflowCode);
}
function close(workflowCode) {
  return WorkflowApi.close(workflowCode);
}
function remove(workflowCode) {
  return WorkflowApi.remove(workflowCode);
}
function getRelatedInfomationOfSchema(appCode, schemaCode) {
  return WorkflowApi.getRelatedInfomationOfSchema(appCode, schemaCode);
}
function getControlVOs(schemaCode, showAll) {
  return WorkflowApi.getControlVOs(schemaCode, showAll);
}
function getWorkflowDesignerInfo(workflowCode, trigger) {
  return WorkflowApi.getWorkflowDesignerInfo(workflowCode, trigger);
}
function getActivityInfoByWorkItemId(workItemId) {
  return WorkflowApi.getActivityInfoByWorkItemId(workItemId);
}
function manageWorkflow(params) {
  return WorkflowApi.manageWorkflow(params);
}
function managerWorkflowParticipants(params) {
  return WorkflowApi.managerWorkflowParticipants(params);
}
function getActivityTemplateInfo(params) {
  return WorkflowApi.getActivityTemplateInfo(params);
}
function bulkTerminate(params) {
  return WorkflowApi.bulkTerminate(params);
}
function bulkUpdateParticipants(params) {
  return WorkflowApi.bulkUpdateParticipants(params);
}
const Workflow = {
  getControlVOs,
  getWorkflowClauses,
  getRelatedInfomationOfSchema,
  getWorkflowDesignerInfo,
  forward,
  submit,
  getActivityInfoByWorkItemId,
  validateCreateWorkflow,
  remove,
  open,
  close,
  publish,
  getWorkflowControls,
  getWorkflowFieldPermission,
  getWorkflowAccessPermission,
  getWorkflowRecords,
  getWorkflowRecordById,
  saveWorkflowSubBizObject,
  updateWorkflowSubBizObject,
  deleteWorkflowSubBizObject,
  submitWorkflowBizData,
  getWorkflowInstanceSteps,
  manageWorkflow,
  managerWorkflowParticipants,
  getActivityTemplateInfo,
  bulkTerminate,
  bulkUpdateParticipants
};
const service$7 = proxyService(Workflow);
function handoverPost$2(params) {
  const path = "/v3/handover";
  return request(path, {
    method: "post",
    data: params
  });
}
function handoverGetRecords$2(params) {
  const path = "/v3/handover/records";
  return request(path, {
    method: "post",
    data: params
  });
}
function handoverPending$2(sourceOwnerId) {
  const path = `/v3/handover/${sourceOwnerId}/apps`;
  return request(path, {
    method: "get"
  });
}
const HandoverApi$1 = {
  handoverPost: handoverPost$2,
  handoverGetRecords: handoverGetRecords$2,
  handoverPending: handoverPending$2
};
function handoverPost$1(params) {
  return HandoverApi$1.handoverPost(params);
}
function handoverGetRecords$1(params) {
  return HandoverApi$1.handoverGetRecords(params);
}
function handoverPending$1(sourceOwnerId) {
  return HandoverApi$1.handoverPending(sourceOwnerId);
}
const Handover$1 = {
  handoverPost: handoverPost$1,
  handoverGetRecords: handoverGetRecords$1,
  handoverPending: handoverPending$1
};
const service$6 = proxyService(Handover$1);
function queryPrintTemplats$1(printTemplatDto) {
  const path = `/v1/print/getPrintTemplates`;
  return request(path, {
    method: "post",
    data: printTemplatDto
  });
}
const PrintApi = {
  queryPrintTemplats: queryPrintTemplats$1
};
function queryPrintTemplats(appCode, schemaCode, parms) {
  return PrintApi.queryPrintTemplats({
    appCode,
    corpId: parms.corpId,
    engineCode: parms.engineCode,
    memberId: parms.memberId,
    schemaCode,
    userId: parms.userId
  });
}
const Print = {
  queryPrintTemplats
};
const service$5 = proxyService(Print);
function getAppIntegrateInfo(appCode) {
  return IntegrateApi.queryAppIntegrateInfo(appCode);
}
function getSchemaIntegrateInfo(appCode, schemaCode) {
  return IntegrateApi.querySchemaIntegrateInfo(appCode, schemaCode);
}
function queryTaskProgress(taskId) {
  return IntegrateApi.queryTaskProgress(taskId);
}
function getUnFinishedTaskProgress(param) {
  return IntegrateApi.getUnFinishedTaskProgress(param);
}
function addDashboardNode(dashboardNodes) {
  return IntegrateApi.addDashboardNode(dashboardNodes);
}
function deleteDashboardNode(code) {
  return IntegrateApi.deleteDashboardNode(code);
}
function updateDashboardNode(dashboardNodes) {
  return IntegrateApi.updateDashboardNode(dashboardNodes);
}
function queryDashboardNodeList(params) {
  return IntegrateApi.queryDashboardNodeList(params);
}
function countTodo(participant) {
  return IntegrateApi.countTodo(participant);
}
function queryTodosInfo(params) {
  return IntegrateApi.queryTodosInfo(params);
}
function queryOriginalTodos(params) {
  return IntegrateApi.queryOriginalTodos(params);
}
function batchUpdateBizData(params) {
  return IntegrateApi.batchUpdateBizData(params);
}
function batchDeleteBizData(params) {
  return IntegrateApi.batchDeleteBizData(params);
}
function dataConvert(params) {
  return IntegrateApi.dataConvert(params);
}
function expressionEvaluate(param) {
  return IntegrateApi.expressionEvaluate(param);
}
function expireExportTask(params) {
  return IntegrateApi.expireExportTask(params);
}
function expireExportTaskProgress(actionId) {
  return IntegrateApi.expireExportTaskProgress(actionId);
}
const Integrate = {
  getAppIntegrateInfo,
  getSchemaIntegrateInfo,
  queryTaskProgress,
  getUnFinishedTaskProgress,
  addDashboardNode,
  deleteDashboardNode,
  updateDashboardNode,
  queryDashboardNodeList,
  countTodo,
  queryTodosInfo,
  queryOriginalTodos,
  batchUpdateBizData,
  batchDeleteBizData,
  dataConvert,
  expressionEvaluate,
  expireExportTask,
  expireExportTaskProgress
};
const service$4 = proxyService(Integrate);
function queryAppletterList$2(offset, pageSize, readStatus, userId) {
  const path = `/v1/appletter/listInfo`;
  return request(path, {
    method: "get",
    params: {
      offset,
      pageSize,
      readStatus,
      userId
    }
  });
}
function getUnreadCount$2() {
  const path = "/v1/appletter/countUnread";
  return request(path, {
    method: "get"
  });
}
function markReadAll$2() {
  const path = "/v1/appletter/markReadWithUser";
  return request(path, {
    method: "post"
  });
}
function markRead$2(ids) {
  const path = "/v1/appletter/markRead";
  return request(path, {
    method: "post",
    data: ids
  });
}
function getNoticeIdByEventId$2(params) {
  const path = "/v1/appletter/getId";
  return request(path, {
    method: "get",
    params
  });
}
function getNoticeDetailById$2(id) {
  const path = `/v1/appletter/detail/${id}`;
  return request(path, {
    method: "get"
  });
}
const AppletterApi = {
  queryAppletterList: queryAppletterList$2,
  getUnreadCount: getUnreadCount$2,
  markReadAll: markReadAll$2,
  markRead: markRead$2,
  getNoticeIdByEventId: getNoticeIdByEventId$2,
  getNoticeDetailById: getNoticeDetailById$2
};
function queryAppletterList$1(offset, pageSize, readStatus, userId) {
  return AppletterApi.queryAppletterList(offset, pageSize, readStatus, userId);
}
function getUnreadCount$1() {
  return AppletterApi.getUnreadCount();
}
function markReadAll$1() {
  return AppletterApi.markReadAll();
}
function markRead$1(ids) {
  return AppletterApi.markRead(ids);
}
function getNoticeIdByEventId$1(params) {
  return AppletterApi.getNoticeIdByEventId(params);
}
function getNoticeDetailById$1(id) {
  return AppletterApi.getNoticeDetailById(id);
}
const AppletterModel = {
  queryAppletterList: queryAppletterList$1,
  getUnreadCount: getUnreadCount$1,
  markReadAll: markReadAll$1,
  markRead: markRead$1,
  getNoticeIdByEventId: getNoticeIdByEventId$1,
  getNoticeDetailById: getNoticeDetailById$1
};
const service$3 = proxyService(AppletterModel);
function addComment$1(params) {
  return request("/v1/comment", {
    method: "post",
    data: params
  });
}
function queryCommentList$1(params) {
  return request(`/v1/comment/list`, {
    method: "post",
    data: params
  });
}
function delComment$1(commentId) {
  return request(`/v1/comment/${commentId}`, {
    method: "delete"
  });
}
function getCommentFileInfo$1(params) {
  return request("/v1/comment/file/token", {
    method: "post",
    data: params
  });
}
function getAppMember$1(params) {
  return request("/v1/role/appMember", {
    method: "post",
    data: params
  });
}
function getCommentFileUrl$1(params) {
  return request("/v1/comment/file", {
    method: "post",
    data: params
  });
}
const CommentApi = {
  addComment: addComment$1,
  queryCommentList: queryCommentList$1,
  delComment: delComment$1,
  getCommentFileInfo: getCommentFileInfo$1,
  getAppMember: getAppMember$1,
  getCommentFileUrl: getCommentFileUrl$1
};
function addComment(params) {
  return CommentApi.addComment(params);
}
function queryCommentList(params) {
  return CommentApi.queryCommentList(params);
}
function delComment(commentId) {
  return CommentApi.delComment(commentId);
}
function getCommentFileInfo(params) {
  return CommentApi.getCommentFileInfo(params);
}
function getAppMember(params) {
  return CommentApi.getAppMember(params);
}
function getCommentFileUrl(params) {
  return CommentApi.getCommentFileUrl(params);
}
const COMMENT = {
  addComment,
  queryCommentList,
  delComment,
  getCommentFileInfo,
  getAppMember,
  getCommentFileUrl
};
const service$2 = proxyService(COMMENT);
function queryThirdPartyConfigInfo$1() {
  const path = "/v1/thirdparty/config";
  return request(path, {
    method: "get"
  });
}
function getDingtalkJsapiTicket$1(params) {
  const path = "/v1/thirdparty/dingtalk/jsapi/ticket";
  return request(path, {
    method: "get",
    params
  });
}
function removeDingDingDaApp$1(params) {
  const path = "/v1/thirdparty/dingtalk/dingdingda/app/remove";
  return request(path, {
    method: "delete",
    params
  });
}
function createDingDingDaApp$1(params) {
  const path = "/v1/thirdparty/dingtalk/dingdingda/app/create";
  return request(path, {
    method: "post",
    data: params
  });
}
function updateDingDingDaApp$1(params) {
  const path = "/v1/thirdparty/dingtalk/dingdingda/app/update";
  return request(path, {
    method: "put",
    data: params
  });
}
function queryCustomCorpOrgIntegrate$1(params) {
  const path = "/v1/corpintegrate/custom/integrate";
  return request(path, {
    method: "get",
    params
  });
}
function modifyCustomCorpOrgIntegrate$1(params) {
  const path = "/v1/corpintegrate/custom/integrate";
  return request(path, {
    method: "post",
    data: params
  });
}
function getCloudpivotIntegrate$1(corpId) {
  const path = "/v1/corpintegrate/cloudpivot";
  return request(path, {
    method: "get",
    params: {
      corpId
    }
  });
}
function addCloudpivotIntegrate$1(params) {
  const path = "/v1/corpintegrate/cloudpivot";
  return request(path, {
    method: "post",
    data: params
  });
}
function updateCloudpivotIntegrate$1(params) {
  const path = `/v1/corpintegrate/cloudpivot?orgSourceId=${params.id}`;
  return request(path, {
    method: "put",
    data: {
      baseUrl: params.baseUrl,
      clientId: params.clientId,
      clientSecret: params.clientSecret
    }
  });
}
const ThirdpartyApi = {
  queryCustomCorpOrgIntegrate: queryCustomCorpOrgIntegrate$1,
  queryThirdPartyConfigInfo: queryThirdPartyConfigInfo$1,
  getDingtalkJsapiTicket: getDingtalkJsapiTicket$1,
  removeDingDingDaApp: removeDingDingDaApp$1,
  createDingDingDaApp: createDingDingDaApp$1,
  updateDingDingDaApp: updateDingDingDaApp$1,
  modifyCustomCorpOrgIntegrate: modifyCustomCorpOrgIntegrate$1,
  getCloudpivotIntegrate: getCloudpivotIntegrate$1,
  addCloudpivotIntegrate: addCloudpivotIntegrate$1,
  updateCloudpivotIntegrate: updateCloudpivotIntegrate$1
};
function queryThirdPartyConfigInfo() {
  return ThirdpartyApi.queryThirdPartyConfigInfo();
}
function getDingtalkJsapiTicket(corpId, url) {
  return ThirdpartyApi.getDingtalkJsapiTicket({
    corpId,
    url
  });
}
function removeDingDingDaApp(params) {
  return ThirdpartyApi.removeDingDingDaApp(params);
}
function createDingDingDaApp(params) {
  return ThirdpartyApi.createDingDingDaApp(params);
}
function updateDingDingDaApp(params) {
  return ThirdpartyApi.updateDingDingDaApp(params);
}
function queryCustomCorpOrgIntegrate(params) {
  return ThirdpartyApi.queryCustomCorpOrgIntegrate(params);
}
function modifyCustomCorpOrgIntegrate(params) {
  return ThirdpartyApi.modifyCustomCorpOrgIntegrate(params);
}
function getCloudpivotIntegrate(corpId) {
  return ThirdpartyApi.getCloudpivotIntegrate(corpId);
}
function addCloudpivotIntegrate(params) {
  return ThirdpartyApi.addCloudpivotIntegrate(params);
}
function updateCloudpivotIntegrate(params) {
  return ThirdpartyApi.updateCloudpivotIntegrate(params);
}
const Thirdparty = {
  queryThirdPartyConfigInfo,
  getDingtalkJsapiTicket,
  removeDingDingDaApp,
  createDingDingDaApp,
  updateDingDingDaApp,
  queryCustomCorpOrgIntegrate,
  modifyCustomCorpOrgIntegrate,
  getCloudpivotIntegrate,
  addCloudpivotIntegrate,
  updateCloudpivotIntegrate
};
const service$1 = proxyService(Thirdparty);
function delRecycleApp$1(appCode) {
  return AppApi.delRecycleApp(appCode);
}
function queryRecycleApps$1(params) {
  return AppApi.queryRecycleApps(params);
}
function restoreRecycleApp$1(appCode) {
  return AppApi.restoreRecycleApp(appCode);
}
function recycleApp(appCode) {
  return AppApi.recycleApp(appCode);
}
const AppRecycle = {
  queryRecycleApps: queryRecycleApps$1,
  delRecycleApp: delRecycleApp$1,
  restoreRecycleApp: restoreRecycleApp$1,
  recycleApp
};
const proxyRecycleBinService = proxyService(AppRecycle);
function handsignGetTask(params) {
  const path = "/v1/handsign/task";
  return request(path, {
    method: "get",
    params
  });
}
function handsignAddTask(params) {
  const path = "/v1/handsign/task";
  return request(path, {
    method: "post",
    data: params
  });
}
function handsignFinishTask(params) {
  const path = "/v1/handsign/task/finish";
  return request(path, {
    method: "post",
    data: params
  });
}
function handsignTempFileUpload(params) {
  const path = "/v1/handsign/storage/upload";
  return request(path, {
    method: "post",
    data: params
  });
}
const HandsignApi$1 = {
  handsignGetTask,
  handsignAddTask,
  handsignFinishTask,
  handsignTempFileUpload
};
const Handover = {
  handsignGetTask: HandsignApi$1.handsignGetTask,
  handsignAddTask: HandsignApi$1.handsignAddTask,
  handsignFinishTask: HandsignApi$1.handsignFinishTask,
  handsignTempFileUpload: HandsignApi$1.handsignTempFileUpload
};
const service = proxyService(Handover);
class ContextWrapper extends Context {
  setContextBase(context) {
    this.mContextBase = context;
  }
  async loadContext() {
    await this.mContextBase.loadContext();
  }
  getUserContext() {
    return this.mContextBase.getUserContext();
  }
  getEngineContext(engineCode) {
    return this.mContextBase.getEngineContext(engineCode);
  }
  getAppContext(appCode) {
    return this.mContextBase.getAppContext(appCode);
  }
  getSchemaContext(appCode, schemaCode) {
    return this.mContextBase.getSchemaContext(appCode, schemaCode);
  }
  getWorkflowContext(appCode, schemaCode) {
    return this.mContextBase.getWorkflowContext(appCode, schemaCode);
  }
  getCommonContext() {
    return this.mContextBase.getCommonContext();
  }
  getContextMetaData() {
    return this.mContextBase.getContextMetaData();
  }
  getUserBasicInfo() {
    return this.mContextBase.getUserBasicInfo();
  }
  getEngineInfo() {
    return this.mContextBase.getEngineInfo();
  }
  setEngineInfo(value) {
    this.mContextBase.setEngineInfo(value);
  }
}
class AppContext extends ContextWrapper {
  constructor(appCode) {
    super();
    this.nodeType = DomainTypeEnum.Engine;
    this.appCode = appCode;
    this._appMetaData = new AppMetaData();
  }
  async load() {
    await this.getFunctionNodes();
  }
  async getSelf() {
    const engineContext = super.getEngineContext();
    return await engineContext.getAppByCode(this.appCode);
  }
  async getSelfInfo() {
    const engineContext = super.getEngineContext();
    await Promise.all([engineContext.getApps(), this.getAppIntegrateInfo()]).then((values) => {
    });
  }
  async getAppIntegrateInfo() {
    if (this._appMetaData.cached) {
      return;
    }
    const res = await service$4.getAppIntegrateInfo(this.appCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data.nodes && res.data.nodes.length > 0) {
      res.data.nodes.forEach((element) => {
        this._appMetaData.addFunctionNode(this.appCode, {
          code: element.code,
          appCode: element.appCode,
          displayName: element.displayName,
          type: element.type,
          tag: element.tag,
          parentCode: element.parentCode,
          sortKey: element.sortKey,
          children: element.children && this.recursiveMap(element.children),
          extendData: element.extendData,
          hidden: element.hidden
        });
      });
    }
    if (res.data.roles && res.data.roles.length > 0) {
      const memberId = super.getEngineInfo().memberId;
      this._appMetaData.setAppRole(memberId, res.data.roles);
    }
    if (res.data.views) {
      const views = res.data.views;
      const that = this;
      Object.keys(views).forEach(function(key) {
        that._appMetaData.addViewList(key, views[key]);
      });
    }
    this._appMetaData.cached = true;
  }
  getSchemaContext(schemaCode) {
    return super.getSchemaContext(this.appCode, schemaCode);
  }
  async getFunctionNodes(scene = "Tab") {
    var _a;
    const cacheFunctionNodes = this._appMetaData.getFunctionNodes();
    if (cacheFunctionNodes && cacheFunctionNodes.length > 0) {
      return Promise.resolve({
        data: cacheFunctionNodes
      });
    }
    const res = await service$4.getAppIntegrateInfo(this.appCode);
    if ((_a = res.data) == null ? void 0 : _a.nodes) {
      res.data.nodes.forEach((element) => {
        this._appMetaData.addFunctionNode(this.appCode, {
          code: element.code,
          appCode: element.appCode,
          displayName: element.displayName,
          type: element.type,
          tag: element.tag,
          parentCode: element.parentCode,
          sortKey: element.sortKey,
          children: element.children && this.recursiveMap(element.children),
          extendData: element.extendData,
          hidden: element.hidden
        });
      });
    }
    return {
      data: this._appMetaData.getFunctionNodes()
    };
  }
  async getAllFunctionNodes() {
    const res = await proxyAppService.getSchemas(this.appCode, "All");
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      return {
        data: res.data.filter((node) => !node.hidden)
      };
    }
  }
  async getSchemaByCode(schemaCode) {
    await this.getFunctionNodes();
    const functionNode = this._appMetaData.recursiveFindFunctionNode(this._appMetaData.getAllFunctinoNodes(), schemaCode);
    if (functionNode) {
      const schema = {
        appCode: functionNode.appCode,
        code: functionNode.code,
        displayName: functionNode.displayName,
        type: functionNode.type,
        tag: functionNode.tag
      };
      return Promise.resolve({
        data: schema
      });
    } else {
      const res = await proxyAppService.queryFunctionNode(schemaCode);
      if (res.errorCode) {
        return {
          errorCode: res.errorCode,
          errorMessage: res.errorMessage
        };
      }
      if (!res.data) {
        return {
          errorCode: res.errorCode,
          errorMessage: "\u83B7\u53D6FunctionNode\u4FE1\u606F\u5931\u8D25"
        };
      }
      const schema = {
        appCode: res.data.appCode,
        code: res.data.code,
        displayName: res.data.displayName,
        type: res.data.type,
        tag: res.data.tag
      };
      return Promise.resolve({
        data: schema
      });
    }
  }
  async getSchemas(scene = "OnlySchema") {
    const res = await proxyAppService.getSchemas(this.appCode, scene);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      return {
        data: res.data.filter((node) => !node.hidden)
      };
    }
  }
  async getAllTableModules(params = {
    showChild: true
  }) {
    const res = await proxyAppService.getAllTableModules({
      appCode: this.appCode,
      showChild: params == null ? void 0 : params.showChild
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      return {
        data: res.data.filter((node) => !node.hidden)
      };
    }
  }
  async getSuperRole() {
    if (!this._appMetaData.appSuperRole) {
      await this.getMaxRole();
    }
    return Promise.resolve({
      data: this._appMetaData.appSuperRole
    });
  }
  async getAppPermission() {
    if (!this._appMetaData.appSuperRole) {
      await this.getMaxRole();
    }
    const isManagerAbove = this._appMetaData.appSuperRole === AppRoleEnum.OWNER || this._appMetaData.appSuperRole === AppRoleEnum.MANAGER;
    const isAppOwner = this._appMetaData.appSuperRole === AppRoleEnum.OWNER;
    const isGuest = this._appMetaData.appSuperRole === AppRoleEnum.GUEST;
    const appPermissions = {
      edit: isManagerAbove,
      delete: isAppOwner,
      addMember: isManagerAbove,
      deleteMember: isManagerAbove,
      assignRole: isManagerAbove,
      exit: isManagerAbove,
      showWorkflow: isManagerAbove,
      addWorkflow: isManagerAbove,
      editWorkflow: isManagerAbove,
      deleteWorkflow: isManagerAbove
    };
    const schemaPermissions = {
      add: isManagerAbove,
      edit: isManagerAbove,
      copy: isManagerAbove,
      sort: isManagerAbove,
      delete: isManagerAbove
    };
    const viewPermission = {
      publicized: isManagerAbove,
      sort: isManagerAbove,
      setPreference: !isGuest
    };
    const fieldPermissions = {
      add: isManagerAbove,
      edit: isManagerAbove,
      sort: isManagerAbove,
      delete: isManagerAbove
    };
    return {
      data: {
        isAppOwner,
        isManagerAbove,
        superRole: this._appMetaData.appSuperRole,
        app: appPermissions,
        schema: schemaPermissions,
        view: viewPermission,
        field: fieldPermissions
      }
    };
  }
  async getFunctionNode(code) {
    const cacheFunctionNode = this._appMetaData.recursiveFindFunctionNode(this._appMetaData.getAllFunctinoNodes(), code);
    if (cacheFunctionNode) {
      return Promise.resolve({
        data: cacheFunctionNode
      });
    }
    this.appMetaData.clearFunctionNodes();
    await this.getFunctionNodes();
    const newCacheFunctionNode = this._appMetaData.recursiveFindFunctionNode(this._appMetaData.getAllFunctinoNodes(), code);
    if (newCacheFunctionNode) {
      return Promise.resolve({
        data: newCacheFunctionNode
      });
    }
    const res = await proxyAppService.getAppFunctionNode(code);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      return {
        data: {
          code: res.data.code,
          displayName: res.data.displayName,
          parentCode: res.data.parentCode,
          type: res.data.type,
          sortKey: res.data.sortKey,
          extendData: res.data.extendData
        }
      };
    }
  }
  async createFunctionNode(params) {
    let res;
    if (params.type === FunctionNodeType.Schema && params.tag === SchemaTag.SyncSchema) {
      res = await service$g.addSyncSchemaV3({
        appCode: this.appCode,
        nextCode: null,
        parentCode: params.parentCode,
        displayName: params.displayName,
        sharedAppCode: params.sharedAppCode,
        sharedAuthCode: params.sharedAuthCode,
        sharedSchemaCode: params.sharedSchemaCode,
        syncFields: params.syncFields
      });
      const rs = await service$e.queryDefaultView(res.data);
      if (rs.data) {
        this.appMetaData.addView(res.data, {
          schemaCode: res.data,
          viewCode: rs.data.viewCode,
          displayName: rs.data.displayName,
          mode: rs.data.mode,
          type: rs.data.type,
          createdTime: moment().format("YYYY-MM-DD hh:mm:ss")
        });
      }
    } else {
      res = await proxyAppService.createFunctionNode({
        appCode: this.appCode,
        displayName: params.displayName,
        parentCode: params.parentCode,
        preCode: null,
        nextCode: null,
        type: params.type
      });
    }
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const functionNode = {
      code: res.data,
      appCode: this.appCode,
      parentCode: params.parentCode,
      displayName: params.displayName,
      type: params.type,
      tag: SchemaTag.Normal,
      extendData: {}
    };
    this.appMetaData.clearFunctionNodes();
    await this.getFunctionNodes();
    return {
      data: functionNode
    };
  }
  async updateFunctionNode(params) {
    const res = await proxyAppService.updateFunctionNode({
      appCode: this.appCode,
      code: params.code,
      displayName: params.displayName
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    this.appMetaData.clearFunctionNodes();
    await this.getFunctionNodes();
    return {
      data: true
    };
  }
  async sortFunctionNode(params) {
    const res = await proxyAppService.updateFunctionNode({
      appCode: this.appCode,
      code: params.code,
      parentCode: params.parentCode,
      preCode: params.preCode,
      nextCode: params.nextCode
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    this.appMetaData.clearFunctionNodes();
    await this.getFunctionNodes();
    return {
      data: true
    };
  }
  async deleteFunctionNode(schemaCode) {
    const res = await proxyAppService.deleteFunctionNode(schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    this.appMetaData.clearFunctionNodes();
    await this.getFunctionNodes();
    super.getContextMetaData().cleanSchemaContexts(this.appCode);
    return res;
  }
  async unGroupFunctionNode(groupCode) {
    const res = await proxyAppService.unGroupFunctionNode(groupCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    this.appMetaData.clearFunctionNodes();
    await this.getFunctionNodes();
    return {
      data: res.data
    };
  }
  async moveFunctionNodeToNewGroup(groupCode, data) {
    const res = await proxyAppService.moveFunctionNodeToNewGroup(groupCode, data);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    this.appMetaData.clearFunctionNodes();
    await this.getFunctionNodes();
    return {
      data: res.data
    };
  }
  async copyFunctionNode(sourceCode, displayName, target) {
    const sourceFunctionNode = this._appMetaData.recursiveFindFunctionNode(this._appMetaData.getAllFunctinoNodes(), sourceCode);
    if (!sourceFunctionNode) {
      throw new Error(`Source schema[${sourceCode}] not found.`);
    }
    const res = await proxyAppService.copyFunctionNode(sourceCode, displayName, target);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const rs = await service$e.queryDefaultView(res.data);
    if (rs.data) {
      this.appMetaData.addView(res.data, {
        schemaCode: res.data,
        viewCode: rs.data.viewCode,
        displayName: rs.data.displayName,
        mode: rs.data.mode,
        type: rs.data.type,
        createdTime: moment().format("YYYY-MM-DD hh:mm:ss")
      });
    }
    const newFunctionNode = {
      appCode: this.appCode,
      code: res.data,
      displayName,
      type: sourceFunctionNode.type,
      tag: SchemaTag.Normal
    };
    this.appMetaData.clearFunctionNodes();
    await this.getFunctionNodes();
    return {
      data: newFunctionNode
    };
  }
  async updateFunctionNodeSort(sortedCodes) {
    const res = await proxyAppService.updateNodeSort(sortedCodes);
    this.appMetaData.clearFunctionNodes();
    await this.getFunctionNodes();
    return res;
  }
  async exportData(params) {
    const res = await service$a.exportDataBySection({
      schemaCode: params.schemaCode,
      viewCode: params.viewCode,
      exportAll: true,
      filterItems: DateParse.transform(params.filterItems),
      sorts: params.sorts,
      hideColumnCodes: params.hideColumnCodes,
      scope: params.scope,
      exportColumnCodes: params.exportColumnCodes,
      importColumns: params.importColumns
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async exportDataBySection(params) {
    const res = await service$a.exportSectionData({
      schemaCode: params.schemaCode,
      viewCode: params.viewCode,
      sections: params.sections,
      exportAll: true,
      filterItems: DateParse.transform(params.filterItems),
      sorts: params.sorts,
      hideColumnCodes: params.hideColumnCodes,
      scope: params.scope,
      exportColumnCodes: params.exportColumnCodes,
      importColumns: params.importColumns
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getExportProgress(taskId) {
    const res = await service$a.getExportProgress(taskId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      return {
        data: {
          progress: res.data.progress,
          isCompleted: res.data.isCompleted,
          exportState: res.data.exportState,
          tips: res.data.tips,
          fileUrl: res.data.fileUrl
        }
      };
    } else {
      return {};
    }
  }
  async getImportSupportColumnsOld(schemaCode, importOption) {
    const res = await service$a.getImportColumnsOld(schemaCode, importOption);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const controls = [];
    if (res.data) {
      res.data.forEach((item) => {
        controls.push({
          code: item.code,
          displayName: item.displayName,
          type: item.type,
          required: item.isRequired
        });
      });
    }
    return {
      data: controls
    };
  }
  async getImportSupportColumns(schemaCode) {
    const res = await service$a.getImportColumns(schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const controls = [];
    if (res.data) {
      res.data.forEach((item) => {
        controls.push({
          code: item.code,
          displayName: item.displayName,
          type: item.type,
          required: item.isRequired
        });
      });
    }
    return {
      data: controls
    };
  }
  async previewImport(viewRowCount, formData) {
    const res = await service$a.createPreviewData(viewRowCount, formData);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (!res.data) {
      return {};
    }
    return {
      data: {
        tips: res.data.tips,
        actionId: res.data.actionId,
        displayName: res.data.displayName,
        isTemplate: res.data.isTemplate,
        rowData: res.data.rowData
      }
    };
  }
  async createImport(params) {
    const res = await service$a.startCreateSchema({
      appCode: this.appCode,
      actionId: params.actionId,
      titleRowIndex: params.titleRowIndex,
      titleColumnIndex: params.titleColumnIndex,
      schemaName: params.schemaName,
      parentCode: params.parentCode,
      preCode: params.preCode
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async insertImport(params) {
    const res = await service$a.startImport({
      schemaCode: params.schemaCode,
      actionId: params.actionId,
      titleRowIndex: params.titleRowIndex,
      importOption: params.importOption,
      columnInfo: params.columnInfos
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: true
    };
  }
  async updateImport(params) {
    const res = await service$a.updateImport({
      schemaCode: params.schemaCode,
      actionId: params.actionId,
      titleRowIndex: params.titleRowIndex,
      importOption: params.importOption,
      columnInfo: params.columnInfos
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: true
    };
  }
  async getCreateImportProgress(taskId) {
    const res = await service$a.getSchemaCreateProgress(taskId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data && res.data.schemaCode) {
      this._appMetaData.clearFunctionNodes();
      await this.getFunctionNodes();
    }
    return {
      data: {
        ...res.data,
        failCount: 0
      }
    };
  }
  async getNormalImportProgress(taskId) {
    const res = await service$a.getImportProgress(taskId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: {
        ...res.data,
        failCount: 0
      }
    };
  }
  async getImportExportProgress(taskId) {
    const res = await service$a.queryImportExportProgress(taskId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getMaxRole() {
    if (this._appMetaData.appSuperRole) {
      return Promise.resolve(this._appMetaData.appSuperRole);
    }
    let roles = null;
    const memberId = super.getEngineInfo().memberId;
    const cacheRoles = this._appMetaData.getAppRole(memberId);
    if (cacheRoles && cacheRoles.length > 0) {
      roles = cacheRoles;
    } else {
      const res = await service$c.getAllRolesOfUser(memberId, this.appCode);
      if (res.errorCode) {
        throw new Error(res.errorCode);
      }
      roles = res.data;
      this._appMetaData.setAppRole(memberId, roles);
    }
    this._appMetaData.appSuperRole = AppRoleEnum.GUEST;
    for (const appRole in AppRoleEnum) {
      if (!AppRoleEnum.hasOwnProperty(appRole)) {
        continue;
      }
      let isFound = false;
      for (const i in roles) {
        if (!roles.hasOwnProperty(i)) {
          continue;
        }
        if (roles[i].name === appRole) {
          this._appMetaData.appSuperRole = AppRoleEnum[appRole];
          isFound = true;
          break;
        }
      }
      if (isFound) {
        break;
      }
    }
    return this._appMetaData.appSuperRole;
  }
  recursiveMap(nodes) {
    const children = new Array();
    nodes.forEach((node) => {
      children.push({
        code: node.code,
        appCode: node.appCode,
        displayName: node.displayName,
        type: node.type,
        tag: node.tag,
        parentCode: node.parentCode,
        sortKey: node.sortKey,
        children: node.children && this.recursiveMap(node.children),
        extendData: node.extendData
      });
    });
    return children;
  }
  async addPermissonGroup(parms) {
    const res = await service$c.addRole({
      appCode: this.appCode,
      displayName: parms.displayName,
      roleType: parms.roleType
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async deletePermissonGroup(roleId) {
    const res = await service$c.deleteRole(roleId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async updatePermissonGroup(parms) {
    const res = await service$c.updateRole({
      id: parms.id,
      appCode: this.appCode,
      displayName: parms.displayName
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getPermissonGroups() {
    const res = await service$c.getRoles(this.appCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async queryPreference(target, scope) {
    const res = await service$8.getPreference(target, scope);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async updatePreference(target, params) {
    const memberId = this.getEngineContext().getEngineInfo().memberId;
    const preferenceModel = {
      scope: memberId,
      items: params
    };
    const res = await service$8.updatePreference(target, preferenceModel);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async removeDingDingDaApp({
    corpId,
    appCode
  }) {
    const res = await service$1.removeDingDingDaApp({
      corpId,
      appCode
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getAppSyncRecords() {
    const res = await proxyAppService.getAppSyncRecords(this.appCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  get appMetaData() {
    return this._appMetaData;
  }
}
class EngineContext extends ContextWrapper {
  constructor(engineCode) {
    super();
    this.nodeType = DomainTypeEnum.Engine;
    this.engineCode = engineCode;
    this._engineMetaData = new EngineMetaData();
  }
  load() {
    const engine = super.getContextMetaData().getEngine(this.engineCode);
    if (!engine) {
      throw new Error("engine[" + this.engineCode + "] not found");
    }
    globalStorage.set("X-H3-EngineCode", this.engineCode);
    globalStorage.set("X-Engine-Shard", engine.engineShard);
    globalStorage.set("X-H3-MemberId", engine.memberId);
  }
  async getMemberInfo(memberId) {
    super.getUserBasicInfo();
    const currentMemberId = super.getContextMetaData().getEngine(this.engineCode).memberId;
    if (!memberId || memberId === "") {
      memberId = currentMemberId;
    }
    if (this._engineMetaData.getMember(memberId)) {
      return Promise.resolve({
        data: this._engineMetaData.getMember(memberId)
      });
    }
    const res = await service$9.getMemberInfo(memberId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (!res.data) {
      return {};
    }
    const departments = [];
    if (res.data.departments) {
      res.data.departments.forEach((dept) => {
        departments.push({
          ...dept
        });
      });
    }
    const memberInfo = {
      objectId: res.data.objectId,
      name: res.data.name,
      corpId: res.data.corpId,
      mobile: res.data.mobile,
      email: res.data.email,
      departments,
      corpOwner: res.data.corpOwner,
      profilePhotoUrl: res.data.profilePhotoUrl,
      mediumProfilePhotoUrl: res.data.mediumProfilePhotoUrl,
      bigProfilePhotoUrl: res.data.bigProfilePhotoUrl
    };
    this._engineMetaData.addMember(memberInfo);
    return {
      data: memberInfo
    };
  }
  getCurrentMember() {
    const currentMemberId = super.getContextMetaData().getEngine(this.engineCode).memberId;
    return this._engineMetaData.getMember(currentMemberId);
  }
  async changeDepartment(option) {
    const res = await service$9.changeDepartment(option);
    if (option.memberIds && option.memberIds.length > 0) {
      option.memberIds.forEach((item) => {
        this._engineMetaData.removeMember(item);
      });
    }
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getApps(noCache = false) {
    let apps = this._engineMetaData.getApps();
    if (!noCache && apps && apps.length > 0) {
      return Promise.resolve({
        data: apps
      });
    }
    const res = await proxyAppService.getApps();
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    } else {
      apps = new Array();
      res.data.forEach((element) => {
        const app = {
          code: element.code,
          displayName: element.displayName,
          color: element.color,
          icon: element.icon,
          displayMode: element.displayMode,
          ownerId: element.ownerId,
          description: element.description,
          source: element.source,
          isStoreUp: element.isStoreUp,
          sortIndex: element.sortIndex,
          modifiedTime: element.modifiedTime,
          createTime: element.createTime,
          ownerName: element.ownerName
        };
        this._engineMetaData.addApp(app);
        apps.push(app);
      });
      return {
        ...res,
        data: apps
      };
    }
  }
  clearApps() {
    this._engineMetaData.clearApps();
  }
  async getAppsByPage(params) {
    const res = await proxyAppService.getAppsByPage(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getAllAppNodesByTag(tag) {
    return proxyAppService.getAllModulesByTag(tag);
  }
  async getAppByCode(appCode) {
    const cacheApp = this._engineMetaData.getApp(appCode);
    if (cacheApp) {
      return Promise.resolve({
        data: cacheApp
      });
    }
    return proxyAppService.getApp(appCode).then((res) => {
      if (res.errorCode) {
        return {
          errorCode: res.errorCode,
          errorMessage: res.errorMessage
        };
      } else if (res.data) {
        const app = {
          code: res.data.code,
          displayName: res.data.displayName,
          color: res.data.color,
          icon: res.data.icon,
          displayMode: res.data.displayMode,
          ownerId: res.data.ownerId,
          description: res.data.description,
          source: res.data.source,
          isStoreUp: res.data.isStoreUp,
          sortIndex: res.data.sortIndex,
          modifiedTime: res.data.modifiedTime,
          createTime: res.data.createTime,
          ownerName: res.data.ownerName
        };
        return {
          data: app
        };
      } else {
        return {
          data: null
        };
      }
    });
  }
  async installTemplate(templateId, externalLabel) {
    const res = await service$f.install(externalLabel, true);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      const app = {
        code: res.data.code,
        displayName: res.data.displayName,
        color: res.data.color,
        icon: res.data.icon,
        displayMode: res.data.displayMode,
        description: res.data.description,
        createTime: res.data.createTime,
        ownerName: res.data.ownerName
      };
      this._engineMetaData.addApp(app);
      service$f.increaseInstallNum(templateId);
      return {
        data: app
      };
    } else {
      return {};
    }
  }
  async createApp(params) {
    const res = await proxyAppService.addApp(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      const member = await this.getMemberInfo();
      ({
        code: res.data,
        displayName: params.displayName,
        color: params.color,
        icon: params.icon,
        displayMode: params.displayMode,
        description: params.description,
        modifiedTime: new Date().toDateString(),
        createTime: new Date().toDateString(),
        ownerName: member.data.name
      });
      this._engineMetaData.clearApps();
      await this.getApps();
    }
    return {
      data: res.data
    };
  }
  async updateApp(params) {
    const res = await proxyAppService.updateApp(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const oldVal = this._engineMetaData.getApp(params.code);
    const app = {
      ...oldVal,
      ...params,
      modifiedTime: new Date().toDateString()
    };
    this._engineMetaData.updateApp(app);
    return {
      data: this._engineMetaData.getApp(params.code)
    };
  }
  async deleteApp(appCode) {
    const res = await proxyRecycleBinService.recycleApp(appCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      this._engineMetaData.removeApp(appCode);
    }
    return {
      data: res.data
    };
  }
  async updateAppSortAndPreference(items) {
    const res = await service$8.updatePreference("list/dashboard", {
      items
    });
    return res;
  }
  async updateAppOwner(params) {
    const res = await service$c.changeAppOwner({
      appCode: params.appCode,
      originalOwnerId: params.originalOwnerId,
      newOwnerId: params.newOwnerId
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      const appContext = super.getAppContext(params.appCode);
      appContext.appMetaData.clearAppSuperRole();
    }
    return res;
  }
  async removeAppRoleByUser(params) {
    const res = await service$c.deleteAllRolesOfUser({
      appCode: params.appCode,
      userIds: params.userIds
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const memberInfoRes = await this.getMemberInfo();
    if (res.data && params.userIds.includes(memberInfoRes.data.objectId)) {
      this._engineMetaData.removeApp(params.appCode);
    }
    return res;
  }
  async getOrgRootDepartment() {
    const res = await service$9.getOrgRoot();
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: {
        objectId: res.data.objectId,
        name: res.data.name,
        foreignId: res.data.foreignId,
        parentId: res.data.parentId,
        managerId: res.data.managerId,
        managerName: res.data.managerName,
        memberCount: res.data.memberCount,
        hasChildren: res.data.hasChildren
      }
    };
  }
  async getOrgChildUnits(params) {
    const res = await service$9.queryChildUnits({
      departmentId: params.departmentId,
      states: params.states
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const unitAOs = res.data;
    const orgUnits = this.castOrgUnit(unitAOs);
    return {
      data: orgUnits
    };
  }
  async getOrgMyDepartmentMember(params) {
    const res = await service$9.getMyDepartmentMembers({
      onlyDept: params.onlyDept,
      states: params.states
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const odms = [];
    if (res.data) {
      res.data.forEach((dm) => {
        const children = [];
        if (dm.children) {
          dm.children.forEach((child) => {
            children.push({
              objectId: child.objectId,
              name: child.name,
              mobile: child.mobile,
              avatar: child.avatar
            });
          });
        }
        odms.push({
          departmentId: dm.departmentId,
          departmentName: dm.departmentName,
          hasChild: dm.hasChild,
          children
        });
      });
    }
    return {
      data: odms
    };
  }
  async getDepartmentInfo(departmentId) {
    const res = await service$9.getDeptInfo(departmentId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getOrgChildDepartments(departmentId) {
    const res = await service$9.getChildDepts(departmentId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const departments = [];
    if (res.data) {
      res.data.forEach((child) => {
        departments.push({
          objectId: child.objectId,
          name: child.name,
          parentId: child.parentId,
          managerId: child.managerId,
          managerName: child.managerName,
          memberCount: child.memberCount,
          hasChildren: child.hasChildren,
          foreignId: child.foreignId
        });
      });
    }
    return {
      data: departments
    };
  }
  async getChildDepartmentAndMembers(departmentId) {
    const res = await service$9.getChildUnits(departmentId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async searchOrgMember(params) {
    const res = await service$9.queryMembers({
      corpOwner: params.corpOwner,
      departmentId: params.departmentId,
      name: params.name,
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      states: params.states
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const resultList = [];
    let totalCount = 0;
    if (res.data) {
      totalCount = res.data.totalCount;
      if (res.data.resultList) {
        res.data.resultList.forEach((m) => {
          resultList.push({
            objectId: m.objectId,
            name: m.name,
            mobile: m.mobile,
            profilePhotoUrl: m.profilePhotoUrl,
            smallProfilePhotoUrl: m.smallProfilePhotoUrl,
            mediumProfilePhotoUrl: m.mediumProfilePhotoUrl,
            bigProfilePhotoUrl: m.bigProfilePhotoUrl,
            deptNameTree: m.deptNameTree,
            departments: m.departments,
            state: m.state
          });
        });
      }
    }
    return {
      data: {
        totalCount,
        resultList
      }
    };
  }
  async searchOrgDepartment(params) {
    const res = await service$9.queryDepts({
      name: params.name,
      pageIndex: params.pageIndex,
      pageSize: params.pageSize
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const resultList = [];
    let totalCount = 0;
    if (res.data) {
      totalCount = res.data.totalCount;
      if (res.data.resultList) {
        res.data.resultList.forEach((d) => {
          resultList.push({
            objectId: d.objectId,
            name: d.name,
            parentId: d.parentId,
            managerId: d.managerId,
            managerName: d.managerName,
            memberCount: d.memberCount,
            hasChildren: d.hasChildren,
            deptNameTree: d.deptNameTree,
            departments: d.departments
          });
        });
      }
    }
    return {
      data: {
        totalCount,
        resultList
      }
    };
  }
  async getUserAndDeptRoles(params) {
    const res = await service$c.queryUserAndDeptRoles(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: {
        totalCount: res.data.totalCount,
        resultList: res.data.resultList
      }
    };
  }
  async getOrgMembersByIds(memberIds) {
    const res = await service$9.getMembersByIds(memberIds);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      const members = [];
      res.data.forEach((m) => {
        members.push({
          objectId: m.objectId,
          name: m.name,
          mobile: m.mobile,
          profilePhotoUrl: m.profilePhotoUrl
        });
      });
      return {
        data: members
      };
    } else {
      return {
        data: []
      };
    }
  }
  async getMembersByDeptId(departmentId) {
    const res = await service$9.getChildMembers(departmentId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getContactMembers(parms) {
    const res = await service$9.getCorpMembers(parms);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: {
        totalCount: res.data.totalCount,
        resultList: res.data.resultList
      }
    };
  }
  async inviteMembers(members) {
    const res = await service$9.inviteMembers(members);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getCorpOwners(corpId) {
    const res = await service$9.queryCorpOwners(corpId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async addDashboardNode(dashboardNodes) {
    const res = await service$4.addDashboardNode(dashboardNodes);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async deleteDashboardNode(code) {
    const res = await service$4.deleteDashboardNode(code);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async updateDashboardNode(dashboardNodes) {
    const res = await service$4.updateDashboardNode(dashboardNodes);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getDashboardNodeList(params) {
    const res = await service$4.queryDashboardNodeList(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async expireExportTask(params) {
    const res = await service$4.expireExportTask(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async expireExportTaskProgress(actionId) {
    const res = await service$4.expireExportTaskProgress(actionId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async queryThirdPartyConfigInfo() {
    const res = await service$1.queryThirdPartyConfigInfo();
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getThirdPartyBindBaseInfo(corpId) {
    const res = await service$h.getBindBaseInfo(corpId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getDingtalkJsapiTicket(corpId, url) {
    const res = await service$1.getDingtalkJsapiTicket(corpId, url);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async createDingDingDaApp(params) {
    const res = await service$1.createDingDingDaApp(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async updateDingDingDaApp(params) {
    const res = await service$1.updateDingDingDaApp(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getCustomCorpOrgIntegrate(params) {
    const res = await service$1.queryCustomCorpOrgIntegrate(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async modifyCustomCorpOrgIntegrate(params) {
    const res = await service$1.modifyCustomCorpOrgIntegrate(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  castOrgUnit(unitAOs) {
    const orgUnits = [];
    if (unitAOs && unitAOs.length > 0) {
      unitAOs.forEach((unit) => {
        orgUnits.push({
          objectId: unit.objectId,
          name: unit.name,
          parentId: unit.parentId,
          profilePhotoUrl: unit.profilePhotoUrl,
          unitType: unit.unitType,
          children: this.castOrgUnit(unit.children),
          hasChild: unit.hasChild,
          smallProfilePhotoUrl: unit.smallProfilePhotoUrl,
          mediumProfilePhotoUrl: unit.mediumProfilePhotoUrl,
          bigProfilePhotoUrl: unit.bigProfilePhotoUrl
        });
      });
    }
    return orgUnits;
  }
  async saveCorpAdmin(memberIds) {
    const res = await service$h.saveCorpAdmin(memberIds);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getCorpAdminList() {
    const res = await service$h.getCorpAdminList();
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getCorpOwner() {
    const res = await service$h.getCorpOwner();
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getCloudpivotIntegrate(corpId) {
    const res = await service$1.getCloudpivotIntegrate(corpId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async addCloudpivotIntegrate(params) {
    const res = await service$1.addCloudpivotIntegrate(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async updateCloudpivotIntegrate(params) {
    const res = await service$1.updateCloudpivotIntegrate(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async queryExtentionnodes(params) {
    const res = await proxyAppService.queryExtentionnodes(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async addExtentionnode(params) {
    const res = await proxyAppService.addExtentionnode(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async updateExtentionnode(params) {
    const res = await proxyAppService.updateExtentionnode(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async delExtentionnode(code) {
    const res = await proxyAppService.delExtentionnode(code);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
}
const systemControlCodes = ["ObjectId", "OwnerId", "CreatedBy", "CreatedTime", "ModifiedBy", "ModifiedTime", "OwnerDeptId", "Status", "WorkflowName", "WorkflowActivity", "WorkflowVersion", "ActivityParticipants", "ActivityCode", "WorkflowCode"];
function getQueryItemsWidthSystem(items) {
  if (!items) {
    return [];
  }
  return uniq([...items, ...systemControlCodes]);
}
class SchemaContext extends ContextWrapper {
  constructor(appCode, schemaCode) {
    super();
    this.nodeType = DomainTypeEnum.Schema;
    this.appCode = appCode;
    this.schemaCode = schemaCode;
    this._schemaMetaData = new SchemaMetaData();
    this._controlCacheManager = new ControlCacheManager();
    this._eventListener = new EventDispatcher();
  }
  get _appCode() {
    return this.appCode;
  }
  get _schemaCode() {
    return this.schemaCode;
  }
  get schemaMetaData() {
    return this._schemaMetaData;
  }
  async getSelf() {
    const appContext = super.getAppContext(this.appCode);
    return await appContext.getSchemaByCode(this.schemaCode);
  }
  async getSelfInfo() {
    await Promise.all([this.getSchemaIntegrateInfo()]).then((values) => {
    });
  }
  async getSchemaIntegrateInfo() {
    if (this._schemaMetaData.cached) {
      return;
    }
    const res = await service$4.getSchemaIntegrateInfo(this.appCode, this.schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data.views && res.data.views.length > 0) {
      res.data.views.forEach((viewDto) => {
        this._schemaMetaData.addView({
          schemaCode: viewDto.schemaCode,
          viewCode: viewDto.viewCode,
          displayName: viewDto.displayName,
          mode: viewDto.mode,
          type: viewDto.type,
          belongTo: viewDto.belongTo,
          createdTime: viewDto.createdTime
        });
      });
    }
    if (res.data.controls && res.data.controls.length > 0) {
      this.completeControlPropeties(res.data.controls);
      this._schemaMetaData.setControls(res.data.controls);
    }
    if (res.data.submitRules && res.data.submitRules.length > 0) {
      this._schemaMetaData.submitRules = res.data.submitRules.map((item) => {
        return {
          ruleTitle: item.ruleTitle,
          submit: item.submit,
          rules: item.rules,
          type: item.type,
          tip: item.tip,
          order: item.order
        };
      });
    }
    const accessPermission = {
      add: false,
      delete: false,
      export: false,
      import: false,
      batchDelete: false,
      batchUpdate: false,
      share: false,
      viewHistory: false
    };
    if (res.data.accessPermissions) {
      const accessRes = res.data.accessPermissions;
      accessPermission.add = accessRes.Add;
      accessPermission.delete = accessRes.Delete;
      accessPermission.export = accessRes.Export;
      accessPermission.import = accessRes.Import;
      accessPermission.batchDelete = accessRes.BatchDelete;
      accessPermission.batchUpdate = accessRes.BatchUpdate;
      accessPermission.share = accessRes.Share;
      accessPermission.viewHistory = accessRes.DataChangedHistory;
    }
    const fieldAddPermission = {};
    const fieldEditPermissions = [];
    if (res.data.fieldPermissions) {
      const fieldPermissionSetting = res.data.fieldPermissions;
      const addSceneFieldPermission = fieldPermissionSetting.addSceneFieldPermission;
      const editSceneFieldPermission = fieldPermissionSetting.editSceneFieldPermission;
      if (addSceneFieldPermission && addSceneFieldPermission.fieldPermission) {
        Object.keys(addSceneFieldPermission.fieldPermission).forEach((field) => {
          fieldAddPermission[field] = addSceneFieldPermission.fieldPermission[field];
        });
      }
      if (editSceneFieldPermission && editSceneFieldPermission.conditioAddModels) {
        const conditioFieldPermissions = editSceneFieldPermission.conditioAddModels;
        conditioFieldPermissions.forEach((item) => {
          fieldEditPermissions.push({
            sceneName: item.sceneName,
            conditions: item.conditions,
            expression: item.expression,
            sceneId: item.sceneId,
            enabledFlag: item.enabledFlag,
            fieldEditPermission: item.fieldPermission
          });
        });
      }
    }
    this._schemaMetaData.schemaPermission = {
      accessPermission,
      fieldAddPermission,
      fieldEditPermissions
    };
    this._schemaMetaData.cached = true;
  }
  async queryAppaggregationSchema(schemaCode, refresh = false) {
    if (this._schemaMetaData.cached && !refresh) {
      return {
        data: this._schemaMetaData
      };
    }
    const res = await service$4.getSchemaIntegrateInfo(this.appCode, schemaCode);
    if (!res) {
      return {
        data: null
      };
    }
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getSchemaPreference() {
    const cacheSchemaPreference = this._schemaMetaData.schemaPreference;
    if (cacheSchemaPreference) {
      return Promise.resolve({
        data: cacheSchemaPreference
      });
    }
    const engine = super.getEngineInfo();
    const scope = engine.memberId;
    const res = await service$8.getPreference(this.schemaCode, scope);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    this._schemaMetaData.schemaPreference = res.data;
    return {
      data: res.data
    };
  }
  async updateSchemaPreference(params) {
    const memberId = this.getEngineContext().getEngineInfo().memberId;
    const preferenceModel = {
      scope: memberId,
      items: params
    };
    const res = await service$8.updatePreference(`list/${this.schemaCode}`, preferenceModel);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      this._schemaMetaData.schemaPreference = {
        ...this._schemaMetaData.schemaPreference,
        ...params
      };
    }
    return {
      data: res.data
    };
  }
  async createView(params) {
    const res = await service$e.addView({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      displayName: params.displayName,
      mode: params.mode,
      settings: params.viewSetting,
      type: params.type
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    this._schemaMetaData.addView({
      schemaCode: this.schemaCode,
      viewCode: res.data,
      displayName: params.displayName,
      mode: params.mode,
      type: params.type,
      createdTime: moment().format("YYYY-MM-DD hh:mm:ss")
    });
    return {
      data: res.data
    };
  }
  async updateView(params) {
    const viewRes = await this.getViewByCode(params.viewCode);
    const view = viewRes.data;
    const superRoleRes = await this.getAppContext(this.appCode).getSuperRole();
    const superRole = superRoleRes.data;
    const isManagerAbove = superRole === AppRoleEnum.OWNER || superRole === AppRoleEnum.MANAGER;
    let viewSetting = null;
    let preference = null;
    let viewType = view.type;
    if (viewType === "locked") {
      viewType = "public";
    }
    if (viewType === "personal" && params.type === "public") {
      if (isManagerAbove) {
        viewSetting = {
          ...view.preference
        };
        if (viewSetting && Object.keys(viewSetting).length > 0) {
          const upateSettingRes = await this._updateViewSetting(params.viewCode, viewSetting);
          if (upateSettingRes.data) {
            const memberId = this.getEngineContext().getEngineInfo().memberId;
            service$8.removePreference(params.viewCode, memberId);
            preference = viewSetting;
          }
        }
      } else {
        throw new Error("\u975E\u7BA1\u7406\u5458\u4EE5\u4E0A\u6743\u9650\uFF0C\u4E0D\u80FD\u516C\u5F00\u89C6\u56FE");
      }
    }
    if (viewType === "public" && params.type === "personal") {
      if (isManagerAbove) {
        viewSetting = {
          ...view.preference
        };
        if (viewSetting && Object.keys(viewSetting).length > 0) {
          const upateSettingRes = await this._updateViewSetting(params.viewCode, viewSetting);
          if (upateSettingRes.data) {
            const memberId = this.getEngineContext().getEngineInfo().memberId;
            service$8.removePreference(params.viewCode, memberId);
            preference = viewSetting;
          }
        }
      } else {
        throw new Error("\u975E\u7BA1\u7406\u5458\u4EE5\u4E0A\u6743\u9650\uFF0C\u4E0D\u80FD\u5C06\u516C\u5F00\u89C6\u56FE\u8BBE\u4E3A\u79C1\u6709");
      }
    }
    const res = await service$e.updateView({
      schemaCode: this.schemaCode,
      viewCode: params.viewCode,
      displayName: params.displayName,
      mode: params.mode,
      settings: viewSetting,
      type: params.type
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const cacheView = this._schemaMetaData.getView(params.viewCode);
    this._schemaMetaData.updateView({
      ...cacheView,
      displayName: params.displayName || cacheView.displayName,
      mode: params.mode || cacheView.mode,
      type: params.type || cacheView.type,
      preference: preference || cacheView.preference
    });
    return {
      data: res.data
    };
  }
  _updateViewSetting(viewCode, setting) {
    const mySetting = {
      ...setting
    };
    delete mySetting.baseVersion;
    delete mySetting.changed;
    delete mySetting.origin;
    return service$e.updateViewSetting(viewCode, mySetting);
  }
  async deleteView(viewCode) {
    const res = await service$e.deleteView(this.schemaCode, viewCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    this._schemaMetaData.removeView(viewCode);
    return {
      data: res.data
    };
  }
  async getViews() {
    const cacheViews = this._schemaMetaData.getViews();
    if (cacheViews && cacheViews.length > 0) {
      return {
        data: cacheViews
      };
    }
    const res = await service$e.getViews(this.schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (!res.data) {
      return {
        data: []
      };
    }
    res.data.forEach((viewDto) => {
      this._schemaMetaData.addView({
        schemaCode: viewDto.schemaCode,
        viewCode: viewDto.viewCode,
        displayName: viewDto.displayName,
        mode: viewDto.mode,
        type: viewDto.type,
        belongTo: viewDto.belongTo,
        createdTime: viewDto.createdTime
      });
    });
    return {
      data: this._schemaMetaData.getViews()
    };
  }
  async getViewByCode(viewCode) {
    const cacheView = this._schemaMetaData.getView(viewCode);
    if (cacheView && cacheView.preference) {
      return Promise.resolve({
        data: cacheView
      });
    }
    const res = await service$e.getView(viewCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    } else {
      const outputViewDto = res.data;
      if (!outputViewDto) {
        return {};
      }
      let viewType = outputViewDto.type;
      const settings = outputViewDto.settings || {};
      if (viewType === "locked") {
        viewType = "public";
      }
      let preferenceOrigin;
      if (viewType === "personal") {
        if (!settings.version) {
          preferenceOrigin = await this.getViewPreference(viewCode);
        }
      }
      let result = {};
      if (preferenceOrigin) {
        result = {
          ...settings,
          ...preferenceOrigin
        };
      } else {
        result = settings;
      }
      if (outputViewDto.mode === "Form" && !result.displayFields) {
        result.displayFields = settings.displayFields;
      }
      const view = {
        schemaCode: outputViewDto.schemaCode,
        viewCode: outputViewDto.viewCode,
        displayName: outputViewDto.displayName,
        mode: outputViewDto.mode,
        type: viewType,
        belongTo: outputViewDto.belongTo,
        createdTime: outputViewDto.createdTime,
        preference: result || {}
      };
      this._schemaMetaData.addView(view);
      return {
        data: view
      };
    }
  }
  async getDefaultView() {
    const res = await service$e.queryDefaultView(this.schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async updateViewPreference(viewCode, items, parentSchemaCode) {
    const viewRes = await this.getViewByCode(viewCode);
    const view = viewRes.data;
    let res;
    let _items = items;
    if (view.type === "public") {
      if (items && Object.keys(items).length > 0) {
        if (!view.preference.changed) {
          _items.origin = cloneDeep(view.preference);
          _items.changed = true;
        }
        this._eventListener.dispatchEvent({
          type: "publicViewChanged",
          view,
          items: _items
        });
        res = {
          data: true
        };
      }
    } else {
      if (items && Object.keys(items).length > 0) {
        if (!view.preference.version) {
          _items = {
            ...view.preference,
            ...items
          };
        }
        _items.version = Date.now();
        res = await this._updateViewSetting(viewCode, _items);
      }
    }
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (parentSchemaCode) {
      const parentSchemaContext = super.getSchemaContext(this.appCode, parentSchemaCode);
      parentSchemaContext._schemaMetaData.updateViewPreference(viewCode, _items);
    } else {
      this._schemaMetaData.updateViewPreference(viewCode, _items);
    }
    return {
      data: res.data
    };
  }
  async savePreferenceToView(viewCode) {
    const superRoleRes = await this.getAppContext(this.appCode).getSuperRole();
    const superRole = superRoleRes.data;
    const isManagerAbove = superRole === AppRoleEnum.OWNER || superRole === AppRoleEnum.MANAGER;
    const viewRes = await this.getViewByCode(viewCode);
    const view = viewRes.data;
    if (isManagerAbove) {
      if (view.type === "public") {
        const items = {
          ...view.preference
        };
        items.version = Date.now();
        const upateSettingRes = await this._updateViewSetting(viewCode, items);
        if (upateSettingRes.data) {
          this._schemaMetaData.updateViewPreference(viewCode, {
            changed: false,
            origin: null
          });
          this._eventListener.dispatchEvent({
            type: "publicViewChanged",
            view,
            items: {
              changed: false,
              origin: null
            }
          });
          return {
            data: true
          };
        } else {
          return upateSettingRes;
        }
      } else {
        throw new Error("\u4E2A\u4EBA\u89C6\u56FE\u4E0D\u9700\u8981\u4FDD\u5B58");
      }
    } else {
      throw new Error("\u975E\u7BA1\u7406\u5458\u4EE5\u4E0A\u6743\u9650\uFF0C\u4E0D\u80FD\u5C06\u4FDD\u5B58\u516C\u5F00\u89C6\u56FE");
    }
  }
  async resetViewPreference(viewCode) {
    const viewRes = await this.getViewByCode(viewCode);
    const view = viewRes.data;
    if (view.preference.origin) {
      const items = {
        ...view.preference.origin,
        changed: false,
        origin: null
      };
      const cacheView = this._schemaMetaData.getView(viewCode);
      this._schemaMetaData.updateView({
        ...cacheView,
        preference: items
      });
      this._eventListener.dispatchEvent({
        type: "publicViewChanged",
        view,
        items: {
          changed: false,
          origin: null
        }
      });
    }
  }
  async createControl(control) {
    const res = await service$g.addControlV2(this.schemaCode, {
      displayName: control.displayName,
      type: control.type,
      properties: control.properties,
      displayRule: control.displayRule,
      tips: control.tips,
      required: control.required,
      prevCode: control.prevCode
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const ret = res.data;
    if (res.data && res.data.taskId && res.data.taskId.length > 0) {
      this._schemaMetaData.clearControls();
      this._controlCacheManager.clearControls();
      this._schemaMetaData.schemaPermission = null;
    } else {
      const ctrl = {
        code: ret.controlCode,
        displayName: control.displayName,
        displayRule: control.displayRule,
        prevCode: control.prevCode,
        required: control.required,
        status: 1,
        tips: control.tips,
        type: control.type,
        properties: control.properties
      };
      this._schemaMetaData.schemaPermission = null;
      if (this._schemaMetaData.getControls().length > 0) {
        this._schemaMetaData.addControl(ctrl);
      }
      this._controlCacheManager.addControl(ctrl);
    }
    const formatCtrl = {
      ...control,
      status: 1,
      code: ret.controlCode,
      taskId: ret.taskId
    };
    if (ret.relationSchemaCode) {
      formatCtrl.properties.relationCode = ret.relationSchemaCode;
    }
    if (ret.relationControlCode) {
      formatCtrl.properties.relationControlCode = ret.relationControlCode;
    }
    if (formatCtrl.properties.relationCode) {
      const schema = super.getSchemaContext(this.appCode, formatCtrl.properties.relationCode);
      if (schema) {
        schema._schemaMetaData.clearControls();
        schema._controlCacheManager.clearControls();
        schema._schemaMetaData.schemaPermission = null;
      }
    }
    return {
      data: formatCtrl
    };
  }
  async updateControl(control) {
    control.status = 1;
    const res = await service$g.updateControl(this.schemaCode, {
      ...control
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    await this.getControls();
    if (control.type === "Relationship") {
      const controls = this._schemaMetaData.getControls();
      const currentControl = controls == null ? void 0 : controls.find((item) => item.code === control.code);
      const before = currentControl == null ? void 0 : currentControl.properties.relationCode;
      const after = control.properties.relationCode;
      if (before !== after) {
        [before, after].forEach((item) => {
          if (item) {
            const schema = super.getSchemaContext(this.appCode, item);
            if (schema) {
              schema._schemaMetaData.clearControls();
              this._controlCacheManager.clearControls();
              schema._schemaMetaData.schemaPermission = null;
            }
          }
        });
        controls.forEach((item) => {
          if (item.properties.relationControlCode === currentControl.code) {
            const obj = {
              ...item,
              status: 0
            };
            this._schemaMetaData.updateControl(obj);
            this._controlCacheManager.updateControl(obj);
          }
        });
      }
    }
    this._schemaMetaData.updateControl(control);
    this._controlCacheManager.updateControl(control);
    console.log("\u66F4\u65B0updateControl", res);
    return res;
  }
  updateControlCache(control) {
    this._schemaMetaData.updateControl(control);
    this._controlCacheManager.updateControl(control);
  }
  async getUpdateControlProgress(taskId) {
    var _a, _b, _c, _d;
    const res = await service$4.queryTaskProgress(taskId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data && ((_a = res.data) == null ? void 0 : _a.isCompleted)) {
      this._schemaMetaData.clearControls();
      this._controlCacheManager.clearControls();
    }
    return {
      data: {
        progress: (_b = res.data) == null ? void 0 : _b.progress,
        tip: (_c = res.data) == null ? void 0 : _c.tip,
        isCompleted: (_d = res.data) == null ? void 0 : _d.isCompleted,
        status: res.data.status
      }
    };
  }
  async deleteControl(controlCode) {
    var _a;
    const res = await service$g.deleteControl(this.schemaCode, controlCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    await this.getControls();
    const currentControl = (_a = this._controlCacheManager.getControls()) == null ? void 0 : _a.find((item) => item.code === controlCode);
    if ((currentControl == null ? void 0 : currentControl.type) === "Relationship") {
      const before = currentControl == null ? void 0 : currentControl.properties.relationCode;
      if (before) {
        const schema = super.getSchemaContext(this.appCode, before);
        if (schema) {
          schema._schemaMetaData.clearControls();
          schema._controlCacheManager.clearControls();
          schema._schemaMetaData.schemaPermission = null;
        }
      }
    }
    if ((currentControl == null ? void 0 : currentControl.type) === "SubTable") {
      const relationCode = currentControl.properties.relationCode;
      const relationSchemaContext = this.getSchemaContext(this.appCode, relationCode);
      const relationControls = await relationSchemaContext.getControls();
      if (Array.isArray(relationControls.data)) {
        relationControls.data.forEach((item) => {
          if (item.type === "Relationship") {
            const schema = super.getSchemaContext(this.appCode, item.properties.relationCode);
            if (schema) {
              schema._schemaMetaData.clearControls();
              schema._controlCacheManager.clearControls();
              schema._schemaMetaData.schemaPermission = null;
            }
          }
        });
      }
    }
    this._schemaMetaData.removeControl(controlCode);
    this._controlCacheManager.removeControl(controlCode);
    return res;
  }
  async getControls(needAuth = true) {
    var _a;
    const cacheControls = needAuth ? this._schemaMetaData.getControls() : this._controlCacheManager.getControls();
    if (cacheControls && cacheControls.length > 0) {
      return Promise.resolve({
        data: cacheControls
      });
    }
    const params = {
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      needSubControl: true,
      needAuth,
      needStatus: true
    };
    const controlDtoArrayRes = await service$g.getAccssibleControls(this.schemaCode, params);
    if (controlDtoArrayRes.errorCode) {
      return {
        errorCode: controlDtoArrayRes.errorCode,
        errorMessage: controlDtoArrayRes.errorMessage
      };
    }
    if ((_a = controlDtoArrayRes == null ? void 0 : controlDtoArrayRes.data) == null ? void 0 : _a.controls) {
      this.completeControlPropeties(controlDtoArrayRes.data.controls);
      if (needAuth) {
        this._schemaMetaData.setControls(controlDtoArrayRes.data.controls);
        return {
          data: this._schemaMetaData.getControls()
        };
      } else {
        this._controlCacheManager.setControls(controlDtoArrayRes.data.controls);
        return {
          data: this._controlCacheManager.getControls()
        };
      }
    }
  }
  async getAllControls() {
    const res = await service$g.getAllControls(this.schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getControlDefaultProperty(controlCode, targetControlType) {
    const res = await service$g.getControlProperty(this.schemaCode, controlCode, targetControlType);
    return res;
  }
  async getSubControls() {
    const res = await service$g.getSubControls(this.appCode, this.schemaCode);
    const controls = [];
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      res.data.forEach((controlDto) => {
        let childrenControls = null;
        if (controlDto.childrenControls) {
          childrenControls = controlDto.childrenControls.map((item) => {
            return {
              code: item.code,
              displayName: item.displayName,
              displayRule: item.displayRule,
              prevCode: item.prevCode,
              required: item.required,
              status: item.status,
              tips: item.status,
              type: item.type,
              properties: item.properties
            };
          });
        }
        controls.push({
          code: controlDto.code,
          displayName: controlDto.displayName,
          displayRule: controlDto.displayRule,
          prevCode: controlDto.prevCode,
          required: controlDto.required,
          status: controlDto.status,
          tips: controlDto.status,
          type: controlDto.type,
          properties: controlDto.properties,
          childrenControls
        });
      });
    }
    return {
      data: controls
    };
  }
  async getSharedSetting() {
    const cacheSchemaSharedSetting = this._schemaMetaData.schemaSharedSetting;
    if (cacheSchemaSharedSetting) {
      return Promise.resolve({
        data: cacheSchemaSharedSetting
      });
    }
    const res = await service$g.getSharedSetting(this.schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (!res.data) {
      return {};
    }
    this._schemaMetaData.schemaSharedSetting = {
      sharedState: res.data.sharedState,
      sharedFields: res.data.sharedFields
    };
    return {
      data: this._schemaMetaData.schemaSharedSetting
    };
  }
  async saveSharedSetting(params) {
    let res;
    const cacheSchemaSharedSetting = this._schemaMetaData.schemaSharedSetting;
    if (cacheSchemaSharedSetting) {
      res = await service$g.updateSharedschemasetting({
        schemaCode: this.schemaCode,
        sharedState: params.sharedState,
        sharedFields: params.sharedFields
      });
    } else {
      res = await service$g.addShardSchema({
        schemaCode: this.schemaCode,
        sharedState: params.sharedState,
        sharedFields: params.sharedFields
      });
    }
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      const appContext = super.getAppContext(this.appCode);
      appContext.appMetaData.clearFunctionNodes();
      await appContext.getFunctionNodes();
      this._schemaMetaData.schemaSharedSetting = {
        ...cacheSchemaSharedSetting,
        ...params
      };
    }
    return {
      data: res.data
    };
  }
  async getSyncSchemaSetting() {
    var _a, _b, _c, _d;
    const res = await service$g.getSyncSetting(this.schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const sharedControls = [];
    if (res.data && res.data.sharedControls) {
      res.data.sharedControls.forEach((controlDto) => {
        sharedControls.push({
          code: controlDto.code,
          displayName: controlDto.displayName,
          displayRule: controlDto.displayRule,
          prevCode: controlDto.prevCode,
          required: controlDto.required,
          status: controlDto.status,
          tips: controlDto.status,
          type: controlDto.type,
          properties: controlDto.properties
        });
      });
    }
    return {
      data: {
        sharedControls,
        sourceSchemaCode: (_a = res.data) == null ? void 0 : _a.sourceSchemaCode,
        syncFieldMaps: (_b = res.data) == null ? void 0 : _b.syncFieldMaps,
        syncSharedFields: (_c = res.data) == null ? void 0 : _c.syncSharedFields,
        syncState: (_d = res.data) == null ? void 0 : _d.syncState
      }
    };
  }
  async saveSyncSchemaSetting(params) {
    const res = await service$g.updateSyncSetting({
      schemaCode: this.schemaCode,
      sourceSchemaCode: params.sourceSchemaCode,
      syncState: params.syncState,
      syncSharedFields: params.syncSharedFields,
      syncFieldMaps: params.syncFieldMaps,
      filterItems: params.filterItems
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      this._schemaMetaData.clearControls();
      this._schemaMetaData.schemaPermission = null;
    }
    return {
      data: res.data
    };
  }
  async getSyncSchemaRecord() {
    const res = await service$g.getSyncRecord(this.schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (!res.data) {
      return {};
    }
    return {
      data: {
        recordLevel: res.data.recordLevel,
        syncTime: res.data.syncTime,
        sharedAppCode: res.data.sharedAppCode,
        sharedSchemaCode: res.data.sharedSchemaCode,
        sharedAppName: res.data.sharedAppName,
        sharedSchemaName: res.data.sharedSchemaName,
        sharedAppOwnerName: res.data.sharedAppOwnerName,
        tips: res.data.tips
      }
    };
  }
  async getSubmitRule() {
    if (this._schemaMetaData.submitRules && this._schemaMetaData.submitRules.length > 0) {
      return Promise.resolve({
        data: this._schemaMetaData.submitRules
      });
    }
    const res = await service$g.getSubmitRule(this.appCode, this.schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      this._schemaMetaData.submitRules = res.data.map((item) => {
        return {
          ruleTitle: item.ruleTitle,
          submit: item.submit,
          rules: item.rules,
          type: item.type,
          tip: item.tip,
          order: item.order
        };
      });
    }
    return {
      data: this._schemaMetaData.submitRules
    };
  }
  async addSubmitRule(rule) {
    const res = await service$g.addSubmitRule([rule], this.appCode, this.schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      this._schemaMetaData.addSubmitRule(rule);
    }
    return {
      data: res.data
    };
  }
  async updateAllSubmitRule(rules) {
    const res = await service$g.updateSubmitRule(rules, this.appCode, this.schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      this._schemaMetaData.submitRules = rules;
    }
    return {
      data: res.data
    };
  }
  async generateShortLink(viewCode) {
    const res = await service$b.getShortLink(this.appCode, this.schemaCode, viewCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getRecords(params) {
    const req = {
      ...params,
      filterItems: DateParse.transform(params.filterItems),
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      scope: params.scope || null,
      queryItems: getQueryItemsWidthSystem(params.queryItems)
    };
    const res = await service$d.getList(this.appCode, this.schemaCode, req);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    } else {
      const rows = [];
      let total = 0;
      if (res.data && res.data.rows) {
        total = res.data.total;
        res.data.rows.forEach((row) => {
          rows.push({
            ObjectId: row.ObjectId,
            Name: row.Name,
            OwnerId: row.OwnerId,
            OwnerDeptId: row.OwnerDeptId,
            status: row.status,
            CreatedBy: row.CreatedBy,
            CreatedTime: row.CreatedTime,
            ModifiedBy: row.ModifiedBy,
            ModifiedTime: row.ModifiedTime,
            ...row
          });
        });
      }
      return {
        data: {
          rows,
          total
        }
      };
    }
  }
  async getRecordById(objectId) {
    const res = await service$d.getSingleData(this.schemaCode, objectId, this.appCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    } else {
      const data = res.data;
      const row = {
        ...data,
        ObjectId: data.ObjectId,
        Name: data.Name,
        OwnerId: data.OwnerId,
        OwnerDeptId: data.OwnerDeptId,
        status: data.status,
        CreatedBy: data.CreatedBy,
        CreatedTime: data.CreatedTime,
        ModifiedBy: data.ModifiedBy,
        ModifiedTime: data.ModifiedTime
      };
      return {
        data: row
      };
    }
  }
  async saveRecordV4(appCode, schemaCode, data) {
    const dto = {
      appCode,
      schemaCode,
      data
    };
    const res = await service$d.saveBizObjectV4(appCode, schemaCode, dto);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: {
        ...res.data
      }
    };
  }
  async batchSubmit(dto) {
    const res = await service$d.batchSubmitBizObject(dto);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: {
        ...res.data
      }
    };
  }
  async getColumnDatas(columnDataParams) {
    const req = {
      ...columnDataParams,
      filterItems: DateParse.transform(columnDataParams.filterItems)
    };
    const res = await service$d.getColumnDatas({
      ...req
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const columnDatas = [];
    if (res.data && res.data.length > 0) {
      res.data.forEach((element) => {
        columnDatas.push({
          count: element.count,
          value: element.value
        });
      });
    }
    return {
      data: columnDatas
    };
  }
  async getRecordStatistics(params) {
    const res = await service$d.getStatistics({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      viewCode: params.viewCode,
      columns: params.columns,
      filterItems: DateParse.transform(params.filterItems),
      sorts: params.sorts
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async batchDeleteRecord(bizObjectIds, uowId) {
    const res = await service$d.batchDeleteBizObject(this.schemaCode, {
      bizObjectIds,
      uowId
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getRecordExistFilter(objectId, filter) {
    var _a;
    const _filterItems = cloneDeep(DateParse.transform(filter));
    if (_filterItems[0] && _filterItems[0][0]) {
      const filterItems = cloneDeep(_filterItems);
      filterItems[0].push({
        code: "ObjectId",
        controlType: "ObjectId",
        method: "in",
        value: [objectId]
      });
      const params = {
        filterItems,
        sorts: [],
        pageIndex: 0,
        pageSize: 1,
        scope: null
      };
      const ret = await this.getRecords(params);
      const datas = ((_a = ret == null ? void 0 : ret.data) == null ? void 0 : _a.rows) || [];
      if (datas.length === 0) {
        return {
          data: false
        };
      }
    }
    return {
      data: true
    };
  }
  async bulkEditByIds(params) {
    return await service$d.bulkEditByIds({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      controlCode: params.controlCode,
      objIds: params.objIds,
      value: params.value
    });
  }
  async bulkEditByFilter(params) {
    return await service$d.bulkEditByFilter({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      controlCode: params.controlCode,
      filterItems: DateParse.transform(params.filterItems),
      editAll: params.editAll,
      sorts: params.sorts,
      value: params.value
    });
  }
  async getBulkEditProgress(taskId) {
    return await service$d.getBulkEditProgress(taskId);
  }
  async abortBulkEdit(taskId) {
    return await service$d.abortBulkEdit(taskId);
  }
  async getUnFinishedTask() {
    const memberId = this.getEngineInfo().memberId;
    return await service$d.queryUnFinishedTask(memberId, this.schemaCode);
  }
  async getSchemaPermission() {
    const cacheSchemaPermission = this._schemaMetaData.schemaPermission;
    if (cacheSchemaPermission) {
      return Promise.resolve({
        data: cacheSchemaPermission
      });
    }
    const accessRes = await service$c.getUserAccessPermission(this.appCode, this.schemaCode);
    if (accessRes.errorCode) {
      return {
        errorCode: accessRes.errorCode,
        errorMessage: accessRes.errorMessage
      };
    }
    const accessPermission = {
      add: false,
      delete: false,
      export: false,
      import: false,
      batchDelete: false,
      batchUpdate: false,
      share: false,
      viewHistory: false
    };
    if (accessRes.data) {
      accessPermission.add = accessRes.data.Add;
      accessPermission.delete = accessRes.data.Delete;
      accessPermission.export = accessRes.data.Export;
      accessPermission.import = accessRes.data.Import;
      accessPermission.batchDelete = accessRes.data.BatchDelete;
      accessPermission.batchUpdate = accessRes.data.BatchUpdate;
      accessPermission.share = accessRes.data.Share;
      accessPermission.viewHistory = accessRes.data.DataChangedHistory;
    }
    const fieldAddPermission = {};
    const fieldEditPermissions = [];
    const fieldRes = await service$c.getUserFieldPermissionV3(this.appCode, this.schemaCode);
    if (fieldRes.errorCode) {
      return {
        errorCode: accessRes.errorCode,
        errorMessage: accessRes.errorMessage
      };
    }
    const fieldPermissionSetting = fieldRes.data;
    if (fieldPermissionSetting) {
      const addSceneFieldPermission = fieldPermissionSetting.addSceneFieldPermission;
      const editSceneFieldPermission = fieldPermissionSetting.editSceneFieldPermission;
      if (addSceneFieldPermission && addSceneFieldPermission.fieldPermission) {
        Object.keys(addSceneFieldPermission.fieldPermission).forEach((field) => {
          fieldAddPermission[field] = addSceneFieldPermission.fieldPermission[field];
        });
      }
      if (editSceneFieldPermission && editSceneFieldPermission.conditioAddModels) {
        const conditioFieldPermissions = editSceneFieldPermission.conditioAddModels;
        conditioFieldPermissions.forEach((item) => {
          fieldEditPermissions.push({
            sceneName: item.sceneName,
            condition: item.condition,
            conditions: item.conditions,
            expression: item.expression,
            sceneId: item.sceneId,
            enabledFlag: item.enabledFlag,
            fieldEditPermission: item.fieldPermission
          });
        });
      }
    }
    this._schemaMetaData.schemaPermission = {
      accessPermission,
      fieldAddPermission,
      fieldEditPermissions
    };
    return {
      data: this._schemaMetaData.schemaPermission
    };
  }
  async addViewPermissionSetting(params) {
    const res = await service$c.addViewPermissionSetting(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async updateViewPermissionSetting(params) {
    const res = await service$c.updateViewPermissionSetting(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getViewPermissionSetting(viewCode) {
    const res = await service$c.getViewPermissionSetting(this.appCode, this.schemaCode, viewCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getViewFieldPermissionSetting(viewCode, subSchemaCode) {
    const res = await service$c.queryViewFieldPermissionSetting(this.appCode, viewCode, subSchemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getUserViewFieldPermissions(viewCode, subSchemaCode) {
    const res = await service$c.queryUserViewFieldPermissions(this.appCode, this.schemaCode, viewCode, subSchemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getViewSubTableAccessPermission(viewCode, subSchemaCode) {
    const res = await service$c.getViewAccessPermissionSetting(this.appCode, this.schemaCode, subSchemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async addAllPermissionsByRole(permissions) {
    const res = await service$c.addRoleAllPermissions(permissions);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async updateAllPermissionsByRole(permissions) {
    const res = await service$c.updateRoleAllPermissions(permissions);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getFieldPermissionsByRole(roleId, schemaCode) {
    const res = await service$c.queryRoleFieldPermissions(roleId, schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async updatePermissionscene(id, flag) {
    const res = await service$c.updatePermissionScene(id, flag);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getBizDataHistory(params) {
    const res = await service$d.getBizDataHistory({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      pageIndex: params.pageIndex,
      pageSize: params.pageSize
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getBizDataHistoryDetails(params) {
    const res = await service$d.getBizDataHistoryDetails({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      objectIds: params.objectIds,
      batchNo: params.batchNo,
      pageIndex: params.pageIndex,
      pageSize: params.pageSize
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getRelationDataValues(params) {
    let fn = service$d.queryRelationDataValues;
    if (params.relationSchemaType === "SUB_SCHEMA") {
      fn = service$d.queryRelationSubTableValues;
    }
    const res = await fn({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      controlCode: params.controlCode,
      objectId: params.objectId,
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      filterItems: DateParse.transform(params.filterItems),
      uowId: params.uowId,
      viewCode: params.viewCode,
      queryItems: getQueryItemsWidthSystem(params.queryItems)
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: {
        total: res.data.total,
        rows: res.data.rows
      }
    };
  }
  async getRelationDataStatus(params) {
    let fn = service$d.queryRelationDataStatus;
    if (params.relationSchemaType === "SUB_SCHEMA") {
      fn = service$d.queryRelationSubTableStatus;
    }
    const res = await fn({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      controlCode: params.controlCode,
      objectId: params.objectId,
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      filterItems: DateParse.transform(params.filterItems),
      uowId: params.uowId,
      queryItems: getQueryItemsWidthSystem(params.queryItems)
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: {
        total: res.data.total,
        rows: res.data.rows
      }
    };
  }
  async getSubTableDatalink(params) {
    const res = await service$d.datalink({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      changeControl: params.changeControl,
      content: params.content,
      uowId: params.uowId,
      returnRealType: true
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getSubTableDatalinkV1(params) {
    const res = await service$d.datalinkV1({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      changeControl: params.changeControl,
      content: params.content,
      uowId: params.uowId,
      returnRealType: true
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getViewPreference(viewCode) {
    const engine = super.getEngineInfo();
    const scope = engine.memberId;
    const res = await service$8.getPreference(viewCode, scope);
    if (res.errorCode) {
      console.error(`\u83B7\u53D6\u4E2A\u6027\u5316\u5931\u8D25\uFF0CviewCode: ${viewCode}, errorCode: ${res.errorCode}, errorMsg: ${res.errorMessage}`);
    }
    return res.data;
  }
  async getWorkflowControls(propertyCode, workItemId, subSchemaCode) {
    const res = await service$7.getWorkflowControls(propertyCode, workItemId, subSchemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getWorkflowFieldPermission(propertyCode, workItemId, subSchemaCode) {
    const res = await service$7.getWorkflowFieldPermission(propertyCode, workItemId, subSchemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getWorkflowAccessPermission(propertyCode, workItemId, memberId) {
    const ret = await service$7.getWorkflowAccessPermission(propertyCode, workItemId, memberId);
    const accessPermission = {
      add: false,
      delete: false,
      export: false,
      import: false,
      batchDelete: false,
      batchUpdate: false,
      share: false,
      viewHistory: false
    };
    if (ret.data) {
      accessPermission.add = ret.data.Add;
      accessPermission.delete = ret.data.Delete;
      accessPermission.export = ret.data.Export;
      accessPermission.import = ret.data.Import;
      accessPermission.batchDelete = ret.data.BatchDelete;
      accessPermission.batchUpdate = ret.data.BatchUpdate;
      accessPermission.share = ret.data.Share;
      accessPermission.viewHistory = ret.data.DataChangedHistory || false;
    }
    return {
      data: {
        ...accessPermission
      }
    };
  }
  async getWorkflowRecords(params) {
    const res = await service$7.getWorkflowRecords(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getWorkflowRecordById(workItemId) {
    const res = await service$7.getWorkflowRecordById(workItemId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async saveWorkflowSubRecord(record, bizObjectId = "", workitemId = "", parentObjectId = "", parentSchemaCode = "", subTableControlCode = "", uowId) {
    const dto = {
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      content: record,
      bizObjectId,
      parentObjectId,
      parentSchemaCode,
      subTableControlCode,
      uowId
    };
    const res = await service$7.saveWorkflowSubBizObject(dto, workitemId);
    return {
      data: {
        ...res.data,
        ObjectId: res.data.ObjectId,
        Name: res.data.Name,
        OwnerId: res.data.OwnerId,
        OwnerDeptId: res.data.OwnerDeptId,
        status: res.data.status,
        CreatedBy: res.data.CreatedBy,
        CreatedTime: res.data.CreatedTime,
        ModifiedBy: res.data.ModifiedBy,
        ModifiedTime: res.data.ModifiedTime
      }
    };
  }
  async updateWorkflowSubRecord(bizObjectId, workitemId = "", record, parentObjectId = "", parentSchemaCode = "", subTableControlCode = "", uowId) {
    const dto = {
      bizObjectId,
      schemaCode: this.schemaCode,
      appCode: this.appCode,
      content: record,
      parentObjectId,
      parentSchemaCode,
      subTableControlCode,
      uowId
    };
    const res = await service$7.updateWorkflowSubBizObject(dto, workitemId);
    if (!res.errorCode) {
      if (!res.data) {
        return {
          data: {}
        };
      }
      return {
        data: {
          ...res.data,
          ObjectId: res.data.ObjectId,
          Name: res.data.Name,
          OwnerId: res.data.OwnerId,
          OwnerDeptId: res.data.OwnerDeptId,
          status: res.data.status,
          CreatedBy: res.data.CreatedBy,
          CreatedTime: res.data.CreatedTime,
          ModifiedBy: res.data.ModifiedBy,
          ModifiedTime: res.data.ModifiedTime
        }
      };
    }
  }
  async deleteWorkfowSubRecord(workitemId, bizObjectId, uowId) {
    const res = await service$7.deleteWorkflowSubBizObject(workitemId, this.schemaCode, bizObjectId, uowId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async queryPrintTemplats(parms) {
    const res = await service$5.queryPrintTemplats(this.appCode, this.schemaCode, parms);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async queryScanData(parms) {
    const res = await service$d.queryScanData({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      controlCode: parms.controlCode,
      objectId: parms.objectId,
      toBeRelatedShortLinkCode: parms.toBeRelatedShortLinkCode,
      c: parms.charsetValue
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getBizDataQrCode(bizObjectId) {
    const res = await service$d.getBizDataQrCode(this.appCode, this.schemaCode, bizObjectId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getConversionPreview(control) {
    const res = await service$g.queryConversionPreview(this.schemaCode, control);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  completeControlPropeties(controls) {
    controls.forEach((item) => {
      const properties = item.properties;
      if (!properties) {
        item.properties = {};
      }
      const type = properties.targetType || item.type;
      if (type === "Relationship") {
        if (!properties.displayType) {
          if (properties.systemGenerated) {
            item.properties.displayType = "table";
          } else {
            item.properties.displayType = "card";
          }
        }
      }
    });
  }
  downloadTemplate(data) {
    return service$g.downloadTemplate(data);
  }
  createImportTask(data) {
    return service$g.createImportTask(data);
  }
  getImportTemplate(schemaCode, viewCode, importOption) {
    return service$a.getImportTemplate(schemaCode, viewCode, importOption);
  }
  batchUpdateBizData(data) {
    const req = {
      ...data,
      filters: DateParse.transform(data.filters)
    };
    return service$4.batchUpdateBizData(req);
  }
  batchDeleteBizData(data) {
    const req = {
      ...data,
      filters: DateParse.transform(data.filters)
    };
    return service$4.batchDeleteBizData(req);
  }
  dataConvert(data) {
    return service$4.dataConvert({
      targetSchemaCode: this._schemaCode,
      ...data
    });
  }
  expressionEvaluate(data) {
    return service$4.expressionEvaluate(data);
  }
  addCommentData(params) {
    return service$2.addComment(params);
  }
  queryCommentList(params) {
    return service$2.queryCommentList(params);
  }
  delCommentData(commentId) {
    return service$2.delComment(commentId);
  }
  getCommentFileInfo(params) {
    return service$2.getCommentFileInfo(params);
  }
  async getAppMember(params) {
    const res = await service$2.getAppMember(params);
    return res;
  }
  getCommentFileUrl(params) {
    return service$2.getCommentFileUrl(params);
  }
  async updateSchemaTitle(titleCode) {
    const params = {
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      titleCode
    };
    const res = await service$g.updateSchemaTitle(params);
    if (res.data) {
      const controls = this._schemaMetaData.getControls();
      controls.forEach((item) => {
        if (item.titleFlag) {
          const oldControl = {
            ...item,
            titleFlag: false
          };
          this._schemaMetaData.updateControl(oldControl);
          this._controlCacheManager.updateControl(oldControl);
        }
      });
      const newTitleControl = controls.find((item) => item.code === titleCode);
      if (newTitleControl) {
        const newControl = {
          ...newTitleControl,
          titleFlag: true
        };
        this._schemaMetaData.updateControl(newControl);
        this._controlCacheManager.updateControl(newControl);
      }
    }
    return res;
  }
  querySchemaReferenced(params) {
    const data = {
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      valid: params.valid,
      controlCode: params.controlCode
    };
    return service$g.querySchemaReferenced(data);
  }
  getShared() {
    return service$g.getShared({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      authCode: null
    });
  }
  getSharedByAuthCode(authCode) {
    return service$g.getShared({
      appCode: null,
      schemaCode: null,
      authCode
    });
  }
  addSyncSchemaV3(data) {
    data.appCode = this.appCode;
    return service$g.addSyncSchemaV3(data);
  }
  queryBySyncSchema(sharedAppCode, sharedSchemaCode, sharedAuthCode) {
    return service$g.querySyncSchema({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      sharedAppCode: null,
      sharedSchemaCode: null,
      sharedAuthCode
    });
  }
  queryByShareSchema(sharedAppCode, sharedSchemaCode, sharedAuthCode) {
    return service$g.querySyncSchema({
      appCode: null,
      schemaCode: null,
      sharedAppCode,
      sharedSchemaCode,
      sharedAuthCode
    });
  }
  async queryByShareSchemaForEdit() {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const res = await service$g.querySyncSchema({
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      sharedAppCode: null,
      sharedSchemaCode: null,
      sharedAuthCode: null
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const sharedControls = [];
    if (res.data && res.data[0] && res.data[0].sharedControls) {
      res.data[0].sharedControls.forEach((controlDto) => {
        sharedControls.push({
          code: controlDto.code,
          displayName: controlDto.displayName,
          displayRule: controlDto.displayRule,
          prevCode: controlDto.prevCode,
          required: controlDto.required,
          status: controlDto.status,
          tips: controlDto.status,
          type: controlDto.type,
          properties: controlDto.properties
        });
      });
      return {
        data: {
          sharedControls,
          sourceSchemaCode: (_b = (_a = res.data) == null ? void 0 : _a[0]) == null ? void 0 : _b.schemaCode,
          syncFieldMaps: {},
          syncSharedFields: (_d = (_c = res.data) == null ? void 0 : _c[0]) == null ? void 0 : _d.syncFields,
          syncState: (_f = (_e = res.data) == null ? void 0 : _e[0]) == null ? void 0 : _f.syncState,
          id: (_h = (_g = res.data) == null ? void 0 : _g[0]) == null ? void 0 : _h.id
        }
      };
    }
    return {};
  }
  addShareSet(displayName, sharedFields) {
    return service$g.addShareSet(this.schemaCode, {
      appCode: this.appCode,
      displayName,
      sharedFields
    });
  }
  editShareSet(data) {
    data.appCode = this.appCode;
    return service$g.editShareSet(this.schemaCode, data);
  }
  deleteShareSet(id) {
    return service$g.deleteShareSet(this.schemaCode, id);
  }
  deleteSyncSchema() {
    return service$g.deleteSyncSchema(this.schemaCode);
  }
  editSyncSet(data) {
    data.appCode = this.appCode;
    return service$g.editSyncSet(this.schemaCode, data);
  }
  deleteSyncSet(id) {
    return service$g.deleteSyncSet(this.schemaCode, id);
  }
  getSyncStatus() {
    return service$g.getSyncStatus(this.schemaCode);
  }
  clearControlsCache() {
    this._schemaMetaData.clearControls();
    this._controlCacheManager.clearControls();
  }
  queryIntegrationConfig() {
    return service$g.queryIntegrationConfig(this.appCode, this.schemaCode);
  }
  integrationOriginColumn(integrationConnection, tableName) {
    return service$g.integrationOriginColumn(integrationConnection, tableName);
  }
  addIntegrationOrigin(integrationConfig) {
    return service$g.addIntegrationOrigin(integrationConfig);
  }
  updateIntegrationOrigin(integrationConfig) {
    return service$g.updateIntegrationOrigin(integrationConfig);
  }
  deleteIntegrationOrigin(id) {
    return service$g.deleteIntegrationOrigin(id);
  }
  integrationDbtypes() {
    return service$g.integrationDbtypes();
  }
  integrationDbtypesField(dbType) {
    return service$g.integrationDbtypesField(dbType);
  }
  integrationHandelSync(id) {
    return service$g.integrationHandelSync(id);
  }
  integrationDbTables(integrationConnection) {
    return service$g.integrationDbTables(integrationConnection);
  }
  integrationUpdateName(newName, id) {
    return service$g.integrationUpdateName(newName, id, this.appCode);
  }
  getReportDataSource() {
    return service$g.getReportDataSourceByAppCode(this.appCode);
  }
  getReportDataSourceMetadata(dataSourceId) {
    return service$g.getReportDataSourceMetadata(dataSourceId);
  }
  addEvent(type, listener) {
    this._eventListener.addEventListener(type, listener);
  }
  removeEvent(type, listener) {
    this._eventListener.removeEventListener(type, listener);
  }
  getColumnDistinct(req) {
    return service$d.getColumnDistinct(req);
  }
}
class UserContext extends ContextWrapper {
  constructor() {
    super();
    this.nodeType = DomainTypeEnum.User;
  }
  async load() {
    this.userBasicInfo = {
      userId: "",
      userName: "",
      avatarUrl: "",
      personalSignature: "",
      email: "",
      mobile: ""
    };
    await Promise.all([service$h.getUserIntegrateEngine(), service$j.getUserBasicInfo()]).then((values) => {
      var _a;
      const [corpResponse, userResponse] = values;
      if (corpResponse.errorCode) {
        return corpResponse;
      }
      if (userResponse.errorCode) {
        return userResponse;
      }
      this.userBasicInfo.userId = corpResponse.data.userId;
      if (((_a = corpResponse.data) == null ? void 0 : _a.corpIntegrateEngineAO) && corpResponse.data.corpIntegrateEngineAO.length > 0) {
        corpResponse.data.corpIntegrateEngineAO.forEach((engineAO) => {
          const engine = {
            engineCode: engineAO.engineCode,
            corpId: engineAO.corpId,
            corpName: engineAO.corpName,
            engineShard: engineAO.engineShard,
            entryType: engineAO.entryType,
            lastActive: engineAO.lastActive,
            state: engineAO.state,
            ownFlag: engineAO.ownFlag,
            memberId: engineAO.memberId,
            adminFlag: engineAO.adminFlag
          };
          this.getContextMetaData().addEngine(engine);
        });
      }
      if (userResponse.data) {
        this.userBasicInfo.userName = userResponse.data.userName;
        this.userBasicInfo.avatarUrl = userResponse.data.avatarUrl;
        this.userBasicInfo.personalSignature = userResponse.data.personalSignature;
        this.userBasicInfo.email = userResponse.data.email;
        this.userBasicInfo.mobile = userResponse.data.mobile;
      }
    }).catch((reason) => {
      throw reason;
    });
  }
  getUserInfo() {
    return this.userBasicInfo;
  }
  getToken() {
    return window.localStorage.getItem("X-H3-Token") || "";
  }
  async updateUserInfo(params) {
    const response = service$j.updateUserBasicInfo({
      userName: params.userName,
      avatarUrl: params.avatarUrl,
      personalSignature: params.personalSignature
    });
    const res = await response;
    if (!res.errorCode) {
      this.userBasicInfo = {
        userId: res.data.id,
        userName: res.data.userName,
        avatarUrl: res.data.avatarUrl,
        personalSignature: res.data.personalSignature,
        email: res.data.email,
        mobile: res.data.mobile
      };
    }
    return {
      errorCode: res.errorCode,
      errorMessage: res.errorMessage,
      data: !res.errorCode
    };
  }
  getEngines() {
    return this.getContextMetaData().getEngines();
  }
  async resetEngines() {
    this.getContextMetaData().clearEngines();
    const corpResponse = await service$h.getUserIntegrateEngine();
    if (corpResponse.data.corpIntegrateEngineAO && corpResponse.data.corpIntegrateEngineAO.length > 0) {
      corpResponse.data.corpIntegrateEngineAO.forEach((engineAO, index) => {
        const engine = {
          engineCode: engineAO.engineCode,
          corpId: engineAO.corpId,
          corpName: engineAO.corpName,
          engineShard: engineAO.engineShard,
          entryType: engineAO.entryType,
          lastActive: engineAO.lastActive,
          state: engineAO.state,
          ownFlag: engineAO.ownFlag,
          adminFlag: engineAO.adminFlag,
          memberId: engineAO.memberId
        };
        this.getContextMetaData().addEngine(engine);
        if (index === 0) {
          this.updateEngine(engine);
        }
      });
    }
  }
  updateEngine(engine) {
    super.setEngineInfo(engine);
  }
  async getLatestEngines() {
    const res = await service$h.getUserIntegrateEngine();
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    this.userBasicInfo.userId = res.data.userId;
    if (res.data.corpIntegrateEngineAO && res.data.corpIntegrateEngineAO.length > 0) {
      res.data.corpIntegrateEngineAO.forEach((engineAO) => {
        const engine = {
          engineCode: engineAO.engineCode,
          corpId: engineAO.corpId,
          corpName: engineAO.corpName,
          engineShard: engineAO.engineShard,
          entryType: engineAO.entryType,
          lastActive: engineAO.lastActive,
          state: engineAO.state,
          ownFlag: engineAO.ownFlag,
          adminFlag: engineAO.adminFlag,
          memberId: engineAO.memberId
        };
        super.getContextMetaData().addEngine(engine);
      });
    }
    return {
      data: this.getContextMetaData().getEngines()
    };
  }
  async changeEngineActive(targetEngineCode) {
    const thisEngine = super.getEngineInfo();
    const engine = this.getContextMetaData().getEngine(targetEngineCode);
    if (!engine) {
      throw new Error("engine[" + thisEngine.engineCode + "] not found");
    }
    if (engine) {
      const res = await service$h.changeCorp({
        fromCorpId: thisEngine.corpId,
        fromCorpName: thisEngine.corpName,
        fromEngineCode: thisEngine.engineCode,
        fromMemberId: thisEngine.memberId,
        toCorpId: engine.corpId,
        toCorpName: engine.corpName,
        toEngineCode: engine.engineCode,
        toMemberId: engine.memberId
      });
      this.getEngines().forEach((item) => {
        item.lastActive = 20;
      });
      thisEngine.lastActive = 10;
      if (res.errorCode) {
        return {
          errorCode: res.errorCode,
          errorMessage: res.errorMessage
        };
      }
      if (res.data) {
        window.localStorage.setItem("X-H3-EngineCode", res.data.engineCode);
        window.localStorage.setItem("X-Engine-Shard", res.data.engineShard);
        window.localStorage.setItem("X-H3-MemberId", res.data.memberId);
      }
      return {
        ...res,
        data: !res.errorCode
      };
    }
    return Promise.resolve({
      data: false
    });
  }
  async getAppCross(engineCode, token) {
    const res = await service$h.appCross(engineCode, token);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      window.localStorage.setItem("X-H3-EngineCode", engineCode);
      window.localStorage.setItem("X-H3-Token", token);
      window.localStorage.setItem("X-Engine-Shard", res.data.engineShard);
      window.localStorage.setItem("X-H3-MemberId", res.data.memberId);
    }
    return {
      data: res.data
    };
  }
  async leaveCorp(engineCode, memberId) {
    const res = await service$9.leaveCorp(engineCode, memberId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data) {
      super.getContextMetaData().removeEngine(engineCode);
    }
    return {
      data: res.data
    };
  }
  async loginTerminalCheck() {
    const res = await service$h.loginTerminalCheck();
    if (res == null ? void 0 : res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getAppletterList(params) {
    const res = await service$3.queryAppletterList(params.offset, params.pageSize, params.readStatus, params.userId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: {
        totalCount: res.data.totalCount,
        resultList: res.data.resultList
      }
    };
  }
  async countTodo(participantId) {
    const res = await service$4.countTodo(participantId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async pageTodoList(params) {
    const res = await service$4.queryTodosInfo(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async pageOriginalList(params) {
    const res = await service$4.queryOriginalTodos(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
}
let _fileRequest;
let _fileRequestSimple;
function loadFileApi(id) {
  return request$1.get(`/v1/attachment/${id}`);
}
function uploadFileApi(params) {
  const schemaCode = params.get("schemaCode");
  const propertyCode = params.get("propertyCode");
  return request$1.post(`/v1/attachment?schemaCode=${schemaCode}&propertyCode=${propertyCode}`, {
    body: params
  });
}
function downloadFileApi(id) {
  return request$1.get(`/v1/attachment/download/${id}`, {
    parseResponse: false,
    responseType: "blob",
    getResponse: true
  }).then((resp) => resp.blob());
}
async function uploadAttachmentByFs(params) {
  const file = params.file;
  const fileName = params.fileName || file.name;
  const preRes = params.externalUploadTokenApi ? await params.externalUploadTokenApi() : await service$i.attachmentPresubmission({
    schemaCode: params.schemaCode,
    fileControlCode: params.fileControlCode,
    fileInfos: [{
      name: fileName,
      suffix: fileName.substring(fileName.lastIndexOf(".") + 1),
      contentType: file.type || "application/octet-stream",
      contentLength: file.size
    }]
  });
  if (preRes.errorCode) {
    return preRes;
  }
  const storageKey = preRes.data.storageKey;
  const filePath = preRes.data.fileInfos[0].filePath;
  const token = preRes.data.token;
  const uploadUrl = `${params.fsUrl}/v1/file/${storageKey}?filePath=${encodeURIComponent(filePath)}`;
  const headerKey = "X-FileStorage-AuthParams";
  const engineCodeKey = "X-H3-EngineCode";
  if (!_fileRequest && !params.externalUploadApi) {
    _fileRequest = extend({});
    _fileRequest.interceptors.request.use((url, options) => {
      const optionsHeaders = (options == null ? void 0 : options.headers) || {};
      return {
        url,
        options: {
          ...options,
          headers: {
            [headerKey]: optionsHeaders[headerKey],
            [engineCodeKey]: params.engineCode
          }
        }
      };
    }, {
      global: false
    });
  }
  const fileRes = params.externalUploadApi ? await params.externalUploadApi({
    uploadUrl,
    method: "post",
    headerOptions: {
      [headerKey]: JSON.stringify({
        Token: token
      })
    },
    file,
    postParams: {}
  }) : await _fileRequest(uploadUrl, {
    method: "post",
    headers: {
      [headerKey]: JSON.stringify({
        Token: token
      })
    },
    body: params.formData
  });
  if (fileRes.errorCode) {
    return fileRes;
  }
  const externalRes = await service$i.attachementQueryExternalinfo([{
    filePath,
    contentType: file.type,
    name: fileName
  }]);
  if (externalRes.errorCode) {
    return externalRes;
  }
  return {
    data: {
      name: fileName,
      fileId: preRes.data.fileInfos[0].fileId,
      downloadUrl: externalRes.data[0].downloadUrl,
      previewUrl: externalRes.data[0].previewUrl,
      resizeUrl: externalRes.data[0].resizeUrl,
      size: file.size
    }
  };
}
async function uploadFileApiFs(params) {
  try {
    const fsUrl = window.__ENV_STATE__._H3_FS_URL_;
    const res = await uploadAttachmentByFs({
      schemaCode: params.schemaCode,
      fileControlCode: params.fileControlCode,
      file: params.file,
      fileName: params.fileName,
      formData: params.formData,
      fsUrl,
      engineCode: params.engineCode,
      externalUploadTokenApi: params.externalUploadTokenApi,
      externalUploadApi: params.externalUploadApi
    });
    return res;
  } catch (error) {
    console.error(error);
    return `${error}`;
  }
}
async function uploadFileApiSimple(params) {
  try {
    const fsUrl = window.__ENV_STATE__._H3_FS_URL_;
    const uploadUrl = `${fsUrl}/v1/file/${params.storageKey}?filePath=${encodeURIComponent(params.filePath)}`;
    if (!_fileRequestSimple) {
      _fileRequestSimple = extend({});
      _fileRequestSimple.interceptors.request.use((url, options) => {
        return {
          url,
          options: {
            ...options,
            headers: {
              ...params.headers ? params.headers : {}
            }
          }
        };
      }, {
        global: false
      });
    }
    const fileRes = await _fileRequestSimple(uploadUrl, {
      method: "post",
      body: params.formData
    });
    return fileRes;
  } catch (error) {
    console.error(error);
    return `${error}`;
  }
}
function attachementQueryExternalinfo(data) {
  return service$i.attachementQueryExternalinfo(data);
}
async function previewFileApi(params) {
  try {
    const fsUrl = window.__ENV_STATE__._H3_FS_URL_;
    const uploadUrl = `${fsUrl}/v1/file/${params.storageKey}/url?filePath=${encodeURIComponent(params.filePath)}`;
    if (!_fileRequestSimple) {
      _fileRequestSimple = extend({});
      _fileRequestSimple.interceptors.request.use((url, options) => {
        return {
          url,
          options: {
            ...options,
            headers: {
              ...params.headers ? params.headers : {}
            }
          }
        };
      }, {
        global: false
      });
    }
    const fileRes = await _fileRequestSimple(uploadUrl, {
      method: "post",
      data: {
        auth: "true",
        processType: "download",
        expirationMs: Date.now() + 1e3 * 60 * 60 * 8
      }
    });
    return fileRes;
  } catch (error) {
    console.error(error);
    return `${error}`;
  }
}
const FileApi = {
  loadFileApi,
  uploadFileApi,
  uploadAttachmentByFs,
  downloadFileApi,
  uploadFileApiFs,
  uploadFileApiSimple,
  attachementQueryExternalinfo,
  previewFileApi
};
class CommonContext extends ContextWrapper {
  constructor() {
    super();
    this.nodeType = DomainTypeEnum.Common;
  }
  async getLatestPct() {
    const addrCacheKey = "UG_ADDR_LOCAL_CACHE";
    const addrCacheVersion = 628;
    const addrDataJson = window.localStorage.getItem(addrCacheKey);
    const addrData = JSON.parse(addrDataJson);
    if (addrData && addrData.version >= addrCacheVersion) {
      this.LastPct = addrData.data;
      return {
        data: addrData.data
      };
    }
    const res = await service$i.queryLatestPct();
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    const cache = {
      version: addrCacheVersion,
      data: JSON.parse(res.data.pctData)
    };
    window.localStorage.setItem(addrCacheKey, JSON.stringify(cache));
    this.LastPct = res.data.pctData;
    return {
      data: JSON.parse(res.data.pctData)
    };
  }
  async getTaskProgress(taskId) {
    const res = await service$4.queryTaskProgress(taskId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getAllTaskProgress(params) {
    const res = await service$4.getUnFinishedTaskProgress(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async attachmentByFs(params) {
    return FileApi.uploadAttachmentByFs(params);
  }
}
var TriggeredWays = /* @__PURE__ */ ((TriggeredWays3) => {
  TriggeredWays3[TriggeredWays3["Create"] = 0] = "Create";
  TriggeredWays3[TriggeredWays3["Edit"] = 1] = "Edit";
  TriggeredWays3[TriggeredWays3["Delete"] = 2] = "Delete";
  TriggeredWays3[TriggeredWays3["Unspecified"] = -1] = "Unspecified";
  return TriggeredWays3;
})(TriggeredWays || {});
var BizDataType = /* @__PURE__ */ ((BizDataType2) => {
  BizDataType2[BizDataType2["Bool"] = 1] = "Bool";
  BizDataType2[BizDataType2["DateTime"] = 5] = "DateTime";
  BizDataType2[BizDataType2["Double"] = 7] = "Double";
  BizDataType2[BizDataType2["Int"] = 9] = "Int";
  BizDataType2[BizDataType2["Long"] = 11] = "Long";
  BizDataType2[BizDataType2["Decimal"] = 12] = "Decimal";
  BizDataType2[BizDataType2["String"] = 13] = "String";
  BizDataType2[BizDataType2["ShortString"] = 14] = "ShortString";
  BizDataType2[BizDataType2["SN"] = 15] = "SN";
  BizDataType2[BizDataType2["ByteArray"] = 20] = "ByteArray";
  BizDataType2[BizDataType2["Image"] = 23] = "Image";
  BizDataType2[BizDataType2["File"] = 24] = "File";
  BizDataType2[BizDataType2["TimeSpan"] = 25] = "TimeSpan";
  BizDataType2[BizDataType2["User"] = 26] = "User";
  BizDataType2[BizDataType2["UserArray"] = 27] = "UserArray";
  BizDataType2[BizDataType2["Department"] = 28] = "Department";
  BizDataType2[BizDataType2["DepartmentArray"] = 29] = "DepartmentArray";
  BizDataType2[BizDataType2["Html"] = 30] = "Html";
  BizDataType2[BizDataType2["Xml"] = 32] = "Xml";
  BizDataType2[BizDataType2["Json"] = 33] = "Json";
  BizDataType2[BizDataType2["JsonArray"] = 34] = "JsonArray";
  BizDataType2[BizDataType2["BizObjectArray"] = 41] = "BizObjectArray";
  BizDataType2[BizDataType2["Relationship"] = 50] = "Relationship";
  BizDataType2[BizDataType2["Map"] = 55] = "Map";
  BizDataType2[BizDataType2["Address"] = 56] = "Address";
  BizDataType2[BizDataType2["Unspecified"] = -1] = "Unspecified";
  return BizDataType2;
})(BizDataType || {});
var WorkflowState = /* @__PURE__ */ ((WorkflowState2) => {
  WorkflowState2[WorkflowState2["Inactive"] = 0] = "Inactive";
  WorkflowState2[WorkflowState2["Active"] = 1] = "Active";
  WorkflowState2[WorkflowState2["Unspecified"] = -1] = "Unspecified";
  return WorkflowState2;
})(WorkflowState || {});
var ActivityType = /* @__PURE__ */ ((ActivityType3) => {
  ActivityType3[ActivityType3["Start"] = 0] = "Start";
  ActivityType3[ActivityType3["End"] = 1] = "End";
  ActivityType3[ActivityType3["Connection"] = 2] = "Connection";
  ActivityType3[ActivityType3["FillSheet"] = 3] = "FillSheet";
  ActivityType3[ActivityType3["Approve"] = 4] = "Approve";
  ActivityType3[ActivityType3["SubInstance"] = 9] = "SubInstance";
  ActivityType3[ActivityType3["Notification"] = 25] = "Notification";
  ActivityType3[ActivityType3["Launch"] = 30] = "Launch";
  ActivityType3[ActivityType3["BizRuleCreate"] = 35] = "BizRuleCreate";
  ActivityType3[ActivityType3["BizRuleUpdate"] = 36] = "BizRuleUpdate";
  ActivityType3[ActivityType3["BizRuleDelete"] = 37] = "BizRuleDelete";
  return ActivityType3;
})(ActivityType || {});
var EntryConditionType = /* @__PURE__ */ ((EntryConditionType2) => {
  EntryConditionType2[EntryConditionType2["Any"] = 0] = "Any";
  EntryConditionType2[EntryConditionType2["All"] = 1] = "All";
  return EntryConditionType2;
})(EntryConditionType || {});
var ApproveType = /* @__PURE__ */ ((ApproveType2) => {
  ApproveType2["countersign"] = "100%";
  ApproveType2["orsing"] = "1";
  return ApproveType2;
})(ApproveType || {});
var SkipPolicy = /* @__PURE__ */ ((SkipPolicy2) => {
  SkipPolicy2[SkipPolicy2["None"] = 0] = "None";
  SkipPolicy2[SkipPolicy2["Participated"] = 1] = "Participated";
  SkipPolicy2[SkipPolicy2["LastActivityParticipant"] = 2] = "LastActivityParticipant";
  return SkipPolicy2;
})(SkipPolicy || {});
var ControlStatus = /* @__PURE__ */ ((ControlStatus2) => {
  ControlStatus2[ControlStatus2["Invalid"] = 0] = "Invalid";
  ControlStatus2[ControlStatus2["Available"] = 1] = "Available";
  ControlStatus2[ControlStatus2["None"] = -1] = "None";
  return ControlStatus2;
})(ControlStatus || {});
var BoolValue = /* @__PURE__ */ ((BoolValue2) => {
  BoolValue2[BoolValue2["False"] = 0] = "False";
  BoolValue2[BoolValue2["True"] = 1] = "True";
  BoolValue2[BoolValue2["Unspecified"] = -1] = "Unspecified";
  return BoolValue2;
})(BoolValue || {});
var WorkflowAction = /* @__PURE__ */ ((WorkflowAction2) => {
  WorkflowAction2[WorkflowAction2["Submit"] = 0] = "Submit";
  WorkflowAction2[WorkflowAction2["Approval"] = 1] = "Approval";
  WorkflowAction2[WorkflowAction2["Reject"] = 2] = "Reject";
  WorkflowAction2[WorkflowAction2["Transfer"] = 3] = "Transfer";
  return WorkflowAction2;
})(WorkflowAction || {});
var WorkflowInstanceState = /* @__PURE__ */ ((WorkflowInstanceState2) => {
  WorkflowInstanceState2[WorkflowInstanceState2["Initiated"] = 0] = "Initiated";
  WorkflowInstanceState2[WorkflowInstanceState2["Starting"] = 1] = "Starting";
  WorkflowInstanceState2[WorkflowInstanceState2["Running"] = 2] = "Running";
  WorkflowInstanceState2[WorkflowInstanceState2["Finishing"] = 3] = "Finishing";
  WorkflowInstanceState2[WorkflowInstanceState2["Finished"] = 4] = "Finished";
  WorkflowInstanceState2[WorkflowInstanceState2["Canceled"] = 5] = "Canceled";
  WorkflowInstanceState2[WorkflowInstanceState2["Exception"] = 6] = "Exception";
  WorkflowInstanceState2[WorkflowInstanceState2["Unfinished"] = 7] = "Unfinished";
  WorkflowInstanceState2[WorkflowInstanceState2["NotCanceled"] = 8] = "NotCanceled";
  WorkflowInstanceState2[WorkflowInstanceState2["Unspecified"] = 9] = "Unspecified";
  return WorkflowInstanceState2;
})(WorkflowInstanceState || {});
var ActivityType2 = /* @__PURE__ */ ((ActivityType22) => {
  ActivityType22[ActivityType22["Start"] = 0] = "Start";
  ActivityType22[ActivityType22["End"] = 1] = "End";
  ActivityType22[ActivityType22["Connection"] = 2] = "Connection";
  ActivityType22[ActivityType22["FillSheet"] = 3] = "FillSheet";
  ActivityType22[ActivityType22["Approve"] = 4] = "Approve";
  ActivityType22[ActivityType22["SubInstance"] = 9] = "SubInstance";
  ActivityType22[ActivityType22["Notification"] = 25] = "Notification";
  ActivityType22[ActivityType22["Launch"] = 30] = "Launch";
  ActivityType22[ActivityType22["BizRuleCreate"] = 35] = "BizRuleCreate";
  ActivityType22[ActivityType22["BizRuleUpdate"] = 36] = "BizRuleUpdate";
  ActivityType22[ActivityType22["BizRuleDelete"] = 37] = "BizRuleDelete";
  return ActivityType22;
})(ActivityType2 || {});
var WorkflowTriggerType = /* @__PURE__ */ ((WorkflowTriggerType2) => {
  WorkflowTriggerType2[WorkflowTriggerType2["Sheet"] = 0] = "Sheet";
  WorkflowTriggerType2[WorkflowTriggerType2["FixedCronJob"] = 1] = "FixedCronJob";
  WorkflowTriggerType2[WorkflowTriggerType2["DateFieldCronJob"] = 2] = "DateFieldCronJob";
  WorkflowTriggerType2[WorkflowTriggerType2["Unspecified"] = -1] = "Unspecified";
  return WorkflowTriggerType2;
})(WorkflowTriggerType || {});
var TriggeredWays2 = /* @__PURE__ */ ((TriggeredWays22) => {
  TriggeredWays22[TriggeredWays22["Create"] = 0] = "Create";
  TriggeredWays22[TriggeredWays22["Edit"] = 1] = "Edit";
  TriggeredWays22[TriggeredWays22["Delete"] = 2] = "Delete";
  TriggeredWays22[TriggeredWays22["Unspecified"] = -1] = "Unspecified";
  return TriggeredWays22;
})(TriggeredWays2 || {});
var TokenState = /* @__PURE__ */ ((TokenState2) => {
  TokenState2[TokenState2["Running"] = 0] = "Running";
  TokenState2[TokenState2["Finished"] = 1] = "Finished";
  TokenState2[TokenState2["Dropped"] = 2] = "Dropped";
  TokenState2[TokenState2["Rollback"] = 3] = "Rollback";
  TokenState2[TokenState2["Rejected"] = 4] = "Rejected";
  TokenState2[TokenState2["Vetod"] = 5] = "Vetod";
  TokenState2[TokenState2["Exception"] = 6] = "Exception";
  TokenState2[TokenState2["Unspecified"] = 10] = "Unspecified";
  TokenState2[TokenState2["Undropped"] = 11] = "Undropped";
  return TokenState2;
})(TokenState || {});
var WorkItemState = /* @__PURE__ */ ((WorkItemState2) => {
  WorkItemState2[WorkItemState2["Waiting"] = 0] = "Waiting";
  WorkItemState2[WorkItemState2["Working"] = 1] = "Working";
  WorkItemState2[WorkItemState2["Finished"] = 2] = "Finished";
  WorkItemState2[WorkItemState2["Forwarded"] = 3] = "Forwarded";
  WorkItemState2[WorkItemState2["Retrieved"] = 4] = "Retrieved";
  WorkItemState2[WorkItemState2["Rejected"] = 6] = "Rejected";
  WorkItemState2[WorkItemState2["Vetoed"] = 7] = "Vetoed";
  WorkItemState2[WorkItemState2["Canceled"] = 8] = "Canceled";
  WorkItemState2[WorkItemState2["Invalid"] = 9] = "Invalid";
  WorkItemState2[WorkItemState2["Unfinished"] = 11] = "Unfinished";
  WorkItemState2[WorkItemState2["NotCanceled"] = 12] = "NotCanceled";
  WorkItemState2[WorkItemState2["Unspecified"] = -1] = "Unspecified";
  return WorkItemState2;
})(WorkItemState || {});
class WorkflowContext extends ContextWrapper {
  constructor(appCode, schemaCode) {
    super();
    this.nodeType = DomainTypeEnum.Workflow;
    this.appCode = appCode;
    this.schemaCode = schemaCode;
  }
  async getWorkflowControls(propertyCode, workItemId, subSchemaCode) {
    const res = await service$7.getWorkflowControls(propertyCode, workItemId, subSchemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getWorkflowFieldPermission(propertyCode, workItemId, subSchemaCode) {
    const res = await service$7.getWorkflowFieldPermission(propertyCode, workItemId, subSchemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getWorkflowAccessPermission(propertyCode, workItemId, memberId) {
    const ret = await service$7.getWorkflowAccessPermission(propertyCode, workItemId, memberId);
    const accessPermission = {
      add: false,
      delete: false,
      export: false,
      import: false,
      batchDelete: false,
      batchUpdate: false,
      share: false,
      viewHistory: false
    };
    if (ret.data) {
      accessPermission.add = ret.data.Add;
      accessPermission.delete = ret.data.Delete;
      accessPermission.export = ret.data.Export;
      accessPermission.import = ret.data.Import;
      accessPermission.batchDelete = ret.data.BatchDelete;
      accessPermission.batchUpdate = ret.data.BatchUpdate;
      accessPermission.share = ret.data.Share;
      accessPermission.viewHistory = ret.data.DataChangedHistory || false;
    }
    return {
      data: {
        ...accessPermission
      }
    };
  }
  async getWorkflowRecords(params) {
    const res = await service$7.getWorkflowRecords(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getWorkflowRecordById(workItemId) {
    const res = await service$7.getWorkflowRecordById(workItemId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async submitWorkflowData(params) {
    const res = await service$7.submitWorkflowBizData(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async saveWorkflowSubRecord(record, bizObjectId = "", workitemId = "", parentObjectId = "", parentSchemaCode = "", subTableControlCode = "", uowId) {
    const dto = {
      appCode: this.appCode,
      schemaCode: this.schemaCode,
      content: record,
      bizObjectId,
      parentObjectId,
      parentSchemaCode,
      subTableControlCode,
      uowId
    };
    const res = await service$7.saveWorkflowSubBizObject(dto, workitemId);
    return {
      data: {
        ...res.data,
        ObjectId: res.data.ObjectId,
        Name: res.data.Name,
        OwnerId: res.data.OwnerId,
        OwnerDeptId: res.data.OwnerDeptId,
        status: res.data.status,
        CreatedBy: res.data.CreatedBy,
        CreatedTime: res.data.CreatedTime,
        ModifiedBy: res.data.ModifiedBy,
        ModifiedTime: res.data.ModifiedTime
      }
    };
  }
  async updateWorkflowSubRecord(bizObjectId, workitemId = "", record, parentObjectId = "", parentSchemaCode = "", subTableControlCode = "", uowId) {
    const dto = {
      bizObjectId,
      schemaCode: this.schemaCode,
      appCode: this.appCode,
      content: record,
      parentObjectId,
      parentSchemaCode,
      subTableControlCode,
      uowId
    };
    const res = await service$7.updateWorkflowSubBizObject(dto, workitemId);
    if (!res.errorCode) {
      if (!res.data) {
        return {
          data: {}
        };
      }
      return {
        data: {
          ...res.data,
          ObjectId: res.data.ObjectId,
          Name: res.data.Name,
          OwnerId: res.data.OwnerId,
          OwnerDeptId: res.data.OwnerDeptId,
          status: res.data.status,
          CreatedBy: res.data.CreatedBy,
          CreatedTime: res.data.CreatedTime,
          ModifiedBy: res.data.ModifiedBy,
          ModifiedTime: res.data.ModifiedTime
        }
      };
    }
  }
  async deleteWorkflowSubRecord(workitemId, bizObjectId, uowId) {
    const res = await service$7.deleteWorkflowSubBizObject(workitemId, this.schemaCode, bizObjectId, uowId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async publishWorkflowTemplate(content) {
    const res = await service$7.publish({
      content
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async forwardWorkflow(params) {
    const res = await service$7.forward({
      workItemId: params.workItemId,
      comment: params.comment
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async submitWorkflow(params) {
    const res = await service$7.submit(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getWorkflowInstanceSteps(req) {
    var _a;
    const res = await service$7.getWorkflowInstanceSteps(req);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    if (res.data && res.data.result) {
      res.data.result.forEach((r) => {
        r.tokens && r.tokens.forEach((token) => {
          if (!token.items || token.items.length <= 1 && token.activityType !== ActivityType.Notification) {
            token.userStates = [];
          } else {
            token.userStates = token.items.filter((i) => i !== WorkItemState.Forwarded).map((item) => {
              return {
                participant: item.participant,
                itemState: item.itemState
              };
            });
          }
        });
      });
    }
    return {
      data: (_a = res.data) == null ? void 0 : _a.result
    };
  }
  async getWorkflowClauses(parms) {
    const res = await service$7.getWorkflowClauses({
      appCode: this.appCode,
      workflowCode: parms.workflowCode,
      schemaCode: this.schemaCode,
      workflowType: parms.workflowType
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async validateWorkflow(parms) {
    const res = await service$7.validateCreateWorkflow({
      displayName: parms.displayName,
      schemaCode: this.schemaCode,
      schemaDisplayName: parms.schemaDisplayName,
      triggeredWay: parms.triggeredWay,
      trigger: parms.trigger
    });
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async openWorkflow(workflowCode) {
    const res = await service$7.open(workflowCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async closeWorkflow(workflowCode) {
    const res = await service$7.close(workflowCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async removeWorkflow(workflowCode) {
    const res = await service$7.remove(workflowCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getRelatedSchemaInfomation() {
    const res = await service$7.getRelatedInfomationOfSchema(this.appCode, this.schemaCode);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getWorkflowDesignerControls(showAll = true) {
    const res = await service$7.getControlVOs(this.schemaCode, showAll);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getWorkflowDesignerInfo(workflowCode, trigger) {
    const res = await service$7.getWorkflowDesignerInfo(workflowCode, trigger);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getWorkflowContextInfo(workItemId) {
    const res = await service$7.getActivityInfoByWorkItemId(workItemId);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async manageWorkflow(params) {
    const res = await service$7.manageWorkflow(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async managerWorkflowParticipants(params) {
    const res = await service$7.managerWorkflowParticipants(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async getActivityTemplateInfo(params) {
    const res = await service$7.getActivityTemplateInfo(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async bulkTerminate(params) {
    const res = await service$7.bulkTerminate(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
  async bulkUpdateParticipants(params) {
    const res = await service$7.bulkUpdateParticipants(params);
    if (res.errorCode) {
      return {
        errorCode: res.errorCode,
        errorMessage: res.errorMessage
      };
    }
    return {
      data: res.data
    };
  }
}
class ColumnModule {
  constructor(context) {
    this.context = context;
  }
  async getColumns(params) {
    const schemaContext = this.context.getSchemaContext(params.appCode, params.schemaCode);
    const res = await schemaContext.getControls();
    if (Array.isArray(res == null ? void 0 : res.data)) {
      return res.data;
    }
    return [];
  }
  applyTitleFlag(columns) {
    if (!Array.isArray(columns) || columns.length === 0) {
      return [];
    }
    const result = [...columns];
    const titleIndex = result.findIndex((item) => item.titleFlag);
    if (titleIndex > 0) {
      const titleField = result.splice(titleIndex, 1);
      result.unshift(titleField[0]);
    }
    result.forEach((item, index) => {
      item.titleFlag = index === 0;
    });
    return result;
  }
  applyRelProperties(columns, relColumn) {
    if (!Array.isArray(columns) || columns.length === 0) {
      return [];
    }
    const displayColumn = relColumn.properties.displayColumn || [];
    let displayType = relColumn.properties.displayType;
    if (!displayType) {
      displayType = this.relDisplayTypeDefault(relColumn);
    }
    const filteredcolumns = columns.filter((item) => ![ControlType.QRCode, ControlType.SubTable].includes(item.type));
    const allColumns = this.applyTitleFlag(filteredcolumns);
    const allCodes = allColumns.map((item) => item.code);
    let resultCodes = [];
    displayColumn.forEach((item) => {
      if (item.code && item.checked && allCodes.includes(item.code)) {
        resultCodes.push(item.code);
      }
    });
    if (resultCodes.length === 0) {
      resultCodes = this.relDisplayColumnDefault(relColumn, allCodes, displayType);
    }
    const index = resultCodes.findIndex((code) => code === allCodes[0]);
    if (index > -1) {
      if (index !== 0) {
        resultCodes.splice(index, 1);
        resultCodes.unshift(allCodes[0]);
      }
    } else {
      resultCodes.unshift(allCodes[0]);
    }
    if (displayType === "card") {
      resultCodes = resultCodes.slice(0, 5);
    }
    return resultCodes.map((code) => allColumns.find((item) => item.code === code));
  }
  applyPermission(columns, schemaPermission) {
    if (!Array.isArray(columns) || columns.length === 0) {
      return [];
    }
    const permissionModule = this.context.modules.permission;
    const fieldAddPermission = permissionModule.getFieldAddPermission(schemaPermission);
    const fieldEditPermission = permissionModule.getFieldEditPermission(schemaPermission);
    return columns.filter((item) => {
      var _a, _b;
      return ((_a = fieldAddPermission == null ? void 0 : fieldAddPermission[item.code]) == null ? void 0 : _a.Visible) || ((_b = fieldEditPermission == null ? void 0 : fieldEditPermission[item.code]) == null ? void 0 : _b.Visible);
    });
  }
  applyPrefrence(columns, preference, parentFieldCode) {
    if (!Array.isArray(columns) || columns.length === 0) {
      return [];
    }
    const settings = this.context.modules.preference.getSetting(preference, parentFieldCode);
    const layout = settings.layout || [];
    if (layout.length === 0) {
      return columns;
    }
    const allColumns = this.applyTitleFlag(columns);
    const allCodes = allColumns.map((item) => item.code);
    const resultCodes = [];
    layout.forEach((code) => {
      if (allCodes.includes(code)) {
        resultCodes.push(code);
      }
    });
    for (const code of allCodes) {
      if (!resultCodes.includes(code)) {
        resultCodes.push(code);
      }
    }
    const index = resultCodes.findIndex((code) => code === allCodes[0]);
    if (index > -1) {
      resultCodes.splice(index, 1);
    } else {
      resultCodes.unshift(allCodes[0]);
    }
    return resultCodes.map((code) => allColumns.find((item) => item.code === code));
  }
  relDisplayTypeDefault(relColumn) {
    if (relColumn.properties.systemGenerated) {
      return "table";
    } else {
      return "card";
    }
  }
  relDisplayColumnDefault(relColumn, allCodes, displayType) {
    let resultCodes = [];
    if (displayType === "table") {
      if (relColumn.properties.systemGenerated) {
        resultCodes = allCodes.slice(0, 1);
      } else {
        resultCodes = allCodes.slice(0);
      }
    } else if (displayType === "option") {
      resultCodes = allCodes.slice(0, 1);
    } else {
      resultCodes = allCodes.slice(0, 5);
    }
    return resultCodes;
  }
  async getRelColumns(params) {
    const relSchemaCode = params.relColumn.properties.relationCode;
    let list = await this.getColumns({
      appCode: params.appCode,
      schemaCode: relSchemaCode
    });
    const schemaPermission = await this.context.modules.permission.getSchemaPermission({
      appCode: params.appCode,
      schemaCode: relSchemaCode
    });
    list = this.applyPermission(list, schemaPermission);
    if (list.length === 0) {
      list.push({
        code: "Name",
        displayName: "\u6807\u9898",
        displayRule: [],
        properties: {
          formula: null,
          placeholder: "\u8BF7\u8F93\u5165",
          uniqueness: false,
          scan: false
        },
        required: false,
        type: ControlType.SinglelineText,
        prevCode: "",
        status: 0,
        tips: void 0
      });
    }
    const parentSchemaCode = this.getRelParentSchemaCode(params.relColumn);
    if (parentSchemaCode) {
      let parentList = await this.getColumns({
        appCode: params.appCode,
        schemaCode: parentSchemaCode
      });
      const parentSchemaPermission = await this.context.modules.permission.getSchemaPermission({
        appCode: params.appCode,
        schemaCode: parentSchemaCode
      });
      parentList = this.applyPermission(parentList, parentSchemaPermission);
      parentList = parentList.map((item) => {
        return {
          ...item,
          code: parentSchemaCode + "." + item.code,
          titleFlag: false
        };
      });
      list = list.concat(parentList);
    }
    return list;
  }
  getRelParentSchemaCode(relColumn) {
    var _a;
    const displayColumn = (_a = relColumn.properties) == null ? void 0 : _a.displayColumn;
    const relationSchemaType = relColumn.properties.relationSchemaType;
    if (relationSchemaType === "SUB_SCHEMA" && Array.isArray(displayColumn) && displayColumn.length > 0) {
      const parentColumn = displayColumn.find((item) => item.checked && item.code.includes("."));
      if (parentColumn) {
        const parentSchemaCode = parentColumn.code.split(".")[0];
        return parentSchemaCode;
      }
    }
  }
}
class DataModule {
  constructor(context) {
    this.context = context;
  }
  async queryData(params) {
    const schemaContext = this.context.getSchemaContext(params.appCode, params.schemaCode);
    const res = await schemaContext.getRecords(params);
    if (res.data) {
      return res.data;
    }
    return {
      total: 0,
      rows: []
    };
  }
  async querySubData(params) {
    const schemaContext = this.context.getSchemaContext(params.appCode, params.schemaCode);
    const {
      parentField,
      parentObjectId,
      ...baseParams
    } = params;
    const controlCode = parentField.type === ControlType.Lookup ? parentField.properties.lookupControlCode : parentField.code;
    const queryParams = {
      ...baseParams,
      controlCode,
      objectId: parentObjectId
    };
    const res = await schemaContext.getRelationDataValues(queryParams);
    if (res.data) {
      return res.data;
    }
    return {
      total: 0,
      rows: []
    };
  }
  async queryRelSelectedData(params) {
    const schemaContext = this.context.getSchemaContext(params.appCode, params.schemaCode);
    const {
      parentField,
      parentObjectId,
      ...baseParams
    } = params;
    const controlCode = parentField.type === ControlType.Lookup ? parentField.properties.lookupControlCode : parentField.code;
    const queryParams = {
      ...baseParams,
      controlCode,
      objectId: parentObjectId,
      relationSchemaType: parentField.properties.relationSchemaType
    };
    const res = await schemaContext.getRelationDataValues(queryParams);
    if (res.data) {
      return res.data;
    }
    return {
      total: 0,
      rows: []
    };
  }
  async queryRelOptionalData(params) {
    const schemaContext = this.context.getSchemaContext(params.appCode, params.schemaCode);
    const {
      parentField,
      parentObjectId,
      ...baseParams
    } = params;
    const queryParams = {
      ...baseParams,
      controlCode: parentField.code,
      objectId: parentObjectId,
      relationSchemaType: parentField.properties.relationSchemaType
    };
    const res = await schemaContext.getRelationDataStatus(queryParams);
    if (res.data) {
      return res.data;
    }
    return {
      total: 0,
      rows: []
    };
  }
  applyPermissionOne(row, schemaPermission) {
    const permissionModule = this.context.modules.permission;
    const record = {
      ...row
    };
    let permission;
    if (record.status === -1 || record.isNew || !record.ObjectId) {
      permission = permissionModule.getFieldAddPermission(schemaPermission);
    } else {
      permission = permissionModule.getFieldEditPermission(schemaPermission, record);
    }
    Object.keys(record).forEach((key) => {
      var _a;
      if (((_a = permission == null ? void 0 : permission[key]) == null ? void 0 : _a.Visible) === false) {
        record[key] = null;
      }
    });
    return record;
  }
  applyPermission(rows, schemaPermission, parentSchemaPermission) {
    return rows.map((item) => {
      const record = this.applyPermissionOne(item, schemaPermission);
      if (item.MainData && parentSchemaPermission) {
        record.MainData = this.applyPermissionOne(item.MainData, schemaPermission);
      }
      return record;
    });
  }
  applyDisplayRuleOne(row, columns = []) {
    const record = {
      ...row
    };
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].titleFlag) {
        continue;
      }
      const col = columns[i];
      if (record[col.code] !== void 0 && col.displayRule && col.displayRule[0]) {
        const result = ruleExecAsync(row, col.displayRule);
        if (result === false) {
          record[col.code] = null;
        }
      }
    }
    return record;
  }
  applyDisplayRule(rows, showColumns) {
    return rows.map((item) => {
      const record = this.applyDisplayRuleOne(item, showColumns);
      if (item.MainData) {
        record.MainData = this.applyDisplayRuleOne(record.MainData, showColumns);
      }
      return record;
    });
  }
  getQueryItems(relColumn, showColumns, columns) {
    var _a;
    const queryItems = showColumns.filter((item) => item.code !== "Name").map((item) => item.code);
    let resultCodes = [...queryItems];
    queryItems.forEach((code) => {
      const column = columns.find((item) => item.code === code);
      const displayRule = column.displayRule;
      let parentPrefix = "";
      const arr = code.split(".");
      if (arr.length > 1) {
        parentPrefix = arr[0] + ".";
      }
      if (displayRule && displayRule[0]) {
        displayRule.forEach((group) => {
          group.forEach((rule) => {
            const ruleCode = parentPrefix + rule.code;
            if (!resultCodes.includes(ruleCode)) {
              resultCodes.push(ruleCode);
            }
          });
        });
      }
    });
    if (((_a = relColumn.properties) == null ? void 0 : _a.relationSchemaType) === "SUB_SCHEMA") {
      const relSchemaCode = relColumn.properties.relationCode;
      resultCodes = resultCodes.map((item) => {
        if (!item.includes(".")) {
          return relSchemaCode + "." + item;
        }
        return item;
      });
    }
    return resultCodes;
  }
  encodeRows(rows, columns = []) {
    const columnMap = {};
    const parentColumnMap = {};
    columns.forEach((item) => {
      if (item.code.includes(".")) {
        parentColumnMap[item.code] = {
          ...item,
          code: item.code.split(".")[1]
        };
      } else {
        columnMap[item.code] = item;
      }
    });
    const parentColumnLength = Object.keys(parentColumnMap).length;
    return rows.map((item) => {
      const record = DataProcess.getRawRow(item, columnMap);
      if (record.MainData && parentColumnLength > 0) {
        record.MainData = DataProcess.getRawRow(record.MainData, parentColumnMap);
      }
      return record;
    });
  }
  getRecordValue(record, code) {
    if (!record) {
      return null;
    }
    if (record[code] !== void 0) {
      return record[code];
    }
    if (code.includes(".") && record.MainData) {
      const arr = code.split(".");
      return record.MainData[arr[1]] || null;
    }
    return null;
  }
}
class PermissionModule {
  constructor(context) {
    this.context = context;
  }
  async getAppPermission(appCode) {
    const appContext = this.context.getAppContext(appCode);
    const res = await appContext.getAppPermission();
    return res.data;
  }
  async getSchemaPermission(params) {
    if (!params.workItemId) {
      const schemaContext = this.context.getSchemaContext(params.appCode, params.schemaCode);
      const res = await schemaContext.getSchemaPermission();
      return res.data;
    } else {
      const workItemId = params.workItemId;
      return await this.getWorkFlowPermisson({
        ...params,
        workItemId
      });
    }
  }
  async getWorkFlowPermisson(params) {
    var _a, _b;
    const workflowContext = this.context.getWorkflowContext(params.appCode, params.schemaCode);
    const res = await Promise.all([workflowContext.getWorkflowAccessPermission(params.propertyCode, params.workItemId), workflowContext.getWorkflowFieldPermission(params.propertyCode, params.workItemId)]);
    const accessPermission = (_a = res[0]) == null ? void 0 : _a.data;
    const fieldPermission = (_b = res[1]) == null ? void 0 : _b.data;
    return {
      accessPermission,
      fieldAddPermission: fieldPermission,
      fieldEditPermissions: [{
        fieldEditPermission: fieldPermission
      }]
    };
  }
  getAccessPermission(schemaPermission) {
    return schemaPermission == null ? void 0 : schemaPermission.accessPermission;
  }
  getFieldAddPermission(schemaPermission) {
    return schemaPermission == null ? void 0 : schemaPermission.fieldAddPermission;
  }
  getFieldEditPermission(schemaPermission, record) {
    var _a;
    const permissions = schemaPermission.fieldEditPermissions;
    const list = [];
    if (record) {
      permissions.map((item) => {
        if (item.condition && !item.conditions) {
          return {
            ...item,
            conditions: this.getConditions(item.condition)
          };
        }
        return item;
      }).filter((item) => {
        return item.enabledFlag && item.conditions;
      }).forEach((permission) => {
        const matched = ruleExecAsync(record, this.convertDynamic(permission.conditions));
        if (matched) {
          list.push(permission.fieldEditPermission);
        }
      });
    }
    if (list.length > 0) {
      const result = {};
      list.forEach((item) => Object.keys(item).forEach((code) => {
        if (!result[code]) {
          result[code] = item[code];
        } else {
          result[code] = {
            Visible: result[code].Visible || item[code].Visible,
            Editable: result[code].Editable || item[code].Editable
          };
        }
      }));
      return result;
    }
    return (_a = permissions.find((item) => !item.conditions)) == null ? void 0 : _a.fieldEditPermission;
  }
  getConditions(condition) {
    var _a, _b, _c;
    let result = null;
    const conditionItem = (_c = (_b = (_a = condition == null ? void 0 : condition.rules) == null ? void 0 : _a[0]) == null ? void 0 : _b.rules) == null ? void 0 : _c[0];
    if (conditionItem) {
      result = {
        controlType: "Status",
        code: conditionItem.code,
        method: conditionItem.method,
        valueType: conditionItem.valueType,
        value: [+conditionItem.values]
      };
      return [[result]];
    }
    return null;
  }
  convertDynamic(conditions) {
    var _a, _b;
    const engineContext = this.context.getEngineContext();
    const member = engineContext.getCurrentMember();
    const userId = member == null ? void 0 : member.objectId;
    const deptId = (_b = (_a = member == null ? void 0 : member.departments) == null ? void 0 : _a[0]) == null ? void 0 : _b.departmentId;
    return conditions.map((condition) => {
      return condition.map((conditionItem) => {
        return {
          ...conditionItem,
          value: conditionItem.value.map((valueItem) => {
            if ((valueItem == null ? void 0 : valueItem.id) === "#{currentMember}") {
              return userId;
            }
            if ((valueItem == null ? void 0 : valueItem.id) === "#{currentDept}") {
              return deptId;
            }
            return valueItem;
          })
        };
      });
    });
  }
}
class PreferenceModule {
  constructor(context) {
    this.context = context;
  }
  getSetting(preference, parentFieldCode) {
    if (parentFieldCode) {
      const origin = {};
      const reg = new RegExp(`^${parentFieldCode}_(.*)$`);
      Object.keys(preference || {}).forEach((item) => {
        const arr = item.match(reg);
        if (arr && arr[1]) {
          origin[arr[1]] = preference[item];
        }
      });
      return origin;
    }
    return preference.settings;
  }
}
class OrgModule {
  constructor(context) {
    this.dataFormat = {
      pc: {
        user: userDataFormat,
        dept: deptDataFormat
      },
      mobile: {
        user: userDataFormatMobile,
        dept: deptDataFormatMobile
      }
    };
    this.context = context;
  }
  dataRequestGenerater(params) {
    let dataRequest;
    if ((params == null ? void 0 : params.scene) === "mobile") {
      dataRequest = dataRequestGenerater({
        getContext: () => this.context,
        userDataFormat: this.dataFormat.mobile.user,
        deptDataFormat: this.dataFormat.mobile.dept,
        typeAlias: (type) => {
          let _type = type;
          if (type === "org") {
            _type = "dept";
          } else if (type === "all") {
            _type = "org";
          }
          return _type;
        }
      });
    } else {
      dataRequest = dataRequestGenerater({
        getContext: () => this.context,
        userDataFormat: this.dataFormat.pc.user,
        deptDataFormat: this.dataFormat.pc.dept
      });
    }
    if (params == null ? void 0 : params.allowSelectResigningEmployee) {
      dataRequest = this.searchResigningEmployee(dataRequest, params.allowSelectResigningEmployee);
    }
    return dataRequest;
  }
  searchResigningEmployee(dataRequest, allow = true) {
    let fixedStates = [1];
    if (allow) {
      fixedStates = [1, 3, 4];
    }
    if (!dataRequest._loadUserSearch) {
      dataRequest._loadUserSearch = dataRequest.loadUserSearch;
    }
    dataRequest.loadUserSearch = (keyword, states) => {
      return dataRequest._loadUserSearch(keyword, states || fixedStates);
    };
    if (!dataRequest._loadOrgSearch) {
      dataRequest._loadOrgSearch = dataRequest.loadOrgSearch;
    }
    dataRequest.loadOrgSearch = (keyword, type, states) => {
      return dataRequest._loadOrgSearch(keyword, type, states || fixedStates);
    };
    return dataRequest;
  }
}
function userDataFormat(child) {
  return {
    ...child,
    label: child.name,
    value: child.objectId,
    type: "user",
    avatar: child.profilePhotoUrl,
    nameExtra: [3, 4].includes(child.state) ? "(\u79BB\u804C\u4EBA\u5458)" : ""
  };
}
function deptDataFormat(child) {
  let hasChild = true;
  if (child.hasChild !== void 0) {
    hasChild = child.hasChild;
  } else if (child.hasChildren !== void 0) {
    hasChild = child.hasChildren;
  }
  return {
    ...child,
    label: child.name,
    value: child.objectId,
    type: "dept",
    hasChild
  };
}
function userDataFormatMobile(child) {
  return {
    ...child,
    label: child.name,
    id: child.objectId,
    type: "user",
    avatar: child.mediumProfilePhotoUrl || child.profilePhotoUrl,
    nameExtra: [3, 4].includes(child.state) ? "(\u79BB\u804C\u4EBA\u5458)" : ""
  };
}
function deptDataFormatMobile(child) {
  let hasChild = true;
  if (child.hasChild !== void 0) {
    hasChild = child.hasChild;
  } else if (child.hasChildren !== void 0) {
    hasChild = child.hasChildren;
  }
  return {
    ...child,
    label: child.name,
    id: child.objectId,
    type: "org",
    hasChild
  };
}
function dataRequestGenerater(params) {
  function getEngineContext() {
    return params.getContext().getEngineContext();
  }
  const normalStates = [1];
  async function loadUserInfo2(memberId) {
    const engineContext = getEngineContext();
    const res = await engineContext.getMemberInfo(memberId);
    return res.data;
  }
  function getOrgDetail(departmentId, states) {
    const engineContext = getEngineContext();
    return engineContext.getOrgChildUnits({
      departmentId,
      states
    });
  }
  async function getOrgDetailByDeptId(departmentId, onlyDept, states) {
    const engineContext = getEngineContext();
    const subData = await (onlyDept ? engineContext.getOrgChildDepartments(departmentId) : getOrgDetail(departmentId, states));
    if (!subData.errorCode) {
      const data = subData.data;
      const result = data.map((child) => {
        if (child.unitType === "MEMBER") {
          return params.userDataFormat(child);
        } else {
          const item = params.deptDataFormat(child);
          if (!onlyDept && Array.isArray(item.children)) {
            item.children = item.children.filter((_child) => _child.unitType === "MEMBER").map((_child) => params.userDataFormat(_child));
          }
          return item;
        }
      });
      return onlyDept ? result.filter((item) => item.type === "dept" || item.type === "org") : result;
    }
    return [];
  }
  async function getOrgRootDetail(onlyDept, states) {
    const engineContext = getEngineContext();
    const rootData = await engineContext.getOrgRootDepartment();
    if (!rootData.errorCode) {
      const {
        data
      } = rootData;
      const rootDept = params.deptDataFormat({
        ...data,
        label: data.name,
        value: data.objectId,
        hasChild: onlyDept ? data.hasChildren : true,
        type: "dept",
        unitType: "DEPARTMENT",
        __FINISH__: !onlyDept,
        children: []
      });
      if (!onlyDept && rootDept.value) {
        rootDept.children = await getOrgDetailByDeptId(rootDept.value, onlyDept, states);
      }
      return [rootDept];
    }
    return [];
  }
  async function getMyDeptsDetail(onlyDept) {
    const engineContext = getEngineContext();
    const myDepts = await engineContext.getOrgMyDepartmentMember({
      onlyDept,
      states: normalStates
    });
    if (!myDepts.errorCode) {
      const {
        data = []
      } = myDepts;
      const result = data.map((item) => {
        return {
          objectId: item.departmentId,
          name: item.departmentName,
          hasChildren: item.hasChild,
          children: [],
          label: item.departmentName,
          value: item.departmentId,
          hasChild: onlyDept ? item.hasChild : true,
          type: "dept",
          unitType: "DEPARTMENT"
        };
      }).map(params.deptDataFormat);
      return result;
    }
    return [];
  }
  async function loadDepts(departmentId, onlyDept) {
    let list = [];
    if (departmentId) {
      list = await getOrgDetailByDeptId(departmentId, onlyDept, normalStates);
    } else {
      list = await getOrgRootDetail(onlyDept, normalStates);
    }
    return list;
  }
  async function loadMyDepts(departmentId, onlyDept) {
    const engineContext = getEngineContext();
    const list = departmentId ? await getOrgDetailByDeptId(departmentId, onlyDept, normalStates) : await getMyDeptsDetail(onlyDept);
    if (!onlyDept) {
      const myMemberId = engineContext.getEngineInfo().memberId;
      if (myMemberId) {
        const index = list.findIndex((item) => item.objectId === myMemberId);
        if (index > -1) {
          const delArr = list.splice(index, 1);
          list.unshift(delArr[0]);
        }
      }
    }
    return list;
  }
  async function loadResigningEmployee(pageIndex, pageSize) {
    const states = [3, 4];
    const engineContext = getEngineContext();
    const memberList = await engineContext.searchOrgMember({
      pageIndex: pageIndex + 1,
      pageSize,
      states
    });
    if (!memberList.errorCode) {
      const {
        data: {
          resultList = [],
          totalCount = 0
        } = {
          resultList: [],
          totalCount: 0
        }
      } = memberList;
      return {
        data: resultList.map((child) => params.userDataFormat(child)),
        total: totalCount
      };
    }
    return {
      data: [],
      total: 0
    };
  }
  async function loadMyTeam(pageIndex, pageSize) {
    const engineContext = getEngineContext();
    const memberList = await engineContext.searchOrgMember({
      pageIndex: pageIndex + 1,
      pageSize,
      states: normalStates
    });
    if (!memberList.errorCode) {
      const {
        data: {
          resultList = [],
          totalCount = 0
        } = {
          resultList: [],
          totalCount: 0
        }
      } = memberList;
      return {
        data: resultList.map((child) => params.userDataFormat(child)),
        total: totalCount
      };
    }
    return {
      data: [],
      total: 0
    };
  }
  async function loadUserSearch(keyword, states) {
    const engineContext = getEngineContext();
    let result = [];
    const searchData = await engineContext.searchOrgMember({
      name: keyword,
      pageIndex: 1,
      pageSize: 200,
      states: states || normalStates
    });
    if (searchData) {
      const {
        data: {
          resultList = []
        } = {
          resultList: []
        }
      } = searchData;
      result = resultList.map((child) => {
        const _child = params.userDataFormat(child);
        return _child;
      });
    }
    return result;
  }
  async function loadDeptSearch(keyword) {
    const engineContext = getEngineContext();
    let result = [];
    const searchData = await engineContext.searchOrgDepartment({
      name: keyword,
      pageIndex: 1,
      pageSize: 200
    });
    if (searchData) {
      const {
        data: {
          resultList = []
        } = {
          resultList: []
        }
      } = searchData;
      result = resultList.map((child) => {
        const _child = params.deptDataFormat(child);
        return _child;
      });
    }
    return result;
  }
  async function loadOrgSearch(keyword, type, states) {
    let result = [];
    let _type = type;
    if (typeof params.typeAlias === "function") {
      _type = params.typeAlias(_type);
    }
    switch (_type) {
      case "user": {
        result = await loadUserSearch(keyword, states);
        break;
      }
      case "dept":
        result = await loadDeptSearch(keyword);
        break;
      case "org":
        const deptList = await loadUserSearch(keyword, states);
        const userList = await loadDeptSearch(keyword);
        result = [...deptList || [], ...userList || []];
        break;
    }
    return result;
  }
  return {
    loadDepts,
    loadMyDepts,
    loadMyTeam,
    loadResigningEmployee,
    loadUserSearch,
    loadDeptSearch,
    loadOrgSearch,
    loadUserInfo: loadUserInfo2
  };
}
function installModules(context) {
  context.modules = {
    column: new ColumnModule(context),
    data: new DataModule(context),
    permission: new PermissionModule(context),
    preference: new PreferenceModule(context),
    org: new OrgModule(context)
  };
}
class ContextImpl extends Context {
  constructor() {
    super();
    this.contextMetaData = new ContextMetaData();
    installModules(this);
  }
  async loadContext(corpId) {
    const userContext = this.getUserContext();
    await userContext.load();
    this._userBasicInfo = userContext.getUserBasicInfo();
    const engines = userContext.getEngines();
    if (engines && engines.length > 0) {
      let activeEngine = engines[0];
      if (corpId) {
        const engine = engines.find((item) => item.corpId === corpId);
        if (engine) {
          activeEngine = engine;
        }
      }
      this._engine = activeEngine;
      const engineContext = this.getEngineContext(this._engine.engineCode);
      engineContext.load();
    }
  }
  loadExternalContext(externalEngineCode) {
    const engine = {
      engineCode: externalEngineCode,
      corpId: "",
      corpName: "",
      engineShard: "",
      entryType: EngineEntryType.UNKNOWN,
      lastActive: 10,
      state: EngineState.RUNNINE,
      ownFlag: false,
      adminFlag: false,
      memberId: ""
    };
    this.getContextMetaData().addEngine(engine);
    this._engine = engine;
    const engineContext = this.getEngineContext(this._engine.engineCode);
    engineContext.load();
  }
  getContextMetaData() {
    return this.contextMetaData;
  }
  getUserBasicInfo() {
    return this._userBasicInfo;
  }
  getEngineInfo() {
    return this._engine;
  }
  setEngineInfo(value) {
    this._engine = value;
  }
  getUserContext() {
    const context = this.contextMetaData.getContext("user");
    if (context) {
      return context;
    } else {
      const userContext = new UserContext();
      userContext.setContextBase(this);
      this.contextMetaData.addContext("user", userContext);
      return userContext;
    }
  }
  getEngineContext(code) {
    let engineCode = code;
    if (!engineCode) {
      engineCode = this._engine.engineCode;
    }
    const context = this.contextMetaData.getContext("engine:" + engineCode);
    if (context) {
      return context;
    } else {
      const engineContext = new EngineContext(engineCode);
      engineContext.setContextBase(this);
      this.contextMetaData.addContext("engine:" + engineCode, engineContext);
      return engineContext;
    }
  }
  getAppContext(appCode) {
    if (!appCode) {
      console.error("getAppContext appCode is null");
      return;
    }
    const context = this.contextMetaData.getContext("app:" + appCode);
    if (context) {
      return context;
    } else {
      const appContext = new AppContext(appCode);
      appContext.setContextBase(this);
      this.contextMetaData.addContext("app:" + appCode, appContext);
      return appContext;
    }
  }
  getSchemaContext(appCode, schemaCode) {
    if (!appCode || !schemaCode || appCode === "" || schemaCode === "") {
      throw new Error(`\u521B\u5EFAschemaContext\u5931\u8D25\uFF0CappCode\u6216schemaCode\u4E3A\u7A7A\uFF0CappCode: ${appCode}, schemaCode: ${schemaCode}`);
    }
    const context = this.contextMetaData.getContext("schema:" + appCode + ":" + schemaCode);
    if (context) {
      return context;
    } else {
      const schemaContext = new SchemaContext(appCode, schemaCode);
      schemaContext.setContextBase(this);
      this.contextMetaData.addContext("schema:" + appCode + ":" + schemaCode, schemaContext);
      return schemaContext;
    }
  }
  getWorkflowContext(appCode, schemaCode) {
    if (!appCode || !schemaCode || appCode === "" || schemaCode === "") {
      throw new Error(`\u521B\u5EFAworkflowContext\u5931\u8D25\uFF0CappCode\u6216schemaCode\u4E3A\u7A7A\uFF0CappCode: ${appCode}, schemaCode: ${schemaCode}`);
    }
    const context = this.contextMetaData.getContext("workflow:" + appCode + ":" + schemaCode);
    if (context) {
      return context;
    } else {
      const workflowContext = new WorkflowContext(appCode, schemaCode);
      workflowContext.setContextBase(this);
      this.contextMetaData.addContext("workflow:" + appCode + ":" + schemaCode, workflowContext);
      return workflowContext;
    }
  }
  getCommonContext() {
    const context = this.contextMetaData.getContext("common");
    if (context) {
      return context;
    } else {
      const commonContext = new CommonContext();
      commonContext.setContextBase(this);
      this.contextMetaData.addContext("common", commonContext);
      return commonContext;
    }
  }
}
const getExternalInfo = (shortlinkCode) => {
  return service$b.getExternalInfo(shortlinkCode);
};
const getExternalBizData = (shortLinkCode, sign, charset) => {
  return service$b.getExternalBizData(shortLinkCode, sign, charset);
};
const postSharedFormData = (shortLinkCode, data) => {
  return service$b.postSharedFormData(shortLinkCode, data);
};
const ExternalLinkApi = {
  getExternalInfo,
  getExternalBizData,
  postSharedFormData
};
function queryAppletterList(offset, pageSize, readStatus, userId) {
  return service$3.queryAppletterList(offset, pageSize, readStatus, userId);
}
function getUnreadCount() {
  return service$3.getUnreadCount();
}
function markReadAll() {
  return service$3.markReadAll();
}
function markRead(ids) {
  return service$3.markRead(ids);
}
function getNoticeIdByEventId(params) {
  return service$3.getNoticeIdByEventId(params);
}
function getNoticeDetailById(id) {
  return service$3.getNoticeDetailById(id);
}
const NoticeApi = {
  queryAppletterList,
  getUnreadCount,
  markReadAll,
  markRead,
  getNoticeIdByEventId,
  getNoticeDetailById
};
function getMemberInfo(memberId) {
  return service$9.getMemberInfo(memberId);
}
function getOrgRoot() {
  return service$9.getOrgRoot();
}
function queryChildUnits(option) {
  return service$9.queryChildUnits(option);
}
function getMyDepartmentMembers(option) {
  return service$9.getMyDepartmentMembers(option);
}
function getChildDepts(departmentId) {
  return service$9.getChildDepts(departmentId);
}
function queryMembers(queryOption) {
  return service$9.queryMembers(queryOption);
}
function getDeptInfo(departmentId) {
  return service$9.getDeptInfo(departmentId);
}
function queryDepts(queryOption) {
  return service$9.queryDepts(queryOption);
}
function getChildUnits(departmentId) {
  return service$9.getChildUnits(departmentId);
}
function getChildMembers(departmentId) {
  return service$9.getChildMembers(departmentId);
}
function getCorpMembers(option) {
  return service$9.getCorpMembers(option);
}
function leaveCorp(corpId, memberId) {
  return service$9.leaveCorp(corpId, memberId);
}
function inviteMembers(members) {
  return service$9.inviteMembers(members);
}
function changeDepartment(option) {
  return service$9.changeDepartment(option);
}
function queryCorpOwners(corpId) {
  return service$9.queryCorpOwners(corpId);
}
const USER_INFO_PREFIX = "USER_INFO_PREFIX";
const MODOU_USER_INFO_CACHE_KEY = "MODOU_USER_INFO_CACHE_KEY";
function getUserInfoFromCache(memberId) {
  return globalStorage.getFormLocal(`${USER_INFO_PREFIX}_${memberId}`);
}
function setUserInfoFromCache(memberId, info) {
  globalStorage.setToLocal(`${USER_INFO_PREFIX}_${memberId}`, info, {
    expired: 60 * 1e3,
    compile: true
  });
  const cacheKeys = globalStorage.getFormLocal(MODOU_USER_INFO_CACHE_KEY) || [];
  if (!cacheKeys.includes(memberId)) {
    cacheKeys.push(memberId);
    globalStorage.setToLocal(MODOU_USER_INFO_CACHE_KEY, cacheKeys, {
      compile: true
    });
  }
}
function clearUserInfoCache() {
  const cacheKeys = globalStorage.getFormLocal(MODOU_USER_INFO_CACHE_KEY) || [];
  cacheKeys.forEach((memberId) => {
    globalStorage.removeFormLocal(`${USER_INFO_PREFIX}_${memberId}`);
  });
  globalStorage.removeFormLocal(MODOU_USER_INFO_CACHE_KEY);
}
async function loadUserInfo(memberId) {
  const userInfo = getUserInfoFromCache(memberId);
  if (userInfo) {
    return userInfo;
  }
  try {
    const result = await getMemberInfo(memberId);
    if (result.errorCode) {
      return {};
    }
    const info = result.data;
    info && setUserInfoFromCache(memberId, info);
    return info || {};
  } catch (e) {
    console.error("getUserInfo", e);
    return {};
  }
}
const DEPT_INFO_PREFIX = "DEPT_INFO_PREFIX";
const MODOU_DEPT_INFO_CACHE_KEY = "MODOU_DEPT_INFO_CACHE_KEY";
function getDeptInfoFromCache(departmentId) {
  return globalStorage.getFormLocal(`${DEPT_INFO_PREFIX}_${departmentId}`);
}
function setDeptInfoFromCache(departmentId, info) {
  globalStorage.setToLocal(`${DEPT_INFO_PREFIX}_${departmentId}`, info, {
    expired: 60 * 1e3,
    compile: true
  });
  const cacheKeys = globalStorage.getFormLocal(MODOU_DEPT_INFO_CACHE_KEY) || [];
  if (!cacheKeys.includes(departmentId)) {
    cacheKeys.push(departmentId);
    globalStorage.setToLocal(MODOU_DEPT_INFO_CACHE_KEY, cacheKeys, {
      compile: true
    });
  }
}
function clearDeptInfoCache() {
  const cacheKeys = globalStorage.getFormLocal(MODOU_DEPT_INFO_CACHE_KEY) || [];
  cacheKeys.forEach((departmentId) => {
    globalStorage.removeFormLocal(`${DEPT_INFO_PREFIX}_${departmentId}`);
  });
  globalStorage.removeFormLocal(MODOU_DEPT_INFO_CACHE_KEY);
}
async function loadDeptInfo(departmentId) {
  const userInfo = getDeptInfoFromCache(departmentId);
  if (userInfo) {
    return userInfo;
  }
  try {
    const result = await getDeptInfo(departmentId);
    if (result.errorCode) {
      return {};
    }
    const info = result.data;
    info && setDeptInfoFromCache(departmentId, info);
    return info || {};
  } catch (e) {
    console.error("getDeptInfo", e);
    return {};
  }
}
function getMembersByIds(ids) {
  return service$9.getMembersByIds(ids);
}
function getMembersByName(option) {
  return service$9.getMembersByName(option);
}
function getDepartmentsByIds(ids) {
  return service$9.getDepartmentsByIds(ids);
}
function getDepartmentsByName(option) {
  return service$9.getDepartmentsByName(option);
}
function getMembersMainDept(ids) {
  return service$9.getMembersMainDept(ids);
}
function getMemberEntryLog(params) {
  return service$9.getMemberEntryLog(params);
}
function getDepartmentManager(id, recursively) {
  return service$9.getDepartmentManager(id, recursively);
}
function getMemberManager(id, recursively) {
  return service$9.getMemberManager(id, recursively);
}
const OrgApi = {
  getMemberInfo,
  getOrgRoot,
  queryChildUnits,
  getMyDepartmentMembers,
  getChildDepts,
  queryMembers,
  getDeptInfo,
  queryDepts,
  getChildUnits,
  getChildMembers,
  getCorpMembers,
  leaveCorp,
  inviteMembers,
  changeDepartment,
  queryCorpOwners,
  getUserInfoFromCache,
  setUserInfoFromCache,
  clearUserInfoCache,
  loadUserInfo,
  getDeptInfoFromCache,
  setDeptInfoFromCache,
  clearDeptInfoCache,
  loadDeptInfo,
  getDepartmentsByIds,
  getDepartmentsByName,
  getMembersByIds,
  getMembersByName,
  getMembersMainDept,
  getMemberEntryLog,
  getDepartmentManager,
  getMemberManager
};
function delRecycleApp(appCode) {
  return proxyRecycleBinService.delRecycleApp(appCode);
}
function queryRecycleApps(params) {
  return proxyRecycleBinService.queryRecycleApps(params);
}
function restoreRecycleApp(appCode) {
  return proxyRecycleBinService.restoreRecycleApp(appCode);
}
const RecycleBinService = {
  delRecycleApp,
  queryRecycleApps,
  restoreRecycleApp
};
function getUserBasicInfo() {
  return service$j.getUserBasicInfo();
}
function checkByAccount(code, email, mobile) {
  return service$j.checkByAccount(code, email, mobile);
}
function updateUserBasicInfo(option) {
  return service$j.updateUserBasicInfo(option);
}
function bindUserPhone(option) {
  return service$j.bindUserPhone(option);
}
function bindUserEmail(option) {
  return service$j.bindUserEmail(option);
}
function modifyPassword(passwordResetOption) {
  return service$j.modifyPassword(passwordResetOption);
}
function resetPassword(passwordResetOption) {
  return service$j.resetPassword(passwordResetOption);
}
function updateUserEmail(option) {
  return service$j.updateUserEmail(option);
}
function updateUserPhone(option) {
  return service$j.updateUserPhone(option);
}
function unbindWechat() {
  return service$j.unbindWechat();
}
const UserProfileApi = {
  getUserBasicInfo,
  checkByAccount,
  bindUserEmail,
  bindUserPhone,
  unbindWechat,
  updateUserPhone,
  updateUserEmail,
  modifyPassword,
  resetPassword,
  updateUserBasicInfo
};
function changeCorp(option) {
  return service$h.changeCorp(option);
}
function appCross(engineCode, token) {
  return service$h.appCross(engineCode, token);
}
function loginTerminalCheck() {
  return service$h.loginTerminalCheck();
}
function getUserIntegrateEngine() {
  return service$h.getUserIntegrateEngine();
}
function getFeatures(corpId) {
  return service$h.getFeatures(corpId);
}
function saveCorpAdmin(memberIds) {
  return service$h.saveCorpAdmin(memberIds);
}
function getCorpAdminList() {
  return service$h.getCorpAdminList();
}
function getCorpOwner() {
  return service$h.getCorpOwner();
}
const CorpApi = {
  changeCorp,
  appCross,
  loginTerminalCheck,
  getUserIntegrateEngine,
  getFeatures,
  saveCorpAdmin,
  getCorpAdminList,
  getCorpOwner
};
function handoverPost(params) {
  return service$6.handoverPost(params);
}
function handoverGetRecords(params) {
  return service$6.handoverGetRecords(params);
}
function handoverPending(sourceOwnerId) {
  return service$6.handoverPending(sourceOwnerId);
}
const HandoverApi = {
  handoverPost,
  handoverGetRecords,
  handoverPending
};
const HandsignApi = {
  handsignAddTask: service.handsignAddTask,
  handsignFinishTask: service.handsignFinishTask,
  handsignTempFileUpload: service.handsignTempFileUpload,
  handsignGetTask: service.handsignGetTask
};
var IntertImportOption = /* @__PURE__ */ ((IntertImportOption2) => {
  IntertImportOption2[IntertImportOption2["Effective"] = 1] = "Effective";
  IntertImportOption2[IntertImportOption2["SubmitWorkflow"] = 2] = "SubmitWorkflow";
  return IntertImportOption2;
})(IntertImportOption || {});
function useRefreshToken(params) {
  let tokenValidPromise = Promise.resolve();
  let promisePedding = false;
  const refreshUrl = "/v2/oauth2/refresh_token";
  function skip(currentUrl) {
    const client = currentUrl.indexOf(refreshUrl) > -1;
    return client;
  }
  let refreshCount = 0;
  function refreshToken() {
    if (promisePedding) {
      return tokenValidPromise;
    }
    return new Promise((resolve) => {
      promisePedding = true;
      request.post(refreshUrl, {
        disableBizError: true,
        disableSysError: true,
        credentials: "same-origin"
      }).then((res) => {
        if (res.data) {
          if (res.data.versionChanged) {
            globalStorage.setToLocal("versionChanged", true);
          }
          refreshCount++;
          if (typeof (params == null ? void 0 : params.onSuccess) === "function") {
            params.onSuccess(res);
          }
        }
      }).catch((err) => {
        console.error(err);
        if (typeof (params == null ? void 0 : params.onFail) === "function") {
          params.onFail(err);
        }
      }).finally(() => {
        promisePedding = false;
        resolve(true);
      });
    });
  }
  request.beforeResponseInterceptor = async (response, options) => {
    const currentUrl = response.url;
    if (skip(currentUrl)) {
      return response;
    }
    let has401 = response.status === 401;
    if (!has401 && response.status === 200) {
      if (!options.responseType || options.responseType === "json") {
        const data = await response.clone().json();
        has401 = data && parseInt(data.errorCode) === 401;
      }
    }
    if (has401) {
      const isResend = (options.params || {}).resend;
      if (isResend) {
        return response;
      }
      const tempCount = refreshCount;
      await tokenValidPromise;
      if (tempCount === refreshCount) {
        tokenValidPromise = refreshToken();
        await tokenValidPromise;
      }
      if (tempCount !== refreshCount) {
        const {
          params: params2,
          ...newOptions
        } = options;
        const reRes = await request(response.url, {
          ...newOptions,
          params: {
            resend: 1
          },
          prefix: ""
        });
        return reRes;
      }
    }
    return response;
  };
}
const Api = {
  external: ExternalLinkApi,
  file: FileApi,
  notice: NoticeApi,
  org: OrgApi,
  profile: UserProfileApi,
  corp: CorpApi,
  handover: HandoverApi,
  recycleBin: RecycleBinService,
  handsign: HandsignApi
};
export {
  ActivityType,
  ActivityType2,
  Api,
  AppContext,
  AppRoleEnum,
  ApproveType,
  BizDataType,
  BoolValue,
  CommonContext,
  ContextImpl,
  ControlStatus,
  DomainTypeEnum,
  EngineContext,
  EngineEntryType,
  EngineState,
  EntryConditionType,
  ExportState,
  FieldPermissionType,
  FunctionNodeType,
  ImportState,
  IntertImportOption,
  ObjectType,
  RecordLevel,
  RoleType,
  SchemaContext,
  SchemaTag,
  SharedState,
  SkipPolicy,
  SyncState,
  TokenState,
  TriggeredWays,
  TriggeredWays2,
  UserContext,
  WorkItemState,
  WorkflowAction,
  SchemaContext as WorkflowContext,
  WorkflowInstanceState,
  WorkflowState,
  WorkflowTriggerType,
  request2 as request,
  useRefreshToken
};
