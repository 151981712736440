import Decimal from 'decimal.js-light';
import _toString from 'lodash/toString';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import escapeRegExp from 'lodash/escapeRegExp';
import { Formula } from './type';
import { generateError } from './error';
import toNumber from 'lodash/toNumber';
export const SUBLIST_HANDLE = 1 << 2;
export const RELEVANCE_HANDLE = 1 << 3;
export const CONDITION_HANDLE = 1 << 4;
// 精度为8 舍入方式为四舍五入  //https://my.oschina.net/sunchp/blog/670909
Decimal.config({ precision: 23, rounding: Decimal.ROUND_HALF_UP });
export function toDecimal(value) {
    try {
        return new Decimal(value);
    }
    catch (e) {
        throw generateError('转换数字时发生错误', {
            extraMessage: e.message,
        });
    }
}
export function toInteger(value) {
    if (value < 0) {
        return new Decimal(new Decimal(value).toFixed(0, Decimal.ROUND_UP));
    }
    return new Decimal(new Decimal(value).toFixed(0, Decimal.ROUND_DOWN));
}
export function validDigitCheck(value) {
    const valid = /^[0-9]{1,15}([.][0-9]{0,8})?$/;
    return valid.test(value);
}
// 从字符串中提取数字
export function extractNumber(value) {
    if (!value)
        return 0;
    let format = value.replace(/[^\d-.]/g, ''); // step1: 去掉除-.数字以外的字符
    format = format.substring(0, 1) + format.substring(1).replace(/[-]/g, ''); // step2: 去掉除第一个-以外的所有-，第一个用来判断正负
    return parseFloat(format); // step3: 将字符串转成数字
}
export const paramTypeValues = {
    [Formula.ParamType.TEXT]: '文本',
    [Formula.ParamType.NUMBER]: '数字',
    [Formula.ParamType.DATE]: '时间',
    [Formula.ParamType.BOOL]: '逻辑',
    [Formula.ParamType.SINGLEUSER]: '人员单选',
    [Formula.ParamType.SIGNLEDEPT]: '部门单选',
    [Formula.ParamType.ADDRESS]: '地址',
    [Formula.ParamType.MULTIUSER]: '人员多选',
    [Formula.ParamType.MULTIDEPT]: '部门多选',
    [Formula.ParamType.MultiSelect]: '多选',
    [Formula.ParamType.Relationship]: '关联数据',
    [Formula.ParamType.Attachment]: '文件',
    [Formula.ParamType.ANY]: '不限',
};
export function getParamTypeValue(key) {
    return paramTypeValues[key] || '未知';
}
export function getSourceTypeValue(key) {
    return {
        [Formula.SourceType.FIELD]: '字段',
        [Formula.SourceType.EDIT]: '非字段',
        [Formula.SourceType.CONDITION]: '条件',
        [Formula.SourceType.ANY]: '不限',
    }[key] || '未知';
}
export const fieldTypeValues = {
    [Formula.FieldType.BASIC]: '基本',
    [Formula.FieldType.SUBLIST]: '明细表',
    [Formula.FieldType.RELEVANCE]: '关联数据',
};
export function getFieldTypeValue(key) {
    return fieldTypeValues[key] || '未知';
}
export function checkParamType(claimType, incomingType) {
    if (claimType === Formula.ParamType.ANY) {
        return true;
    }
    return claimType === incomingType;
}
export function checkSourceType(claimType, incomingType) {
    if (claimType === Formula.SourceType.ANY) {
        return true;
    }
    return claimType === incomingType;
}
export function checkParam(claim, incoming) {
    const paramType = checkParamType(claim.paramType, incoming.paramType);
    const sourceType = checkSourceType(claim.sourceType, incoming.sourceType);
    const paramTypeInfo = paramType ? ['', ''] : [
        `类型：${getParamTypeValue(claim.paramType)}`,
        `类型：${getParamTypeValue(incoming.paramType)}`,
    ];
    const sourceTypeInfo = sourceType ? ['', ''] : [
        `来源：${getSourceTypeValue(claim.sourceType)}`,
        `来源：${getSourceTypeValue(incoming.sourceType)}`,
    ];
    return {
        paramType,
        paramTypeInfo,
        sourceType,
        sourceTypeInfo,
    };
}
// 请保证传入都是明细表字段
export function validateSubFromSame(params) {
    return uniq(params.map(param => {
        const [parId] = param.id.split('.');
        return parId;
    })).length === 1;
}
export const extraRelevanceFuncs = ['LOOKUP', 'ROLLUP'];
export const extraSublistFuncs = ['SUM', 'SUMIFS', 'AVG', 'MIN', 'MAX', 'MATCH'];
export function checkFieldParamFrom(identifier, params, type) {
    const types = {
        [RELEVANCE_HANDLE]: {
            fieldType: Formula.FieldType.RELEVANCE,
            fieldName: getFieldTypeValue(Formula.FieldType.RELEVANCE),
            extraFuncs: extraRelevanceFuncs,
        },
        [SUBLIST_HANDLE]: {
            fieldType: Formula.FieldType.SUBLIST,
            fieldName: getFieldTypeValue(Formula.FieldType.SUBLIST),
            extraFuncs: extraSublistFuncs,
        },
    };
    const { fieldType, fieldName, extraFuncs } = types[type] || {};
    const _params = params.filter(param => param.sourceType === Formula.SourceType.FIELD &&
        param.fieldType === fieldType);
    if (_params.length > 0 && !extraFuncs.includes(identifier.name)) {
        throw generateError(`${fieldName}字段${_params.map(_param => getFieldName(_param)).join(', ')}只允许在函数 ${extraFuncs.map(func => `${func}()`).join('，')}中使用，不允许在${identifier.type === Formula.IdentifierType.FUNC ? `函数 ${identifier.name}() 中` : `操作符 "${identifier.name}" `}使用`);
    }
}
export function compatibleValue(params) {
    return params.map(param => {
        if ([Formula.FieldType.SUBLIST, Formula.FieldType.RELEVANCE].includes(param.fieldType) && !param.isCompatible) {
            param.isCompatible = true;
            param.values = param.value || [];
            param.value = param.values[0];
        }
        return {
            ...param,
        };
    });
}
export function validateNull(value) {
    const _value = trimValue(value);
    return _value === '' || _value === null || _value === undefined;
}
export function validateEmpty(value) {
    if (validateNull(value)) {
        return true;
    }
    return Array.isArray(value) && value.length === 0;
}
export function validateParamNull(param) {
    const { value } = param;
    return validateNull(value);
}
export function validateSubParamNull(param) {
    const { values } = param;
    return !values || !values.length;
}
export function trimValue(value) {
    return typeof value === 'string' ? value.trim() : value;
}
export function toText(param) {
    const { value, paramType, formulaFormat } = param;
    let _value = value;
    switch (paramType) {
        case Formula.ParamType.BOOL:
            _value = typeof value !== 'boolean' ? '' : (value ? '是' : '否');
            break;
        case Formula.ParamType.NUMBER:
            _value = value instanceof Decimal ? value.toString() : _toString(value);
            break;
        case Formula.ParamType.ADDRESS:
            if (typeof formulaFormat === 'function') {
                _value = formulaFormat(value, paramType);
            }
            else {
                _value = getAddressText(value);
            }
            break;
        case Formula.ParamType.MultiSelect:
            _value = value.join(',');
            break;
        case Formula.ParamType.SINGLEUSER:
        case Formula.ParamType.SIGNLEDEPT:
        case Formula.ParamType.MULTIUSER:
        case Formula.ParamType.MULTIDEPT:
            _value = getUnitValue(value);
            break;
        case Formula.ParamType.DATE:
            _value = getDateText(value);
            break;
        case Formula.ParamType.TEXT:
            break;
    }
    _value = _value || ''; // 空值转成空字符串
    return {
        ...param,
        value: _value,
    };
}
export function formatParamValue(param) {
    const { value, paramType } = param;
    let _value = value;
    switch (paramType) {
        case Formula.ParamType.DATE:
            _value = trimValue(value);
            break;
        case Formula.ParamType.BOOL:
            _value = typeof value === 'boolean' ? value : null;
            break;
        case Formula.ParamType.NUMBER:
            // 判定是否能转成数字
            if (isNaN(value)) {
                throw generateError(`存在字段${getFieldName(param)}无法转成数字类型`);
            }
            const unary = param.unary || 1;
            _value = toDecimal(value).mul(unary);
            break;
        case Formula.ParamType.TEXT:
            // 全是空格的情况不去除首尾空格
            if (/^\s+$/.test(_value)) {
                break;
            }
            _value = _toString(value).trim();
            break;
        case Formula.ParamType.MultiSelect: // 多选
        case Formula.ParamType.MULTIDEPT: // 部门多选
        case Formula.ParamType.MULTIUSER: // 人员多选
            if (!value) {
                _value = [];
            }
            break;
    }
    return {
        ...param,
        value: _value,
    };
}
export function getUnitValue(value) {
    const str = Array.isArray(value)
        ? value.map(i => i.name).join(',')
        : value?.name;
    return str;
}
export function getAddressText(value) {
    let ret = '';
    if (value?.province) {
        ret += `${value.province}`;
        if (value?.city) {
            ret += `${value.city}`;
        }
        if (value?.area) {
            ret += `${value.area}`;
        }
        if (value?.detail) {
            ret += `${value.detail}`;
        }
    }
    return ret;
}
export function getDateText(value) {
    return value.replace('T', ' ');
}
export function paramDefaultValue(paramType) {
    switch (paramType) {
        case Formula.ParamType.DATE:
            // return '1900-01-01 00:00:00';
            return '';
        case Formula.ParamType.BOOL:
            return false;
        case Formula.ParamType.NUMBER:
            return 0;
        case Formula.ParamType.TEXT:
        default:
            return '';
    }
}
// 判断特定日期的天数
export function daysInMonth(year, month) {
    if (month == 1) {
        if (year % 4 == 0 && year % 100 != 0) {
            return 29;
        }
        else {
            return 28;
        }
    }
    else if ((month <= 6 && month % 2 == 0) || (month > 6 && month % 2 == 1)) {
        return 31;
    }
    else {
        return 30;
    }
}
export function addYear(curDate, toAddYear) {
    var y = curDate.getFullYear();
    var m = curDate.getMonth();
    var hh = curDate.getHours();
    var mm = curDate.getMinutes();
    var ss = curDate.getSeconds();
    var nextY = y;
    // 如果当前月+要加上的月>11 这里之所以用11是因为 js的月份从0开始
    nextY = y + toAddYear;
    var daysInNextMonth = daysInMonth(nextY, m);
    var day = curDate.getDate();
    if (day > daysInNextMonth) {
        day = daysInNextMonth;
    }
    return new Date(nextY, m, day, hh, mm, ss);
}
export function addMonth(curDate, toAddMonth) {
    var y = curDate.getFullYear();
    var m = curDate.getMonth();
    var hh = curDate.getHours();
    var mm = curDate.getMinutes();
    var ss = curDate.getSeconds();
    var nextY = y;
    var nextM = m;
    // 如果当前月+要加上的月>11 这里之所以用11是因为 js的月份从0开始
    if ((m + toAddMonth) > 11) {
        nextY = y + 1;
        nextM = m + toAddMonth - 12;
    }
    else {
        nextM = curDate.getMonth() + toAddMonth;
    }
    var daysInNextMonth = daysInMonth(nextY, nextM);
    var day = curDate.getDate();
    if (day > daysInNextMonth) {
        day = daysInNextMonth;
    }
    return new Date(nextY, nextM, day, hh, mm, ss);
}
/** 来自于字段的时间类型 格式仅支持 YYYY-MM-DD YYYY-MM-DD HH:mm */
export function getDateFormat(value) {
    return value.length > 10 ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
}
/** 来自于字段的时间类型 格式仅支持 YYYY-MM-DD YYYY-MM-DD HH:mm */
export function compleDate(value) {
    if (!value || typeof value !== 'string') {
        return value;
    }
    let _value = value.trim();
    if (_value.length === 10) {
        _value = `${_value} 00:00:00`;
    }
    else if (_value.length === 16) {
        _value = `${_value}:00`;
    }
    else if (_value.length === 7) {
        _value = `${_value}-01 00:00:00`;
    }
    return _value;
}
/** 来自于字段的时间类型 格式仅支持 YYYY-MM-DD YYYY-MM-DD HH:mm */
export function getDateInstanceFromParam(value) {
    if (!value || typeof value !== 'string') {
        return null;
    }
    const _value = compleDate(value);
    return getDateInstance(_value);
}
export function getDateInstance(date) {
    let target = date;
    if (target instanceof Date && target.toUTCString() !== 'Invalid Date')
        return target;
    switch (typeof target) {
        case 'string':
            if (target.length >= 20) {
                target = target.substring(0, 19);
            }
            target = new Date(target.replace(/-/gi, '/'));
            break;
        case 'number':
            target = new Date(target);
            break;
    }
    return target instanceof Date && target.toUTCString() !== 'Invalid Date' ? target : null;
}
export function checkDate(year, month, day) {
    const _year = Math.floor(year);
    const _month = Math.floor(month) - 1;
    const _day = Math.floor(day);
    var date = new Date(_year, _month, _day);
    if (date.getFullYear() !== _year || date.getMonth() !== _month || date.getDate() !== _day) {
        return false;
    }
    return true;
}
export function dateFormat(date, fmt = 'YYYY-MM-DD HH:mm:ss') {
    const o = {
        'Y+': date.getFullYear(),
        'M+': date.getMonth() + 1,
        'D+': date.getDate(),
        // 'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
        'H+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        S: date.getTime(),
    };
    const week = {
        0: '\u65e5',
        1: '\u4e00',
        2: '\u4e8c',
        3: '\u4e09',
        4: '\u56db',
        5: '\u4e94',
        6: '\u516d',
    };
    let res = fmt;
    if (/(Y+)/.test(res)) {
        res = res.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length));
    }
    let tmp;
    if (/(E+)/.test(res)) {
        if (RegExp.$1.length > 1) {
            if (RegExp.$1.length > 2) {
                tmp = '\u661f\u671f';
            }
            else {
                tmp = '\u5468';
            }
        }
        else {
            tmp = '';
        }
        res = res.replace(RegExp.$1, `${tmp}${week[date.getDay()]}`);
    }
    for (const k of Object.keys(o)) {
        if (new RegExp(`(${k})`).test(res)) {
            res = res.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
        }
    }
    return res;
}
// 获取两个日期的天数差，结果为整数
export function getDays(prevDate, nextDate) {
    const prev = getDateInstance(compleDate(dateFormat(prevDate, 'YYYY-MM-DD')));
    const next = getDateInstance(compleDate(dateFormat(nextDate, 'YYYY-MM-DD')));
    return Math.ceil((prev.getTime() - next.getTime()) / (24 * 3600 * 1000));
}
// 获取两个日期的小时差，结果为两位小数
export function getHours(prevDate, nextDate) {
    const prev = getDateInstance(compleDate(dateFormat(prevDate, 'YYYY-MM-DD HH:mm')));
    const next = getDateInstance(compleDate(dateFormat(nextDate, 'YYYY-MM-DD HH:mm')));
    return Math.round(((prev.getTime() - next.getTime()) / (60 * 60 * 1000)) * 100) / 100;
}
// 获取两个日期的分钟差，结果为整数
export function getMinutes(prevDate, nextDate) {
    const prev = getDateInstance(compleDate(dateFormat(prevDate, 'YYYY-MM-DD HH:mm')));
    const next = getDateInstance(compleDate(dateFormat(nextDate, 'YYYY-MM-DD HH:mm')));
    return Math.ceil((prev.getTime() - next.getTime()) / (60 * 1000));
}
export function parseFormulaStrInfo(formula) {
    const strRegExp = /(?<value>"(\\["]|[^"])*")/g;
    const strInfo = [];
    let match;
    while ((match = strRegExp.exec(formula))) {
        const { groups: { value }, index } = match;
        strInfo.push({
            value,
            start: index,
            end: index + value.length - 1,
        });
    }
    return strInfo;
}
export function outSideStr(index, strInfo) {
    return strInfo.every(info => index < info.start || index > info.end);
}
export function doubleQuoteMatch(formula) {
    const _formula = formula.replace(/(?<value>"(\\["]|[^"])*")/g, '');
    return !(/"/.test(_formula));
}
export function eatWhite(index, formula) {
    let _index = index + 1;
    while (/\s/.test(formula[_index])) {
        _index = _index + 1;
    }
    return _index;
}
export function bracketPair(formula, strInfo, brackets = [['(', ')']]) {
    let count = 0;
    const _brackets = brackets.reduce((options, bracket) => {
        const [prefix, suffix] = bracket;
        const prefixKey = `prefix${prefix.charCodeAt(0)}`;
        const suffixKey = `suffix${suffix.charCodeAt(0)}`;
        options[prefixKey] = { symbol: prefix, handle: () => count++ };
        options[suffixKey] = { symbol: suffix, handle: () => count-- };
        return options;
    }, {});
    // /(?<prefix>{)|(?<suffix>})/gm;
    const bracketRegExp = new RegExp(`${Object.entries(_brackets)
        .map(([key, value]) => `(?<${key}>${escapeRegExp(value.symbol)})`).join('|')}`, 'gm');
    let match;
    while ((match = bracketRegExp.exec(formula))) {
        const { groups, index } = match;
        if (!outSideStr(index, strInfo)) {
            continue;
        }
        const group = Object.entries(groups).find(([, value]) => value);
        group && _brackets[group[0]] && _brackets[group[0]].handle();
    }
    return count === 0;
}
export function parseFormulaFieldIds(formula) {
    const strInfo = parseFormulaStrInfo(formula);
    const strRegExp = /[{](?<id>[A-Za-z0-9.]+)[}]/g;
    const fieldIds = [];
    let match;
    while ((match = strRegExp.exec(formula))) {
        const { groups: { id }, index } = match;
        if (outSideStr(index, strInfo) && !fieldIds.includes(id)) {
            fieldIds.push(id);
        }
    }
    return fieldIds;
}
export function parseFormulaFieldIdsWithoutLookup(formula) {
    // 先把formula处理下
    const replaceRegExp = /LOOKUP\(\{[A-Za-z0-9.]+\}\)|ROLLUP\(\{[A-Za-z0-9.]+\}\)/g;
    const _formula = formula.replace(replaceRegExp, '');
    const strInfo = parseFormulaStrInfo(_formula);
    const strRegExp = /[{](?<id>[A-Za-z0-9.]+)[}]/g;
    const fieldIds = [];
    let match;
    while ((match = strRegExp.exec(_formula))) {
        const { groups: { id }, index } = match;
        if (outSideStr(index, strInfo) && !fieldIds.includes(id)) {
            fieldIds.push(id);
        }
    }
    return fieldIds;
}
export function getFieldName(field, defaultName = '') {
    const fieldName = field && field.name ? field.name
        : (defaultName || (field.sourceType === Formula.SourceType.FIELD ? '暂无名称' : ''));
    return fieldName ? ` {${fieldName}} ` : '';
}
// 用来处理子表引用主表公式循环引用判断
function particalContains(formulaIds, id) {
    const idx = formulaIds.findIndex(formulaId => id.indexOf(formulaId) > -1);
    return idx > -1;
}
// 获取字符串中指定字符出现的位置
function getStrSum(source, target) {
    let s = source;
    let b = s.indexOf(target);
    let num = 0;
    while (b !== -1) {
        num++;
        s = s.substring(b + target.length, s.length);
        b = s.indexOf(target);
    }
    return num;
}
// 判断是否存在混用（Lookup， Rollup） + 普通字段
export function lookupFormulaMix(formula) {
    const fields = parseFormulaFieldIds(formula);
    if (fields.length > 0) {
        // 允许多个lookup 和 rollup混合计算， 但是不允许和Lookup、Rollup混用
        // 判断算法， 字段是否有多个， lookup 或 rollup的数量是否和 fields的字段数量相等
        const lookupNum = getStrSum(formula, 'LOOKUP');
        const rollupNum = getStrSum(formula, 'ROLLUP');
        const total = rollupNum + lookupNum;
        if (total > 0 && total < fields.length) {
            throw generateError('暂不支持LOOKUP、ROLLUP引用字段与其他字段混合运算');
        }
    }
}
/**
 * 公式是否嵌套判断
 * @param formula
 * @param fieldId
 * @param fields
 * @param formulaIds
 */
export function validateFormulaNested(formula, fieldId, fields = {}, formulaIds) {
    if (!fieldId || !fields || Object.keys(fields).length === 0) {
        return;
    }
    const _formulaIds = formulaIds ? [...formulaIds, fieldId] : [fieldId];
    // Lookup 或 Rollup 引用的字段不参与判断
    const fieldIds = parseFormulaFieldIdsWithoutLookup(formula);
    for (let _fieldId of fieldIds) {
        // 子表引用主表字段需要截断
        if (fieldId.indexOf('.') > -1 && _fieldId.indexOf('.') < 0) {
            _fieldId = `${fieldId.split('.')[0]}.${_fieldId}`;
        }
        let field = fields[_fieldId];
        if (!field) {
            if (_fieldId.indexOf('.') > -1) {
                field = fields[_fieldId.split('.')[1]];
            }
            else if (fieldId.indexOf('.') > -1) {
                field = fields[`${fieldId.split('.')[0]}.${_fieldId}`];
            }
        }
        const [targetId, ...subFormulaIds] = _formulaIds;
        const name = getFieldName(field, '当前公式');
        // bug, 出现了一种情况是当前字段编码和关联的另外一张表的字段编号一模一样
        if (_formulaIds.includes(_fieldId) || particalContains(_formulaIds, _fieldId)) {
            if (targetId === _fieldId && _formulaIds.length === 1) {
                throw generateError(`公式中嵌套了公式字段${name}，不允许公式嵌套公式字段自身`);
            }
            throw generateError(`公式中存在公式字段${subFormulaIds.map(id => `${getFieldName(fields[id])}`).join(' => ')}嵌套了公式字段${name}，不允许公式循环嵌套`);
        }
        if (!field) {
            continue;
        }
        if (field.formula) { // 公式字段
            validateFormulaNested(field.formula, _fieldId, fields, _formulaIds);
        }
    }
}
/**
 * 是否是对象类型
 * @param param
 * @returns
 */
export function isObjectType(paramType) {
    return [Formula.ParamType.ADDRESS,
        Formula.ParamType.SINGLEUSER,
        Formula.ParamType.SIGNLEDEPT,
        Formula.ParamType.MULTIUSER,
        Formula.ParamType.MULTIDEPT,
        Formula.ParamType.MultiSelect,
    ].includes(paramType);
}
/**
 * 字段类型检测
 * @param identifier
 * @param params
 */
export function validateObjectType(identifier, params) {
    for (let i = 0; i < params.length; i++) {
        if (isObjectType(params[i].paramType)) {
            const text = identifier.type === Formula.IdentifierType.FUNC ? `函数 ${identifier.name}() ` : `操作符 "${identifier.name}" `;
            throw generateError(`${text}参数错误：第${(i + 1)}个参数${getFieldName(params[i])}【${getParamTypeValue(params[i].paramType)}】为对象类型，无法计算，请使用TEXT()函数包裹转换为文本`);
        }
    }
}
;
// 文本contains
export function containsText(source, target) {
    if (!target) {
        return false;
    }
    if (Array.isArray(target)) {
        return intersection(source, target).length === target.length;
    }
    else {
        return source.includes(target);
    }
}
;
// 人员部门contains
export function containsUnit(source, target) {
    if (!target) {
        return false;
    }
    if (Array.isArray(target)) {
        for (const t of target) {
            if (!source.some(s => s.id === t.id)) {
                return false;
            }
        }
        return true;
    }
    else {
        return source.some(s => s.id === target.id);
    }
}
// 获取字段的数字值
export function getNumberValue(param) {
    const { value, unary = 1 } = param;
    if (value instanceof Decimal) {
        return value.mul(unary).toNumber();
    }
    if (typeof value === 'number') {
        return value * unary;
    }
    if (typeof value === 'string') {
        const _value = toNumber(value);
        if (isNaN(_value)) {
            throw generateError(`字段${getFieldName(param)}无法转成数字类型`);
        }
        return _value * unary;
    }
    return 0;
}
// 根据单选类型获取多选类型
export function inferMultiFromSingleType(type) {
    const singleToMultiTypeMap = {
        [Formula.ParamType.SINGLEUSER]: Formula.ParamType.MULTIUSER,
        [Formula.ParamType.SIGNLEDEPT]: Formula.ParamType.MULTIDEPT,
        [Formula.ParamType.TEXT]: Formula.ParamType.MultiSelect,
        [Formula.ParamType.NUMBER]: Formula.ParamType.MultiSelect,
    };
    return singleToMultiTypeMap[type];
}
// 根据多选类型获取单选类型
export function inferSingleFromMultiType(type) {
    const multiToSingleTypeMap = {
        [Formula.ParamType.MULTIUSER]: Formula.ParamType.SINGLEUSER,
        [Formula.ParamType.MULTIDEPT]: Formula.ParamType.SIGNLEDEPT,
        [Formula.ParamType.MultiSelect]: Formula.ParamType.TEXT,
    };
    return multiToSingleTypeMap[type];
}
