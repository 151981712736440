import { FieldType } from "@lcos-kernel/chicken-library";
var SceneType = /* @__PURE__ */ ((SceneType2) => {
  SceneType2["RelationshipDisplayScene"] = "relationshipDisplayScene";
  SceneType2["RelationshipFilterScene"] = "relationshipFilterScene";
  SceneType2["BatchReplaceScene"] = "batchReplaceScene";
  SceneType2["ShareScene"] = "shareScene";
  SceneType2["SortScene"] = "sortScene";
  SceneType2["ControlPropertyScene"] = "controlPropertyScene";
  SceneType2["CalendarDisplayScene"] = "calendarDisplayScene";
  SceneType2["TableCopyScene"] = "tableCopyScene";
  SceneType2["TableHasModalScene"] = "tableHasModalScene";
  SceneType2["FormulaDisplayScene"] = "formulaDisplayScene";
  SceneType2["TableClearCellScene"] = "tableClearCellScene";
  SceneType2["BatchReplaceFilterScene"] = "batchReplaceFilterScene";
  SceneType2["WorkflowPickPersonScene"] = "workflowPickPersonScene";
  SceneType2["MobileTableTextTitleScene"] = "mobileTableTextTitleScene";
  SceneType2["ControlPropertyDisplayScene"] = "controlPropertyDisplayScene";
  SceneType2["AutofillConditionScene"] = "autofillConditionScene";
  SceneType2["ControlPropertyAutoFillScene"] = "controlPropertyAutoFillScene";
  SceneType2["ControlPropertyNotAlertScene"] = "controlPropertyNotAlertScene";
  SceneType2["WorkflowDataNodeDynamicScene"] = "workflowDataNodeDynamicScene";
  SceneType2["MobileTableDisplayColumnScene"] = "mobileTableDisplayColumnScene";
  SceneType2["LookupTargetMultiUserDeptScene"] = "lookupTargetMultiUserDeptScene";
  SceneType2["LookupTargetSingleUserDeptScene"] = "lookupTargetSingleUserDeptScene";
  SceneType2["MemberManageSystemScene"] = "memberManageSystemScene";
  SceneType2["DataLogicMultiUserDept"] = "dataLogicMultiUserDept";
  SceneType2["DataLogicSingleUserDept"] = "dataLogicSingleUserDept";
  SceneType2["TableTextTypeScene"] = "tableTextTypeScene";
  SceneType2["SubmitValidateScene"] = "submitValidateScene";
  SceneType2["SyncTableScene"] = "syncTableScene";
  SceneType2["DataPermissionCustomFieldScene"] = "dataPermissionCustomFieldScene";
  SceneType2["FieldRuleMultiScene"] = "fieldRuleMultiScene";
  SceneType2["MobileQuickFilterUserDeptScene"] = "quickFilterUserDeptScene";
  SceneType2["MobileFilterInputScene"] = "mobileFilterInputScene";
  SceneType2["MobileFilterHasTargetTypeScene"] = "mobileFilterHasTargetTypeScene";
  SceneType2["WorkflowNotificationScene"] = "workflowNotificationScene";
  SceneType2["WorkflowStartNodeDateFilterScene"] = "workflowStartNodeDateFilterScene";
  SceneType2["WorkflowAddNodeSubtableFilterScene"] = "workflowAddNodeSubtableFilterScene";
  SceneType2["WorkflowFilterScene"] = "workflowFilterScene";
  SceneType2["WorkflowTriggerScene"] = "workflowTriggerScene";
  SceneType2["WorkflowUnEditScene"] = "workflowUnEditScene";
  SceneType2["WorkflowAddNodeScene"] = "workflowAddNodeScene";
  SceneType2["WorkflowDataNodeScene"] = "workflowDataNodeScene";
  SceneType2["WorkflowAddNodeSubScene"] = "workflowAddNodeSubScene";
  SceneType2["WorkflowCreateSettingScene"] = "workflowCreateSettingScene";
  SceneType2["MemberDataRangeScene"] = "memberDataRangeScene";
  SceneType2["SelectOptionsRelationScene"] = "selectOptionsRelationScene";
  SceneType2["MemberCustomConditionScene"] = "memberCustomConditionScene";
  return SceneType2;
})(SceneType || {});
var FilterType = /* @__PURE__ */ ((FilterType2) => {
  FilterType2["Base"] = "baseFilterScene";
  FilterType2["Normal"] = "normalFilterScene";
  FilterType2["WorkFlow"] = "workflowFilterScene";
  return FilterType2;
})(FilterType || {});
var DynamicValueMethod = /* @__PURE__ */ ((DynamicValueMethod2) => {
  DynamicValueMethod2["ObjectId"] = "getDynamicObjectIdCtrlTypes";
  DynamicValueMethod2["CreateTime"] = "getDynamicCreateTimeCtrlTypes";
  DynamicValueMethod2["ModifiedTime"] = "getDynamicModifiedTimeCtrlTypes";
  DynamicValueMethod2["Number"] = "getDynamicNumberCtrlTypes";
  DynamicValueMethod2["SingleSelect"] = "getDynamicSingleSelectCtrlTypes";
  DynamicValueMethod2["MultiSelect"] = "getDynamicMultiSelectCtrlTypes";
  DynamicValueMethod2["DateTime"] = "getDynamicDateTimeCtrlTypes";
  DynamicValueMethod2["SingleUser"] = "getDynamicSingleUserCtrlTypes";
  DynamicValueMethod2["MultiUser"] = "getDynamicMultiUserCtrlTypes";
  DynamicValueMethod2["Creater"] = "getDynamicCreaterCtrlTypes";
  DynamicValueMethod2["Modifier"] = "getDynamicModifierCtrlTypes";
  DynamicValueMethod2["Owner"] = "getDynamicOwnerCtrlTypes";
  DynamicValueMethod2["OwnerDept"] = "getDynamicOwnerDeptCtrlTypes";
  DynamicValueMethod2["SingleDept"] = "getDynamicSingleDeptCtrlTypes";
  DynamicValueMethod2["MultiDept"] = "getDynamicMultiDeptCtrlTypes";
  DynamicValueMethod2["UserInfo"] = "getDynamicUserInfoCtrlTypes";
  DynamicValueMethod2["Dept"] = "getDynamicDeptCtrlTypes";
  DynamicValueMethod2["SingleText"] = "getDynamicSingleTextCtrlTypes";
  DynamicValueMethod2["MultiText"] = "getDynamicMultiTextCtrlTypes";
  DynamicValueMethod2["Relationship"] = "getDynamicRelationshipCtrlTypes";
  DynamicValueMethod2["SN"] = "getDynamicSNCtrlTypes";
  DynamicValueMethod2["Address"] = "getDynamicAddressCtrlTypes";
  DynamicValueMethod2["Phone"] = "getDynamicPhoneCtrlTypes";
  DynamicValueMethod2["Email"] = "getDynamicEmailCtrlTypes";
  DynamicValueMethod2["IDNumber"] = "getDynamicIDNumberCtrlTypes";
  DynamicValueMethod2["Attachment"] = "getDynamicAttachmentCtrlTypes";
  DynamicValueMethod2["Formula"] = "getDynamicFormulaCtrlTypes";
  DynamicValueMethod2["LookUp"] = "getDynamicLookUpCtrlTypes";
  DynamicValueMethod2["RollUp"] = "getDynamicRollUpCtrlTypes";
  return DynamicValueMethod2;
})(DynamicValueMethod || {});
var FilterMatchAnyoneScene = /* @__PURE__ */ ((FilterMatchAnyoneScene2) => {
  FilterMatchAnyoneScene2["Normal"] = "normalMatchAnyoneScene";
  FilterMatchAnyoneScene2["Workflow"] = "workflowMatchAnyoneScene";
  return FilterMatchAnyoneScene2;
})(FilterMatchAnyoneScene || {});
function getFreezedSystemControlTypes() {
  return [FieldType.CreatedTime, FieldType.ModifiedTime, FieldType.Creater, FieldType.Modifier, FieldType.SN, FieldType.QRCode, FieldType.Status, FieldType.WorkflowName, FieldType.WorkflowActivity, FieldType.WorkflowVersion, FieldType.ActivityParticipants];
}
function getSystemCtrlTypes() {
  return [...getSystemTimeCtrlTypes(), ...getSystemUserDeptCtrlTypes(), FieldType.SN, FieldType.Rollup, FieldType.QRCode, FieldType.Status, FieldType.WorkflowName, FieldType.WorkflowActivity, FieldType.WorkflowVersion, FieldType.ActivityParticipants];
}
function getUnEditControlTypes() {
  return [FieldType.Rollup, FieldType.Lookup, FieldType.Formula, FieldType.SN];
}
function getDateTimeControlTypes() {
  return [FieldType.CreatedTime, FieldType.ModifiedTime, FieldType.DateTime];
}
function getDynamicControlTypes() {
  return [FieldType.Formula, FieldType.Lookup];
}
function getBeforeDynamicCtrlTypes() {
  return [FieldType.Formula];
}
function getSingleUserControlTypes() {
  return [FieldType.SingleUser, FieldType.Creater, FieldType.Modifier, FieldType.Owner];
}
function getMultiUserControlTypes() {
  return [FieldType.MultiUser, FieldType.ActivityParticipants];
}
function getSingleDeptControlTypes() {
  return [FieldType.SingleDept];
}
function getMultiDeptControlTypes() {
  return [FieldType.MultiDept, FieldType.OwnerDept];
}
function getAllDeptControlTypes() {
  return [...getSingleDeptControlTypes(), ...getMultiDeptControlTypes()];
}
function getUserDeptControlTypes() {
  return [...getSingleUserControlTypes(), ...getMultiUserControlTypes(), ...getSingleDeptControlTypes(), ...getMultiDeptControlTypes()];
}
function getBaseTextControlTypes() {
  return [FieldType.SinglelineText, FieldType.MultilineText, FieldType.PhoneNumber, FieldType.IDNumber, FieldType.Email];
}
function getFilterSceneTextControlTypes() {
  return [FieldType.SinglelineText, FieldType.MultilineText, FieldType.PhoneNumber, FieldType.IDNumber, FieldType.Email, FieldType.SingleSelect];
}
function getTableTextCtrlType() {
  return [FieldType.SinglelineText, FieldType.Number, FieldType.PhoneNumber, FieldType.IDNumber, FieldType.Email, FieldType.MultilineText];
}
function getMultiUserDeptControlTypes() {
  return [FieldType.MultiUser, FieldType.MultiDept, FieldType.OwnerDept];
}
function getSingleUserDeptControlTypes() {
  return [FieldType.SingleUser, FieldType.SingleDept, FieldType.Owner, FieldType.Creater, FieldType.Modifier];
}
function getCommonNotSupportCtrlTypes() {
  return [FieldType.QRCode, FieldType.Location];
}
function getNumberCtrlTypes() {
  return [FieldType.Number];
}
function getSelectUserCtrlTypes() {
  return [FieldType.SingleUser, FieldType.MultiUser];
}
function getSelectCtrlTypes() {
  return [FieldType.SingleSelect, FieldType.MultiSelect];
}
function getOperationCommonFilterCtrlTypes() {
  return [FieldType.SubTable];
}
function getCreateAndModifieCtrlTypes() {
  return [FieldType.CreatedTime, FieldType.ModifiedTime, FieldType.Creater, FieldType.Modifier];
}
function getSystemUserDeptCtrlTypes() {
  return [FieldType.Owner, FieldType.OwnerDept, FieldType.Modifier, FieldType.Creater];
}
function getSystemTimeCtrlTypes() {
  return [FieldType.CreatedTime, FieldType.ModifiedTime];
}
function getShareData() {
  return [FieldType.Relationship, FieldType.SubTable, FieldType.QRCode];
}
function getHasModalCtrlTypes() {
  return [FieldType.DateTime, FieldType.Relationship, FieldType.Address, FieldType.Owner, ...getSelectCtrlTypes(), ...getOperationCommonFilterCtrlTypes(), ...getCommonNotSupportCtrlTypes(), ...getSelectUserCtrlTypes(), ...getAllDeptControlTypes()];
}
function getLineCtrlType() {
  return [FieldType.SinglelineText, FieldType.MultilineText];
}
function getRelationCtrlType() {
  return [FieldType.Relationship, FieldType.Rollup];
}
function getAddressCtrlType() {
  return [FieldType.Address];
}
function getUserInfoCtrlType() {
  return [FieldType.PhoneNumber, FieldType.Email, FieldType.IDNumber];
}
const specific = {
  getFreezedSystemControlTypes,
  getUnEditControlTypes,
  getDateTimeControlTypes,
  getDynamicControlTypes,
  getSingleUserControlTypes,
  getMultiUserControlTypes,
  getSingleDeptControlTypes,
  getMultiDeptControlTypes,
  getAllDeptControlTypes,
  getUserDeptControlTypes,
  getFilterSceneTextControlTypes,
  getMultiUserDeptControlTypes,
  getSingleUserDeptControlTypes,
  getCommonNotSupportCtrlTypes,
  getNumberCtrlTypes,
  getSelectUserCtrlTypes,
  getSelectCtrlTypes,
  getOperationCommonFilterCtrlTypes,
  getCreateAndModifieCtrlTypes,
  getSystemUserDeptCtrlTypes,
  getSystemTimeCtrlTypes,
  getSystemCtrlTypes,
  getHasModalCtrlTypes,
  getTableTextCtrlType,
  getLineCtrlType,
  getRelationCtrlType,
  getAddressCtrlType,
  getBaseTextControlTypes,
  getBeforeDynamicCtrlTypes,
  getUserInfoCtrlType,
  getShareData
};
const batchReplaceFilterScene = {
  getNotSupportCtrlTypes() {
    return [...specific.getCommonNotSupportCtrlTypes(), ...specific.getOperationCommonFilterCtrlTypes()];
  }
};
const batchReplaceScene = {
  getSupportCtrlTypes() {
    return [...specific.getTableTextCtrlType(), ...specific.getSelectCtrlTypes(), ...specific.getSelectUserCtrlTypes(), ...specific.getAllDeptControlTypes(), ...specific.getAddressCtrlType(), FieldType.DateTime, FieldType.Owner, FieldType.Relationship];
  }
};
const calendarDisplayScene = {
  getNotSupportCtrlTypes: function() {
    return [FieldType.Relationship, ...specific.getOperationCommonFilterCtrlTypes()];
  }
};
const controlPropertyAutoFillScene = {
  getNotSupportCtrlTypes() {
    return [FieldType.Relationship, FieldType.OwnerDept, FieldType.Status, FieldType.WorkflowName, FieldType.WorkflowActivity, FieldType.WorkflowVersion, FieldType.ActivityParticipants, ...specific.getCommonNotSupportCtrlTypes(), ...specific.getOperationCommonFilterCtrlTypes()];
  }
};
const autofillConditionScene = {
  getNotSupportCtrlTypes() {
    return [FieldType.SubTable];
  }
};
const controlPropertyDisplayScene = {
  getNotSupportCtrlTypes() {
    return [...specific.getRelationCtrlType(), ...specific.getOperationCommonFilterCtrlTypes(), ...specific.getCommonNotSupportCtrlTypes()];
  }
};
const controlPropertyNotAlertScene = {
  getSupportCtrlTypes: function() {
    return [FieldType.Rollup, FieldType.SN, ...specific.getDynamicControlTypes()];
  }
};
const selectOptionsRelationScene = {
  getNotSupportCtrlTypes() {
    return [FieldType.SubTable];
  }
};
const formulaDisplayScene = {
  getNotSupportCtrlTypes() {
    return [FieldType.Relationship];
  }
};
const mobileTableDisplayColumnScene = {
  getNotSupportCtrlTypes() {
    return [FieldType.QRCode];
  }
};
const mobileTableTextTitleScene = {
  getSupportCtrlTypes() {
    return [...specific.getFilterSceneTextControlTypes(), FieldType.SN, FieldType.Number];
  }
};
const relationshipDisplayScene = {
  getNotSupportCtrlTypes: function() {
    return [...specific.getCommonNotSupportCtrlTypes(), FieldType.SubTable];
  }
};
const relationshipFilterScene = {
  getNotSupportCtrlTypes: function() {
    return [FieldType.Relationship, ...specific.getOperationCommonFilterCtrlTypes()];
  }
};
const shareScene = {
  getNotSupportCtrlTypes: function() {
    return [...specific.getShareData()];
  }
};
const sortScene = {
  getNotSupportCtrlTypes: function() {
    return [...specific.getSingleUserDeptControlTypes(), ...specific.getMultiUserDeptControlTypes(), ...specific.getCommonNotSupportCtrlTypes(), ...specific.getOperationCommonFilterCtrlTypes(), ...specific.getAddressCtrlType(), FieldType.Relationship, FieldType.Attachment, FieldType.Signature];
  }
};
const tableClearCellScene = {
  getNotSupportCtrlTypes() {
    return [...specific.getSystemCtrlTypes(), ...specific.getDynamicControlTypes(), FieldType.Relationship, FieldType.SubTable];
  }
};
const tableCopyScene = {
  getNotSupportCtrlTypes() {
    return [...specific.getOperationCommonFilterCtrlTypes(), ...specific.getCommonNotSupportCtrlTypes(), FieldType.Relationship];
  }
};
const tableHasModalScene = {
  getSupportCtrlTypes() {
    return [...specific.getHasModalCtrlTypes()];
  }
};
const workflowAddNodeScene = {
  getSupportCtrlTypes() {
    return [...specific.getLineCtrlType(), ...specific.getSelectCtrlTypes(), ...specific.getUserDeptControlTypes(), ...specific.getDateTimeControlTypes(), ...specific.getDynamicControlTypes(), ...specific.getNumberCtrlTypes(), ...specific.getRelationCtrlType(), FieldType.Attachment, FieldType.ObjectId];
  }
};
const workflowAddNodeSubScene = {
  getNotSupportCtrlTypes() {
    return [...specific.getSystemUserDeptCtrlTypes(), ...specific.getSystemTimeCtrlTypes(), FieldType.ObjectId];
  }
};
const workflowDataNodeDynamicScene = {
  getSupportCtrlTypes() {
    return [...specific.getTableTextCtrlType(), ...specific.getSelectCtrlTypes(), ...specific.getDateTimeControlTypes(), ...specific.getUserDeptControlTypes(), ...specific.getDynamicControlTypes(), ...specific.getAddressCtrlType(), ...specific.getRelationCtrlType(), FieldType.Attachment, FieldType.SN, FieldType.ObjectId];
  }
};
const workflowDataNodeScene = {
  getSupportCtrlTypes() {
    return [...specific.getTableTextCtrlType(), ...specific.getSelectCtrlTypes(), ...specific.getDateTimeControlTypes(), ...specific.getUserDeptControlTypes(), ...specific.getDynamicControlTypes(), ...specific.getRelationCtrlType(), ...specific.getAddressCtrlType()];
  }
};
const workflowPickPersionScene = {
  getSupportCtrlTypes() {
    return [...specific.getSingleUserControlTypes(), ...specific.getAllDeptControlTypes()];
  }
};
const workflowUnEditScene = {
  getSupportCtrlTypes() {
    return [FieldType.Rollup, ...specific.getFreezedSystemControlTypes(), ...specific.getDynamicControlTypes()];
  }
};
const workflowFilterScene$1 = {
  getNotSupportCtrlTypes() {
    return [FieldType.Address, ...specific.getCommonNotSupportCtrlTypes(), ...specific.getOperationCommonFilterCtrlTypes()];
  }
};
const lookupTargetSingleUserDeptScene = {
  getSupportCtrlTypes() {
    return specific.getSingleUserDeptControlTypes();
  }
};
const lookupTargetMultiUserDeptScene = {
  getSupportCtrlTypes() {
    return specific.getMultiUserDeptControlTypes();
  }
};
const memberManageSystemScene = {
  getSupportCtrlTypes() {
    return [...specific.getFreezedSystemControlTypes(), ...specific.getCommonNotSupportCtrlTypes()];
  }
};
const dataLogicMultiUserDept = {
  getSupportCtrlTypes() {
    return [...specific.getMultiUserDeptControlTypes()];
  }
};
const dataLogicSingleUserDept = {
  getSupportCtrlTypes() {
    return [...specific.getSingleUserDeptControlTypes()];
  }
};
const tableTextTypeScene = {
  getSupportCtrlTypes() {
    return [...specific.getTableTextCtrlType()];
  }
};
const workflowTriggerScene = {
  getSupportCtrlTypes() {
    return [...specific.getDynamicControlTypes(), ...specific.getAllDeptControlTypes(), ...specific.getSelectUserCtrlTypes(), ...specific.getSelectCtrlTypes(), ...specific.getNumberCtrlTypes(), ...specific.getRelationCtrlType(), ...specific.getAddressCtrlType(), ...specific.getBaseTextControlTypes(), FieldType.Owner, FieldType.Modifier, FieldType.ModifiedTime, FieldType.Status, FieldType.DateTime, FieldType.Attachment];
  }
};
const workflowCreateSettingScene = {
  getSupportCtrlTypes() {
    return [...specific.getBaseTextControlTypes(), ...specific.getUserDeptControlTypes(), ...specific.getSelectCtrlTypes(), ...specific.getDateTimeControlTypes(), ...specific.getDynamicControlTypes(), ...specific.getNumberCtrlTypes(), FieldType.SN, FieldType.Relationship, FieldType.Rollup, FieldType.Attachment];
  }
};
const submitValidateScene = {
  getNotSupportCtrlTypes() {
    return [FieldType.SubTable, FieldType.Address, FieldType.OwnerDept];
  }
};
const syncTableScene = {
  getNotSupportCtrlTypes() {
    return specific.getOperationCommonFilterCtrlTypes();
  }
};
const dataPermissionCustomFieldScene = {
  getSupportCtrlTypes() {
    return [...specific.getSingleUserControlTypes(), ...specific.getMultiUserControlTypes()];
  }
};
const fieldRuleMultiScene = {
  getSupportCtrlTypes() {
    return [...specific.getDateTimeControlTypes(), ...specific.getUserDeptControlTypes(), FieldType.SingleSelect, FieldType.Relationship].filter((item) => item !== FieldType.OwnerDept);
  }
};
const quickFilterUserDeptScene = {
  getSupportCtrlTypes() {
    return [FieldType.SingleUser, FieldType.MultiUser, FieldType.SingleDept, FieldType.MultiDept];
  }
};
const mobileFilterInputScene = {
  getSupportCtrlTypes() {
    return [FieldType.SinglelineText, FieldType.MultilineText, FieldType.Relationship, FieldType.Address, FieldType.SN];
  }
};
const mobileFilterHasTargetTypeScene = {
  getSupportCtrlTypes() {
    return [...specific.getUserInfoCtrlType(), ...specific.getDynamicControlTypes(), FieldType.Rollup];
  }
};
const workflowNotificationScene = {
  getSupportCtrlTypes() {
    return [...specific.getBaseTextControlTypes(), ...specific.getSelectCtrlTypes(), ...specific.getNumberCtrlTypes(), ...specific.getAddressCtrlType(), ...specific.getDateTimeControlTypes(), ...specific.getUserDeptControlTypes(), ...specific.getRelationCtrlType(), ...specific.getDynamicControlTypes(), FieldType.SN].filter((item) => item !== FieldType.Modifier);
  }
};
const workflowStartNodeDateFilterScene = {
  getSupportCtrlTypes() {
    return [...specific.getBaseTextControlTypes(), ...specific.getNumberCtrlTypes(), ...specific.getSelectCtrlTypes(), ...specific.getUserDeptControlTypes(), ...specific.getDateTimeControlTypes(), ...specific.getDynamicControlTypes(), FieldType.Rollup, FieldType.Relationship, FieldType.SN, FieldType.Attachment];
  }
};
const workflowAddNodeSubtableFilterScene = {
  getSupportCtrlTypes() {
    return [...specific.getLineCtrlType(), ...specific.getSelectCtrlTypes(), ...specific.getUserDeptControlTypes(), ...specific.getDateTimeControlTypes(), ...specific.getDynamicControlTypes(), ...specific.getNumberCtrlTypes(), ...specific.getRelationCtrlType(), FieldType.Attachment, FieldType.SN, FieldType.ObjectId];
  }
};
const memberDataRangeScene$1 = {
  getNotSupportCtrlTypes() {
    return [FieldType.SubTable, FieldType.Relationship];
  }
};
const memberDataRangeScene = {
  getNotSupportCtrlTypes() {
    return [FieldType.SubTable, FieldType.Relationship];
  }
};
const scenes = {
  sortScene,
  shareScene,
  tableCopyScene,
  syncTableScene,
  batchReplaceScene,
  tableTextTypeScene,
  tableHasModalScene,
  submitValidateScene,
  formulaDisplayScene,
  tableClearCellScene,
  fieldRuleMultiScene,
  workflowFilterScene: workflowFilterScene$1,
  workflowUnEditScene,
  workflowAddNodeScene,
  calendarDisplayScene,
  workflowTriggerScene,
  workflowDataNodeScene,
  mobileFilterInputScene,
  dataLogicMultiUserDept,
  dataLogicSingleUserDept,
  memberManageSystemScene,
  relationshipFilterScene,
  workflowAddNodeSubScene,
  batchReplaceFilterScene,
  quickFilterUserDeptScene,
  workflowPickPersonScene: workflowPickPersionScene,
  relationshipDisplayScene,
  mobileTableTextTitleScene,
  workflowNotificationScene,
  workflowCreateSettingScene,
  controlPropertyDisplayScene,
  autofillConditionScene,
  controlPropertyAutoFillScene,
  controlPropertyNotAlertScene,
  workflowDataNodeDynamicScene,
  mobileTableDisplayColumnScene,
  dataPermissionCustomFieldScene,
  lookupTargetMultiUserDeptScene,
  mobileFilterHasTargetTypeScene,
  lookupTargetSingleUserDeptScene,
  workflowStartNodeDateFilterScene,
  workflowAddNodeSubtableFilterScene,
  memberDataRangeScene: memberDataRangeScene$1,
  selectOptionsRelationScene,
  memberCustomConditionScene: memberDataRangeScene
};
const baseFilterScene = {
  getDynamicDateTimeCtrlTypes: function() {
    return [...specific.getDateTimeControlTypes(), ...specific.getDynamicControlTypes()];
  },
  getDynamicSingleUserCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes()];
  },
  getDynamicMultiUserCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes(), ...specific.getMultiUserControlTypes()];
  },
  getDynamicUserInfoCtrlTypes: function() {
    return [...specific.getBaseTextControlTypes()];
  },
  getDynamicDeptCtrlTypes: function() {
    return [...specific.getAllDeptControlTypes()];
  },
  getDynamicSingleTextCtrlTypes: function() {
    return [...specific.getBaseTextControlTypes(), ...specific.getBeforeDynamicCtrlTypes(), FieldType.SingleSelect, FieldType.SN];
  },
  getDynamicMultiTextCtrlTypes: function() {
    return [...specific.getFilterSceneTextControlTypes(), ...specific.getBeforeDynamicCtrlTypes()];
  },
  getDynamicRelationshipCtrlTypes: function() {
    return [FieldType.ObjectId, FieldType.Relationship];
  }
};
const normalFilterScene = {
  getDynamicObjectIdCtrlTypes: function() {
    return [FieldType.ObjectId, FieldType.Relationship];
  },
  getDynamicDateTimeCtrlTypes: function() {
    return [...specific.getDateTimeControlTypes()];
  },
  getDynamicCreateTimeCtrlTypes: function() {
    return [...specific.getDateTimeControlTypes()];
  },
  getDynamicModifiedTimeCtrlTypes: function() {
    return [...specific.getDateTimeControlTypes()];
  },
  getDynamicNumberCtrlTypes: function() {
    return [...specific.getNumberCtrlTypes()];
  },
  getDynamicSingleSelectCtrlTypes: function() {
    return [FieldType.SingleSelect];
  },
  getDynamicMultiSelectCtrlTypes: function() {
    return [FieldType.MultiSelect];
  },
  getDynamicCreaterCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes()];
  },
  getDynamicModifierCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes()];
  },
  getDynamicOwnerCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes()];
  },
  getDynamicSingleUserCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes()].filter((item) => item !== FieldType.Modifier);
  },
  getDynamicMultiUserCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes()];
  },
  getDynamicSingleDeptCtrlTypes: function() {
    return [...specific.getAllDeptControlTypes()].filter((item) => item !== FieldType.MultiDept);
  },
  getDynamicMultiDeptCtrlTypes: function() {
    return [...specific.getAllDeptControlTypes()];
  },
  getDynamicSingleTextCtrlTypes: function() {
    return [...specific.getBaseTextControlTypes()];
  },
  getDynamicMultiTextCtrlTypes: function() {
    return [...specific.getBaseTextControlTypes()];
  },
  getDynamicRelationshipCtrlTypes: function() {
    return [FieldType.ObjectId, FieldType.Relationship];
  },
  getDynamicSNCtrlTypes: function() {
    return [FieldType.SinglelineText, FieldType.SN];
  },
  getDynamicAddressCtrlTypes: function() {
    return [...specific.getAddressCtrlType()];
  }
};
const workflowFilterScene = {
  getDynamicObjectIdCtrlTypes: function() {
    return [FieldType.ObjectId, FieldType.Relationship];
  },
  getDynamicDateTimeCtrlTypes: function() {
    return [...specific.getDateTimeControlTypes(), ...specific.getDynamicControlTypes(), FieldType.Rollup];
  },
  getDynamicCreateTimeCtrlTypes: function() {
    return specific.getDateTimeControlTypes();
  },
  getDynamicModifiedTimeCtrlTypes: function() {
    return specific.getDateTimeControlTypes();
  },
  getDynamicNumberCtrlTypes: function() {
    return [...specific.getDynamicControlTypes(), ...specific.getNumberCtrlTypes(), FieldType.Rollup];
  },
  getDynamicMultiSelectCtrlTypes: function() {
    return [...specific.getDynamicControlTypes(), ...specific.getSelectCtrlTypes(), FieldType.SinglelineText];
  },
  getDynamicSingleSelectCtrlTypes: function() {
    return [...specific.getDynamicControlTypes(), FieldType.SinglelineText, FieldType.SingleSelect];
  },
  getDynamicCreaterCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes(), ...specific.getDynamicControlTypes()].filter((type) => type !== FieldType.Creater);
  },
  getDynamicModifierCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes(), ...specific.getDynamicControlTypes()].filter((type) => type !== FieldType.Modifier);
  },
  getDynamicOwnerCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes(), ...specific.getDynamicControlTypes()];
  },
  getDynamicOwnerDeptCtrlTypes: function() {
    return [...specific.getAllDeptControlTypes(), ...specific.getDynamicControlTypes()];
  },
  getDynamicSingleUserCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes(), ...specific.getDynamicControlTypes()];
  },
  getDynamicMultiUserCtrlTypes: function() {
    return [...specific.getSingleUserControlTypes(), ...specific.getMultiUserControlTypes(), ...specific.getDynamicControlTypes()];
  },
  getDynamicSingleDeptCtrlTypes: function() {
    return [FieldType.SingleDept, FieldType.OwnerDept, ...specific.getDynamicControlTypes()];
  },
  getDynamicMultiDeptCtrlTypes: function() {
    return [...specific.getAllDeptControlTypes(), ...specific.getDynamicControlTypes()];
  },
  getDynamicSingleTextCtrlTypes: function() {
    return [...specific.getFilterSceneTextControlTypes(), ...specific.getDynamicControlTypes(), FieldType.SN, FieldType.MultiSelect];
  },
  getDynamicMultiTextCtrlTypes: function() {
    return [...specific.getFilterSceneTextControlTypes(), ...specific.getDynamicControlTypes(), FieldType.SN, FieldType.MultiSelect];
  },
  getDynamicSNCtrlTypes: function() {
    return [...specific.getDynamicControlTypes(), FieldType.SN, FieldType.SinglelineText];
  },
  getDynamicAddressCtrlTypes: function() {
    return [...specific.getAddressCtrlType(), FieldType.Lookup];
  },
  getDynamicPhoneCtrlTypes: function() {
    return [FieldType.PhoneNumber];
  },
  getDynamicEmailCtrlTypes: function() {
    return [FieldType.Email];
  },
  getDynamicIDNumberCtrlTypes: function() {
    return [FieldType.IDNumber];
  },
  getDynamicAttachmentCtrlTypes: function() {
    return [FieldType.Attachment];
  },
  getDynamicFormulaCtrlTypes: function() {
    return [...specific.getLineCtrlType(), FieldType.Number, FieldType.DateTime, ...specific.getDynamicControlTypes()];
  },
  getDynamicLookUpCtrlTypes: function() {
    return [FieldType.SinglelineText, FieldType.MultilineText, FieldType.Number, FieldType.DateTime, ...specific.getDynamicControlTypes()];
  },
  getDynamicRollUpCtrlTypes: function() {
    return [FieldType.Number, FieldType.Rollup, ...specific.getDynamicControlTypes()];
  },
  getDynamicRelationshipCtrlTypes: function() {
    return [FieldType.Relationship, FieldType.Lookup, FieldType.ObjectId];
  }
};
const filterScenes = {
  baseFilterScene,
  normalFilterScene,
  workflowFilterScene
};
const normalMatchAnyoneScene = {
  getDynamicSingleTextCtrlTypes() {
    return [...specific.getSelectCtrlTypes()];
  },
  getDynamicCreaterCtrlTypes() {
    return [...specific.getSingleUserControlTypes(), ...specific.getMultiUserControlTypes()];
  },
  getDynamicModifierCtrlTypes() {
    return [...specific.getSingleUserControlTypes(), ...specific.getMultiUserControlTypes()];
  },
  getDynamicOwnerCtrlTypes() {
    return [...specific.getSingleUserControlTypes(), ...specific.getMultiUserControlTypes()];
  },
  getDynamicSingleUserCtrlTypes() {
    return [...specific.getSingleUserControlTypes(), ...specific.getMultiUserControlTypes()].filter((item) => item !== FieldType.Modifier);
  },
  getDynamicSingleDeptCtrlTypes() {
    return [...specific.getAllDeptControlTypes()];
  }
};
const workflowMatchAnyoneScene = {
  getDynamicSingleSelectCtrlTypes() {
    return [FieldType.SinglelineText, ...specific.getSelectCtrlTypes(), ...specific.getDynamicControlTypes()];
  },
  getDynamicCreaterCtrlTypes() {
    return [...specific.getSingleUserControlTypes(), ...specific.getMultiUserControlTypes(), ...specific.getDynamicControlTypes()].filter((item) => item !== FieldType.Creater);
  },
  getDynamicModifierCtrlTypes() {
    return [...specific.getSingleUserControlTypes(), ...specific.getMultiUserControlTypes(), ...specific.getDynamicControlTypes()].filter((item) => item !== FieldType.Modifier);
  },
  getDynamicOwnerCtrlTypes() {
    return [...specific.getSingleUserControlTypes(), ...specific.getMultiUserControlTypes(), ...specific.getDynamicControlTypes()];
  },
  getDynamicSingleUserCtrlTypes() {
    return [...specific.getSingleUserControlTypes(), ...specific.getMultiUserControlTypes(), ...specific.getDynamicControlTypes()];
  },
  getDynamicSingleDeptCtrlTypes() {
    return [...specific.getAllDeptControlTypes(), ...specific.getDynamicControlTypes()];
  }
};
const filterMatchAnyoneScenes = {
  normalMatchAnyoneScene,
  workflowMatchAnyoneScene
};
function querySceneCtrlTypes(sceneType) {
  const scene = scenes[sceneType];
  if (scene) {
    return scene.getSupportCtrlTypes ? scene.getSupportCtrlTypes() : scene.getNotSupportCtrlTypes();
  } else {
    return [];
  }
}
function queryDynamicCtrlTypes(controlType, filterType = FilterType.Base) {
  if (filterScenes[filterType][controlType]) {
    return filterScenes[filterType][controlType]();
  } else {
    return [];
  }
}
function queryMatchAnyoneCtrlTypes(controlType, scene) {
  if (filterMatchAnyoneScenes[scene][controlType]) {
    return filterMatchAnyoneScenes[scene][controlType]();
  } else {
    return [];
  }
}
function getScene(sceneType) {
  return scenes[sceneType];
}
export {
  DynamicValueMethod,
  FilterMatchAnyoneScene,
  FilterType,
  SceneType,
  getScene,
  queryDynamicCtrlTypes,
  queryMatchAnyoneCtrlTypes,
  querySceneCtrlTypes,
  specific as specificControlTypes
};
