import { H3Dropdown } from "@lateinos/awesome-ui";
import { checkSpecified } from "@lcos-kernel/chicken-library";
import ContentLoading from "@lcos-kernel/content-loading";
import { IconBevelCircleBottomStroke, IconTeamNodesStroke } from "@h3-icons/basic";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("h3-dropdown", {
    ref: "dropdown",
    class: _vm.mainCls,
    attrs: {
      "overlayCls": `${_vm.mainCls}__popper`,
      "controlCls": `${_vm.mainCls}__img-popper`,
      "offset": _vm.offset,
      "visible": _vm.innerVisible,
      "placement": _vm.placement,
      "reference": _vm.reference,
      "arrow": _vm.arrow,
      "zIndex": _vm.zIndex
    },
    on: {
      "toggle": _vm.fireToggle,
      "show": _vm.fireShow,
      "hide": _vm.fireHide
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function() {
        return [_c("div", {
          class: `${_vm.mainCls}-container`
        }, [_c("div", {
          staticClass: "head"
        }, [_c("span", {
          staticClass: "team-icon-wrap"
        }, [_c("icon-team-nodes-stroke", {
          staticClass: "icon-team"
        })], 1), _c("span", {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.deptInfo.name))])]), _c("div", {
          staticClass: "cont"
        }, [_c("div", {
          staticClass: "label-item"
        }, [_c("span", {
          staticClass: "label"
        }, [_vm._v("\u90E8\u95E8")]), _c("span", {
          staticClass: "label-cont sub-label",
          domProps: {
            "innerHTML": _vm._s(_vm.deptTreeName)
          }
        })]), _c("div", {
          staticClass: "label-item"
        }, [_c("span", {
          staticClass: "label"
        }, [_vm._v("\u90E8\u95E8\u4E3B\u7BA1")]), _c("span", {
          staticClass: "label-cont"
        }, [_vm._v(_vm._s(_vm.deptInfo.managerName || "\u672A\u8BBE\u7F6E"))])])])])];
      },
      proxy: true
    }])
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
const deptInfo_vue_vue_type_style_index_0_lang = "";
const deptInfo_vue_vue_type_style_index_1_scoped_true_lang = "";
function normalizeComponent(scriptExports, render2, staticRenderFns2, functionalTemplate, injectStyles, scopeId, moduleIdentifier, shadowMode) {
  var options = typeof scriptExports === "function" ? scriptExports.options : scriptExports;
  if (render2) {
    options.render = render2;
    options.staticRenderFns = staticRenderFns2;
    options._compiled = true;
  }
  if (functionalTemplate) {
    options.functional = true;
  }
  if (scopeId) {
    options._scopeId = "data-v-" + scopeId;
  }
  var hook;
  if (moduleIdentifier) {
    hook = function(context) {
      context = context || this.$vnode && this.$vnode.ssrContext || this.parent && this.parent.$vnode && this.parent.$vnode.ssrContext;
      if (!context && typeof __VUE_SSR_CONTEXT__ !== "undefined") {
        context = __VUE_SSR_CONTEXT__;
      }
      if (injectStyles) {
        injectStyles.call(this, context);
      }
      if (context && context._registeredComponents) {
        context._registeredComponents.add(moduleIdentifier);
      }
    };
    options._ssrRegister = hook;
  } else if (injectStyles) {
    hook = shadowMode ? function() {
      injectStyles.call(
        this,
        (options.functional ? this.parent : this).$root.$options.shadowRoot
      );
    } : injectStyles;
  }
  if (hook) {
    if (options.functional) {
      options._injectStyles = hook;
      var originalRender = options.render;
      options.render = function renderWithStyleInjection(h, context) {
        hook.call(context);
        return originalRender(h, context);
      };
    } else {
      var existing = options.beforeCreate;
      options.beforeCreate = existing ? [].concat(existing, hook) : [hook];
    }
  }
  return {
    exports: scriptExports,
    options
  };
}
const FakeElement = typeof Element !== "undefined" ? Element : Object;
const __vue2_script = {
  name: "UserInfo",
  components: {
    H3Dropdown,
    ContentLoading,
    IconBevelCircleBottomStroke,
    IconTeamNodesStroke
  },
  model: {
    prop: "visible",
    event: "toggle"
  },
  props: {
    reference: {
      type: FakeElement
    },
    deptId: {
      type: String
    },
    offset: {
      type: Array,
      default: () => [0, 6]
    },
    zIndex: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: false
    },
    arrow: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      validator: (value) => /^(auto|top|bottom|left|right)(-start|-end)?$/g.test(value),
      default: "bottom-start"
    },
    dataRequests: {
      type: Object,
      required: true,
      validator: (value) => {
        return checkSpecified(value, ["loadDeptInfo"]);
      }
    }
  },
  data() {
    return {
      innerVisible: false,
      mainCls: "dept-info-view",
      deptInfo: {}
    };
  },
  computed: {
    deptTreeName() {
      const treeVal = this.deptInfo.deptNameTree || [];
      return treeVal.length ? treeVal.join('<span class="primary-text"> / </span>') : "";
    }
  },
  watch: {
    visible(nVal) {
      this.innerVisible = nVal;
    }
  },
  methods: {
    async loadData(deptId) {
      const {
        loadDeptInfo
      } = this.dataRequests;
      if (!(loadDeptInfo && deptId)) {
        return;
      }
      const res = await loadDeptInfo(deptId);
      this.deptInfo = res || {};
    },
    popperToggle(reference, listeners) {
      this.$refs.dropdown.popperToggle(reference, listeners);
    },
    fireToggle(visible) {
      this.innerVisible = visible;
      if (!visible) {
        this.imgVisible = visible;
      }
      this.$emit("toggle", this.innerVisible);
    },
    fireShow() {
      this.innerVisible = true;
      if (this.deptId) {
        this.loadData(this.deptId);
      }
      this.$emit("show");
    },
    fireHide() {
      this.$emit("hide");
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "6f2e6d4d", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
const DeptInfo = /* @__PURE__ */ function() {
  return __component__.exports;
}();
let $vm;
let pluginInstance;
let configOption = {};
function createPlugin(Vue, component) {
  if (typeof document === "undefined") {
    throw new Error("current non-browser environment");
  }
  if (!pluginInstance) {
    const componentProps = component.mixins ? Object.assign({}, component.mixins.reduce((props, mixin) => Object.assign(props, mixin.props || {}), {}), component.props) : Object.assign({}, component.props);
    const componentOption = {};
    for (const prop in componentProps) {
      if (!componentProps.hasOwnProperty(prop)) {
        continue;
      }
      const propItem = componentProps[prop];
      let value;
      if (propItem instanceof Object && propItem !== Object) {
        value = propItem.default;
        if (typeof value === "undefined") {
          value = null;
        }
        const type = propItem.type;
        if (value instanceof Function && type !== Function && (!(type instanceof Array) || type instanceof Array && (!type.includes(Function) || !(type[0] instanceof Function)))) {
          value = value();
        }
      }
      componentOption[prop] = value;
    }
    $vm = new Vue({
      el: document.createElement("div"),
      data() {
        return {
          componentOption: {
            ...componentOption,
            dataRequests: {
              loadDeptInfo: () => ({})
            }
          }
        };
      },
      methods: {
        show(options = {}, listeners = {}) {
          const {
            reference,
            ..._options
          } = options;
          Object.assign(this.componentOption, componentOption, configOption, _options);
          this.$refs.deptInfo.popperToggle(reference, listeners);
        },
        hide(listeners = {}) {
          this.$refs.deptInfo.popperToggle(null, listeners);
        }
      },
      render(h) {
        return h(component, {
          ref: "deptInfo",
          props: this.componentOption
        });
      }
    });
    document.body.appendChild($vm.$el);
    pluginInstance = {
      show(options = {}, listeners) {
        $vm.show(options, listeners);
      },
      hide(listeners) {
        $vm.hide(listeners);
      },
      visible() {
        return $vm.visible;
      },
      config(options = {}) {
        const {
          dataRequests,
          offset,
          placement,
          zIndex,
          arrow
        } = options;
        configOption = {
          ...configOption,
          ...{
            dataRequests,
            offset,
            placement,
            zIndex,
            arrow
          }
        };
      }
    };
  }
  component.$deptInfo = pluginInstance;
  Vue.prototype.$deptInfo = pluginInstance;
  return pluginInstance;
}
const install = (Vue) => {
  Vue.component(DeptInfo.name, DeptInfo);
  const deptInfoInstance = createPlugin(Vue, DeptInfo);
  DeptInfo.$sharedDeptInfo = deptInfoInstance;
  Vue.prototype.$sharedDeptInfo = deptInfoInstance;
};
if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}
DeptInfo.install = install;
export {
  DeptInfo as default
};
(function(){ try {var elementStyle = document.createElement('style'); elementStyle.appendChild(document.createTextNode(".dept-info-view__popper {\n  padding: 0;\n}\n.dept-info-view-container .primary-text {\n  color: #315efb;\n  font-weight: bold;\n}\n.dept-info-view-container[data-v-6f2e6d4d] {\n  width: 360px;\n}\n.dept-info-view-container .head[data-v-6f2e6d4d] {\n  padding: 24px 32px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.dept-info-view-container .head .team-icon-wrap[data-v-6f2e6d4d] {\n  width: 64px;\n  height: 64px;\n  background: #8490a4;\n  border-radius: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.dept-info-view-container .head .icon-team[data-v-6f2e6d4d] {\n  font-size: 43px;\n  color: #fff;\n}\n.dept-info-view-container .head .title[data-v-6f2e6d4d] {\n  font-size: 18px;\n  font-weight: 600;\n  color: #121933;\n  line-height: 24px;\n  margin-left: 24px;\n}\n.dept-info-view-container .cont[data-v-6f2e6d4d] {\n  background: #fbfcfe;\n  padding: 24px 32px 51px;\n}\n.dept-info-view-container .label-item[data-v-6f2e6d4d] {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n}\n.dept-info-view-container .label-item[data-v-6f2e6d4d]:not(:last-child) {\n  margin-bottom: 16px;\n}\n.dept-info-view-container .label-item .label[data-v-6f2e6d4d] {\n  font-size: 14px;\n  color: #707481;\n  display: inline-block;\n  width: 56px;\n  flex-shrink: 0;\n  line-height: 22px;\n}\n.dept-info-view-container .label-item .label-cont[data-v-6f2e6d4d] {\n  font-size: 14px;\n  color: #121933;\n  display: inline-block;\n  margin-left: 24px;\n  flex: 1;\n  overflow: hidden;\n}\n.dept-info-view-container .label-item .sub-label[data-v-6f2e6d4d] {\n  font-size: 12px;\n  color: #707481;\n  line-height: 22px;\n}"));  elementStyle.id = "@lcos-kernel/dept-info"; document.head.appendChild(elementStyle);} catch(e) {console.error('vite-plugin-css-injected-by-js', e);} })();