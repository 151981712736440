import { generateError } from '../error';
import { Formula } from '../type';

const MANAGEROF: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'MANAGEROF',
  type: Formula.IdentifierType.FUNC,
  outputType: {
    paramType: Formula.ParamType.SINGLEUSER,
    sourceType: Formula.SourceType.ANY,
  },
  validate (identifier, params) {
    if (params.length < 1) {
      throw generateError(`函数 ${identifier.name}() 至少需要1个参数，但传入了${params.length}个参数`);
    }
    if (params.length > 2) {
      throw generateError(`函数 ${identifier.name}() 最多需要2个参数，但传入了${params.length}个参数`);
    }
    const [param, findSuperior] = params;
    if (param.paramType !== Formula.ParamType.SINGLEUSER && param.paramType !== Formula.ParamType.SIGNLEDEPT) {
      throw generateError(`函数 ${identifier.name}() 要求第一个参数是人员单选或部门单选`);
    }
    if (findSuperior && findSuperior.paramType !== Formula.ParamType.BOOL) {
      throw generateError(`函数 ${identifier.name}() 要求第二个参数是布尔类型`);
    }
    return identifier.outputType;
  },
  calculate: (identifier, params, compiler) => {
    const value = null;
    const [param, findSuperior] = params;
    const result = { ...identifier.outputType, value };
    let manageFunction: Function;
    if (param.paramType === Formula.ParamType.SINGLEUSER) {
      manageFunction = compiler._externalMethods.managerOfUser;
    } else if (param.paramType === Formula.ParamType.SIGNLEDEPT) {
      manageFunction = compiler._externalMethods.managerOfDept;
    } else {
      manageFunction = null;
    }
    if (param.value?.id && manageFunction) {
      return manageFunction(param.value.id, findSuperior?.value).then((user: any) => {
        if (!user) {
          return result;
        }
        result.value = user;
        return result;
      });
    }
    return result;
  },
};

export default MANAGEROF;
