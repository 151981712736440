// 记录着色的颜色

interface RuleColor {
  name: string;
  background: string;
  border: string;
  icon: string;
  borderHover?: string;
}

// 行/列/日程着色
const ruleColors: RuleColor[] = [
  {
    name: 'color0',
    background: '#EFFAFF',
    border: '#D3EBF6',
    icon: '#59A6C9',
    borderHover: '#ACE2FB',
  },
  {
    name: 'color1',
    background: '#FFFCEA',
    border: '#F5ECB4',
    icon: '#DCCB5F',
    borderHover: '#F5E05F',
  },
  {
    name: 'color2',
    background: '#FFF2F4',
    border: '#F6DADF',
    icon: '#F8A1B1',
    borderHover: '#F8B0BD',
  },
  {
    name: 'color3',
    background: '#EEFDE9',
    border: '#D3F1C8',
    icon: '#92D47A',
    borderHover: '#A6E98D',
  },
  {
    name: 'color4',
    background: '#FFF4EF',
    border: '#FFE3D5',
    icon: '#FAB491',
    borderHover: '#F3BFA5',
  },
  {
    name: 'color5',
    background: '#F5EFFF',
    border: '#E6DCF7',
    icon: '#BE98FC',
    borderHover: '#C5ABF1',
  },
  {
    name: 'color6',
    background: '#EEF2FF',
    border: '#D5E1FA',
    icon: '#85A2FC',
    borderHover: '#96B6F8',
  },
  {
    name: 'color7',
    background: '#ECFDFB',
    border: '#C9F3EF',
    icon: '#82C6BF',
    borderHover: '#86D0C9',
  },
  {
    name: 'color8',
    background: '#B7E8FF',
    border: '#A8D7EE',
    icon: '#59A6C9',
    borderHover: '#78CBF3',
  },
  {
    name: 'color9',
    background: '#FFF29C',
    border: '#F3E68F',
    icon: '#DCCB5F',
    borderHover: '#EFD525',
  },
  {
    name: 'color10',
    background: '#FFC3CE',
    border: '#F9A3B2',
    icon: '#FE8EA2',
    borderHover: '#F8778D',
  },
  {
    name: 'color11',
    background: '#C4F4B4',
    border: '#ADDF9C',
    icon: '#92D47A',
    borderHover: '#7EDD5E',
  },
  {
    name: 'color12',
    background: '#FFC580',
    border: '#FDB08A',
    icon: '#F7925F',
    borderHover: '#F58E5B',
  },
  {
    name: 'color13',
    background: '#D0B2FF',
    border: '#BFA2F0',
    icon: '#AE7FF8',
    borderHover: '#A173F0',
  },
  {
    name: 'color14',
    background: '#A3A9F8',
    border: '#8D95F9',
    icon: '#7F88F9',
    borderHover: '#5F6AF7',
  },
  {
    name: 'color15',
    background: '#A2EAE2',
    border: '#A2D5D0',
    icon: '#82C6BF',
    borderHover: '#5FCCC1',
  },
];

const ruleColorMap: Record<string, RuleColor> = (function () {
  const obj: any = {};
  ruleColors.forEach((item) => {
    obj[item.name] = item;
  });
  return obj;
})();

enum RuleColorTypeName {
  ROW = '行着色',
  COLUMN = '列着色',
  SCHEDULE = '日程着色',
}

export {
  ruleColors, ruleColorMap, RuleColorTypeName,
};
