import { BaseError } from '@lateinos/request';
import { notification } from '@lateinos/antd-vue';
import toLogin from '@/utils/to-login';
export default function errorHandler (err) {
  if (err instanceof BaseError && parseInt((err.errorCode).toString()) === 401) {
    toLogin(window.location.href);
    return err;
  }
  // 忽略错误
  if (err instanceof BaseError && err.errorCode === 'corp.integrate.user.org.empty') {
    return err;
  }
  if (err.errorCode === 'rule.submit.validate.failed') {
    // 对校验规则的errorCode处理错误标题
    err.errorTitle = '校验不通过';
  }
  notificationQueue({
    title: err.errorTitle || '错误',
    message: err.errorMessage || '请联系管理员处理',
    traceId: err.response?.headers.get('traceparent'),
  });
}

// 轮询时错误处理，不提示报错，但是跳转401
export function pollingErrorHandler (err) {
  if (err instanceof BaseError && parseInt((err.errorCode).toString()) === 401) {
    toLogin(window.location.href);
    return err;
  }
}
type ErrorInfo = {
  title: string;
  message: string | Function;
  traceId?: string;
};

let errorInfoCache: ErrorInfo[] = [];

let queueTimer: any;

function handleNotification (info: ErrorInfo) {
  notification.error({
    message: info.title,
    description: info.message,
  });
}

function notificationQueue (info: ErrorInfo, immediate: boolean = false) {
  if (immediate) {
    handleNotification(info);
  } else {
    errorInfoCache.push(info);
  }
  if (queueTimer) {
    clearTimeout(queueTimer);
    queueTimer = null;
  }
  queueTimer = <any>setTimeout(() => {
    if (errorInfoCache.length === 0) {
      return;
    }
    const traceStyle = {
      style: `font-size: 12px;
              transform: translateX(-12%) scale(0.75);
              width: 320px;
              margin: 0`
    };
    if (errorInfoCache.length === 1) {
      const _info = errorInfoCache.shift();
      const message = _info.message;
      const traceId = _info.traceId?.substr(3, 32);
      _info.message = (h: Function) => {
        return h('div', {}, [
          h('p', {}, message),
          h('p', traceStyle, '请求序号：' + traceId),
        ]);
      };
      handleNotification(_info!);
      return;
    }
    const infos = [...errorInfoCache];
    errorInfoCache = [];
    handleNotification({
      title: '错误',
      message: (h: Function) => {
        return h('ul', {
          style: 'list-style: none;margin: 0;padding: 0;max-height: 40vh;overflow: auto;',
        }, infos.map(({ title, message, traceId }) => {
          const traceIdStr = traceId?.substr(3, 32);
          return h('li', { style: 'padding: 0;margin: 0 0 8px 0;' }, [
            h('p', { style: 'margin: 0 0 4px 0;padding: 0;font-size: 14px;font-weight: 600;' }, title),
            h('p', { style: ' margin: 0;padding: 0;font-size: 14px;' }, message),
            h('p', traceStyle, '请求序号：' + traceIdStr),
          ]);
        }));
      },
    });
    queueTimer = null;
  }, 300);
};
