import { request } from '@lateinos/request';
import { request as accessRrequest } from '@lcos-kernel/data-access';
import getFinger from '@/startup/finger';
import { getRealProcessEnv } from '@lcos-kernel/chicken-library';

export function addHeader(key:string, value:string) {
  const option = {};
  option[key] = value;
  console.log(request);
  request.extendOptions({
    headers: option,
  });
  accessRrequest.extendOptions({
    headers: option,
  });
}

export default () => {
  const finger = getFinger();
  request.extendOptions({
    headers: {
      'X-App-Name': 'ug-external-webapp',
      'X-App-Version': window.__UG_POLYMER_VERSION__,
      'X-GUID': finger,
    },
    prefix: getRealProcessEnv(process.env.apiPrefix),
  });
  accessRrequest.extendOptions({
    headers: {
      'X-App-Name': 'ug-external-webapp',
      'X-App-Version': window.__UG_POLYMER_VERSION__,
      'X-GUID': finger,
    },
    prefix: getRealProcessEnv(process.env.apiPrefix),
  });
};
