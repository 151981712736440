// 将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// formatDate(new Date(), "yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// formatDate(new Date(), "yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
function formatDate (date, fmt) {
  // author: meizz
  let formatStr = '';
  let reg;
  let k;
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    formatStr = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length),
    );
  }
  for (k in o) {
    if (!o.hasOwnProperty(k)) {
      continue;
    }
    reg = new RegExp('(' + k + ')');
    if (reg.test(formatStr)) {
      formatStr = formatStr.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? o[k]
          : ('00' + o[k]).substr(('' + o[k]).length),
      );
    }
  }
  return formatStr;
}

function createDateFromString (dateString) {
  // new Date() 构造函数，参数如果没有时分秒，得到的是0时区的标准时间
  // new Date('2020-01-01') => 2020-01-01T00:00:00.000Z
  // 如果带了时分秒，哪怕都是0，得到的是当地时区的时间。
  // 如果转换为0时区，会相差N个小时
  // new Date('2020-01-01 0:0:0') => 2019-12-31T16:00:00.000Z
  // 差了8个小时（因为我们在东八区）;
  // 这个可能是 node v11.10.0 的 bug;
  // 为了保证使用的是当地（系统）时区
  // 需要把时区偏移量加入到时间字符串
  const timeOffset = new Date().getTimezoneOffset() / 60;
  const timeZone = ' GMT' + (timeOffset < 0 ? '+' : '-') + Math.abs(timeOffset);
  const date = new Date(Date.parse(dateString + timeZone));
  if (date.toString() === 'Invalid Date') {
    throw new Error('日期参数不合法');
  }
  return date;
}
export default {
  // SUM(a,b,...),求和
  SUM (...args) {
    let sum = 0;
    for (let i = 0; i < args.length; i += 1) {
      const num = Number(args[i]);
      if (Number.isNaN(num) || args[i] === '') {
        continue;
      }
      sum += num;
    }
    return sum;
  },
  // MAX(a,b,c,...),求最大值
  MAX (...args) {
    const sorted = args.sort(function max (prev, next) {
      if (prev < next) {
        return 1;
      } else if (prev > next) {
        return -1;
      }
      return 0;
    });
    return sorted[0];
  },
  // MIN(a,b,...),求最小值
  MIN (...args) {
    const sorted = args.sort(function max (prev, next) {
      if (prev > next) {
        return 1;
      } else if (prev < next) {
        return -1;
      }
      return 0;
    });
    return sorted[0];
  },
  // ABS(a),求绝对值
  ABS (...args) {
    const num = Number(args[0]);
    if (!Number.isNaN(num)) {
      return Math.abs(num);
    }
    return 0;
  },
  // AVG(a,b,...),求平均值
  AVG (...args) {
    let count = 0;
    let sum = 0;
    for (let i = 0, len = args.length; i < len; i += 1) {
      const num = Number(args[i]);
      if (Number.isNaN(num)) {
        continue;
      }
      sum += num;
      count++;
    }
    // 这里需要考虑精度，将来需要引进外部库（例如：bignumber.js）
    return count === 0 ? 0 : sum / count;
  },
  // SIN(a)//参数是角度
  SIN (...args) {
    const deg = args[0];
    try {
      return Math.sin((2 * Math.PI * deg) / 360);
    } catch (e) {
      console.error(e);
      return 0;
    }
  },
  // COS(a)//参数是角度
  COS (...args) {
    const deg = args[0];
    try {
      return Math.cos((2 * Math.PI * deg) / 360);
    } catch (e) {
      console.error(e);
      return 0;
    }
  },
  // PI()
  PI () {
    return Math.PI;
  },
  // ROUND(a,b) 四舍五入到制定位数
  ROUND (...args) {
    const target = Number(args[0]);
    const accuracy = Number(args[1]);
    if (Number.isNaN(target) || Number.isNaN(accuracy)) {
      return 0;
    }
    return (
      Math.round(target * Number(`1e${accuracy}`)) / Number(`1e${accuracy}`)
    );
  },
  // SQRT(a)
  SQRT (...args) {
    const numVal = Number(args[0]);
    if (Number.isNaN(numVal) || numVal < 0) {
      return 0;
    }
    return Math.sqrt(numVal);
  },
  // NOW(),当前时间
  NOW () {
    return formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss');
  },
  // YEAR(date),返回日期年份()
  YEAR (...args) {
    if (args.length < 1) {
      return 1900;
    }
    const date = createDateFromString(args[0]);
    return date.getFullYear();
  },
  // YEARS(endDate,startDate)返回两个日期之间年份差
  YEARS (...args) {
    const dateEnd = createDateFromString(args[0]);
    const dateStart = createDateFromString(args[1]);
    const diff = +dateEnd - +dateStart;
    return Math.round((100 * diff) / (1000 * 3600 * 24 * 365)) / 100;
  },
  // QUARTER(date),返回日期季度
  QUARTER (...args) {
    if (args.length < 1) {
      return 1;
    }
    const date = createDateFromString(args[0]);
    const month = date.getMonth() + 1;
    return Math.ceil(month / 3);
  },
  // MONTH(date),返回月份
  MONTH (...args) {
    const date = createDateFromString(args[0]);
    return date.getMonth() + 1;
  },
  // DAY(date),返回当月第几天
  DAY (...args) {
    const date = createDateFromString(args[0]);
    return date.getDate();
  },
  // HOUR(time),返回时间中小时部分
  HOUR (...args) {
    const date = createDateFromString(args[0]);
    return date.getHours();
  },
  // MINUTE(time),返回时间中分钟部分
  MINUTE (...args) {
    const date = createDateFromString(args[0]);
    return date.getMinutes();
  },
  // SECOND(time),返回时间中秒部分
  SECOND (...args) {
    const date = createDateFromString(args[0]);
    return date.getSeconds();
  },
  // TODAY(),返回当前日期
  TODAY () {
    return formatDate(new Date(), 'yyyy-MM-dd');
  },
  // WEEKDAY(date),返回指定日期为星期几
  WEEKDAY (...args) {
    const date = createDateFromString(args[0]);
    return date.getDay();
  },
  // WEEKNUM(date),返回指定日期是一年中第几周
  WEEKNUM (...args) {
    const date = createDateFromString(args[0]);
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const days =
      (date.getTime() - firstDayOfYear.getTime()) / (1000 * 60 * 60 * 24);
    const firstWeekDay = firstDayOfYear.getDay(); // 第一天星期几
    const weeknum = Math.ceil(Number(days - (6 - firstWeekDay)) / 7);
    return weeknum + 1;
  },
  // DATE(year,month,day,[hour,[minute,[second]]]),将年月日时分秒转成日期对象
  DATE (...args) {
    if (args.length < 3) {
      throw new Error('至少需要3个参数');
    }
    for (let i = 0; i < args.length; i += 1) {
      if (Number.isNaN(args[i])) {
        throw new Error('参数不合法');
      }
    }
    // let m = ['FullYear', 'Month', 'Date', 'Hours', 'Minutes', 'Seconds'];
    let dateString = '';
    for (let i = 0; i < 6; i++) {
      dateString += args[i] || '0';
      if (i <= 1) {
        dateString += '-';
      } else if (i === 2) {
        dateString += ' ';
      } else if (i <= 4) {
        dateString += ':';
      }
    }
    return createDateFromString(dateString);
  },

  // ADDYEAR(date, years),将指定日期加减指定年数
  ADDYEAR (...args) {
    const [dateString, years] = args;
    const date = createDateFromString(dateString);
    let fmt = 'yyyy-MM-dd';
    if (dateString.length > 10) {
      fmt = 'yyyy-MM-dd hh:mm';
    }
    date.setFullYear(date.getFullYear() + parseInt(years));
    return formatDate(date, fmt);
  },
  // ADDMONTH(date, months),将指定日期加减指定月数
  ADDMONTH (...args) {
    const [dateString, months] = args;
    const date = createDateFromString(dateString);
    let fmt = 'yyyy-MM-dd';
    if (dateString.length > 10) {
      fmt = 'yyyy-MM-dd hh:mm';
    }
    date.setMonth(date.getMonth() + parseInt(months));
    return formatDate(date, fmt);
  },
  // ADDDAY(date, days),将指定日期加减指定天数
  ADDDAY (...args) {
    const [dateString, days] = args;
    const date = createDateFromString(dateString);
    let fmt = 'yyyy-MM-dd';
    if (dateString.length > 10) {
      fmt = 'yyyy-MM-dd hh:mm';
    }
    date.setDate(date.getDate() + parseInt(days));
    return formatDate(date, fmt);
  },
  // DAYS(end,start) 两个日期之间天数
  DAYS (...args) {
    const dateEnd = createDateFromString(args[0]);
    const dateStart = createDateFromString(args[1]);
    const diff = dateEnd.getTime() - dateStart.getTime();
    return Math.round((100 * diff) / (1000 * 3600 * 24)) / 100;
  },
  // HOURS(end,start) 两个日期之间小时数
  HOURS (...args) {
    const dateEnd = createDateFromString(args[0]);
    const dateStart = createDateFromString(args[1]);
    const diff = dateEnd.getTime() - dateStart.getTime();
    return Math.round((100 * diff) / (1000 * 3600)) / 100;
  },
  // MINUTES(end,start) 两个日期之间分钟数
  MINUTES (...args) {
    const dateEnd = createDateFromString(args[0]);
    const dateStart = createDateFromString(args[1]);
    const diff = dateEnd.getTime() - dateStart.getTime();
    return Math.round((100 * diff) / (1000 * 60)) / 100;
  },
  // StartsWith(string, substr) 判断字符串是否以特定字符串开始
  STARTSWITH (...args) {
    const string = args[0];
    const substr = args[1];
    if (string === undefined || substr === undefined) {
      return false;
    }
    return (string + '').startsWith(substr + '');
  },
  // IsNullOrEmpty(a) 判断字符串是否为空
  ISNULL (...args) {
    const arg = args[0];
    if (arg === 0) {
      return false;
    } else if (!arg) {
      return true;
    } else if (arg instanceof Array && arg.length === 0) {
      return true;
    } else if (typeof arg === 'object' && Object.keys(arg).length === 0) {
      return true;
    }
    return false;
  },

  IF (...args) {
    return args[0] ? args[1] : args[2];
  },

  NEWGUID () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 || 0;
      const v = c === 'x' ? r : (r && 0x3) || 0x8;
      return v.toString(16);
    });
  },
  // COUNT(v) 统计
  COUNT () {
    return arguments.length;
  },
  // MOD(a,b)两数相除余数
  MOD (...args) {
    const mod = 0;
    if (args.length === 0) {
      return 0;
    } else if (args.length === 1) {
      return Number(args[0]);
    }
    const num = Number(args[0]);
    const div = Number(args[1]);
    if (div === 0) {
      return mod;
    }
    return num % div;
  },
  // INT(v),向下取整
  INT (...args) {
    if (args.length === 0) {
      return 0;
    }
    return Math.floor(args[0]);
  },

  // LEFT(text,number),从左边第一个字符串开始截取制定长度的字符串
  LEFT (...args) {
    let left = '';
    if (args.length > 0) {
      const str = args[0];
      const len = args[1] ? args[1] : 0;
      left = `${str}`.slice(0, len);
    }
    return left;
  },
  // LEN(text),计算字符串长度
  LENGTH (...args) {
    let len = 0;
    if (!args[0]) {
      return 0;
    }
    if (args.length > 0) {
      len = `${args[0]}`.length;
    }
    return len;
  },
  // LOWER(text),将字符串转成小写
  TOLOWER (...args) {
    return (args[0] + '').toLowerCase();
  },
  // ToUpper(text),将字符串转成大写
  TOUPPER (...args) {
    return (args[0] + '').toUpperCase();
  },
  // replace(oldtext,start,num,newtext),将字符串中部分字符串用另外字符串替换
  REPLACE (...args) {
    if (args.length !== 4) {
      throw new Error('参数个数错误');
    }
    const [oldtext, start, num, newtext] = args;

    return oldtext.slice(0, start) + newtext + oldtext.slice(start + num);
  },
  // right(text,num),从字符串右边截取 num 个字符
  RIGHT (...args) {
    const [text, num] = args;
    return text.slice(-num);
  },
  // search(text_find,text_within,start_num),在第二个字符串中从指定位置开始查找第一个字符串
  SEARCH (...args) {
    let index = 0;
    if (args.length < 3) {
      return index;
    }
    const oldStr = args[1] || '';
    const target = args[0];
    const start = Number(args[2]);
    index = `${oldStr}`.indexOf(target, start - 1);
    return index + 1;
  },
  // substitute(text, old, newText, times),将字符串中旧字符串用新字符串替换
  SUBSTITUTE (...args) {
    if (args.length < 4) {
      throw new Error('缺少参数');
    }
    const [text, old, newText, times] = args;
    let idx = -1;
    let last = 0;
    let ret = '';
    const replaceText = new Array(times + 1).join(newText);
    if (('' + old).length === 0) {
      return text;
    }
    while (true) {
      // debugger;
      idx = text.indexOf(old, last);
      if (idx === -1) {
        break;
      }
      ret += text.slice(last, idx) + replaceText;
      last = idx + old.length;
    }
    return ret + text.slice(last);
  },
  // trim(t),去掉字符首位空格
  TRIM (...args) {
    let trim = '';
    if (args.length > 0) {
      trim = `${args[0]}`.trim();
    }
    return trim;
  },
  // Concatenate(a,b,c,...),拼接字符串
  CONCATENATE (...args) {
    return args.join('');
  },
  // VALUE(t),将文本转化为数字
  VALUE (...args) {
    let value = 0;
    if (args.length > 0) {
      value = Number(args[0]);
    }
    return value;
  },
  // CONTAIN()
  CONTAINS (...args) {
    if (args.length < 2) {
      return false;
    }
    // source 可能有多个值
    // 例如人员多选是否包含目标时
    const source = args.slice(0, -1);
    const target = args.slice(-1)[0];
    const ret = source.find(function (str) {
      return str.indexOf(target) > -1;
    });
    return ret !== undefined;
  },
  // split(text,separator),分割字符串
  SPLIT (...args) {
    if (args.length === 0) {
      return [];
    }
    if (args.length === 1) {
      const split = [];
      split.push(args[0]);
      return split;
    }
    const text = args[0];
    const separator = args[1];
    return text.split(separator);
  },
  // substring(text,start,num)
  SUBSTRING (...args) {
    if (args.length < 3 || args[0] === '') {
      return null;
    }
    const start = Number(args[1]);
    const length = Number(args[2]);
    const substring = `${args[0]}`.slice(start - 1, start + length - 1);
    return substring;
  },
  // 获取地址控件值
  GETADDRESS (...args) {
    let address = '';
    if (args.length === 0) {
      return '';
    }
    address = args[0];
    if (address === '') {
      return address;
    }
    let temp;
    if (typeof address === 'string') {
      try {
        temp = JSON.parse(address);
      } catch (e) {
        temp = {};
      }
    } else {
      temp = address;
    }
    address = temp.adname;
    if (temp.Detail && temp.Detail.trim() !== '') {
      if (address && address.trim() !== '') {
        address += ` ${temp.Detail}`;
      } else {
        address = temp.Detail;
      }
    }
    return address;
  },
  // 将数值转成大写人民币
  UPPERMONEY (...args) {
    // 整数和小数
    let [integer, decimal] = ('' + args[0]).split('.', 2);
    // 整数部分最长支持76位，超出部分将被截断
    if (integer.length > 76) {
      alert('整数部分超出76位，高位将被截断');
      integer = integer.slice(-76);
    }
    const chineseNum = [
      '零',
      '壹',
      '贰',
      '叁',
      '肆',
      '伍',
      '陆',
      '柒',
      '捌',
      '玖',
    ];
    const chineseUint = [
      '元',
      '万',
      '亿',
      '兆',
      '京',
      '垓',
      '秭',
      '穣',
      '沟',
      '涧',
      '正',
      '版载',
      '极',
      '恒河沙',
      '阿僧只',
      '那由他',
      '不可思议',
      '无量',
      '大数',
    ];
    const ret = [];
    // 当每一段（零到万、万到亿）数据，只有低位出现过非零数字，高位才能出现零
    // 例如：100，十位为零，但是个位不是非零，所以十位这个零也不需要添加。转换后为壹佰元
    // 例如：101，个位是非零，十位的零需要添加，则为壹佰零壹元
    // i 每迭代4次，刷新 hasNonZero 为 false
    let hasNonZero = false;
    integer = integer
      .split('')
      .reverse()
      .join('');
    for (let i = 0; i < integer.length; i++) {
      const num = integer[i];
      const mod = i % 4;
      if (mod === 0) {
        // 出现两个连续的 chineseUint，需要剔掉一个;
        // '元'不需要剔除
        if (chineseUint.indexOf(ret[0]) >= 1) {
          ret.shift();
        }
        ret.unshift(chineseUint[Math.floor(i / 4)]);
        hasNonZero = false;
      }
      // 第一次出现0，并且（当前段）已出现过非0数字，
      // 例如：1001，当个位是非0，十位是0，这种情形
      // 需要把“零”入队，否则不需要。
      // 零不能连续出现
      if (num === '0') {
        if (ret[0] !== '零' && hasNonZero) {
          ret.unshift('零');
        }
        continue;
      }
      // 零和非零需要分开处理;
      if (mod >= 1) {
        ret.unshift(['', '拾', '佰', '仟'][mod]);
      }
      hasNonZero = true;
      ret.unshift(chineseNum[+num]);
    }
    // 处理小数部分，目前暂时支持2位
    // 不存在小数: undefined, 长度为0, '00', '0'
    if (!decimal || decimal.length === 0 || +decimal - 0 === 0) {
      ret.push('整');
    } else {
      ret.push(chineseNum[+decimal[0]]);
      if (decimal[0] !== '0') {
        ret.push('角');
      }
      if (decimal[1] && decimal[1] !== '0') {
        ret.push(chineseNum[+decimal[1]] + '分');
      }
    }
    return ret.join('');
  },
};
