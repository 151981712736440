import { Formula } from '../type';
import { generateError } from '../error';
import toNumber from 'lodash/toNumber';
import { getParamTypeValue } from '../utils';

const FIXED: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'FIXED',
  type: Formula.IdentifierType.FUNC,
  outputType: {
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.EDIT,
  },
  validate (identifier, params) {
    if (params.length < 1) {
      throw generateError(`函数 ${identifier.name}() 最少需要1个参数，但传入了${params.length}个参数`);
    }
    if (params.length > 2) {
      throw generateError(`函数 ${identifier.name}() 最多需要2个参数，但传入了${params.length}个参数`);
    }

    const [_param1, _param2] = params;
    if (_param1.paramType !== Formula.ParamType.NUMBER) {
      throw generateError(`函数 ${identifier.name}() 的第1个参数应为数字，但传入了${getParamTypeValue(_param1.paramType)}`);
    }

    if (_param2) {
      if (_param2.paramType !== Formula.ParamType.NUMBER) {
        throw generateError(`函数 ${identifier.name}() 的第2个参数应为数字，但传入了${getParamTypeValue(_param2.paramType)}`);
      }
      if (_param2.sourceType !== Formula.SourceType.EDIT) {
        throw generateError(`函数 ${identifier.name}() 的第2个参数应为数字不能为字段`);
      }
      // 第二个参数必须大于等于0，且为整数
      const _param2Value = !isNaN(_param2.value) ? toNumber(_param2.value) * (_param2.unary || 1) : _param2.value;
      if ((_param2Value < 0 || _param2Value % 1 !== 0)) {
        throw generateError(`函数 ${identifier.name}() 的第2个参数应为大于等于0的整数${_param2Value ? '，但传入了' + _param2Value : ''}`);
      }
    }

    return identifier.outputType;
  },
  calculate: (identifier, params, compiler) => {
    const _params = compiler._pretreatment(identifier, params);
    const [_param1, _param2] = _params;
    const _param2Value = !isNaN(_param2.value) ? toNumber(_param2.value) * (_param2.unary || 1) : _param2.value;
    const value = _param1.value.toFixed(_param2Value || 0);
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default FIXED;
