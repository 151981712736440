import { generateError } from '../error';
import { Formula } from '../type';
import { getParamTypeValue } from '../utils';

const IDSTODEPTS: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'IDSTODEPTS',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.MULTIDEPT,
    sourceType: Formula.SourceType.ANY,
  },
  validate (identifier, params) {
    if (params.length !== 1) {
      throw generateError(`函数 ${identifier.name}() 需要1个参数，但传入了${params.length}个参数`);
    }
    const param = params[0];
    if (param.paramType !== Formula.ParamType.TEXT) {
      throw generateError(`函数 ${identifier.name}() 的第1个参数应为字符串，但传入了${getParamTypeValue(param.paramType)}`);
    }
    if (Object.prototype.hasOwnProperty.call(param, 'value')) {
      const str = param.value.replace(/\\/g, '');
      if (!/^\[(["'][^"']*["'],?\s*)+\]$/.test(str)) {
        throw generateError(`函数 ${identifier.name}() 的第1个参数的格式不正确，应该如下："[\\"10000\\", \\"10001\\", \\"10002\\"]"`);
      }
    }
    return identifier.outputType;
  },
  calculate: (identifier, params, compiler) => {
    const value = null;
    const result = { ...identifier.outputType, value };
    const param = params[0];
    if (param.value) {
      const str = param.value.replace(/\\/g, '');
      if (!/^\[(["'][^"']*["'],?\s*)+\]$/.test(str)) {
        throw generateError(`函数 ${identifier.name}() 的第1个参数的格式不正确，应该如下："[\\"10000\\", \\"10001\\", \\"10002\\"]"`);
      }
      return compiler._externalMethods.idsToDepts(str).then((depts: any) => {
        if (!depts) {
          return result;
        }
        result.value = depts;
        return result;
      });
    }
    return result;
  },
};

export default IDSTODEPTS;
