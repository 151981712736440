import { Api } from '@lcos-kernel/data-access';

interface ExternalInfo {
  appCode: string;
  controls: Object[];
  engineCode: string;
  schemaCode: string;
  viewCode: string;
  viewSetting: Record<string, any>;
}

export const getExternalInfo = (shortlinkCode: string) => {
  return Api.external.getExternalInfo(shortlinkCode);
};

export const getExternalBizData = (shortLinkCode: string, sign: string, charset: string) => {
  return Api.external.getExternalBizData(shortLinkCode, sign, charset);
};

// 不需要登录
export function postSharedFormData (shortLinkCode, data) {
  return Api.external.postSharedFormData(shortLinkCode, data);
};
