import { Formula } from '../type';
import { generateError } from '../error';
import { validateObjectType, getParamTypeValue, getNumberValue } from '../utils';
const SEARCH = {
    name: 'SEARCH',
    type: Formula.IdentifierType.FUNC,
    outputType: {
        paramType: Formula.ParamType.NUMBER,
        sourceType: Formula.SourceType.EDIT,
    },
    validate(identifier, params) {
        validateObjectType(identifier, params);
        if (params.length < 2) {
            throw generateError(`函数 ${identifier.name}() 至少需要2个参数，但传入了${params.length}个参数`);
        }
        if (params.length > 3) {
            throw generateError(`函数 ${identifier.name}() 最多需要3个参数，但传入了${params.length}个参数`);
        }
        const [findText, withinText, startNum] = params;
        if (findText.paramType !== Formula.ParamType.TEXT) {
            throw generateError(`函数 ${identifier.name}() 的第一个参数应为文本，但传入了${getParamTypeValue(findText.paramType)}`);
        }
        if (withinText.paramType !== Formula.ParamType.TEXT) {
            throw generateError(`函数 ${identifier.name}() 的第一个参数应为文本，但传入了${getParamTypeValue(withinText.paramType)}`);
        }
        if (startNum && !isNaN(startNum.value)) {
            const value = getNumberValue(startNum);
            if (value < 1 || value % 1 !== 0) {
                throw generateError(`函数 ${identifier.name}() 的第三个参数应该为正整数`);
            }
        }
        return identifier.outputType;
    },
    calculate: (identifier, params, compiler) => {
        const _params = compiler._pretreatment(identifier, params);
        const [findText, withinText, startNum] = _params;
        let start = 1;
        if (startNum) {
            start = getNumberValue(startNum);
        }
        // 忽略大小写
        const _withinText = withinText.value ? withinText.value.toLowerCase() : '';
        const _findText = findText.value ? findText.value.toLowerCase() : '';
        let value = 0;
        if (_withinText && _findText) {
            value = _withinText.indexOf(_findText, start - 1) + 1;
        }
        return {
            ...identifier.outputType,
            value,
        };
    },
};
export default SEARCH;
