// export enum RuleValueType {
//   Fixed = 'fixed', // 固定值
//   Dynamic = 'dynamic', // 动态值

//   DynamicRange = 'function', // 动态范围
// }

export enum RuleOperators {
  // 等于
  Equal = 'equal',

  // 不等于
  NotEqual = 'notequal',

  // 大于、晚于
  Above = 'above',

  // 小于、早于
  Below = 'below',

  // 大于等于
  NotBelow = 'notbelow',

  // 小于等于
  NotAbove = 'notabove',

  // 包含、同时包含
  Contains = 'contains',

  // 不包含
  NotContains = 'notcontains',

  // 等于任意一个
  Any = 'any',

  // 在范围内
  In = 'in',

  // 不在范围内
  NotIn = 'notin',

  // 为空
  Empty = 'empty',

  // 不为空
  NotEmpty = 'notempty',

  // 包含任意一个暂时未定义
  ContainsAny = 'containsany',

  Range = 'range',

  OutRange = 'outofrange',

  NumRange = 'numrange',

  NumOutOfRange = 'numoutofrange',

  IsDirty = 'isdirty',
}

/**
 * 赋值的所有类型枚举 (日期类的在此拓展)
 */
export enum RuleValueType {
  Fixed = 'fixed', // 固定值
  Dynamic = 'dynamic', // 动态值
  DynamicRange = 'dynamicrange', // 动态范围
  Function = 'function', // 动态范围

  Custom = 'custom', // 自定义
  Yesterday = 'yesterday', // 昨天
  Today = 'today', // 今天
  Tomorrow = 'tomorrow', // 明天
  CurrentWeek = 'thisweek', // 本周
  LastWeek = 'lastweek', // 上周
  NextWeek = 'nextweek', // 下周
  CurrentMonth = 'thismonth', // 本月
  LastMonth = 'lastmonth', // 上月
  NextMonth = 'nextmonth', // 下月
  CurrentQuarter = 'thisquarter', // 本季度
  LastQuarter = 'lastquarter', // 上季度
  NextQuarter = 'nextquarter', // 下季度
  // LastYear = 'lastyear', // 去年
  // CurrentYear = 'thisyear', // 今年
  // NextYear = 'nextyear', // 明年
}

export enum DateRuleValueType {
  Yesterday = 'yesterday', // 昨天
  Today = 'today', // 今天
  Tomorrow = 'tomorrow', // 明天
  CurrentWeek = 'thisweek', // 本周
  LastWeek = 'lastweek', // 上周
  NextWeek = 'nextweek', // 下周
  CurrentMonth = 'thismonth', // 本月
  LastMonth = 'lastmonth', // 上月
  NextMonth = 'nextmonth', // 下月
  CurrentQuarter = 'thisquarter', // 本季度
  LastQuarter = 'lastquarter', // 上季度
  NextQuarter = 'nextquarter', // 下季度
  LastYear = 'lastyear', // 去年
  CurrentYear = 'thisyear', // 今年
  NextYear = 'nextyear', // 明年
}

// 规则项类型
export interface RuleItemType {
  code: string;
  controlType?: string;
  method: RuleOperators;
  value: any[];
  valueType?: RuleValueType;
};

// 相对日期类型
export enum RelativeDateType {
  Current = 'current',
  Past = 'past',
  Future = 'future',
  UnLimited = 'unLimited'
}

// 日期单位
export enum DateUnitType {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export const ParseDateFormatString = 'YYYY-MM-DD HH:mm:ss';

// 动态范围值类型
export type DynamicRangeValueType = {
  time: RelativeDateType;
  num: number;
  unit: DateUnitType;
};
