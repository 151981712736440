import { Formula } from '../type';

const MATCH: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'MATCH',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.BOOL,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.BOOL,
    sourceType: Formula.SourceType.EDIT,
  },
  // validate (identifier, params, compiler) {
  //   const outputType = compiler._funcValidate(identifier, params);
  //   return outputType;
  // },
  calculate: (identifier, params) => {
    const [param] = params;
    return {
      ...identifier.outputType,
      value: param.value,
    };
  },
};

export default MATCH;
