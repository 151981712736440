


































































































import { Component, Vue } from 'vue-property-decorator';
import { Input } from '@lateinos/antd-vue';
import { IconEditStroke, IconQrcodeStroke } from '@h3-icons/basic';
import {
  H3Modal, H3Button, H3Dropdown, H3Loading
} from '@lateinos/awesome-ui';
import { SchemaContext, View } from '@lcos-kernel/data-access';
import { FormExternalLink, FieldPreferenceT } from '@lcos-kernel/form';
import { DefaultCoverThumbnail } from '../const';
import { getExternalInfo, postSharedFormData } from '@/api/external-api';
import QRCode from 'qrcode';
import { addHeader } from '@/startup/request';

@Component({
  name: 'FormView',
  components: {
    AInput: Input,
    ATextArea: Input.TextArea,
    IconEditStroke,
    H3Button,
    H3Modal,
    IconQrcodeStroke,
    H3Dropdown,
    H3Loading,
    FormExternalLink,
  },
})
export default class FormView extends Vue {
  appCode: string = null;

  schemaCode: string = null;

  viewCode: string = null;

  // ui style
  subappContentHeight: string = '0';

  // context
  schemaContext: SchemaContext = null;

  // 状态
  loading: boolean = true;

  submitting: boolean = false;

  // 视图配置信息
  previewImage = '';

  formTitle: string = '';

  formViewCover: string = DefaultCoverThumbnail;

  formDescription: string = '';

  shortUrl: string = '';

  currentView: View;

  preference = {};

  showQRCode: boolean = false;

  // 表单信息
  fieldPreference: FieldPreferenceT = {
    layout: null,
  };

  fieldPermissions : Record <string, Record<string, boolean>>= {};

  columns: any[];

  controlCodes: string[];

  controlValues: {};

  // currentSchemaControls: Control[] = [];

  fieldOperatePermission = {};

  index: number = 0;

  dataLogic = {};

  isSubmitOnce :string ='1';

  shortCode :string = null;

  fieldInfos :any ={};

  successMsg :string = null;

  postCount :number = 0;

  submitComplete :boolean =false;

  checkSubmit :boolean =true;

  supportImages = [
    `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/logo/logo@1x.png`,
    `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/logo/logo@2x.png 2x`
  ];

  bgImageMap = {
    bi: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/bi.png`,
    diannao: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/diannao.png`,
    shanshui: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/shanshui.png`,
    xingkong: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/xingkong.png`,
    yu: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/yu.png`,
  };

  // thumbnailImageMap = {
  //   bi: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/thumbnail/bi.png`,
  //   diannao: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/thumbnail/diannao.png`,
  //   shanshui: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/thumbnail/shanshui.png`,
  //   xingkong: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/thumbnail/xingkong.png`,
  //   yu: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/thumbnail/yu.png`,
  // };

  created () {
    this.shortCode = this.$route.params.shortCode;
    this.postCount = this.getHistroyPostCount();
  }

  mounted () {
    if (this._isMobile()) {
      this.$router.replace(`/m/form/share/${this.shortCode}`);
      return;
    }
    if (this.$route.params.submitAgain && this.$route.params.submitAgain === '1') {
      this.checkSubmit = false;
    }
    this.loadContext();
    this.init();
    this.resizeContentHeight();
    window.addEventListener('resize', this.resizeContentHeight);
  }

  _isMobile() {
    const flag = navigator.userAgent.toLowerCase().match(/mobile/i);
    return flag;
  }

  getHistroyPostCount () {
    const count = localStorage.getItem(`FORM_SHARE_${this.shortCode}`) || 0;
    return Number(count);
  }

  hasbeenPost (res) {
    const firstFillForm = this.postCount === 0;
    const fillFormAgain = this.submitComplete === false && !(this.isSubmitOnce === '1');
    return firstFillForm || fillFormAgain;
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.resizeContentHeight);
  }

  loadContext () {
  }

  async init () {
    this.loadViewData();
  }

  async loadViewData () {
    // debugger;
    if (this.$route.params.submitAgain) {
      this.shortCode = this.$route.params.submitAgain;
    }
    const res = await getExternalInfo(this.shortCode);
    // if (res.data.controls.length === 0) {
    //   this.$router.push({
    //     path: '/form/result',
    //     query: { negative: 'true' }
    //   });
    //   return;
    // }
    const externalInfo = res.data;
    // eslint-disable-next-line no-prototype-builtins
    if (externalInfo.viewSetting.hasOwnProperty('submitOnce')) {
      this.isSubmitOnce = externalInfo.viewSetting.submitOnce ? '1' : '0';
    }
    if (!this.hasbeenPost(res)) {
      this.$router.push({
        path: '/form/result',
        query: { res: 'true', isSubmitOnce: this.isSubmitOnce, url: this.shortCode, successMsg: this.successMsg }
      });
      return;
    }
    this.appCode = externalInfo.appCode;
    this.schemaCode = externalInfo.schemaCode;
    this.viewCode = externalInfo.viewCode;
    const externalInfoViewSetting = externalInfo.viewSetting;
    this.formTitle = externalInfoViewSetting.formTitle;
    this.formDescription = externalInfoViewSetting.formDes;
    this.formViewCover = externalInfoViewSetting.formViewCover || DefaultCoverThumbnail;
    this.shortUrl = externalInfoViewSetting.shortUrl;
    this.successMsg = externalInfoViewSetting.successMsg || null;
    // this.currentSchemaControls = externalInfo.controls;
    this.fieldPreference.layout = externalInfo.viewSetting?.layout || [];
    const displayFields = externalInfo.viewSetting.displayFields;
    this.fieldOperatePermission = {
      add: false,
      edit: false,
      sort: false,
      delete: false,
    };

    window.$ugContext.loadExternalContext(externalInfo.engineCode);
    addHeader('X-H3-EngineCode', externalInfo.engineCode);
    addHeader('X-H3-AppCode', externalInfo.appCode);
    // addHeader('X-H3-MemberId', '31441122');

    this.fieldPermissions = {};

    const blockList: string[] =
    ['Creater', 'Modifier', 'Relationship', 'Lookup', 'Rollup', 'SubTable', 'OwnerDept', 'QRCode'];
    //   'SingleUser',
    // 'MultiUser', 'MultiDept', 'SingleDept', 'SingleUOwnerser', 'Owner',
    // if (displayFields) {
    //   externalInfo.controls = externalInfo.controls.filter(el => displayFields.includes(el.code));
    // }
    // 需要过滤掉关联数据和关联引用引用关联数据类型
    externalInfo.controls = externalInfo.controls.filter(ctrl => ctrl.type !== 'Relationship' && ctrl.properties?.targetType !== 'Relationship');
    externalInfo.controls.forEach(ctrl => {
      this.fieldPermissions[ctrl.code] = {
        Visible: !blockList.includes(ctrl.type) && (displayFields || []).includes(ctrl.code),
        Editable: !blockList.includes(ctrl.type),
      };
    });
    console.log(this.fieldPermissions, 'sss');
    // this.controlCodes = externalInfo.controls.filter(ctrl => !blockList.includes(ctrl.type)).map(ctrl => {
    //   // 补全
    //   console.log(this.fieldPreference.layout);
    //   if (this.fieldPreference.layout.indexOf(ctrl.code) === -1) {
    //     this.fieldPreference.layout.push(ctrl.code);
    //   }
    //   return ctrl.code;
    // });
    // // 排序
    // if (this.fieldPreference.layout) {
    //   this.controlCodes.sort((a, b) => {
    //     const aIndex = this.fieldPreference.layout.indexOf(a);
    //     const bIndex = this.fieldPreference.layout.indexOf(b);
    //     return aIndex - bIndex;
    //   });
    // }
    this.columns = externalInfo.controls;
    // this.dataLogic = new DataLogic({
    //   columns: externalInfo.controls,
    //   origin: {},
    //   config: {
    //     appCode: this.appCode,
    //     schemaCode: this.schemaCode,
    //     viewCode: this.viewCode,
    //     permission: this.fieldPermissions as any,
    //     uowId: '',
    //     defaultValues: {},
    //   },
    // });
    // // eslint-disable-next-line no-prototype-builtins
    // const defautlValue = await (this.dataLogic as any).dataInit({});
    // this.controlValues = Object.assign({}, defautlValue);
    console.log('z---------', this.fieldPermissions);
    this.loading = false;
  }

  get reactColorClass () {
    const bgImage = this.previewImage || this.formViewCover;
    if (['bi', 'diannao'].indexOf(bgImage) > -1) {
      return 'deep-color';
    }
    return '';
  }

  resizeContentHeight () {
    this.subappContentHeight = window.innerHeight + 'px';
  }

  showBigQRCode () {
    this.showQRCode = true;
  }

  hideQRCode () {
    this.showQRCode = false;
  }

  preViewQRCode () {
    this.showQRCode = true;
    var container = this.$refs.qrcode as HTMLElement;
    // 删除旧的二维码
    container.innerHTML = '';
    const host = this.$envContext.envState._H3_EXTERNAL_LINK_URL_ || 'http://externallink.devair.h3yun.com';
    const formUrl = `${host}/m/form/share/${this.shortUrl}`;
    QRCode.toCanvas(formUrl,
      {
        errorCorrectionLevel: 'H',
        width: 220,
        scale: 2,
      }, (err, canvas) => {
        if (err) throw err;
        container.appendChild(canvas);
      },
    );
  }

  async onSubmitForm () {
    // debugger;
    if (!this.checkSubmit) {
      return;
    }
    this.submitting = true;
    const formMain = this.$refs['form-main-ref'] as any;
    try {
      const { data } = await formMain.getSubmitData();
      delete data.CreatedTime;
      const res = await postSharedFormData(this.shortUrl, data);
      // if (!res.data) {
      //   this.submitting = false;
      //   window.location.reload();
      //   return;
      // }
      // if (!res.data && res.errorCode && res.errorCode === 'formula.arg.error') {
      //   const self = this;
      //   this.$modal.warning({
      //     title: '警告',
      //     content: '原数据表字段失效',
      //     onOk () {
      //       self.submitting = false;
      //       window.location.reload();
      //     },
      //   });
      //   return;
      // }
      if (res.data) {
        this.postCount++;
        this.updateHistroyPostCount(this.postCount);
        this.gotoSubmitResult(res);
      }
    } catch {
    }
    this.submitting = false;
  }

  updateHistroyPostCount (val) {
    // debugger;
    return localStorage.setItem(`FORM_SHARE_${this.shortCode}`, val);
  }

  gotoSubmitResult (res) {
    this.$router.push({
      path: '/form/result',
      query: { res: res.data, isSubmitOnce: this.isSubmitOnce, url: this.shortCode, successMsg: this.successMsg }
    });
  }
}
