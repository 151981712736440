import { generateError } from '../error';
import { Formula } from '../type';
import { getParamTypeValue, inferMultiFromSingleType, toText, formatParamValue, validateParamNull, } from '../utils';
import { uniq, uniqBy } from 'lodash';
const TOARRAY = {
    name: 'TOARRAY',
    type: Formula.IdentifierType.FUNC,
    outputType: {
        paramType: Formula.ParamType.ANY,
        sourceType: Formula.SourceType.ANY,
    },
    validate(identifier, params) {
        if (params.length < 1) {
            throw generateError(`函数 ${identifier.name}() 至少需要1个参数，但传入了${params.length}个参数`);
        }
        const inputType = params[0].paramType;
        const outputType = inferMultiFromSingleType(inputType);
        if (!outputType) {
            throw generateError(`函数 ${identifier.name}() 的参数类型[${getParamTypeValue(inputType)}]无法转换成数组，请输入类型[文本/数字/人员单选/部门单选]`);
        }
        for (let i = 0; i < params.length; i++) {
            const param = params[i];
            if (param.paramType !== inputType) {
                throw generateError(`函数 ${identifier.name}() 的第${i + 1}个参数与其他参数类型不同，传入了[${getParamTypeValue(param.paramType)}]，请调整为与其他参数同一类型`);
            }
        }
        return {
            paramType: outputType,
            sourceType: Formula.SourceType.ANY,
        };
    },
    calculate: (identifier, params) => {
        const value = null;
        const inputType = params[0].paramType;
        const outputType = inferMultiFromSingleType(inputType);
        if (!outputType) {
            throw generateError(`函数 ${identifier.name}() 的参数类型[${getParamTypeValue(inputType)}]无法转换成数组，请输入类型[文本/数字/人员单选/部门单选]`);
        }
        for (let i = 0; i < params.length; i++) {
            const param = params[i];
            if (param.paramType !== inputType) {
                throw generateError(`函数 ${identifier.name}() 的第${i + 1}个参数与其他参数类型不同，传入了[${getParamTypeValue(param.paramType)}]，请调整为与其他参数同一类型`);
            }
        }
        const result = { ...identifier.outputType, value, paramType: outputType };
        // 去空
        result.value = [...params.filter(param => !validateParamNull(param)).map(param => {
                if (param.paramType === Formula.ParamType.NUMBER) {
                    return toText(param).value;
                }
                return formatParamValue(param).value;
            })];
        // 去重
        if (outputType === Formula.ParamType.MULTIUSER || outputType === Formula.ParamType.MULTIDEPT) {
            result.value = uniqBy(result.value, 'id');
        }
        else {
            result.value = uniq(result.value);
        }
        return result;
    },
};
export default TOARRAY;
