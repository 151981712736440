import guid from './utils';
export default class Layer {
    constructor(params) {
        this._isRemoved = false;
        this.id = guid();
        this.children = [];
        this.manager = params.manager;
        this.getDom = () => {
            this._dom = params.getDom();
            return this._dom;
        };
        // 先获取一遍，便于观察
        this.getDom();
        this.manager.tiggerCreate(this);
    }
    active(activeBy) {
        if (this.manager.current === this) {
            return;
        }
        // remove与append冲突时，append优先，跳过remove
        if (activeBy === 'remove' && this.manager._appending) {
            return;
        }
        this.manager.current = this;
        this.manager.tiggerActive(this, activeBy);
    }
    remove() {
        // 已经被移除
        if (this._isRemoved) {
            return;
        }
        if (this.children.length > 0) {
            this.children.slice().forEach(child => {
                child.remove();
            });
        }
        this.manager.tiggerRemove(this);
        const index = this.parent.children.findIndex(child => child === this);
        if (index > -1) {
            this.parent.children.splice(index, 1);
        }
        let parent = this.parent;
        while (parent) {
            // 如果父类已经被移除，则往上遍历
            if (!parent._isRemoved) {
                parent.active('remove');
                break;
            }
            parent = parent.parent;
        }
        this._isRemoved = true;
    }
    append(layer, active = true) {
        layer.parent = this;
        this.children.push(layer);
        if (active) {
            layer.active('append');
        }
    }
}
