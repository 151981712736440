import { generateError } from '../error';
import { Formula } from '../type';
import toNumber from 'lodash/toNumber';

const SQRT: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'SQRT',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.NUMBER,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.NUMBER,
    sourceType: Formula.SourceType.EDIT,
  },
  validate (identifier, params, compiler) {
    const outputType = compiler._funcValidate(identifier, params);
    const [param] = params;
    if (Object.prototype.hasOwnProperty.call(param, 'value') && !isNaN(param.value)) {
      const unary = param.unary || 1;
      const value = toNumber(param.value) * unary;
      if (value < 0) {
        throw generateError(`函数 ${identifier.name}() 第二个参数不能为负数`);
      }
    }
    return outputType;
  },
  calculate: (identifier, params) => {
    const [_param] = params;
    let value = null;
    if (_param.value && !isNaN(_param.value)) {
      const _value = toNumber(_param.value) * (_param.unary || 1);
      if (_value >= 0) {
        value = Math.sqrt(_value);
      }
    }
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default SQRT;
