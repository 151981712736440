import Layer from './layer';
import { EventDispatcher } from '../../utils';

export default class LayerManager extends EventDispatcher {
  root: Layer;

  current: Layer;

  map: Map<string, Layer>;

  constructor () {
    super();
    this.map = new Map();
    this.root = new Layer({
      getDom: () => document.body,
      manager: this,
    });
    this.current = this.root;
    this.onMousedown = this.onMousedown.bind(this);
    this.addMouseEvent();
  }

  create (params: {
    getDom: () => HTMLElement;
    active?: boolean;
  }) {
    const layer = new Layer({
      getDom: params.getDom,
      manager: this,
    });
    const parent = this.current || this.root;
    parent.append(layer, params.active);
    return layer;
  }

  matchLayer (node: HTMLElement) {
    for (const [, value] of this.map) {
      if (value.getDom() === node) {
        return value;
      }
    }
    return null;
  }

  tiggerCreate (layer: Layer) {
    this.map.set(layer.id, layer);
    this.dispatchEvent({ type: 'create', layer });
  }

  // active防抖
  private _activeTimer = 0;

  // 激活方式有append、remove、click
  // 处理append后有remove抢占激活的情况
  // 策略1：append激活优先级高于remove，出现append后的短时间内（500ms）禁止remove方式
  // 策略2：remove的是当前激活的，需要跳过此规则，否则快速打开关闭时会出问题
  _appending = false;
  private _appendTimer = 0;

  tiggerActive (layer: Layer, activeBy?: string) {
    // append计时
    if (activeBy === 'append') {
      this._appending = true;
      clearTimeout(this._appendTimer);
      this._appendTimer = setTimeout(() => {
        this._appending = false;
      }, 500);
    }
    // 防抖
    clearTimeout(this._activeTimer);
    this._activeTimer = setTimeout(() => {
      this.dispatchEvent({ type: 'active', layer });
    }, 0);
  }

  tiggerRemove (layer: Layer) {
    // 策略2
    if (layer === this.current) {
      clearTimeout(this._appendTimer);
      this._appending = false;
    }
    this.map.delete(layer.id);
    this.dispatchEvent({ type: 'remove', layer });
  }

  onEvent (name: string, fn: (layer: Layer) => void) {
    const eventFn = (e: any) => {
      fn(e.layer);
    };
    this.addEventListener(name, eventFn);
    return () => {
      this.removeEventListener(name, eventFn);
    };
  }

  onCreate (fn: (layer: Layer) => void) {
    return this.onEvent('create', fn);
  }

  onActive (fn: (layer: Layer) => void) {
    return this.onEvent('active', fn);
  }

  onRemove (fn: (layer: Layer) => void) {
    return this.onEvent('remove', fn); 
  }

  onMousedown (e: MouseEvent) {
    let node = e.target as HTMLElement;
    let current: Layer;
    while (node.parentNode) {
      current = this.matchLayer(node);
      if (current) {
        break;
      }
      node = node.parentNode as HTMLElement;
    }
    if (current) {
      current.active('click');
    }
  }

  addMouseEvent () {
    window.addEventListener('mousedown', this.onMousedown);
  }

  removeMouseEvent () {
    window.removeEventListener('mousedown', this.onMousedown);
  }
}
