import { FieldType } from '../../const';
import Decrypt from './algo';
import getKey from './base';
var EncryptType;
(function (EncryptType) {
    EncryptType["AES"] = "AES";
})(EncryptType || (EncryptType = {}));
// 简单类型
const PlainTypes = [
    FieldType.SinglelineText,
    FieldType.MultilineText,
    FieldType.SingleSelect,
    FieldType.DateTime,
    FieldType.Email,
    FieldType.IDNumber,
    FieldType.PhoneNumber,
    FieldType.SN,
    FieldType.Number,
    FieldType.Rollup,
];
// 对象类型
const ObjectTypes = [
    FieldType.Address,
    FieldType.Attachment,
    FieldType.Relationship,
    FieldType.MultiDept,
    FieldType.SingleDept,
    FieldType.SingleUser,
    FieldType.MultiUser,
    FieldType.SubTable,
];
// 引用类型
const RelationTypes = [
    FieldType.Formula,
    FieldType.Lookup,
];
const prefix = 'E-';
const nonceMap = new Map();
const getDecryptKey = (nonce) => {
    if (!nonceMap.has(nonce)) {
        nonceMap.set(nonce, getKey(nonce));
    }
    return nonceMap.get(nonce);
};
// 解密
export function decrypt(content, encryptType, nonce) {
    const key = getDecryptKey(nonce);
    switch (encryptType) {
        case EncryptType.AES:
            return Decrypt.aes(content, key);
        default:
            break;
    }
}
function getDecryptFields(row) {
    const keys = Object.keys(row || {});
    return keys.filter(key => key.startsWith(prefix));
}
// 获取加密控件真实值
function getRealValue(value, type, control) {
    if (PlainTypes.includes(type)) {
        return value;
    }
    if (ObjectTypes.includes(type)) {
        try {
            value = JSON.parse(value);
            return value;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    }
    if (RelationTypes.includes(type)) {
        const realType = control.properties?.targetType;
        return getRealValue(value, realType, control);
    }
    return null;
}
function convertAddressValue(row, controlMap) {
    // 处理地址数据，是否显示详细地址
    Object.keys(row).forEach(code => {
        const column = controlMap[code];
        if (column && column.type === FieldType.Address && row[code]) {
            const displayFormat = column?.properties.displayFormat;
            row[code].showDetail = displayFormat && displayFormat.indexOf('-D') > -1;
        }
    });
    return row;
}
// 解密单条数据
/**
 *
 * @param row 待解密的数据
 * @param controlMap 字段map
 * @param columns 待解密的字段集，可选参数
 * @param encryptType 数据加密类型，可选参数
 * @param nonce 数据加密随机数，可选参数
 * @returns
 */
function getRawRow(row, controlMap, columns, encryptType, nonce) {
    let type = encryptType;
    let random = nonce;
    let fields = columns;
    if (!type) {
        // 判断是否需要有待解密的字段
        fields = getDecryptFields(row);
        if (fields.length === 0) {
            return convertAddressValue(row, controlMap);
        }
        type = row.EncryptType;
        random = row.Nonce;
    }
    fields.forEach((field) => {
        const realField = field.replace(prefix, '');
        if (!row[field]) {
            row[realField] = row[field];
        }
        else {
            const value = decrypt(row[field], type, random);
            const column = controlMap[realField];
            if (column) {
                row[realField] = getRealValue(value, column.type, column);
                delete row[field];
            }
        }
    });
    return convertAddressValue(row, controlMap);
}
;
// 解密多条数据
/**
 *
 * @param rows 待解密数据集合
 * @param controlMap 字段map
 * @returns
 */
function getRawRows(rows, controlMap) {
    if (rows.length === 0) {
        return [];
    }
    const row = rows[0];
    const fields = getDecryptFields(row);
    const type = row.EncryptType;
    const nonce = row.Nonce;
    const ret = [];
    rows.forEach(_row => {
        ret.push(getRawRow(_row, controlMap, fields, type, nonce));
    });
    return ret;
}
;
const DataProcess = {
    getRawRow,
    getRawRows,
};
export default DataProcess;
