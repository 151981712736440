import Vue from 'vue';
// @ts-ignore
// import { initGlobalRouter } from './router';
// import { initPerformance } from './performance';
// import initGlobal from './global';
import initRequest from './request';

// import titleMixin from './title';
// import { initMonitor } from '@lateinos/lateinos-monitor';
import { ContextImpl } from '@lcos-kernel/data-access';
import errorHandler from './error-handler';
import { request } from '@lateinos/request';

/**
 * 设置apm链路追踪和用户行为埋点
 * @param contextParams 传给服务器的用户上下文
 */

export function startUp (context, router) {
  // initPerformance();
  // await initMonitor(Vue);

  const ugContext = new ContextImpl();
  // ugContext.loadContext();
  // Vue.prototype.$ugContext = ugContext;
  window.$ugContext = ugContext;

  initRequest();
  request.setGlobalErrorHandler(errorHandler);
  // initGlobal();
  // initGlobalRouter(context, router);
  // Vue.mixin(titleMixin);

  // 客户端渲染时，鉴权数据提前加载并渲染
  // 服务端渲染时，由serverPrefetch获取
  return context;
}
