import { Formula } from '../type';
import {
  formatParamValue, getFieldName, getParamTypeValue, containsText, containsUnit,
} from '../utils';
import { generateError } from '../error';

// 支持的参数格式对
const MatchPairs = {
  [Formula.ParamType.TEXT]: [Formula.ParamType.TEXT, Formula.ParamType.MULTITEXT],
  [Formula.ParamType.MULTITEXT]: [Formula.ParamType.TEXT, Formula.ParamType.MULTITEXT],
  [Formula.ParamType.MultiSelect]: [Formula.ParamType.TEXT, Formula.ParamType.MULTITEXT, Formula.ParamType.MultiSelect],
  [Formula.ParamType.MULTIUSER]: [Formula.ParamType.SINGLEUSER, Formula.ParamType.MULTIUSER],
  [Formula.ParamType.MULTIDEPT]: [Formula.ParamType.SIGNLEDEPT, Formula.ParamType.MULTIDEPT],
};

function checkParamType(incoming: Formula.Param, paramIndex: number, result: string[]) {
  if (paramIndex === 0) {
    if (![
      Formula.ParamType.TEXT,
      Formula.ParamType.MULTITEXT,
      Formula.ParamType.MultiSelect,
      Formula.ParamType.MULTIDEPT,
      Formula.ParamType.MULTIUSER].includes(incoming.paramType)) {
        result.push
        (`第1个参数要求是【文本、多行文本、地址、多选、人员多选、部门多选】，传入的是${getFieldName(incoming)}【${getParamTypeValue(incoming.paramType)}】`);
      }
  } else if (paramIndex === 1) {
    if (![
      Formula.ParamType.TEXT,
      Formula.ParamType.MULTITEXT,
      Formula.ParamType.MultiSelect,
      Formula.ParamType.SIGNLEDEPT,
      Formula.ParamType.SINGLEUSER,
      Formula.ParamType.MULTIDEPT,
      Formula.ParamType.MULTIUSER].includes(incoming.paramType)) {
        result.push
        (`第2个参数要求是【文本、多行文本、多选、人员单选、人员多选、部门单选、部门多选】，传入的是${getFieldName(incoming)}【${getParamTypeValue(incoming.paramType)}】`);
      }
  }
}

// 判断参数对是否匹配
function checkParamPair (incomings: Formula.Param[], result: string[]) {
  const firstParamType = incomings[0].paramType;
  const secondParamType = incomings[1].paramType;
  const matchTypes = MatchPairs[firstParamType];
  if (!matchTypes.includes(secondParamType)) {
    result.push(`第1个参数类型为【${getParamTypeValue(firstParamType)}】，
      支持的类型参数为【${matchTypes.map(type => getParamTypeValue(type).join(','))}】，
      传入的是${getFieldName(incomings[1])}【${getParamTypeValue(secondParamType)}`);
  }
}

const CONTAINS: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'CONTAINS',
  type: Formula.IdentifierType.FUNC, // 支持文本和对象类型
  inputType: [{
    paramType: Formula.ParamType.ANY,
    sourceType: Formula.SourceType.ANY,
  }, {
    paramType: Formula.ParamType.ANY,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.BOOL,
    sourceType: Formula.SourceType.EDIT,
  },
  validate (identifier, params, compiler) {
    const { name, inputType, outputType } = identifier;
    if (inputType.length !== params.length) {
      throw generateError(`函数 ${name}() 需要${inputType.length}个参数，但传入了${params.length}个参数`);
    }
    const result = [];
    inputType.forEach((claim, index) => {
      const incoming = params[index];
      checkParamType(incoming, index, result);
    });
    if (result.length === 0) {
      checkParamPair(params, result);
    }
    if (result.length > 0) {
      throw generateError(`函数 ${name}() 参数错误：\n${result.join('\n')}`);
    }
    return outputType;
  },
  calculate: (identifier, params) => {
    const _params = params.map(param => formatParamValue(param));
    let value;
    // TODO 改造执行
    const firstParamType = params[0].paramType;
    switch (firstParamType) {
      case Formula.ParamType.TEXT:
      case Formula.ParamType.MULTITEXT:
        value = _params[0].value.includes(_params[1].value);
        break;
      case Formula.ParamType.MultiSelect:
        value = containsText(_params[0].value, _params[1].value);
        break;
      case Formula.ParamType.MULTIDEPT:
      case Formula.ParamType.MULTIUSER:
        value = containsUnit(_params[0].value, _params[1].value);
        break;
    }
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default CONTAINS;
