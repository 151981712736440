// 控件枚举
import {
  IconFieldSerialnumberStroke,
  IconFieldTextareaStroke,
  IconBevelCircleBottomStroke,
  IconFieldInputStroke,
  IconTickMultipleStroke,
  IconFieldCalendarStroke,
  IconFieldNumberStroke,
  IconFieldAttachmentStroke,
  IconFieldPositionStroke,
  IconTimeStroke,
  IconUserAddStroke,
  IconUserStroke,
  IconFieldTimeStroke,
  IconTabletStroke,
  IconFormLinkStroke,
  IconUserGapWaitStroke,
  IconTeamSingleStroke,
  IconFieldSummaryStroke,
  IconFieldFormulaStroke,
  IconUserSyncStroke,
  IconTableSubStroke,
  IconFieldReferenceStroke,
  IconIdCardStroke,
  IconFieldMailStroke,
  IconTeamSubStroke,
  IconFieldIdStroke,
  IconFieldDatastatusStroke,
  IconProcessLinkFill,
  IconUserGapStampStroke,
  IconTeamNodesStroke,
  IconVersionStroke,
  IconEditStroke,
} from '@h3-icons/basic';

export enum FieldType {
  /* 系统控件 */
  // 创造者
  Creater = 'Creater',
  // 拥有者
  Owner = 'Owner',
  // 修改人
  Modifier = 'Modifier',
  // 拥有者部门
  OwnerDept = 'OwnerDept',
  // 创建时间
  CreatedTime = 'CreatedTime',
  // 修改时间
  ModifiedTime = 'ModifiedTime',
  /* 自定义控件 */
  // 段文本
  SinglelineText = 'SinglelineText',
  // 长文本
  MultilineText = 'MultilineText',
  // 数值
  Number = 'Number',
  // 日期
  DateTime = 'DateTime',
  // 单选
  SingleSelect = 'SingleSelect',
  // 多选
  MultiSelect = 'MultiSelect',
  // 地址
  Address = 'Address',
  // 文件
  Attachment = 'Attachment',
  // 人员单选
  SingleUser = 'SingleUser',
  // 部门单选
  SingleDept = 'SingleDept',
  // 人员多选
  MultiUser = 'MultiUser',
  // 部门多选
  MultiDept = 'MultiDept',
  // 关联记录
  Relationship = 'Relationship',
  // 自动编号
  SN = 'SN',
  // 位置
  Loaction = 'Loaction',

  Rollup = 'Rollup',

  Lookup = 'Lookup',

  Formula= 'Formula',
  // 明细表
  SubTable = 'SubTable',

  PhoneNumber = 'PhoneNumber',
  Email = 'Email',
  IDNumber = 'IDNumber',
  Status = 'Status',
  QRCode = 'QRCode',
  ObjectId = 'ObjectId',
  Location = 'Location',
  WorkflowName = 'WorkflowName',
  WorkflowVersion = 'WorkflowVersion',
  WorkflowActivity = 'WorkflowActivity',
  ActivityParticipants = 'ActivityParticipants',
  Signature = 'Signature',
}

export const FieldIcon = {
  [FieldType.SN]: IconFieldSerialnumberStroke,
  [FieldType.SinglelineText]: IconFieldInputStroke,
  [FieldType.MultilineText]: IconFieldTextareaStroke,
  [FieldType.Number]: IconFieldNumberStroke,
  [FieldType.SingleSelect]: IconBevelCircleBottomStroke,
  [FieldType.MultiSelect]: IconTickMultipleStroke,
  [FieldType.DateTime]: IconFieldCalendarStroke,
  [FieldType.Attachment]: IconFieldAttachmentStroke,
  [FieldType.Address]: IconFieldPositionStroke,
  [FieldType.CreatedTime]: IconTimeStroke,
  [FieldType.Creater]: IconUserAddStroke,
  [FieldType.ModifiedTime]: IconFieldTimeStroke,
  [FieldType.Modifier]: IconUserSyncStroke,
  [FieldType.MultiUser]: IconUserStroke,
  [FieldType.MultiDept]: IconTeamSingleStroke,
  [FieldType.SingleUser]: IconUserStroke,
  [FieldType.SingleDept]: IconTeamSingleStroke,
  [FieldType.Relationship]: IconFormLinkStroke,
  [FieldType.Owner]: IconUserGapWaitStroke,
  [FieldType.OwnerDept]: IconTeamSubStroke,
  [FieldType.Rollup]: IconFieldSummaryStroke,
  [FieldType.Lookup]: IconFieldReferenceStroke,
  [FieldType.Formula]: IconFieldFormulaStroke,
  [FieldType.SubTable]: IconTableSubStroke,
  [FieldType.IDNumber]: IconIdCardStroke,
  [FieldType.Email]: IconFieldMailStroke,
  [FieldType.PhoneNumber]: IconTabletStroke,
  [FieldType.Status]: IconFieldDatastatusStroke,
  [FieldType.WorkflowName]: IconTeamNodesStroke,
  [FieldType.WorkflowActivity]: IconProcessLinkFill,
  [FieldType.WorkflowVersion]: IconVersionStroke,
  [FieldType.ActivityParticipants]: IconUserGapStampStroke,
  [FieldType.Signature]: IconEditStroke,
  ObjectId: IconFieldIdStroke,
};
