import { generateError } from '../error';
import { formatNumber, formatDate } from '../format';
import { Formula } from '../type';
import {
  toText, formatParamValue, validateParamNull, getParamTypeValue,
} from '../utils';

const TEXTFORMAT: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'TEXTFORMAT',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.ANY,
    sourceType: Formula.SourceType.ANY,
  }, {
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.EDIT,
  }],
  outputType: {
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.EDIT,
  },
  validate: (identifier, params) => {
    if (params.length !== 2) {
      throw generateError(`函数 ${identifier.name}() 需要2个参数，但传入了${params.length}个参数`);
    }
    const [_param, format] = params;

    if (![Formula.ParamType.NUMBER, Formula.ParamType.DATE].includes(_param.paramType)) {
      throw generateError(`函数 ${identifier.name}() 第一个参数类型必须是数字或日期，但传入了${getParamTypeValue(_param.paramType)}`);
    }

    if (format.paramType !== Formula.ParamType.TEXT) {
      throw generateError(`函数 ${identifier.name}() 第二个参数类型必须是文本，但传入了${getParamTypeValue(format.paramType)}`);
    }

    if (_param.paramType === Formula.ParamType.NUMBER) {
      const matched = format.value.match(/(\[Num\d\])/);
      if (matched) {
        const Nums = ['[Num0]', '[Num1]', '[Num2]'];
        if (!Nums.includes(matched[1])) {
          throw generateError(`函数 ${identifier.name}() 第二个参数不支持${matched[1]}`);
        }
      } else {
        if (/[#0,]+[^#0.,%]+[#0,]+/.test(format.value)) {
          throw generateError(`函数 ${identifier.name}() 第二个参数中，其他字符应该只能在#和0的最前，或者最后，不能在中间`);
        }
        const numFormat = format.value.match(/([#0.,%]+)/);
        if (numFormat) {
          const numFormatValue = numFormat[1];
          const dotIndex = numFormatValue.indexOf('.');
          const commaIndex = numFormatValue.indexOf(',');
          const percentIndex = numFormatValue.indexOf('%');
          if (percentIndex !== -1 && percentIndex !== numFormatValue.length - 1) {
            throw generateError(`函数 ${identifier.name}() 第二个参数%应放在最后一个#或者0的后面`);
          }
          if (dotIndex !== -1 && commaIndex !== -1 && dotIndex < commaIndex) {
            throw generateError(`函数 ${identifier.name}() 第二个参数千位符','应放在'.'前面`);
          }
          if (commaIndex !== -1) {
            const prev = numFormatValue[commaIndex - 1];
            const next = numFormatValue[commaIndex + 1];
            if (!['#', '0'].includes(prev) || !['#', '0'].includes(next)) {
              throw generateError(`函数 ${identifier.name}() 千位符应','只能在#或者0的中间，不能在头尾`);
            }
          }
          const [integer, decimal] = numFormatValue.split('.');
          if (integer.length === 0) {
            throw generateError(`函数 ${identifier.name}() 整数部分不能为空`);
          }
          if (integer && !/^(0|[^#])+$/.test(integer) && !/^(#|[^0])+$/.test(integer)) {
            throw generateError(`函数 ${identifier.name}() 整数部分0或#不能混用`);
          }
          if (decimal && !/^(0|[^#])+$/.test(decimal) && !/^(#|[^0])+$/.test(decimal)) {
            throw generateError(`函数 ${identifier.name}() 小数部分0或#不能混用`);
          }
        } else {
          throw generateError(`函数 ${identifier.name}() 第二个参数格式错误，请查看下方的用法`);
        }
      }
    }

    return identifier.outputType;
  },
  calculate: (identifier, params) => {
    let [_param, format] = params;
    // 数字类型但是为null, 处理成'';
    if (
      _param.paramType === Formula.ParamType.NUMBER &&
      validateParamNull(_param)
    ) {
      _param.value = '';
    } else {
      _param = formatParamValue(_param);
    }
    if (_param.paramType === Formula.ParamType.NUMBER) {
      const value = formatNumber(_param.value, format.value);
      return {
        ...identifier.outputType,
        value,
      };
    }
    if (_param.paramType === Formula.ParamType.DATE) {
      const value = formatDate(toText(_param).value, format.value);
      return {
        ...identifier.outputType,
        value,
      };
    }
  },
};

export default TEXTFORMAT;
