import { Formula } from '../type';
import { getFieldName, getParamTypeValue, validateObjectType } from '../utils';
import { generateError } from '../error';
const IF = {
    name: 'IF',
    type: Formula.IdentifierType.FUNC,
    inputType: [{
            paramType: Formula.ParamType.BOOL,
            sourceType: Formula.SourceType.ANY,
        }, {
            paramType: Formula.ParamType.ANY,
            sourceType: Formula.SourceType.ANY,
        }, {
            paramType: Formula.ParamType.ANY,
            sourceType: Formula.SourceType.ANY,
        }],
    outputType: {
        paramType: Formula.ParamType.ANY,
        sourceType: Formula.SourceType.EDIT,
    },
    validate(identifier, params, compiler) {
        validateObjectType(identifier, params);
        let outputType = compiler._funcValidate(identifier, params);
        const [, ..._params] = params;
        let resultParamType = _params[0].paramType;
        if (resultParamType === Formula.ParamType.VOID) {
            resultParamType = _params[1].paramType;
        }
        if (resultParamType === Formula.ParamType.VOID) {
            throw generateError(`函数 ${identifier.name}() 的返回值不能都为NULL`);
        }
        if (_params.some(param => resultParamType !== param.paramType && param.paramType !== Formula.ParamType.VOID)) {
            throw generateError(`函数 ${identifier.name}() 要求返参类型一致，现在传入为${identifier.name} (条件, ${_params.map(param => `返参类型：${getFieldName(param)}${getParamTypeValue(param.paramType)}`).join(', ')})`);
        }
        outputType = {
            ...outputType,
            paramType: resultParamType,
        };
        return outputType;
    },
    calculate: (identifier, params, compiler) => {
        const _params = compiler._pretreatment(identifier, params);
        const [condition, prev, next] = _params;
        const value = condition.value ? prev.value : next.value;
        let resultParamType = prev.paramType;
        if (resultParamType === Formula.ParamType.VOID) {
            resultParamType = next.paramType;
        }
        if (resultParamType === Formula.ParamType.VOID) {
            throw generateError(`函数 ${identifier.name}() 的返回值不能都为NULL`);
        }
        return {
            ...identifier.outputType,
            paramType: resultParamType,
            value,
        };
    },
};
export default IF;
