import { ControlType, ControlSystem, ValidationTypes } from "@lcos-kernel/controls-core";
import { cloneDeep, memoize, uniq } from "lodash";
import { SharedFormulaCompiler, FieldReflectFormula, FieldReflect, Formula, ruleExec } from "@lcos-kernel/chicken-library";
import moment from "moment";
import { Api } from "@lcos-kernel/data-access";
async function getAutoRelation(params) {
  var _a, _b, _c, _d, _e;
  const {
    schemaContext,
    content,
    column
  } = params;
  const emptyResult = {
    data: {
      [column.code]: {
        total: 0,
        values: [],
        tempRecords: [],
        increments: {
          Append: [],
          Delete: []
        },
        empty: true
      }
    }
  };
  const isMultiple = ((_a = column.properties) == null ? void 0 : _a.countLimit) !== 1;
  const pageSize = isMultiple ? 100 : 1;
  const relationCode = (_b = column.properties) == null ? void 0 : _b.relationCode;
  const appCode = schemaContext._appCode;
  const relationSchemaContext = schemaContext.getSchemaContext(appCode, relationCode);
  if (!relationSchemaContext) {
    console.log("\u81EA\u52A8\u5173\u8054\u5931\u8D25\uFF0C\u672A\u627E\u5230\u5173\u8054\u8868");
    return emptyResult;
  }
  const controlsRes = await relationSchemaContext.getControls();
  const sourceControls = controlsRes.data;
  if (!Array.isArray(sourceControls) || sourceControls.length === 0) {
    console.error("\u5173\u8054\u8868\u5B57\u6BB5\u4E0D\u5B58\u5728\uFF0C\u65E0\u6CD5\u7B5B\u9009");
    return emptyResult;
  }
  const autoRelationFilter = convertDynamicFilterItems((_c = column.properties) == null ? void 0 : _c.relationAutoFillFilters, content, sourceControls);
  if (getDeleteSourceCode(autoRelationFilter)) {
    console.error("\u5B58\u5728\u5173\u8054\u5B57\u6BB5\u88AB\u5220\u9664\uFF0C\u65E0\u6CD5\u7B5B\u9009");
    return emptyResult;
  }
  if (getDynamicEmpty(autoRelationFilter)) {
    console.error("\u5B58\u5728\u52A8\u6001\u5B57\u6BB5\u503C\u4E3A\u7A7A\uFF0C\u65E0\u6CD5\u7B5B\u9009");
    return emptyResult;
  }
  if (!((_d = autoRelationFilter == null ? void 0 : autoRelationFilter[0]) == null ? void 0 : _d[0])) {
    console.error("\u7B5B\u9009\u6761\u4EF6\u4E3A\u7A7A\uFF0C\u4E0D\u8FDB\u884C\u7B5B\u9009");
    return emptyResult;
  }
  const sorts = (((_e = column.properties) == null ? void 0 : _e.relationAutoFillSorts) || []).map((item) => {
    return {
      code: item.fieldCode,
      isAsc: item.sort === 0
    };
  });
  if (sorts.length === 0) {
    sorts.push({
      code: ControlType.CreatedTime,
      isAsc: false
    });
  }
  const res = await relationSchemaContext.getRecords({
    pageIndex: 0,
    pageSize,
    filterItems: autoRelationFilter,
    sorts
  });
  if (res.data && Array.isArray(res.data.rows)) {
    const data = {
      [column.code]: {
        total: res.data.rows.length,
        values: res.data.rows.map((item) => ({
          id: item.ObjectId,
          name: item.Name
        })),
        tempRecords: res.data.rows,
        increments: {
          Append: res.data.rows.map((item) => item.ObjectId),
          Delete: []
        },
        empty: true
      }
    };
    return {
      data
    };
  }
  return emptyResult;
}
function convertDynamicFilterItems(filterItems, record, sourceControls) {
  if (filterItems && filterItems.length > 0) {
    const ranges = cloneDeep(filterItems) || [[]];
    const sourceControlsMap = {};
    sourceControls.forEach((control) => {
      sourceControlsMap[control.code] = control;
    });
    ranges.forEach((range, index) => {
      const dynamicItems = [];
      range.forEach((selectionItem) => {
        if (selectionItem.valueType === "dynamic") {
          if (selectionItem.dynamicCode || selectionItem.dynamicCode === 0) {
            selectionItem.value = [selectionItem.dynamicCode];
          }
          dynamicItems.push(selectionItem);
          selectionItem.valueType = "fixed";
          selectionItem.dynamicValue = [...selectionItem.value];
          selectionItem.value.forEach((code) => {
            const value = record ? record[code] : null;
            if (selectionItem.controlType === "Relationship") {
              selectionItem.value = value ? Array.isArray(value.values) && value.values.map((item) => item.id) : null;
            } else {
              selectionItem.value = value ? Array.isArray(value) ? value.length ? value : [null] : [value] : [null];
            }
          });
        }
      });
      ranges[index] = range.map((selectionItem) => {
        if (!sourceControlsMap[selectionItem.code]) {
          return {
            ...selectionItem,
            hasDeleteSourceCode: true
          };
        }
        if (!dynamicItems.includes(selectionItem)) {
          return selectionItem;
        }
        const value = selectionItem.value;
        if (value && (value[0] || value[0] === 0)) {
          return selectionItem;
        } else {
          return {
            ...selectionItem,
            hasEmptyDynamic: true
          };
        }
      });
    });
    return ranges;
  }
  return [[]];
}
function getDynamicEmpty(filterItems) {
  var _a;
  if (!((_a = filterItems == null ? void 0 : filterItems[0]) == null ? void 0 : _a[0])) {
    return false;
  }
  const items = filterItems.find((group) => {
    return group.find((rule) => {
      return rule.hasEmptyDynamic;
    });
  });
  return items == null ? void 0 : items[0];
}
function getDeleteSourceCode(filterItems) {
  var _a;
  if (!((_a = filterItems == null ? void 0 : filterItems[0]) == null ? void 0 : _a[0])) {
    return false;
  }
  const items = filterItems.find((group) => {
    return group.find((rule) => {
      return rule.hasDeleteSourceCode;
    });
  });
  return items == null ? void 0 : items[0];
}
async function queryDatalinks$1(datalinks) {
  if (datalinks.length === 0) {
    return;
  }
  const logic = datalinks[0].logic;
  const origin = {
    ...logic.origin
  };
  Object.keys(origin).forEach((code) => {
    var _a, _b, _c, _d;
    const type = (_a = logic.columnMap[code]) == null ? void 0 : _a.type;
    if (type === ControlType.SubTable) {
      origin[code] = ((_b = origin[code]) == null ? void 0 : _b.increments) || {};
    }
    if (type === ControlType.Relationship) {
      const increments = ((_c = origin[code]) == null ? void 0 : _c.increments) || {};
      const values2 = ((_d = origin[code]) == null ? void 0 : _d.values) || [];
      const result = {
        Append: [...increments.Append || []],
        Delete: [...increments.Delete || []]
      };
      values2.forEach((item) => {
        if ((item == null ? void 0 : item.id) && !result.Append.includes(item.id)) {
          result.Append.push(item.id);
        }
      });
      origin[code] = result;
    }
  });
  const values = {};
  const duplicateKeys = {};
  const config = logic.config;
  const schemaContext = config.ugContext.getSchemaContext(config.appCode, config.schemaCode);
  for (let index = 0; index < datalinks.length; index++) {
    const item = datalinks[index];
    const changeControl = item.params.changeControl;
    let _key = changeControl;
    if (item.params.isAutoRelation) {
      _key += `-${item.code}-autoRelation`;
    }
    if (!duplicateKeys[_key]) {
      duplicateKeys[_key] = true;
      if (item.params.isAutoRelation) {
        const res = await getAutoRelation({
          schemaContext,
          content: origin,
          column: item.params.column
        });
        console.log("\u4ECE\u540E\u53F0\u83B7\u53D6\u81EA\u52A8\u5173\u8054", res.data);
        Object.assign(values, res.data);
      } else {
        const res = await schemaContext.getSubTableDatalinkV1({
          changeControl,
          content: origin,
          uowId: config.uowId
        });
        Object.assign(values, res.data);
      }
    }
  }
  datalinks.forEach((item) => {
    item.result.value = values[item.code];
    item.status = 1;
  });
}
const SourceType$1 = SharedFormulaCompiler.Formula.SourceType;
const FormulaFieldType$1 = SharedFormulaCompiler.Formula.FieldType;
function parseFormulaFieldIds$1(formula) {
  return SharedFormulaCompiler.parseFormulaFieldIds(formula).map((item) => {
    return item;
  });
}
function parseFormulaFields$1(logic, formula) {
  return parseFormulaFieldIds$1(formula);
}
function collectDepsOn$1(logic, field) {
  var _a;
  let deps = [];
  const properties = field.properties;
  switch (field.type) {
    case ControlType.Formula:
      if (properties.formula) {
        deps = parseFormulaFields$1(logic, properties.formula);
      }
      break;
    case ControlType.Lookup:
    case ControlType.Rollup:
      if (properties.relationControlCode) {
        deps = [properties.relationControlCode];
      }
      break;
    default:
      if (properties.autofill) {
        if (properties.autofill.type === "Calculated") {
          if (properties.autofill.formula) {
            deps = parseFormulaFields$1(logic, properties.autofill.formula);
          }
        } else if (properties.autofill.type === "Crossed") {
          const filterItems = ((_a = properties.autofill.value) == null ? void 0 : _a.filterItems) || [[]];
          filterItems.forEach((item) => {
            item.forEach((ii) => {
              deps = deps.concat(ii.value);
            });
          });
        }
      }
      break;
  }
  return deps;
}
function collectDeps$1(logic, fields) {
  const depsOn = {};
  const depsBy = {};
  fields.forEach((field) => {
    const deps = collectDepsOn$1(logic, field);
    depsOn[field.code] = deps;
    deps.forEach((d) => {
      if (depsBy[d]) {
        if (!depsBy[d].includes(field.code)) {
          depsBy[d].push(field.code);
        }
      } else {
        depsBy[d] = [field.code];
      }
    });
  });
  return {
    depsOn,
    depsBy
  };
}
async function queryDatalinks(datalinks) {
  if (datalinks.length === 0) {
    return;
  }
  const logic = datalinks[0].logic;
  const getRowContent = (rowId) => {
    const row = logic.config.rows.find((r) => r.ObjectId === rowId);
    if (row) {
      Object.keys(row).forEach((code) => {
        var _a, _b, _c;
        const type = (_a = logic.columnMap[code]) == null ? void 0 : _a.type;
        if (type === ControlType.Relationship) {
          const increments = ((_b = row[code]) == null ? void 0 : _b.increments) || {};
          const values2 = ((_c = row[code]) == null ? void 0 : _c.values) || [];
          const result = {
            Append: [...increments.Append || []],
            Delete: [...increments.Delete || []]
          };
          values2.forEach((item) => {
            if ((item == null ? void 0 : item.id) && !result.Append.includes(item.id)) {
              result.Append.push(item.id);
            }
          });
          row[code] = result;
        }
      });
    }
    return row;
  };
  const values = {};
  const config = logic.config;
  const schemaContext = config.ugContext.getSchemaContext(config.appCode, config.schemaCode);
  for (let index = 0; index < datalinks.length; index++) {
    const item = datalinks[index];
    const changeControl = item.params.changeControl;
    const res = await schemaContext.getSubTableDatalinkV1({
      changeControl,
      content: getRowContent(item.rowId)
    });
    values[item.rowId] = res.data;
  }
  datalinks.forEach((item) => {
    item.result.value = values[item.rowId][item.code];
    item.status = 1;
  });
}
var AsyncStatus$1 = /* @__PURE__ */ ((AsyncStatus2) => {
  AsyncStatus2[AsyncStatus2["Unfinished"] = 0] = "Unfinished";
  AsyncStatus2[AsyncStatus2["Finished"] = 1] = "Finished";
  AsyncStatus2[AsyncStatus2["Unknown"] = 2] = "Unknown";
  return AsyncStatus2;
})(AsyncStatus$1 || {});
class AsyncDep$1 {
  constructor(params) {
    this.type = "formula";
    this.status = 0;
    this.result = {
      value: null,
      valueType: ""
    };
    this.type = params.type;
    this.logic = params.logic;
    this.rowId = params.rowId;
    params.code && (this.code = params.code);
  }
}
class AsyncDataLink$1 extends AsyncDep$1 {
  constructor(params) {
    super({
      ...params,
      type: "datalink"
    });
    this.params = params.params;
  }
}
function getAsyncDep$1(effect) {
  var _a, _b;
  const column = effect.logic.columnMap[effect.code];
  if (((_b = (_a = column.properties) == null ? void 0 : _a.autofill) == null ? void 0 : _b.type) === "Crossed") {
    return new AsyncDataLink$1({
      code: effect.code,
      logic: effect.logic,
      rowId: effect.rowId,
      params: {
        changeControl: effect.depChain[effect.depChain.length - 1]
      }
    });
  }
  return null;
}
async function executeAsyncList$1(asyncDepList) {
  const datalink = [];
  asyncDepList.forEach((item) => {
    if (item instanceof AsyncDataLink$1) {
      datalink.push(item);
    }
  });
  await queryDatalinks(datalink);
  asyncDepList.forEach((item) => {
    if (item.status === 0) {
      item.status = 2;
    }
  });
}
class Queue$1 {
  constructor(logic) {
    this.running = false;
    this.queue = [];
    this.asyncQueue = [];
    this.nextQueue = [];
    this.timer = null;
    this.logic = logic;
  }
  async executeQueue() {
    if (this.running) {
      return;
    }
    try {
      this.running = true;
      if (this.queue.length === 0) {
        this.queue = this.nextQueue;
        this.nextQueue = [];
      }
      while (this.queue.length > 0) {
        const first = this.queue[0];
        await this.executeOne(first);
        this.queue.shift();
        if (this.queue.length === 0) {
          await this.executeAsyncQueue();
          this.queue = this.nextQueue;
          this.nextQueue = [];
        }
      }
      if (this.logic.config.valueUpdate) {
        this.logic.config.valueUpdate(this.logic.config.rows);
      }
    } catch (error) {
      console.error(error);
    }
    this.running = false;
  }
  async executeOne(current) {
    const depOn = current.logic.depsOn[current.code];
    if (depOn) {
      for (let i = 0; i < this.queue.length; i++) {
        const element = this.queue[i];
        if (depOn.includes(element.code)) {
          this.queue.push(current);
          return;
        }
      }
    }
    const tempVal = current.logic.data[current.code];
    await current.execute();
    if (current.asyncDep && current.asyncDep.status === AsyncStatus$1.Unfinished) {
      this.asyncQueue.push(current.asyncDep);
      this.nextQueue.push(current);
    }
    if (tempVal !== current.logic.data[current.code]) {
      const depBy = current.logic.depsBy[current.code];
      if (depBy) {
        for (let i = 0; i < this.queue.length; i++) {
          const element = this.queue[i];
          if (depBy.includes(element.code)) {
            this.queue.splice(i, 1);
            i--;
          }
        }
      }
    }
  }
  async executeAsyncQueue() {
    await executeAsyncList$1(this.asyncQueue);
    this.asyncQueue = [];
  }
}
function formatFieldValue$1(field, value) {
  let result = value;
  let type = field.type;
  if (field.type === ControlType.Lookup) {
    type = field.properties.targetType;
  }
  switch (type) {
    case ControlType.MultiSelect:
      result = typeMultiSelect(field, value);
      break;
  }
  return result;
}
function typeAddress$1(field, value) {
  if (!value)
    return;
  const {
    displayFormat
  } = field.properties;
  return getAddressText$1(value, displayFormat);
}
function typeMultiSelect(field, value) {
  const str = Array.isArray(value) ? value.join(",") : value || "";
  return str;
}
function typeUser$1(field, value) {
  const str = Array.isArray(value) ? value.map((i) => i.name).join(",") : value == null ? void 0 : value.name;
  return str;
}
function typeDept$1(field, value) {
  const str = Array.isArray(value) ? value.map((i) => i.name).join(",") : value == null ? void 0 : value.name;
  return str;
}
function getAddressText$1(value, displayFormat) {
  return ControlSystem.Controls.Address.addressFormat(value, displayFormat);
}
function formatFieldParam$1(field, fieldCode) {
  if (field.type === ControlType.SubTable) {
    return;
  }
  if (field.type === ControlType.Relationship) {
    return;
  }
  let paramType = FieldReflectFormula[field.type];
  if ([ControlType.Rollup, ControlType.Lookup, ControlType.Formula].includes(field.type)) {
    paramType = FieldReflectFormula[field.properties.targetType];
  }
  const formatReflect = {
    address: typeAddress$1,
    singleuser: typeUser$1,
    multiuser: typeUser$1,
    singledept: typeDept$1,
    multidept: typeDept$1
  };
  const getFormulaFormat = () => {
    const func = formatReflect[paramType];
    if (!func)
      return void 0;
    return (value) => {
      return func(field, value);
    };
  };
  const param = {
    id: fieldCode,
    name: field.displayName,
    paramType,
    fieldType: FormulaFieldType$1.BASIC,
    value: "",
    sourceType: SourceType$1.FIELD,
    formula: "",
    formulaFormat: getFormulaFormat()
  };
  return param;
}
const SourceType = SharedFormulaCompiler.Formula.SourceType;
const FormulaFieldType = SharedFormulaCompiler.Formula.FieldType;
function parseFormulaFieldIds(formula) {
  return SharedFormulaCompiler.parseFormulaFieldIds(formula).map((item) => {
    return item;
  });
}
function formatFieldValue(field, value) {
  const result = value;
  return result;
}
function typeAddress(field, value) {
  if (!value)
    return;
  const {
    displayFormat
  } = field.properties;
  return getAddressText(value, displayFormat);
}
function typeUser(field, value) {
  const str = Array.isArray(value) ? value.map((i) => i.name).join(",") : value == null ? void 0 : value.name;
  return str;
}
function typeDept(field, value) {
  const str = Array.isArray(value) ? value.map((i) => i.name).join(",") : value == null ? void 0 : value.name;
  return str;
}
function getAddressText(value, displayFormat) {
  return ControlSystem.Controls.Address.addressFormat(value, displayFormat);
}
function formatFieldParam(field, fieldCode) {
  if (!field) {
    return;
  }
  if (field.type === ControlType.SubTable) {
    return;
  }
  if (field.type === ControlType.Relationship) {
    return;
  }
  let paramType = FieldReflectFormula[field.type];
  if ([ControlType.Rollup, ControlType.Lookup, ControlType.Formula].includes(field.type)) {
    paramType = FieldReflectFormula[field.properties.targetType];
  }
  const formatReflect = {
    address: typeAddress,
    singleuser: typeUser,
    multiuser: typeUser,
    singledept: typeDept,
    multidept: typeDept
  };
  const getFormulaFormat = () => {
    const func = formatReflect[paramType];
    if (!func)
      return void 0;
    return (value) => {
      return func(field, value);
    };
  };
  const param = {
    id: fieldCode,
    name: field.displayName,
    paramType,
    fieldType: FormulaFieldType.BASIC,
    value: "",
    sourceType: SourceType.FIELD,
    formula: "",
    formulaFormat: getFormulaFormat()
  };
  return param;
}
function getRelExpressionFieldParams(fieldCode, value, paramType) {
  return {
    id: fieldCode,
    name: "",
    paramType,
    fieldType: FormulaFieldType.EXPRESSION,
    value,
    sourceType: SourceType.FIELD,
    formula: "",
    formulaFormat: ""
  };
}
const regex = /(LOOKUP|ROLLUP|ROLLUPIFS)\(\s*\{\w+(\.\w+)?\}\s*(\s*,\s*\d+)*(\s*,\s*MATCH\([\s\S]*\))?\s*\)/g;
const variableReg = /\{[0-9A-Za-z]+[\.0-9A-Za-z]*\}/;
function extractExpressions(formula) {
  const expressions = formula.match(regex);
  return expressions;
}
function getTargetField(expression) {
  const field = expression.replace(/[\(\)\.\{\}\,\s]/g, "");
  return `{${field}}`;
}
function getVariable(expression) {
  const fields = expression.match(variableReg);
  if (fields.length > 0) {
    return fields[0].replace("{", "").replace("}", "");
  }
  return "";
}
function formatRelationships(expressions, rowValues) {
  const map = {};
  expressions.forEach((expression) => {
    var _a;
    const field = getVariable(expression);
    if (field && field.indexOf(".") > -1) {
      const relCode = field.split(".")[0];
      if (!map[relCode]) {
        map[relCode] = {
          expressions: [],
          values: ((_a = rowValues[relCode]) == null ? void 0 : _a.increments) || {
            Append: [],
            Delete: [],
            Update: []
          }
        };
      }
      map[relCode].expressions.push(expression);
    }
  });
  return map;
}
function getFormatRequest(schemaCode, bizObjectId, rowValues, expressions) {
  return {
    schemaCode,
    bizObjectId,
    relationships: formatRelationships(expressions, rowValues)
  };
}
async function getCalcResult(schemaCode, bizObjectId, rowValues, expressions, context) {
  let expressionResults = {};
  if (expressions.length > 0) {
    const param = getFormatRequest(schemaCode, bizObjectId, rowValues, expressions);
    const result = await context.expressionEvaluate(param);
    expressionResults = {
      ...expressionResults,
      ...result.data.expressionResults
    };
  }
  return expressionResults;
}
function getFormatFieldParams(results, map) {
  const fieldParams = [];
  Object.keys(results).forEach((item) => {
    const fieldCode = map[item].replace(/[\{\}]/g, "");
    let {
      resultType,
      resultValue
    } = results[item];
    const paramType = FieldReflectFormula[resultType];
    if (resultType === FieldReflect.DateTime) {
      resultValue = (resultValue || "").replace("T", " ");
    }
    const param = getRelExpressionFieldParams(fieldCode, resultValue, paramType);
    fieldParams.push(param);
  });
  return fieldParams;
}
async function formulaProcess(logic, formula) {
  let _formula = formula;
  const expressions = extractExpressions(_formula);
  if (!expressions || expressions.length === 0) {
    return null;
  }
  const map = {};
  expressions.forEach((expression) => {
    const targetField = getTargetField(expression);
    _formula = _formula.replace(expression, targetField);
    map[expression] = targetField;
  });
  const {
    config,
    origin
  } = logic;
  const {
    schemaCode,
    appCode,
    ugContext
  } = config;
  const schemaContext = ugContext.getSchemaContext(appCode, schemaCode);
  const results = await getCalcResult(schemaCode, origin.ObjectId, origin, expressions, schemaContext);
  const fieldParams = getFormatFieldParams(results, map);
  return {
    fieldParams,
    formula: _formula,
    expressionMap: map
  };
}
function getCalcParam$1(column, value, item) {
  const param = formatFieldParam$1(column, item);
  if (param) {
    param.value = formatFieldValue$1(column, value);
    return param;
  }
  return null;
}
async function frontendCalc$1(logic, _formula, depOn, rowId, extraFieldParams) {
  const fieldParams = [];
  let formula = _formula;
  let relMap = null;
  const relFields = [];
  if (extraFieldParams) {
    fieldParams.push(...extraFieldParams);
  }
  const ret = await formulaProcess(logic, formula);
  if (ret) {
    const {
      fieldParams: relFieldParams,
      formula: newFormula,
      expressionMap
    } = ret;
    formula = newFormula;
    fieldParams.push(...relFieldParams);
    relMap = expressionMap;
  }
  if (relMap) {
    Object.keys(relMap).forEach((exp) => {
      relFields.push(relMap[exp].replace(/[\{\}]/g, ""));
    });
  }
  const depOnFields = parseFormulaFieldIds$1(formula);
  depOnFields.forEach((item) => {
    if (relFields.includes(item)) {
      return;
    }
    let column, val, param;
    if (item.indexOf(".") > -1) {
      const fields = item.split(".");
      if (logic.rootColumnMap[item]) {
        column = logic.rootColumnMap[item];
        if (column) {
          val = logic.origin[fields[1]];
          param = getCalcParam$1(column, val, item);
        }
      }
    } else {
      column = logic.columnMap[item];
      if (column) {
        const row = logic.rowMap[rowId];
        val = row[item];
        param = getCalcParam$1(column, val, item);
      }
    }
    if (param) {
      fieldParams.push(param);
    }
  });
  const result = logic.formulaCompiler.calculate(formula, fieldParams);
  return result;
}
function calculatedFormat$1(value, column) {
  if (!column) {
    return value;
  }
  const targetType = column.properties.targetType;
  let type = targetType || column.type;
  if (targetType === ControlType.CreatedTime || targetType === ControlType.ModifiedTime) {
    type = ControlType.DateTime;
  }
  if (type === ControlType.Number) {
    const precision = column.properties.precision || 0;
    return ControlSystem.Controls.Number.valueFormatter(value, {
      precision
    });
  } else if (type === ControlType.DateTime) {
    const displayFormat = column.properties.displayFormat;
    return ControlSystem.Controls.DateTime.valueFormatter(value, {
      displayFormat
    });
  } else if (type === ControlType.SingleSelect) {
    const options = column.properties.options;
    return ControlSystem.Controls.SingleSelect.valueFormatter(value, {
      options
    });
  } else if (type === ControlType.MultiSelect) {
    const options = column.properties.options;
    return ControlSystem.Controls.MultiSelect.valueFormatter(value, {
      options
    });
  }
  return value;
}
function createLookUpExpression$1(field) {
  if (field.type !== ControlType.Lookup) {
    return "";
  }
  const {
    relationControlCode,
    lookupControlCode
  } = field.properties;
  if (!relationControlCode || !lookupControlCode) {
    return "";
  }
  return `LOOKUP({${relationControlCode}.${lookupControlCode}})`;
}
const RollUpFunction$1 = {
  COUNT: 1,
  NOTEMPTY: 2,
  EMPTY: 3,
  MAX: 4,
  MIN: 5,
  AVG: 6,
  SUM: 7,
  CONCAT: 8
};
const RollUpFlag$1 = {
  "\n": 0,
  "\uFF0C": 1,
  "\u3002": 2
};
function createRollUpExpression$1(field) {
  if (field.type !== ControlType.Rollup) {
    return "";
  }
  const {
    relationControlCode,
    rollupControlCode
  } = field.properties;
  const functionName = field.properties.function;
  const flag = field.properties.rollupConcatFlag;
  const functionValue = RollUpFunction$1[functionName];
  const otherValue = RollUpFlag$1[flag] || 0;
  if (!relationControlCode || !rollupControlCode || functionValue === void 0) {
    return "";
  }
  const selectionRange = field.properties.selectionRange;
  if (selectionRange && selectionRange[0] && selectionRange[0][0]) {
    const match = "MATCH({0})";
    return `ROLLUPIFS({${relationControlCode}.${rollupControlCode}}, ${functionValue}, ${otherValue}, ${match})`;
  } else {
    return `ROLLUP({${relationControlCode}.${rollupControlCode}}, ${functionValue}, ${otherValue})`;
  }
}
function getFormula$1(column) {
  var _a, _b, _c, _d;
  const type = column.type;
  let formula;
  if (type === ControlType.Lookup) {
    formula = createLookUpExpression$1(column);
  } else if (type === ControlType.Rollup) {
    formula = createRollUpExpression$1(column);
  } else if (type === ControlType.Formula) {
    formula = column.properties.formula;
  } else if (((_b = (_a = column.properties) == null ? void 0 : _a.autofill) == null ? void 0 : _b.type) === "Calculated") {
    formula = (_d = (_c = column.properties) == null ? void 0 : _c.autofill) == null ? void 0 : _d.formula;
  }
  return formula;
}
class Effect$1 {
  constructor(params) {
    this.logic = params.logic;
    this.code = params.code;
    this.rowId = params.rowId;
    this.formula = params.formula;
    this.depChain = params.depChain;
  }
  async execute() {
    var _a, _b, _c;
    const column = this.logic.columnMap[this.code];
    if (!column) {
      console.error(`\u5217${this.code}\u4E0D\u5B58\u5728`);
      return true;
    }
    if (!this.asyncDep) {
      this.asyncDep = getAsyncDep$1(this);
    }
    if (!this.asyncDep || this.asyncDep.status === AsyncStatus$1.Finished) {
      let result;
      if (!this.asyncDep) {
        const depOn = this.logic.depsOn[this.code] || [];
        const res = await frontendCalc$1(this.logic, this.formula, depOn, this.rowId);
        result = res.result;
      } else {
        if (this.asyncDep.type === "datalink") {
          result = calculatedFormat$1(this.asyncDep.result.value, column);
        }
        if (this.asyncDep.type === "formula") {
          result = this.asyncDep.result.value;
        }
      }
      if (((_b = (_a = column.properties) == null ? void 0 : _a.autofill) == null ? void 0 : _b.type) === "Calculated" || column.type === ControlType.Formula || column.type === ControlType.Lookup || column.type === ControlType.Rollup) {
        result = calculatedFormat$1(result, column);
      }
      const row = this.logic.rowMap[this.rowId];
      row[this.code] = result;
      if (((_c = this.logic.depsBy[this.code]) == null ? void 0 : _c.length) > 0) {
        for (const depBy of this.logic.depsBy[this.code]) {
          this.logic.queue.nextQueue.push(new Effect$1({
            code: depBy,
            logic: this.logic,
            rowId: this.rowId,
            depChain: [this.code],
            formula: getFormula$1(this.logic.columnMap[depBy])
          }));
        }
      }
      return true;
    }
    return false;
  }
}
function observe$1(logic) {
  const queue = logic.queue;
  logic.rootColumns.forEach((column) => {
    const key = column.code;
    const depKey = `${logic.config.rootScheamCode}.${key}`;
    Object.defineProperty(logic.data, key, {
      get() {
        return logic.origin[key];
      },
      set(val) {
        logic.origin[key] = val;
        const depBy = logic.depsBy[depKey] || [];
        if (depBy.length === 0) {
          return;
        }
        depBy.forEach((code) => {
          const formula = getFormula$1(logic.columnMap[code]);
          logic.config.rows.forEach((row) => {
            queue.nextQueue.push(new Effect$1({
              code,
              logic,
              rowId: row.ObjectId,
              formula,
              depChain: [depKey]
            }));
          });
        });
        if (!queue.running) {
          setTimeout(() => {
            queue.executeQueue();
          }, 1);
        }
      }
    });
  });
}
function getExternalMethods(dataLogic) {
  const methods = {
    dataLogic,
    setDatalogic: (val) => {
      methods.dataLogic = val;
    },
    async getUserInfo(id) {
      if (!/^[\w]+$/.test(id)) {
        return null;
      }
      const res = await Api.org.loadUserInfo(id);
      if (!(res == null ? void 0 : res.objectId)) {
        return null;
      }
      return {
        id: res.objectId,
        name: res.name,
        avatar: res.profilePhotoUrl,
        mobile: res.mobile
      };
    },
    async getDeptInfo(id) {
      if (!/^[\w]+$/.test(id)) {
        return null;
      }
      const res = await Api.org.loadDeptInfo(id);
      if (!(res == null ? void 0 : res.objectId)) {
        return null;
      }
      return {
        id: res.objectId,
        name: res.name
      };
    },
    getUserName() {
      var _a, _b, _c, _d;
      return ((_d = (_c = (_b = (_a = methods.dataLogic) == null ? void 0 : _a.config) == null ? void 0 : _b.defaultValues) == null ? void 0 : _c.CreatedBy) == null ? void 0 : _d.name) || "";
    },
    async userDept(id, level) {
      var _a;
      if (!/^[\w]+$/.test(id)) {
        return null;
      }
      const user = await Api.org.loadUserInfo(id);
      const department = (_a = user.departments) == null ? void 0 : _a[0];
      if (!department) {
        return null;
      }
      if (level) {
        const names = department.namePath.split("/");
        const ids = department.idPath.split("/");
        return {
          id: ids[level] || department.departmentId,
          name: names[level] || department.department
        };
      } else {
        return {
          id: department.departmentId,
          name: department.department
        };
      }
    },
    async userDepts(ids, level) {
      if (!Array.isArray(ids) || ids.length === 0) {
        return [];
      }
      const res = await Api.org.getMembersMainDept(ids);
      if (res.data) {
        return res.data.map((item) => {
          if (level) {
            const names = item.namePath.split("/");
            const _ids = item.idPath.split("/");
            return {
              id: _ids[level] || item.objectId,
              name: names[level] || item.name
            };
          } else {
            return {
              id: item.objectId,
              name: item.name
            };
          }
        });
      }
      return null;
    },
    async idToUser(id) {
      if (!/^[\w]+$/.test(id)) {
        return null;
      }
      const user = await Api.org.loadUserInfo(id);
      if (!(user == null ? void 0 : user.objectId)) {
        return null;
      }
      return {
        id: user.objectId,
        name: user.name,
        avatar: user.profilePhotoUrl
      };
    },
    async idToDept(id) {
      if (!/^[\w]+$/.test(id)) {
        return null;
      }
      const dept = await Api.org.loadDeptInfo(id);
      if (!(dept == null ? void 0 : dept.objectId)) {
        return null;
      }
      return {
        id: dept.objectId,
        name: dept.name
      };
    },
    async idsToUsers(idsStr) {
      let ids = [];
      try {
        ids = JSON.parse(idsStr);
      } catch (error) {
        console.log(error);
        return [];
      }
      ids = uniq(ids.map((item) => item.trim()));
      if (!Array.isArray(ids) || ids.length === 0) {
        return [];
      }
      const res = await Api.org.getMembersByIds(ids);
      if (res.data) {
        return res.data.map((item) => {
          return {
            id: item.objectId,
            name: item.name,
            avatar: item.profilePhotoUrl
          };
        });
      }
      return [];
    },
    async idsToDepts(idsStr) {
      let ids = [];
      try {
        ids = JSON.parse(idsStr);
      } catch (error) {
        console.log(error);
        return [];
      }
      ids = uniq(ids.map((item) => item.trim()));
      if (!Array.isArray(ids) || ids.length === 0) {
        return [];
      }
      const res = await Api.org.getDepartmentsByIds(ids);
      if (res.data) {
        return res.data.map((item) => {
          return {
            id: item.objectId,
            name: item.name
          };
        });
      }
      return [];
    },
    async nameToUser(name, priorityDepartmentIds) {
      const param = {
        name,
        priorityDepartmentIds
      };
      const res = await Api.org.getMembersByName(param);
      if (res.data && res.data[0]) {
        return {
          id: res.data[0].objectId,
          name: res.data[0].name,
          avatar: res.data[0].profilePhotoUrl
        };
      }
      return null;
    },
    async nameToDept(name, priorityDepartmentIds) {
      const param = {
        name,
        priorityDepartmentIds
      };
      const res = await Api.org.getDepartmentsByName(param);
      if (res.data && res.data[0]) {
        return {
          id: res.data[0].objectId,
          name: res.data[0].name
        };
      }
      return null;
    },
    async managerOfDept(id, findSuperior) {
      const res = await Api.org.getDepartmentManager(id, findSuperior);
      if (res.data) {
        return {
          id: res.data.objectId,
          name: res.data.name,
          avatar: res.data.profilePhotoUrl
        };
      }
      return null;
    },
    async managerOfUser(id, findSuperior) {
      const res = await Api.org.getMemberManager(id, findSuperior);
      if (res.data) {
        return {
          id: res.data.objectId,
          name: res.data.name,
          avatar: res.data.profilePhotoUrl
        };
      }
      return null;
    }
  };
  Object.keys(methods).forEach((key) => {
    if (typeof methods[key] === "function") {
      methods[key] = memoize(methods[key]);
    }
  });
  return methods;
}
class FormLogic {
  constructor(params) {
    this.rootColumnMap = {};
    this.columnMap = {};
    this.data = {};
    this.depsOn = {};
    this.depsBy = {};
    this.rowMap = {};
    this.origin = {};
    this.config = {
      appCode: "",
      schemaCode: "",
      viewCode: "",
      ugContext: window.$ugContext,
      columns: [],
      rows: [],
      defaultValues: {}
    };
    this.config = Object.assign({}, this.config, params);
    this.rootColumnMap = this.getColumnMap(this.config.rootColumns, this.config.rootScheamCode);
    this.columnMap = this.getColumnMap(this.config.columns);
    this.rootColumns = this.config.rootColumns;
    this.initRowMap();
    if (params.rootObject) {
      this.setOrigin(params.rootObject);
    }
    const {
      depsOn,
      depsBy
    } = collectDeps$1(this, this.config.columns);
    this.depsOn = depsOn;
    this.depsBy = depsBy;
    this.queue = new Queue$1(this);
    observe$1(this);
    if (!this.config.externalMethods) {
      this.config.externalMethods = getExternalMethods(this);
    } else {
      this.config.externalMethods.setDatalogic(this);
    }
    this.formulaCompiler = new SharedFormulaCompiler.FormulaCompiler({
      externalMethods: this.config.externalMethods
    });
  }
  initRowMap() {
    this.config.rows.forEach((row) => {
      this.rowMap[row.ObjectId] = row;
    });
  }
  getColumnMap(columns, prefix) {
    const ret = {};
    columns.forEach((col) => {
      const code = prefix ? `${prefix}.${col.code}` : col.code;
      ret[code] = col;
    });
    return ret;
  }
  setOrigin(origin) {
    this.origin = origin;
  }
  setValue(code, val) {
    this.data[code] = val;
  }
}
function getOrignalData(logic) {
  const data = {};
  logic.columns.forEach((col) => {
    data[col.code] = logic.data[col.code];
  });
  return data;
}
async function handleSubtables(subtables) {
  if (subtables.length === 0) {
    return;
  }
  const batchCalc = () => {
    const promises = [];
    subtables.forEach((subtable) => {
      promises.push(new Promise((resolve) => {
        const subColumn = subtable.logic.columnMap[subtable.code];
        const {
          appCode,
          schemaCode,
          viewCode,
          ugContext
        } = subtable.logic.config;
        const source = cloneDeep(subtable.logic.data[subColumn.code].records);
        const formLogic = new FormLogic({
          schemaCode: subColumn.properties.relationCode,
          appCode,
          viewCode,
          ugContext,
          rootColumns: subtable.logic.columns,
          rootObject: getOrignalData(subtable.logic),
          columns: subColumn.childrenControls || [],
          rows: subtable.logic.data[subColumn.code].records,
          rootScheamCode: schemaCode,
          childCode: subColumn.code,
          valueUpdate: (rows) => {
            subtable.logic.origin[subColumn.code].records = rows;
            subtable.logic.notify.addSubTableChange(subtable.code, {
              type: "calc",
              result: rows,
              source
            });
            subtable.result.value = rows;
            subtable.status = 1;
            resolve(true);
          }
        });
        formLogic.setValue(subtable.depOn, subtable.logic.data[subtable.depOn]);
      }));
    });
    return Promise.all(promises);
  };
  await batchCalc();
}
var AsyncStatus = /* @__PURE__ */ ((AsyncStatus2) => {
  AsyncStatus2[AsyncStatus2["Unfinished"] = 0] = "Unfinished";
  AsyncStatus2[AsyncStatus2["Finished"] = 1] = "Finished";
  AsyncStatus2[AsyncStatus2["Unknown"] = 2] = "Unknown";
  return AsyncStatus2;
})(AsyncStatus || {});
class AsyncDep {
  constructor(params) {
    this.type = "formula";
    this.status = 0;
    this.result = {
      value: null,
      valueType: ""
    };
    this.type = params.type;
    this.logic = params.logic;
    params.code && (this.code = params.code);
  }
}
class AsyncDataLink extends AsyncDep {
  constructor(params) {
    super({
      ...params,
      type: "datalink"
    });
    this.params = params.params;
  }
}
class AsyncSubTable extends AsyncDep {
  constructor(params) {
    super({
      ...params,
      type: "subtable"
    });
    this.depOn = params.depOn;
  }
}
function getAsyncDep(effect) {
  var _a, _b, _c, _d;
  if (effect.isSubtable) {
    return new AsyncSubTable({
      code: effect.code,
      logic: effect.logic,
      depOn: effect.depChain[0]
    });
  }
  const column = effect.logic.columnMap[effect.code];
  const hasCrossed = ((_b = (_a = column.properties) == null ? void 0 : _a.autofill) == null ? void 0 : _b.type) === "Crossed";
  if (hasCrossed) {
    return new AsyncDataLink({
      code: effect.code,
      logic: effect.logic,
      params: {
        changeControl: effect.depChain[effect.depChain.length - 1]
      }
    });
  }
  const relationAutoFillFilters = ((_c = column.properties) == null ? void 0 : _c.relationAutoFillFilters) || [[]];
  if (column.type === "Relationship" && ((_d = relationAutoFillFilters == null ? void 0 : relationAutoFillFilters[0]) == null ? void 0 : _d[0])) {
    return new AsyncDataLink({
      code: effect.code,
      logic: effect.logic,
      params: {
        changeControl: effect.depChain[effect.depChain.length - 1],
        isAutoRelation: true,
        column
      }
    });
  }
  return null;
}
async function executeAsyncList(asyncDepList) {
  const datalink = [];
  const subtables = [];
  asyncDepList.forEach((item) => {
    if (item instanceof AsyncSubTable) {
      if (!subtables.find((subtable) => subtable.code === item.code)) {
        subtables.push(item);
      }
    }
    if (item instanceof AsyncDataLink) {
      datalink.push(item);
    }
  });
  await queryDatalinks$1(datalink);
  await handleSubtables(subtables);
  asyncDepList.forEach((item) => {
    if (item.status === 0) {
      item.status = 2;
    }
  });
}
var DataStatus = /* @__PURE__ */ ((DataStatus2) => {
  DataStatus2[DataStatus2["Add"] = 1] = "Add";
  DataStatus2[DataStatus2["Update"] = 2] = "Update";
  DataStatus2[DataStatus2["Delete"] = 3] = "Delete";
  return DataStatus2;
})(DataStatus || {});
function getSubTableCodes(logic, depOn) {
  const ret = [];
  const specialFields = depOn.filter((filed) => filed.indexOf(".") > -1);
  if (specialFields.length > 0) {
    for (const field of specialFields) {
      const mainField = field.split(".")[0];
      const col = logic.columnMap[mainField];
      if (col && col.type === ControlType.SubTable) {
        ret.push(col);
      }
    }
  }
  return ret;
}
function mergeRecords(logic, rows, column) {
  var _a;
  const draftDatas = ((_a = logic.config.draftRowsMap) == null ? void 0 : _a[column.code]) || [];
  if (draftDatas.length === 0) {
    return rows;
  }
  const addMap = {};
  const editMap = {};
  const removeMap = {};
  draftDatas.forEach((draft) => {
    switch (draft.status) {
      case DataStatus.Add:
        addMap[draft.id] = draft.data;
        break;
      case DataStatus.Update:
        editMap[draft.id] = draft.data;
        break;
      case DataStatus.Delete:
        removeMap[draft.id] = draft.data;
        break;
    }
  });
  const retRows = [];
  Object.keys(addMap).forEach((key) => {
    retRows.push(addMap[key]);
  });
  rows.forEach((row) => {
    if (editMap[row.ObjectId]) {
      retRows.push(editMap[row.ObjectId]);
    } else if (!removeMap[row.ObjectId]) {
      retRows.push(row);
    }
  });
  return retRows;
}
async function getSubTableValue(logic, parentObjectId, column) {
  var _a, _b, _c;
  const records = (_a = logic.origin[column.code]) == null ? void 0 : _a.records;
  if (records && records.length > 0) {
    return records;
  }
  if (((_b = logic.origin[column.code]) == null ? void 0 : _b.total) === 0) {
    return [];
  }
  const params = {
    objectId: parentObjectId,
    controlCode: column.code,
    filterItems: [],
    sorts: [],
    pageIndex: 0,
    pageSize: 100,
    viewCode: logic.config.viewCode,
    scope: null
  };
  const parentSchemaContext = logic.config.ugContext.getSchemaContext(logic.config.appCode, logic.config.schemaCode);
  const res = await parentSchemaContext.getRelationDataValues(params);
  if ((_c = res == null ? void 0 : res.data) == null ? void 0 : _c.rows) {
    logic.origin[column.code] = {
      ...logic.data[column.code],
      total: res.data.total,
      records: mergeRecords(logic, res.data.rows, column)
    };
  }
  return res;
}
async function batchGetSubTableValues(logic, parentObjectId, columns) {
  await Promise.all(columns.map((column) => getSubTableValue(logic, parentObjectId, column)));
}
function getCalcParam(column, value, item, isSubTable = false) {
  const param = formatFieldParam(column, item);
  if (param) {
    if (isSubTable) {
      param.fieldType = FormulaFieldType.SUBLIST;
    }
    param.value = formatFieldValue(column, value);
    return param;
  }
  return null;
}
function getSubTableFieldValues(logic, item) {
  var _a;
  const fields = item.split(".");
  const records = ((_a = logic.origin[fields[0]]) == null ? void 0 : _a.records) || [];
  return records.length > 0 ? records.map((row) => {
    return row[fields[1]];
  }) : [];
}
async function frontendCalc(logic, _formula, depOn, extraFieldParams) {
  let formula = _formula;
  const fieldParams = [];
  let relMap = null;
  const relFields = [];
  if (extraFieldParams) {
    fieldParams.push(...extraFieldParams);
  }
  const ret = await formulaProcess(logic, formula);
  if (ret) {
    const {
      fieldParams: relFieldParams,
      formula: newFormula,
      expressionMap
    } = ret;
    formula = newFormula;
    fieldParams.push(...relFieldParams);
    relMap = expressionMap;
  }
  const depOnFields = parseFormulaFieldIds(formula);
  const subFields = getSubTableCodes(logic, depOnFields);
  if (subFields.length > 0) {
    await batchGetSubTableValues(logic, logic.origin.ObjectId || logic.data.objectId, subFields);
  }
  if (relMap) {
    Object.keys(relMap).forEach((exp) => {
      relFields.push(relMap[exp].replace(/[\{\}]/g, ""));
    });
  }
  depOnFields.forEach((item) => {
    if (relFields.includes(item)) {
      return;
    }
    let column, val, param;
    if (item.indexOf(".") > -1) {
      const fields = item.split(".");
      if (logic.columnMap[fields[0]]) {
        const childrenControls = column = logic.columnMap[fields[0]].childrenControls || [];
        column = childrenControls.find((control) => control.code === fields[1]);
        val = getSubTableFieldValues(logic, item);
        param = getCalcParam(column, val, item, true);
      } else if (logic.config.rootColumnMap[item]) {
        column = logic.config.rootColumnMap[item];
        if (column) {
          val = logic.config.rootObject[fields[1]];
          param = getCalcParam(column, val, item);
        }
      }
    } else {
      column = logic.columnMap[item];
      if (column) {
        val = logic.data[item];
        param = getCalcParam(column, val, item);
      }
    }
    if (param) {
      fieldParams.push(param);
    }
  });
  const result = logic.formulaCompiler.calculate(formula, fieldParams);
  if (result instanceof Promise) {
    const _result = await result;
    if (_result.err) {
      console.log(formula, result);
    }
    return _result;
  } else {
    if (result.err) {
      console.log(formula, result);
    }
    return result;
  }
}
function createLookUpExpression(field) {
  if (field.type !== ControlType.Lookup) {
    return "";
  }
  const {
    relationControlCode,
    lookupControlCode
  } = field.properties;
  if (!relationControlCode || !lookupControlCode) {
    return "";
  }
  return `LOOKUP({${relationControlCode}.${lookupControlCode}})`;
}
const RollUpFunction = {
  COUNT: 1,
  NOTEMPTY: 2,
  EMPTY: 3,
  MAX: 4,
  MIN: 5,
  AVG: 6,
  SUM: 7,
  CONCAT: 8
};
const RollUpFlag = {
  "\n": 0,
  "\uFF0C": 1,
  "\u3002": 2
};
function createRollUpExpression(field) {
  if (field.type !== ControlType.Rollup) {
    return "";
  }
  const {
    relationControlCode,
    rollupControlCode
  } = field.properties;
  const functionName = field.properties.function;
  const flag = field.properties.rollupConcatFlag;
  const functionValue = RollUpFunction[functionName];
  const otherValue = RollUpFlag[flag] || 0;
  if (!relationControlCode || !rollupControlCode || functionValue === void 0) {
    return "";
  }
  const selectionRange = field.properties.selectionRange;
  if (selectionRange && selectionRange[0] && selectionRange[0][0]) {
    const match = "MATCH({0})";
    return `ROLLUPIFS({${relationControlCode}.${rollupControlCode}}, ${functionValue}, ${otherValue}, ${match})`;
  } else {
    return `ROLLUP({${relationControlCode}.${rollupControlCode}}, ${functionValue}, ${otherValue})`;
  }
}
function getFormula(column) {
  var _a, _b, _c, _d, _e;
  const type = column.type;
  let formula;
  if (type === ControlType.Lookup) {
    formula = createLookUpExpression(column);
  } else if (type === ControlType.Rollup) {
    formula = createRollUpExpression(column);
  } else if (type === ControlType.Formula) {
    formula = column.properties.formula;
  } else if (((_b = (_a = column.properties) == null ? void 0 : _a.autofill) == null ? void 0 : _b.type) === "Calculated") {
    formula = (_d = (_c = column.properties) == null ? void 0 : _c.autofill) == null ? void 0 : _d.formula;
  } else if (type === ControlType.SingleSelect || type === ControlType.MultiSelect) {
    if (((_e = column.properties) == null ? void 0 : _e.optionSourceType) === "SchemaData") {
      formula = "NULL";
    }
  }
  return formula;
}
function calculatedFormat(value, column) {
  if (!column) {
    return value;
  }
  const targetType = column.properties.targetType;
  let type = targetType || column.type;
  if (targetType === ControlType.CreatedTime || targetType === ControlType.ModifiedTime) {
    type = ControlType.DateTime;
  }
  if (type === ControlType.Number) {
    const precision = column.properties.precision || 0;
    return ControlSystem.Controls.Number.valueFormatter(value, {
      precision
    });
  } else if (type === ControlType.DateTime) {
    const displayFormat = column.properties.displayFormat;
    return ControlSystem.Controls.DateTime.valueFormatter(value, {
      displayFormat
    });
  } else if (type === ControlType.SingleSelect && column.type === ControlType.SingleSelect) {
    const options = column.properties.options;
    return ControlSystem.Controls.SingleSelect.valueFormatter(value, {
      options
    });
  } else if (type === ControlType.MultiSelect && column.type === ControlType.MultiSelect) {
    const options = column.properties.options;
    return ControlSystem.Controls.MultiSelect.valueFormatter(value, {
      options
    });
  }
  return value;
}
function calculatedSubTableFormat(rows, column) {
  const subColumns = column.childrenControls || [];
  const columnMap = {};
  subColumns.forEach((item) => columnMap[item.code] = item);
  return rows.map((item) => {
    const result = {};
    Object.keys(item).forEach((key) => {
      result[key] = calculatedFormat(item[key], columnMap[key]);
    });
    return result;
  });
}
function getDefaultDateTime(column) {
  var _a;
  const {
    autofill,
    formula,
    displayFormat
  } = column.properties;
  let value = (_a = autofill == null ? void 0 : autofill.value) != null ? _a : formula;
  if (value === "TODAY") {
    value = moment(new Date()).format(displayFormat);
  } else if (value === "TOMORROW") {
    value = moment(new Date()).add(1, "days").format(displayFormat);
  } else if (value === "NEXTMONTH") {
    value = moment(new Date()).add(1, "months").format(displayFormat);
  }
  return value;
}
const ResultTypeMap = {
  [Formula.ParamType.TEXT]: [ControlType.SinglelineText, ControlType.MultilineText, ControlType.Number, ControlType.Email, ControlType.SN, ControlType.IDNumber, ControlType.PhoneNumber, ControlType.SingleSelect],
  [Formula.ParamType.DATE]: [ControlType.DateTime, ControlType.ModifiedTime, ControlType.CreatedTime],
  [Formula.ParamType.MULTITEXT]: [ControlType.MultilineText],
  [Formula.ParamType.NUMBER]: [ControlType.Number, ControlType.Status],
  [Formula.ParamType.ADDRESS]: [ControlType.Address],
  [Formula.ParamType.MultiSelect]: [ControlType.MultiSelect],
  [Formula.ParamType.SINGLEUSER]: [ControlType.SingleUser, ControlType.Creater, ControlType.Modifier, ControlType.Owner],
  [Formula.ParamType.SIGNLEDEPT]: [ControlType.SingleDept],
  [Formula.ParamType.MULTIDEPT]: [ControlType.MultiDept, ControlType.OwnerDept],
  [Formula.ParamType.MULTIUSER]: [ControlType.MultiUser],
  [Formula.ParamType.Relationship]: [ControlType.Relationship]
};
class ValueWithDepChain {
  constructor(value, depChain) {
    this.depChain = depChain;
    this.value = value;
  }
}
class Effect {
  constructor(params) {
    this.logic = params.logic;
    this.code = params.code;
    this.depChain = params.depChain;
    this.formula = getFormula(this.logic.columnMap[this.code]);
    this.isSubtable = params.isSubtable;
  }
  async execute() {
    var _a, _b, _c, _d, _e;
    const column = this.logic.columnMap[this.code];
    if (!column) {
      console.error(`\u5217${this.code}\u4E0D\u5B58\u5728`);
      return true;
    }
    if (this.logic.protectCodes.includes(this.code)) {
      return true;
    }
    if (!this.asyncDep) {
      this.asyncDep = getAsyncDep(this);
    }
    if (!this.asyncDep || this.asyncDep.status === AsyncStatus.Finished) {
      let result;
      if (!this.asyncDep) {
        this.logic.depsOn[this.code] || [];
        const res = await frontendCalc(this.logic, this.formula);
        const targetType = ((_a = column.properties) == null ? void 0 : _a.targetType) || ((_c = (_b = column.properties) == null ? void 0 : _b.autofill) == null ? void 0 : _c.targetType);
        if (targetType && res.resultType && !this.typeValidate(targetType, res.resultType)) {
          return false;
        }
        if (res.resultType === "number") {
          result = parseFloat(res.result).toFixed(column.properties.precision || 0);
        } else {
          result = res.result;
        }
      } else {
        if (this.asyncDep.type === "datalink") {
          if (column.type === ControlType.SubTable) {
            if (Array.isArray(this.asyncDep.result.value)) {
              const values = calculatedSubTableFormat(this.asyncDep.result.value, column);
              this.logic.notify.addSubTableChange(this.code, values);
            } else {
              this.logic.notify.addSubTableChange(this.code, this.asyncDep.result.value);
            }
            return true;
          } else {
            result = calculatedFormat(this.asyncDep.result.value, column);
          }
        }
        if (this.asyncDep.type === "formula") {
          result = this.asyncDep.result.value;
        }
        if (this.asyncDep.type === "subtable") {
          return;
        }
      }
      if (((_e = (_d = column.properties) == null ? void 0 : _d.autofill) == null ? void 0 : _e.type) === "Calculated" || column.type === ControlType.Formula || column.type === ControlType.Lookup || column.type === ControlType.Rollup) {
        result = calculatedFormat(result, column);
      }
      if (column.type === ControlType.SinglelineText && typeof result === "string") {
        result = result.substring(0, 200);
      }
      this.logic.data[this.code] = new ValueWithDepChain(result, [...this.depChain, this.code]);
      return true;
    }
    return false;
  }
  typeValidate(targetType, resultType) {
    if (resultType === Formula.ParamType.ANY) {
      return true;
    }
    const matchTypes = ResultTypeMap[resultType];
    if (matchTypes == null ? void 0 : matchTypes.includes(targetType)) {
      return true;
    }
    return false;
  }
}
function observe(logic) {
  const queue = logic.queue;
  logic.columns.forEach((column) => {
    const key = column.code;
    Object.defineProperty(logic.data, key, {
      get() {
        return logic.origin[key];
      },
      set(_val) {
        let val = _val;
        let depChain = [key];
        if (val instanceof ValueWithDepChain) {
          depChain = val.depChain;
          val = val.value;
        }
        if (logic.origin[key] === val) {
          return;
        }
        const clonedValue = cloneDeep(val);
        logic.origin[key] = clonedValue;
        logic.notify.addChange(key, clonedValue);
        let depBySubTable = false;
        if (!logic.isSubTable && logic.depsBySubtable[key] && logic.depsBySubtable[key].length > 0) {
          const subCodes = logic.depsBySubtable[key];
          subCodes.forEach((subCode) => {
            var _a;
            const addEffect = () => {
              var _a2;
              if (((_a2 = logic.data[subCode]) == null ? void 0 : _a2.total) > 0 && !queue.nextQueue.find((effect) => effect.code === subCode)) {
                depBySubTable = true;
                queue.nextQueue.push(new Effect({
                  code: subCode,
                  logic,
                  depChain: [key],
                  isSubtable: true
                }));
                if (!queue.running) {
                  setTimeout(() => {
                    queue.executeQueue();
                  }, 1);
                }
              }
            };
            if (!((_a = logic.origin[subCode]) == null ? void 0 : _a.records)) {
              const subColumn = logic.columnMap[subCode];
              getSubTableValue(logic, logic.origin.ObjectId, subColumn).then(() => {
                addEffect();
              });
            } else {
              addEffect();
            }
          });
        }
        const depBy = logic.depsBy[key] || [];
        if (depBy.length === 0 && !depBySubTable) {
          !queue.running && queue.publish();
          return;
        }
        depBy.forEach((code) => {
          if (depChain.includes(code)) {
            return;
          }
          if (logic.stage === Stage.Initing) {
            queue.addQueue(new Effect({
              code,
              logic,
              depChain: [key]
            }), queue.nextQueue);
          } else {
            queue.nextQueue.push(new Effect({
              code,
              logic,
              depChain: [key]
            }));
          }
        });
        if (!queue.running) {
          setTimeout(() => {
            queue.executeQueue();
          }, 1);
        }
      }
    });
  });
}
function parseFormulaFields(logic, formula) {
  const fields = parseFormulaFieldIds(formula);
  const deps = fields.map((field) => {
    if (field.indexOf(".") > 0) {
      const splits = field.split(".");
      const column = logic.columnMap[splits[0]];
      if (column) {
        return splits[0];
      }
    }
    return field;
  });
  return deps;
}
function collectDepsOn(logic, field) {
  var _a, _b, _c;
  let deps = [];
  const properties = field.properties;
  switch (field.type) {
    case ControlType.Formula:
      if (properties.formula) {
        deps = parseFormulaFields(logic, properties.formula);
      }
      break;
    case ControlType.Lookup:
    case ControlType.Rollup:
      if (properties.relationControlCode) {
        deps = [properties.relationControlCode];
      }
      break;
    case ControlType.SingleSelect:
    case ControlType.MultiSelect:
      if (properties.optionSourceType === "SchemaData") {
        const filterItems = ((_a = properties.optionSetting) == null ? void 0 : _a.filterItems) || [];
        filterItems.forEach((group) => {
          group.forEach((item) => {
            if (item.valueType === "dynamic" && item.value && item.value[0]) {
              deps = deps.concat(item.value[0]);
            }
          });
        });
      }
      break;
  }
  if (properties.autofill) {
    if (properties.autofill.type === "Calculated") {
      if (properties.autofill.formula) {
        deps = deps.concat(parseFormulaFields(logic, properties.autofill.formula));
      }
    } else if (properties.autofill.type === "Crossed") {
      const filterItems = ((_b = properties.autofill.value) == null ? void 0 : _b.filterItems) || [[]];
      filterItems.forEach((item) => {
        item.forEach((ii) => {
          deps = deps.concat(ii.value);
        });
      });
    }
  }
  const relationAutoFillFilters = (properties == null ? void 0 : properties.relationAutoFillFilters) || [[]];
  if ((_c = relationAutoFillFilters == null ? void 0 : relationAutoFillFilters[0]) == null ? void 0 : _c[0]) {
    relationAutoFillFilters.forEach((item) => {
      item.forEach((ii) => {
        deps = deps.concat(ii.value);
      });
    });
  }
  return deps;
}
function collectDepsOnBySubtable(logic, field) {
  let deps = [];
  const properties = field.properties;
  switch (field.type) {
    case ControlType.Formula:
      if (properties.formula) {
        deps = parseFormulaFields(logic, properties.formula);
      }
      break;
    case ControlType.Lookup:
    case ControlType.Rollup:
      if (properties.relationControlCode) {
        deps = [properties.relationControlCode];
      }
      break;
    default:
      if (properties.autofill) {
        if (properties.autofill.type === "Calculated") {
          if (properties.autofill.formula) {
            deps = parseFormulaFields(logic, properties.autofill.formula);
          }
        }
      }
      break;
  }
  return deps;
}
function collectDeps(logic, fields) {
  const depsOn = {};
  const depsBy = {};
  const subFields = fields.filter((field) => field.type === ControlType.SubTable);
  const childFields = [];
  subFields.forEach((subField) => {
    const childrens = subField.childrenControls || [];
    childrens.forEach((child) => {
      childFields.push({
        ...child,
        code: `${subField.code}.${child.code}`
      });
    });
  });
  const allFields = [].concat(...fields).concat(...childFields);
  allFields.forEach((field) => {
    const deps = collectDepsOn(logic, field);
    depsOn[field.code] = deps;
    deps.forEach((d) => {
      if (depsBy[d]) {
        if (!depsBy[d].includes(field.code)) {
          depsBy[d].push(field.code);
        }
      } else {
        depsBy[d] = [field.code];
      }
    });
  });
  return {
    depsOn,
    depsBy
  };
}
function depsBySubtable(logic, fields) {
  const depsBy = {};
  const allFields = fields.filter((field) => field.type === ControlType.SubTable);
  allFields.forEach((subTableField) => {
    if (subTableField) {
      const childControls = subTableField.childrenControls || [];
      for (const childField of childControls) {
        const deps = collectDepsOnBySubtable(logic, childField);
        for (const dep of deps) {
          if (dep.indexOf(".") > 0) {
            const splits = dep.split(".");
            if (logic.config.schemaCode === splits[0]) {
              if (!depsBy[splits[1]]) {
                depsBy[splits[1]] = [];
              }
              if (!depsBy[splits[1]].includes(subTableField.code)) {
                depsBy[splits[1]].push(subTableField.code);
              }
            }
          }
        }
      }
    }
  });
  return depsBy;
}
async function getChangedVisible(logic, changed) {
  const result = {};
  const keys = Object.keys(changed);
  if (keys.length === 0) {
    return result;
  }
  const ruleContext = new RuleContext(logic);
  for (let i = 0; i < logic.columns.length; i++) {
    const column = logic.columns[i];
    if (column.titleFlag) {
      continue;
    }
    const rules = column.displayRule;
    if (!rules || rules.length === 0) {
      continue;
    }
    const isDepChanged = rules.some((rule) => {
      return rule.some((ii) => {
        if (keys.includes(ii.code)) {
          return true;
        }
        if (ii.valueType === "dynamic") {
          return ii.value.some((iii) => keys.includes(iii));
        }
        return false;
      });
    });
    if (isDepChanged) {
      result[column.code] = await ruleExec(ruleContext, rules);
    }
  }
  return result;
}
class RuleContext {
  constructor(logic) {
    this.logic = logic;
  }
  async getValue(key) {
    return this.logic.origin[key];
  }
}
function getEffectValidations(control, options) {
  const validations = control.options.validations || [];
  const ret = [];
  validations.forEach((item) => {
    if (item.type === ValidationTypes.NotNull) {
      options.required && ret.push(item);
    } else if (item.type === ValidationTypes.NoRepeat) {
      options.uniqueness && ret.push(item);
    } else {
      ret.push(item);
    }
  });
  return ret;
}
async function validate(params, ignore) {
  if (params.record && !(params.col.code in params.record) && params.col.type === ControlType.Relationship) {
    return {
      status: true
    };
  }
  const {
    value,
    col,
    permission
  } = params;
  const {
    properties,
    required
  } = col;
  const opt = {
    required,
    uniqueness: properties.uniqueness
  };
  const control = ControlSystem.Controls[col.type];
  const validations = getEffectValidations(control, opt);
  if (permission.Visible && validations.length > 0) {
    let ret = true;
    for (const validation of validations) {
      if (ignore && ignore.includes(validation.type)) {
        continue;
      }
      if (validation.type === ValidationTypes.NoRepeat) {
        ret = true;
      } else {
        ret = control[validation.method](value, col);
      }
      if (!ret) {
        return {
          status: false,
          errorMsg: `${col.displayName}${validation.message}`
        };
      }
    }
  }
  return {
    status: true
  };
}
function batchValidate(params, ignore) {
  if (params.record && !(params.col.code in params.record) && params.col.type === ControlType.Relationship) {
    return {
      status: true
    };
  }
  const {
    value,
    col,
    permission
  } = params;
  const {
    properties,
    required
  } = col;
  const opt = {
    required,
    uniqueness: (properties == null ? void 0 : properties.uniqueness) || false
  };
  const control = ControlSystem.Controls[col.type];
  const validations = getEffectValidations(control, opt);
  if (permission.Visible && validations.length > 0) {
    let ret = true;
    for (const validation of validations) {
      if (ignore && ignore.includes(validation.type)) {
        continue;
      }
      ret = control[validation.method](value, col);
      if (!ret) {
        return {
          status: false,
          errorMsg: `${col.displayName}${validation.message}`
        };
      }
    }
  }
  return {
    status: true
  };
}
async function validateCodes(logic, codes) {
  const errors = [];
  for (let i = 0; i < codes.length; i++) {
    const code = codes[i];
    const params = {
      value: logic.origin[code],
      record: logic.origin,
      bizObjectId: logic.origin.ObjectId,
      col: logic.columnMap[code],
      permission: logic.config.permission[code] || {
        Editable: false,
        Visible: false
      },
      schemaCode: logic.config.schemaCode
    };
    const res = await validate(params);
    errors.push({
      code,
      status: res.status,
      errorMsg: res.errorMsg
    });
  }
  return errors;
}
class Notify {
  constructor(logic) {
    this.listeners = {
      change: [],
      visibleChange: [],
      subTableChange: [],
      validate: [],
      processing: []
    };
    this.on = (eventKey, fn) => {
      const arr = this.listeners[eventKey];
      arr && arr.push(fn);
    };
    this.off = (eventKey, fn) => {
      const arr = this.listeners[eventKey];
      if (!arr) {
        return;
      }
      const index = arr.indexOf(fn);
      if (index > -1) {
        arr.splice(index, 1);
      }
    };
    this.changed = {};
    this.subTableChanged = {};
    this.logic = logic;
  }
  addChange(key, value) {
    this.changed[key] = value;
  }
  addSubTableChange(key, value) {
    if (Array.isArray(value) || (value == null ? void 0 : value.type) === "calc") {
      this.subTableChanged[key] = value;
    } else {
      this.subTableChanged[key] = [];
    }
  }
  publish() {
    this.notifyProcess(true);
    const changedCodes = Object.keys(this.changed);
    if (changedCodes.length > 0) {
      this.listeners.change.forEach((item) => {
        item(this.changed);
      });
    }
    if (Object.keys(this.subTableChanged).length > 0) {
      this.listeners.subTableChange.forEach((item) => {
        item(this.subTableChanged);
      });
    }
    if (changedCodes.length > 0 && this.listeners.visibleChange.length > 0) {
      getChangedVisible(this.logic, this.changed).then((visibleChanged) => {
        if (Object.keys(visibleChanged).length > 0) {
          this.listeners.visibleChange.forEach((item) => {
            item(visibleChanged);
          });
        }
      });
    }
    if (changedCodes.length > 0 && this.listeners.validate.length > 0) {
      validateCodes(this.logic, changedCodes).then((res) => {
        this.listeners.validate.forEach((item) => {
          item(res);
        });
      });
    }
    this.changed = {};
    this.subTableChanged = {};
    this.notifyProcess(false);
  }
  notifyProcess(val) {
    if (this.listeners.processing.length > 0) {
      this.listeners.processing.forEach((item) => {
        item(val);
      });
    }
  }
}
async function dataInit(columns, data, dataLogic) {
  var _a, _b, _c, _d, _e, _f, _g;
  const defaultValues = dataLogic.config.defaultValues;
  const depsOn = dataLogic.depsOn;
  const formulaFields = [];
  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    if (data[column.code] !== null && data[column.code] !== void 0) {
      continue;
    }
    if (column.type === ControlType.Lookup || column.type === ControlType.Rollup) {
      data[column.code] = null;
      continue;
    }
    if (column.type === ControlType.SingleSelect) {
      if (Array.isArray(column.properties.formula)) {
        data[column.code] = column.properties.formula[0] || "";
        continue;
      }
    }
    if (column.type === ControlType.MultiSelect) {
      if (Array.isArray(column.properties.formula) && column.properties.formula.length > 0) {
        data[column.code] = column.properties.formula;
        continue;
      }
    }
    if (column.type === ControlType.Creater || column.type === ControlType.Owner || column.type === ControlType.OwnerDept || column.type === ControlType.CreatedTime) {
      data[column.code] = defaultValues[column.code] || null;
      continue;
    }
    if (column.type === ControlType.Location) {
      const anyLocation = column.properties.extra.anyLocation;
      const currentLocation = column.properties.extra.currentLocation;
      if (anyLocation.defaultLocation === "current" || currentLocation.defaultLocation === "current") {
        data[column.code] = await ControlSystem.Controls.Location.getCurrentLocationValue();
      }
      continue;
    }
    if (((_b = (_a = column.properties) == null ? void 0 : _a.autofill) == null ? void 0 : _b.type) === "Default") {
      if (column.type === ControlType.DateTime) {
        if (typeof getDefaultDateTime === "function") {
          data[column.code] = getDefaultDateTime(column);
        }
      } else if ([ControlType.SingleUser, ControlType.MultiUser, ControlType.SingleDept, ControlType.MultiDept].includes(column.type)) {
        const autofillValue = column.properties.autofill.value;
        if (Array.isArray(autofillValue)) {
          const tempIdMaps = {};
          data[column.code] = autofillValue.map((item) => getDynamicValuePersonnel(item, defaultValues)).filter((item) => {
            if ((item == null ? void 0 : item.id) && !tempIdMaps[item.id]) {
              tempIdMaps[item.id] = true;
              return true;
            }
            return false;
          });
        } else {
          data[column.code] = getDynamicValuePersonnel(autofillValue, defaultValues);
        }
      } else {
        data[column.code] = column.properties.autofill.value;
      }
      continue;
    }
    if (column.type === ControlType.Formula || ((_d = (_c = column.properties) == null ? void 0 : _c.autofill) == null ? void 0 : _d.formula)) {
      if (dataLogic.isSubTable) {
        formulaFields.push(column);
      } else {
        if (!depsOn[column.code] || depsOn[column.code].length === 0) {
          formulaFields.push(column);
        }
      }
    }
    if (((_f = (_e = column.properties) == null ? void 0 : _e.autofill) == null ? void 0 : _f.type) === "None") {
      data[column.code] = (_g = column.properties) == null ? void 0 : _g.autofill.value;
      continue;
    }
    data[column.code] = null;
  }
  if (formulaFields.length > 0) {
    const frontCalcs = [];
    formulaFields.forEach((field) => {
      var _a2;
      const isAutoFill = field.type !== ControlType.Formula;
      const formula = isAutoFill ? field.properties.autofill.formula : (_a2 = field.properties) == null ? void 0 : _a2.formula;
      frontCalcs.push({
        field,
        formula,
        logic: dataLogic,
        isAutoFill
      });
    });
    if (frontCalcs.length > 0) {
      await batchFrontCalc(frontCalcs, data);
    }
  }
  return data;
}
const batchFrontCalc = async function(calcs, data) {
  for (const calcItem of calcs) {
    const {
      field,
      logic,
      formula,
      isAutoFill
    } = calcItem;
    const ret = await frontendCalc(logic, formula);
    if (ret.result) {
      const value = ret.result;
      if (isAutoFill || field.type === ControlType.Formula) {
        data[field.code] = calculatedFormat(value, field);
      } else {
        data[field.code] = value;
      }
    }
  }
};
function getDynamicValuePersonnel(item, defaultValues) {
  var _a;
  if (!item) {
    return item;
  }
  if (item.id === "#{currentMember}") {
    return defaultValues.OwnerId;
  }
  if (item.id === "#{currentDept}") {
    return (_a = defaultValues.OwnerDeptId) == null ? void 0 : _a[0];
  }
  return item;
}
class Queue {
  constructor(logic) {
    this.running = false;
    this.queue = [];
    this.asyncQueue = [];
    this.nextQueue = [];
    this.timer = null;
    this.logic = logic;
  }
  addQueue(effect, queue) {
    const idx = queue.findIndex((e) => e.code === effect.code);
    if (idx > -1) {
      queue.splice(idx, 1);
    }
    queue.push(effect);
  }
  async executeQueue() {
    if (this.running) {
      return;
    }
    try {
      this.running = true;
      this.logic.notify.notifyProcess(true);
      if (this.queue.length === 0) {
        this.queue = this.nextQueue;
        this.nextQueue = [];
      }
      while (this.queue.length > 0) {
        const first = this.queue[0];
        await this.executeOne(first);
        this.queue.shift();
        if (this.queue.length === 0) {
          await this.executeAsyncQueue();
          this.queue = this.nextQueue;
          this.nextQueue = [];
        }
      }
      this.publish();
    } catch (error) {
      console.error(error);
    }
    this.running = false;
  }
  publish() {
    this.timer && clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (!this.running) {
        this.logic.notify.publish();
      }
    }, 100);
  }
  async executeOne(current) {
    const depOn = current.logic.depsOn[current.code];
    if (depOn && current.logic.stage !== Stage.Initing) {
      for (let i = 0; i < this.queue.length; i++) {
        const element = this.queue[i];
        if (depOn.includes(element.code)) {
          this.queue.push(current);
          return;
        }
      }
    }
    const tempVal = current.logic.data[current.code];
    await current.execute();
    if (current.asyncDep && current.asyncDep.status === AsyncStatus.Unfinished) {
      this.asyncQueue.push(current.asyncDep);
      this.nextQueue.push(current);
    }
    if (tempVal !== current.logic.data[current.code]) {
      if (current.logic.stage !== Stage.Initing) {
        const depBy = current.logic.depsBy[current.code];
        if (depBy) {
          for (let i = 0; i < this.queue.length; i++) {
            const element = this.queue[i];
            if (depBy.includes(element.code)) {
              this.queue.splice(i, 1);
              i--;
            }
          }
        }
      }
    }
  }
  async executeAsyncQueue() {
    await executeAsyncList(this.asyncQueue);
    this.asyncQueue = [];
  }
}
var Stage = /* @__PURE__ */ ((Stage2) => {
  Stage2[Stage2["Initing"] = 0] = "Initing";
  Stage2[Stage2["Inited"] = 1] = "Inited";
  Stage2[Stage2["Processing"] = 2] = "Processing";
  Stage2[Stage2["Idle"] = 3] = "Idle";
  return Stage2;
})(Stage || {});
class DataLogic {
  constructor(params) {
    this.stage = 3;
    this.data = {};
    this.isSubTable = false;
    this.origin = {};
    this.columns = [];
    this.columnMap = {};
    this.config = {
      appCode: "",
      schemaCode: "",
      viewCode: "",
      defaultValues: {},
      permission: {},
      uowId: null,
      ugContext: window.$ugContext
    };
    this.protectCodes = [];
    this.depsOn = {};
    this.depsBy = {};
    this.depsBySubtable = {};
    this.on = (eventKey, fn) => {
      this.notify.on(eventKey, fn);
    };
    this.off = (eventKey, fn) => {
      this.notify.off(eventKey, fn);
    };
    this.config = Object.assign({}, this.config, params.config);
    this.columns = params.columns;
    params.columns.forEach((column) => {
      this.columnMap[column.code] = column;
    });
    this.isSubTable = !!this.config.rootObject;
    const {
      depsOn,
      depsBy
    } = collectDeps(this, this.columns);
    this.depsOn = depsOn;
    this.depsBy = depsBy;
    if (!this.isSubTable) {
      const dp = depsBySubtable(this, this.columns);
      this.depsBySubtable = dp;
    }
    this.queue = new Queue(this);
    observe(this);
    this.notify = new Notify(this);
    if (params.origin) {
      this.setOrigin(params.origin);
    }
    if (!this.config.externalMethods) {
      this.config.externalMethods = getExternalMethods(this);
    } else {
      this.config.externalMethods.setDatalogic(this);
    }
    this.formulaCompiler = new SharedFormulaCompiler.FormulaCompiler({
      externalMethods: this.config.externalMethods
    });
  }
  setOrigin(origin) {
    this.origin = origin;
  }
  columnsHasValue(values) {
    return this.columns.filter((column) => {
      return !!values[column.code];
    });
  }
  async dataInit(data) {
    this.stage = 0;
    const _value = {};
    this.columns.forEach((column) => {
      _value[column.code] = null;
    });
    const values = Object.assign(_value, this.origin);
    const bakData = cloneDeep(data);
    const initValues = await dataInit(this.columns, data, this);
    Object.keys(initValues).forEach((key) => {
      if (initValues[key] && !values[key]) {
        values[key] = initValues[key];
      }
    });
    const columns = this.columnsHasValue(values);
    if (columns && columns.length > 0) {
      return await new Promise((resolve) => {
        const fn = () => {
          const result = {};
          Object.keys(this.origin).forEach((key) => {
            result[key] = bakData[key] || this.origin[key];
          });
          this.stage = 1;
          resolve(result);
          this.off("change", fn);
        };
        this.on("change", fn);
        columns.forEach((col) => {
          const key = col.code;
          this.origin[key] = null;
        });
        let hasSetOperation = false;
        Object.keys(values).forEach((key) => {
          var _a, _b, _c, _d;
          const column = this.columnMap[key];
          if (column) {
            if ((column == null ? void 0 : column.type) === ControlType.Relationship || ((_a = column == null ? void 0 : column.properties) == null ? void 0 : _a.targetType) === ControlType.Relationship) {
              if (((_d = (_c = (_b = values[key]) == null ? void 0 : _b.increments) == null ? void 0 : _c.Append) == null ? void 0 : _d.length) > 0) {
                this.setData(key, values[key]);
                hasSetOperation = true;
              }
            } else {
              if (values[key]) {
                hasSetOperation = true;
                this.setData(key, values[key]);
              }
            }
          }
        });
        if (!hasSetOperation) {
          this.stage = 1;
          resolve(values);
        }
      });
    }
    return values;
  }
  validate(codes) {
    return validateCodes(this, codes);
  }
  setData(code, value) {
    this.data[code] = value;
  }
  getData(code) {
    return this.data[code];
  }
}
function getColumnMap(columns) {
  const ret = {};
  columns.forEach((column) => {
    ret[column.code] = column;
  });
  return ret;
}
async function batchInit(params) {
  const {
    config,
    rows
  } = params;
  const columns = deleteAutofill(params.columns, rows == null ? void 0 : rows[0]);
  const list = new Array(rows.length).fill(null);
  const columnMap = getColumnMap(columns);
  const _config = {
    ...config,
    externalMethods: getExternalMethods(null)
  };
  await Promise.all(rows.map((row, idx) => initRow(columns, columnMap, _config, row, idx, list)));
  return list;
}
async function initRow(columns, columnMap, config, origin, index, list) {
  const dataLogic = new DataLogic({
    columns,
    config,
    origin: {
      ObjectId: origin.ObjectId
    }
  });
  Object.keys(origin).forEach((key) => {
    var _a;
    if (columnMap[key] && columnMap[key].type === ControlType.Relationship) {
      origin[key] = {
        ...origin[key]
      };
      origin[key].increments = {
        Append: (((_a = origin[key]) == null ? void 0 : _a.values) || []).map((value) => value.id),
        Delete: [],
        Update: []
      };
    }
  });
  const row = await dataLogic.dataInit(origin);
  list.splice(index, 1, row);
}
function deleteAutofill(columns, row) {
  if (!row) {
    return columns;
  }
  const codes = Object.keys(row).filter((key) => key !== "ObjectId");
  if (codes.length === 0) {
    return columns;
  }
  const newColumns = cloneDeep(columns);
  codes.forEach((code) => {
    var _a;
    const col = newColumns.find((item) => item.code === code);
    if ((_a = col == null ? void 0 : col.properties) == null ? void 0 : _a.autofill) {
      delete col.properties.autofill;
    }
  });
  return newColumns;
}
export {
  FormulaFieldType,
  SourceType,
  batchInit,
  batchValidate,
  DataLogic as default,
  initRow,
  parseFormulaFieldIds,
  validate,
  validateCodes
};
