import { __decorate } from "tslib";
import { getLayerManager } from './index';
import { Vue, Component, Watch } from 'vue-property-decorator';
export function createMixin(params) {
    return {
        data() {
            return {
                layer: null,
                activeLayer: null,
                removeEvents: [],
            };
        },
        watch: {
            layerVisible(val) {
                if (val) {
                    this.layer = getLayerManager().create({
                        getDom: params.getDom.bind(this),
                    });
                    if (typeof this.addHotKeys === 'function') {
                        this.addHotKeys && this.addHotKeys(this.layer);
                    }
                }
                else {
                    if (this.layer) {
                        this.layer.remove();
                    }
                }
            }
        },
        beforeDestroy() {
            if (this.removeEvents.length > 0) {
                this.removeEvents.forEach((el) => el());
            }
            if (this.layer) {
                this.layer.remove();
            }
        },
        computed: {
            activeInside() {
                let layer = this.activeLayer;
                while (layer) {
                    if (this.layer === layer) {
                        return true;
                    }
                    layer = layer.parent;
                }
                return false;
            }
        },
        methods: {
            addLayerEvent(type, fn) {
                const removeEvent = getLayerManager().onEvent(type, fn);
                this.removeEvents.push(removeEvent);
            }
        },
    };
}
export const H3DropdownMixin = createMixin({
    getDom: function () {
        if (typeof this.getDropdownRef === 'function') {
            return this.getDropdownRef()?.popperElm;
        }
        return null;
    },
});
let H3DropdownMixinClass = class H3DropdownMixinClass extends Vue {
    constructor() {
        super(...arguments);
        this.layer = null;
        this.activeLayer = null;
        this.removeEvents = [];
    }
    beforeDestroy() {
        H3DropdownMixin.beforeDestroy.call(this);
    }
    onLayerVisibleChange(val) {
        H3DropdownMixin.watch.layerVisible.call(this, val);
    }
    get activeInside() {
        return H3DropdownMixin.computed.activeInside.call(this);
    }
    addLayerEvent(type, fn) {
        H3DropdownMixin.methods.addLayerEvent.call(this, type, fn);
    }
};
__decorate([
    Watch('layerVisible')
], H3DropdownMixinClass.prototype, "onLayerVisibleChange", null);
H3DropdownMixinClass = __decorate([
    Component
], H3DropdownMixinClass);
export { H3DropdownMixinClass };
export const H3ModalMixin = createMixin({
    getDom: function () {
        if (typeof this.getModalRef === 'function') {
            let comp = this.getModalRef();
            while (comp && comp.$children && comp.$children.length > 0) {
                if (comp.$el && comp.$el.classList && comp.$el.classList.contains('h3-modal')) {
                    return comp.$el;
                }
                comp = comp.$children[0];
            }
            return null;
        }
        return null;
    },
});
let H3ModalMixinClass = class H3ModalMixinClass extends Vue {
    constructor() {
        super(...arguments);
        this.layer = null;
        this.removeEvents = [];
    }
    beforeDestroy() {
        H3ModalMixin.beforeDestroy.call(this);
    }
    onLayerVisibleChange(val) {
        H3ModalMixin.watch.layerVisible.call(this, val);
    }
    get activeInside() {
        return H3ModalMixin.computed.activeInside.call(this);
    }
    addLayerEvent(type, fn) {
        H3ModalMixin.methods.addLayerEvent.call(this, type, fn);
    }
};
__decorate([
    Watch('layerVisible')
], H3ModalMixinClass.prototype, "onLayerVisibleChange", null);
H3ModalMixinClass = __decorate([
    Component
], H3ModalMixinClass);
export { H3ModalMixinClass };
