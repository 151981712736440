import { generateError } from '../error';
import { Formula } from '../type';
import { getParamTypeValue } from '../utils';
import { uniq } from 'lodash';
const NAMETODEPT = {
    name: 'NAMETODEPT',
    type: Formula.IdentifierType.FUNC,
    outputType: {
        paramType: Formula.ParamType.SIGNLEDEPT,
        sourceType: Formula.SourceType.ANY,
    },
    validate(identifier, params) {
        if (params.length < 1) {
            throw generateError(`函数 ${identifier.name}() 至少需要1个参数，但传入了${params.length}个参数`);
        }
        for (let i = 0; i < params.length; i++) {
            const param = params[i];
            if (i === 0) {
                if (param.paramType !== Formula.ParamType.TEXT) {
                    throw generateError(`函数 ${identifier.name}() 的第${i + 1}个参数应为字符串，但传入了${getParamTypeValue(param.paramType)}`);
                }
                continue;
            }
            if (param.paramType !== Formula.ParamType.SIGNLEDEPT && param.paramType !== Formula.ParamType.MULTIDEPT) {
                throw generateError(`函数 ${identifier.name}() 的第${i + 1}个参数应为部门单选或部门多选，但传入了${getParamTypeValue(param.paramType)}`);
            }
        }
        return identifier.outputType;
    },
    calculate: (identifier, params, compiler) => {
        const value = null;
        const [param, ...depts] = params;
        const result = { ...identifier.outputType, value };
        if (param.value) {
            const deptIds = [];
            depts.forEach(item => {
                if (Array.isArray(item.value)) {
                    deptIds.push(...item.value.map(dept => dept.id));
                }
                else if (item.value.id) {
                    deptIds.push(item.value.id);
                }
            });
            return compiler._externalMethods.nameToDept(param.value, uniq(deptIds)).then((user) => {
                if (!user) {
                    return result;
                }
                result.value = user;
                return result;
            });
        }
        return result;
    },
};
export default NAMETODEPT;
