import { generateError } from '../error';
import { Formula } from '../type';
import { getNumberValue } from '../utils';

const USERDEPT: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'USERDEPT',
  type: Formula.IdentifierType.FUNC,
  outputType: {
    paramType: Formula.ParamType.SIGNLEDEPT,
    sourceType: Formula.SourceType.ANY,
  },
  validate (identifier, params) {
    if (params.length < 1) {
      throw generateError(`函数 ${identifier.name}() 至少需要1个参数，但传入了${params.length}个参数`);
    }
    if (params.length > 2) {
      throw generateError(`函数 ${identifier.name}() 最多需要2个参数，但传入了${params.length}个参数`);
    }
    const [param, level] = params;
    if (param.paramType !== Formula.ParamType.SINGLEUSER) {
      throw generateError(`函数 ${identifier.name}() 要求第一个参数是人员单选`);
    }
    if (level && (
      level.paramType !== Formula.ParamType.NUMBER ||
      level.sourceType !== Formula.SourceType.EDIT ||
      isNaN(level.value) ||
      getNumberValue(level) < 0 ||
      (typeof level.value.isInteger === 'function' && !level.value.isInteger())
    )) {
      throw generateError(`函数 ${identifier.name}() 要求第二个参数是等于或大于0的整数`);
    }
    return identifier.outputType;
  },
  calculate: (identifier, params, compiler) => {
    const value = null;
    const [param, level] = params;
    const result = { ...identifier.outputType, value };
    const _level = level ? getNumberValue(level) : level;
    if (param.value?.id) {
      return compiler._externalMethods.userDept(param.value.id, _level).then((user: any) => {
        if (!user) {
          return result;
        }
        result.value = user;
        return result;
      });
    }
    return result;
  },
};

export default USERDEPT;
