import { Formula } from '../type';

const USERPHONE: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'USERPHONE',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.SINGLEUSER,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.ANY,
  },
  calculate: (identifier, params, compiler) => {
    const value = null;
    const [param] = params;
    const result = { ...identifier.outputType, value };
    if (param.value?.id) {
      return compiler._externalMethods.getUserInfo(param.value.id).then((user: any) => {
        if (!user?.mobile) {
          return result;
        }
        result.value = user.mobile;
        return result;
      });
    }
    return result;
  },
};

export default USERPHONE;
