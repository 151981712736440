import { generateError } from '../error';
import { Formula } from '../type';
import { getParamTypeValue, getNumberValue, validateNull, } from '../utils';
import { get } from 'lodash';
const JSONVALUE = {
    name: 'JSONVALUE',
    type: Formula.IdentifierType.FUNC,
    inputType: [{
            paramType: Formula.ParamType.TEXT,
            sourceType: Formula.SourceType.ANY,
        }, {
            paramType: Formula.ParamType.ANY,
            sourceType: Formula.SourceType.EDIT,
        }],
    outputType: {
        paramType: Formula.ParamType.TEXT,
        sourceType: Formula.SourceType.EDIT,
    },
    validate: (identifier, params) => {
        if (params.length !== 2) {
            throw generateError(`函数 ${identifier.name}() 需要2个参数，但传入了${params.length}个参数`);
        }
        const [_param, _key] = params;
        if (_param.paramType !== Formula.ParamType.TEXT) {
            throw generateError(`函数 ${identifier.name}() 第一个参数类型必须是文本，但传入了${getParamTypeValue(_param.paramType)}`);
        }
        if (![Formula.ParamType.TEXT, Formula.ParamType.NUMBER].includes(_key.paramType)) {
            throw generateError(`函数 ${identifier.name}() 第二个参数类型必须是文本或数字，但传入了${getParamTypeValue(_key.paramType)}`);
        }
        if (_param.sourceType === Formula.SourceType.EDIT) {
            const value = _param.value.replace(/\\"/g, '"');
            try {
                JSON.parse(value);
            }
            catch (e) {
                throw generateError(`函数 ${identifier.name}() 第一个参数不是有效的JSON字符串`);
            }
        }
        if (_key.paramType === Formula.ParamType.NUMBER && _key.sourceType === Formula.SourceType.EDIT) {
            const key = getNumberValue(_key);
            // 必须是正整数或0
            if (key < 0 || key % 1 !== 0) {
                throw generateError(`函数 ${identifier.name}() 第二个参数如果是数字，必须是正整数`);
            }
        }
        return identifier.outputType;
    },
    calculate: (identifier, params) => {
        const [_param, _key] = params;
        if (validateNull(_key.value)) {
            throw generateError(`函数 ${identifier.name}() 第二个参数不能为空`);
        }
        let key = _key.value;
        if (_key.paramType === Formula.ParamType.NUMBER) {
            key = getNumberValue(_key);
            // 必须是正整数或0
            if (key < 0 || key % 1 !== 0) {
                throw generateError(`函数 ${identifier.name}() 第二个参数如果是数字，必须是正整数`);
            }
        }
        else if (_key.paramType === Formula.ParamType.TEXT) {
            key = _key.value.split('.');
        }
        let value = null;
        try {
            const val = _param.value.replace(/\\"/g, '"');
            const jsonValue = JSON.parse(val);
            value = get(jsonValue, key, null);
        }
        catch (e) {
            throw generateError(`函数 ${identifier.name}() 第一个参数不是有效的JSON字符串`);
        }
        if (value !== null && typeof value !== 'string') {
            value = JSON.stringify(value);
        }
        return {
            ...identifier.outputType,
            value,
        };
    },
};
export default JSONVALUE;
