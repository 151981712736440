import { Formula } from '../type';
import { generateError } from '../error';
import {
  toText, formatParamValue, validateObjectType, validateParamNull,
} from '../utils';

const CONCATENATE: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'CONCATENATE',
  type: Formula.IdentifierType.FUNC, // 只支持文本类型
  outputType: {
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.EDIT,
  },
  validate (identifier, params, compiler) {
    compiler._preValidate(identifier, params);
    validateObjectType(identifier, params);

    if (params.length < 1) {
      throw generateError(`函数 ${identifier.name}() 至少需要1个参数，但传入了${params.length}个参数`);
    }
    return identifier.outputType;
  },
  calculate: (identifier, params) => {
    const _params = params.map(param => {
      // 数字类型但是为null, 处理成'';
      if (
        param.sourceType === Formula.SourceType.FIELD &&
        param.paramType === Formula.ParamType.NUMBER &&
        validateParamNull(param)
      ) {
        param.value = '';
        return param;
      }
      return formatParamValue(param);
    });
    const value = _params.map(_param => toText(_param).value).join('');
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default CONCATENATE;
