// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { EnvContext } from '@/typings/context';
import { startUp } from './startup';
import { H3Toast, H3ImageViewer } from '@lateinos/thinking-ui';
import { H3Modal } from '@lateinos/awesome-ui';
import VueCompositionAPI from '@vue/composition-api';
import './styles/reset.less';
import '@lateinos/awesome-ui/dist/antd-style.css';

// 加载@h3-icons的symbol
import '@h3-icons/basic/dist/symbol';
import '@h3-icons/basic-biz/dist/symbol';
import '@h3-icons/ug-biz/dist/symbol';
import '@h3-icons/thinking-ui/dist/symbol';

Vue.config.productionTip = false;

const context: EnvContext = {
  url: window.location.pathname,
  envState: window.__ENV_STATE__,
  isDevEnv: window.location.host.includes('dev') || process.env.NODE_ENV !== 'production',
} as any;

Vue.use(H3Modal);
Vue.use(H3Toast);
Vue.use(H3ImageViewer);
Vue.use(VueCompositionAPI);
Vue.prototype.$envContext = context;

startUp(context, router);

window.__ENV_STATE__ = {
  _H3_ASSETS_CDN_URL_: 'https://static.iyouge.com',
  ...window.__ENV_STATE__,
};

Vue.prototype.$modal = H3Modal;
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
});
