
import { Formula } from '../type';
import { generateError } from '../error';
import { getNumberValue, validateObjectType } from '../utils';

const REPLACE: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'REPLACE',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.ANY,
  }, {
    paramType: Formula.ParamType.NUMBER,
    sourceType: Formula.SourceType.ANY,
  }, {
    paramType: Formula.ParamType.NUMBER,
    sourceType: Formula.SourceType.ANY,
  }, {
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.EDIT,
  },
  validate (identifier, params, compiler) {
    validateObjectType(identifier, params);
    const outputType = compiler._funcValidate(identifier, params);
    const [, index, len] = params;
    if (!isNaN(index.value)) {
      const value = getNumberValue(index);
      if (value < 1 || value % 1 !== 0) {
        throw generateError(`函数 ${identifier.name}() 的第二个参数应该为正整数`);
      }
    }
    if (!isNaN(len.value)) {
      const value = getNumberValue(index);
      if (value < 1 || value % 1 !== 0) {
        throw generateError(`函数 ${identifier.name}() 的第三个参数应该为正整数`);
      }
    }
    return outputType;
  },
  calculate: (identifier, params, compiler) => {
    const _params = compiler._pretreatment(identifier, params);
    const [_str, _index, _len, _new] = _params;
    if (_index.value.lt(1) || !_index.value.isInteger() || _len.value.lt(1) || !_len.value.isInteger()) {
      throw generateError(`函数 ${identifier.name}() 第二个参数和第三个参数应该为正整数`);
    }
    const index = getNumberValue(_index);
    const len = getNumberValue(_len);
    let value = _str.value;
    if (index - 1 < _str.value.length) {
      value = _str.value.substr(0, index - 1) + _new.value + _str.value.substr(index - 1 + len);
    }
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default REPLACE;
