import { Formula } from '../type';
import { toInteger } from '../utils';
const INT: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'INT',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.NUMBER,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.NUMBER,
    sourceType: Formula.SourceType.EDIT,
  },
  calculate: (identifier, params, compiler) => {
    const _params = compiler._pretreatment(identifier, params);
    const [_param] = _params;
    const value = toInteger(_param.value);
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default INT;
