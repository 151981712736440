import { Formula } from '../type';
import { generateError } from '../error';
import {
  RELEVANCE_HANDLE,
  CONDITION_HANDLE,
  getNumberValue,
} from '../utils';

const TOPK: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'TOPK',
  type: Formula.IdentifierType.FUNC,
  outputType: {
    paramType: Formula.ParamType.ANY,
    sourceType: Formula.SourceType.EDIT,
  },
  validate (identifier, params, compiler) {
    compiler._preValidate(identifier, params, RELEVANCE_HANDLE | CONDITION_HANDLE);
    if (params.length < 2) {
      throw generateError(`函数 ${identifier.name}() 至少需要2个参数，但传入了${params.length}个参数`);
    }
    if (params.length > 3) {
      throw generateError(`函数 ${identifier.name}() 最多需要3个参数，但传入了${params.length}个参数`);
    }

    const [param, k, sort] = params;
    if (param.sourceType !== Formula.SourceType.FIELD ||
      ![Formula.ParamType.NUMBER, Formula.ParamType.DATE].includes(param.paramType) ||
        (param.sourceType === Formula.SourceType.FIELD &&
          (param as Formula.FieldParam).fieldType !== Formula.FieldType.SUBLIST)) {
      throw generateError(`函数 ${identifier.name}() 第一个参数需要是明细表中的数字和日期字段`);
    }

    if (k.sourceType !== Formula.SourceType.EDIT) {
      throw generateError(`函数 ${identifier.name}() 第二个参数不能是字段`);
    }

    if (sort && sort.sourceType !== Formula.SourceType.EDIT) {
      throw generateError(`函数 ${identifier.name}() 第三个参数不能是字段`);
    }

    if (!isNaN(k.value)) {
      const value = getNumberValue(k);
      if (value < 1 || value % 1 !== 0) {
        throw generateError(`函数 ${identifier.name}() 的第二个参数应该为正整数`);
      }
    }

    if (sort && !isNaN(sort.value)) {
      const value = getNumberValue(sort);
      if (value !== 1 && value !== -1) {
        throw generateError(`函数 ${identifier.name}() 的第三个参数应该为1或-1`);
      }
    }

    return {
      ...identifier.outputType,
      paramType: param.paramType,
    };
  },
  calculate (identifier, params) {
    const [param, k, sort] = params;
    const values = (param as Formula.FieldParam).values;
    const _index = getNumberValue(k);
    let value = null;
    if (_index >= 1 && _index % 1 === 0) {
      let index = _index - 1;
      if (!sort || getNumberValue(sort) === -1) {
        index = values.length - 1 - index;
      }
      if (index >= 0 && index < values.length) {
        value = values[index];
      }
    }
    return {
      ...identifier.outputType,
      paramType: param.paramType,
      value,
    };
  },
};

export default TOPK;
