var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"formview-subapp share-form",style:({height: _vm.subappContentHeight})},[_c('div',{staticClass:"form-view-container"},[_c('div',{staticClass:"form-view",class:[_vm.formViewCover]},[_c('div',{ref:"form-cover",staticClass:"form-bg",style:({backgroundImage: ("url(" + (_vm.bgImageMap[_vm.formViewCover]) + ")")})}),_c('div',{staticClass:"qr-code-wrap"},[_c('h3-dropdown',{style:({
            height: '38px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
          }),attrs:{"placement":"bottom","visible":_vm.showQRCode,"offset":[0, 10],"arrow":true,"overlayCls":"no-padding qrcode-dropdown"},on:{"hide":_vm.hideQRCode,"show":_vm.preViewQRCode},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"qrcode"},[_c('div',{ref:"qrcode",staticClass:"canvas-wrapper"})])]},proxy:true}])},[_c('div',{staticClass:"qrcode-icon",on:{"mouseenter":_vm.showBigQRCode,"mouseleave":_vm.hideQRCode}},[_c('icon-qrcode-stroke')],1)])],1),(!_vm.loading)?_c('div',{staticClass:"form-view-body"},[_c('div',{staticClass:"form-view-title"},[_c('h2',{class:[_vm.reactColorClass]},[_c('div',{style:({cursor: 'default'})},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('a-text-area',{staticClass:"form-descript",class:{
              'descript-readonly': false,
              'deep-color': _vm.reactColorClass,
            },attrs:{"autoSize":{minRows: 1, maxRows: 3},"size":"small","placeholder":" ","maxLength":200,"disabled":""},model:{value:(_vm.formDescription),callback:function ($$v) {_vm.formDescription=$$v},expression:"formDescription"}})],1),_c('div',{staticClass:"form-view-content",class:{'setting-form-view': true}},[_c('div',{ref:"viewContent"},[_c('form-external-link',{key:_vm.index,ref:"form-main-ref",attrs:{"schemaInfo":{
                appCode: _vm.appCode,
                schemaCode: _vm.schemaCode,
                viewCode: _vm.viewCode,
              },"fieldPermissions":_vm.fieldPermissions,"fieldSettings":_vm.columns,"fieldPreference":_vm.fieldPreference}})],1),_c('h3-button',{staticStyle:{"display":"block","margin":"auto"},attrs:{"loading":_vm.submitting,"type":"primary"},on:{"click":_vm.onSubmitForm}},[_vm._v(" 提交 ")])],1)]):_vm._e()])]),(_vm.loading)?_c('div',{staticClass:"loading-box"},[_c('h3-loading')],1):_vm._e(),_c('div',{staticClass:"form-view-footer"},[_c('img',{attrs:{"src":_vm.supportImages[0],"srcset":_vm.supportImages[1]}}),_c('span',[_vm._v("有格提供支持")])])])}
var staticRenderFns = []

export { render, staticRenderFns }