/**
 * @author 李周
 * @param
 * 组件描述，请详细说明
 */
import moment from 'moment';
import { RuleMethod, methodFactory, timeRange } from './rule-function';

/**
 * @param context 所需要的数据
 * {
 *  code1: value1,
 *  code2: value2,
 *  getValue (code, valueType) {
 *
 *  }
 * }
 * @param rules 一个二维数组，定义了一些规则， 一维之间是或关系，二维是与关系
 * 例如：[[a1, a2, a3],[b1, b2]] a1,a2...之间是与，求出结果与 b 是或关系
 * 规则：{code, method, valueType, value,}
 * code: 或者是fieldCode
 * method： 规则名称
 * valueType：value 类型
 * value：对比值, 一个一维数组
 */

enum ValueType{
  CONST = 'fixed',
  FIELDCODE = 'dynamic',
  RANG = 'function',
}
interface RuleConext {
  [fieldCode: string]: any;
  getValue?(key: string, _self: RuleConext): Promise<any>;
};

interface Rule {
  code: string;
  controlType?: ComponentType;
  method: RuleMethod;
  value: any[];
  valueType: ValueType;
}
enum ComponentType {
  ADDRESS = 'Address',
  ATTACHMENT = 'Attachment',
  CREATEDTIME = 'CreatedTime',
  CREATER = 'Creater',
  DATETIME = 'DateTime',
  EMAIL = 'Email',
  FORMULA = 'Formula',
  IDNUMBER = 'IDNumber',
  LOOKUP = 'Lookup',
  MODIFIEDTIME = 'ModifiedTime',
  MODIFIER = 'Modifier',
  MULTIDEPT = 'MultiDept',
  MULTILINETEXT = 'MultilineText',
  MULTISELECT = 'MultiSelect',
  MULTIUSER = 'MultiUser',
  NUMBER = 'Number',
  OWNER = 'Owner',
  PHONENUMBER = 'PhoneNumber',
  RELATIONSHIP = 'Relationship',
  ROLLUP = 'Rollup',
  SINGLEDEPT = 'SingleDept',
  SINGLELINE_TEXT = 'SinglelineText',
  SINGLESELECT = 'SingleSelect',
  SINGLEUSER = 'SingleUser',
  SN = 'SN',
  SUBTABLE = 'SubTable',
  TEMPLATENESTED = 'TemplateNested',
  TEMPLATEPLAIN = 'TemplatePlain',
  TEMPORARY = 'Temporary',
};
// 异步版本
export default async function ruleExec (context: RuleConext, rules: any[][]): Promise<boolean> {
  const propValues = { ...context };
  for (let i = 0; i < rules.length; i++) {
    for (let rule = rules[i], j = 0; j < rule.length; j++) {
      const {
        code,
        valueType,
        value,
      } = (rule[j] as Rule);
      propValues[code] = await getPropValue(context, code);
      // 动态值
      if (valueType === ValueType.FIELDCODE) {
        propValues[value[0]] = await getPropValue(context, value[0]);
      }
    }
  }
  return ruleExecAsync(context, rules, propValues);
}

// 同步版本
export function ruleExecAsync (context: RuleConext, rules: any[][], propValues?: Record<string, any>): boolean {
  const _propsValues = propValues || context;
  let result: boolean;
  for (let i = 0; i < rules.length; i++) {
    result = true;
    for (let rule = rules[i], j = 0; j < rule.length; j++) {
      let {
        code,
        controlType,
        method,
        valueType,
        value,
      } = (rule[j] as Rule);
      let firstParam = _propsValues[code] || null;
      let secondParam;
      // 常量
      if (valueType === ValueType.CONST) {
        secondParam = value;
      } else if (valueType === ValueType.FIELDCODE) {
        secondParam = _propsValues[value[0]] || null;
      } else if (valueType === ValueType.RANG) {
        secondParam = timeRange(value[0]);
        // 日期部分规则名称不符合实际场景
        switch (method) {
          case RuleMethod.EQUAL:
            method = RuleMethod.RANGE;
            break;
          case RuleMethod.NOTEQUAL:
            method = RuleMethod.OUTOFRANGE;
            break;
          default:
            break;
        }
      }
      // 针对部分控件值的特殊处理
      if (isUserComponent(controlType)) {
        firstParam = convertOrgComputeValue(firstParam);
        secondParam = convertOrgComputeValue(secondParam);
      } else if (isAddressComponent(controlType)) {
        const showDetail = firstParam?.showDetail;
        firstParam = convertAdressComputeValue(firstParam, showDetail);
        secondParam = convertAdressComputeValue(secondParam[0], showDetail);
      } else if (isDateComponent(controlType)) {
        firstParam = convertTime([firstParam])[0];
        if (!Array.isArray(secondParam)) {
          secondParam = [secondParam];
        }
        secondParam = convertTime(secondParam);
      } else if (isDeptOrgComponent(controlType)) {
        firstParam = convertOrgComputeValue(firstParam);
        secondParam = convertOrgComputeValue(secondParam);
      } else if (isRelationshipComponent(controlType)) {
        firstParam = getRelationshipValueIds(firstParam);
      } else if (isNumberComponent(controlType)) {
        firstParam = getNumberValue(firstParam);
        secondParam = getNumberValue(secondParam);
      } else {
        if (controlType && typeof ComponentType[controlType.toUpperCase()] === 'undefined') {
          // console.warn(`cann't find component ${controlType}, is it a new component ?`);
        }
      }
      if (!Array.isArray(secondParam)) {
        secondParam = [secondParam];
      }
      // 多选的不包含，替换为“不包含任意一个”
      // 当前“不包含”公式是“同时包含”的取反，意为“不同时包含”，意思不对
      if (isMultiSelectComponent(controlType) && method === RuleMethod.NOTCONTAINS) {
        method = RuleMethod.NOTCONTAINSANY;
      }
      result = result && runCompareMethod(method, firstParam, secondParam);
    }
    // 过滤空规则
    if (result && rules[i].length) {
      break;
    }
  }
  return result;
}

function isUserComponent (type: string) :boolean {
  return ['SingleUser', 'Creater', 'Owner', 'Modifier', 'MultiUser'].indexOf(type) > -1;
}
function isDeptOrgComponent (type: string) :boolean {
  return ['SingleDept', 'MultiDept'].indexOf(type) > -1;
}
function isAddressComponent (type: string) :boolean {
  return type === 'Address';
}
function isNumberComponent (type: string): boolean {
  return type === 'Number';
}

// 将日期转换为毫秒数
function convertTime (dates: any[]) : number[] {
  return dates.map((date) => {
    // 屏蔽null '' undefined
    if (!date) {
      return null;
    }
    if (typeof date === 'number') {
      return date;
    }
    try {
      const milliseconds = moment(date) as any - 0;
      return milliseconds;
    } catch (e) {
      return null;
    }
  });
}
function isDateComponent (type: string) :boolean {
  return ['DateTime', 'Date', 'DatePicker'].indexOf(type) > -1;
}
function isRelationshipComponent (type: string) {
  return type === 'Relationship';
}
function getRelationshipValueIds (val: any) {
  if (val && Array.isArray(val.values)) {
    return val.values.map((item: any) => item.id);
  }
  return [];
}

function convertOrgComputeValue (objs: any[]): string[] {
  if (!Array.isArray(objs)) {
    // 为空时，赋予空数组，而不是[undefined]
    // eslint-disable-next-line no-param-reassign
    objs = objs ? [objs] : [];
  }
  return objs.map((obj) => {
    return (obj && (obj.id || obj.value)) || obj;
  });
}
function runCompareMethod (method: RuleMethod, sourceVal: any, targetVal: any) : boolean {
  const func = methodFactory(method);
  return func.apply(null, [sourceVal].concat(targetVal));
}
function getNumberValue (val: string | string[]) {
  if (Array.isArray(val)) {
    return val.map(getNumberValue);
  }
  // 无值时，不转换为数字
  if (!val) {
    return val;
  }
  return Number(val);
}

async function getPropValue (ctx: RuleConext, key: string): Promise<any> {
  if (!ctx) {
    return null;
  }
  if (key in ctx) {
    return ctx[key];
  } else if (typeof ctx.getValue === 'function') {
    return await ctx.getValue(key, ctx);
  }
  return null;
}

function isMultiSelectComponent (type: string) {
  return ['MultiSelect', 'MultiDept', 'MultiUser', 'OwnerDept'].includes(type);
}

function convertAdressComputeValue (val: any, showDetail: boolean) {
  const code = val && val.code;
  if (!code) {
    return null;
  }
  if (showDetail) {
    return {
      code,
      detail: val.detail || '',
    };
  }
  return { code };
}
