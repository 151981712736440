import { Formula } from '../type';
import { generateError } from '../error';
import {
  validateSubFromSame,
  validateSubParamNull,
  validateParamNull,
  formatParamValue,
  parseFormulaFieldIds,
  toDecimal,
  RELEVANCE_HANDLE,
} from '../utils';
import { CONDITION_KEY } from '../condition-utils';

const SUMIFS: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'SUMIFS',
  type: Formula.IdentifierType.FUNC,
  outputType: {
    paramType: Formula.ParamType.NUMBER,
    sourceType: Formula.SourceType.EDIT,
  },
  validate (identifier, params, compiler) {
    compiler._preValidate(identifier, params, RELEVANCE_HANDLE);
    if (params.length < 2) {
      throw generateError(`函数 ${identifier.name}() 需要2个参数，但传入了${params.length}个参数`);
    }

    const [param, condition] = params;
    if (param.paramType !== Formula.ParamType.NUMBER ||
      param.sourceType !== Formula.SourceType.FIELD ||
        (param.sourceType === Formula.SourceType.FIELD &&
          (param as Formula.FieldParam).fieldType !== Formula.FieldType.SUBLIST)) {
      throw generateError(`函数 ${identifier.name}() 第一个参数需要是明细表中的数字类型字段`);
    }

    if (condition.sourceType !== Formula.SourceType.CONDITION) {
      throw generateError(`函数 ${identifier.name}() 第二个参数需要是函数 ${CONDITION_KEY}()`);
    }

    const conditionParam = condition.value;
    const fieldIds = parseFormulaFieldIds(conditionParam);
    const sublistFieldIds = fieldIds.filter(fieldId => /[^.^\s]+\.[^.^\s]+/gm.test(fieldId));
    if (!validateSubFromSame([
      param,
      ...sublistFieldIds.map(fieldId => compiler._getField(fieldId))
        .filter(fieldParam => fieldParam.fieldType === Formula.FieldType.SUBLIST),
    ] as Formula.FieldParam[],
    )) {
      throw generateError(`函数 ${identifier.name}() 参数不支持不同明细表的字段`);
    }

    const internalCompiler = compiler._internalCompiler();
    const { err } = internalCompiler.validate((condition as Formula.ConditionParam).formula,
      Object.values(compiler.fields), null, compiler._fieldId, { onlyCondition: true });
    if (err) {
      throw err;
    }
    return identifier.outputType;
  },
  calculate (identifier, params, compiler) {
    const [param, condition] = params;
    const conditionFormula = (condition as Formula.ConditionParam).formula;
    let candidate = [];
    const internalCompiler = compiler._internalCompiler();
    const fieldIds = parseFormulaFieldIds(conditionFormula);
    const sublistFieldIds = fieldIds.filter(fieldId => /[^.^\s]+\.[^.^\s]+/gm.test(fieldId));
    const checkNull = validateSubParamNull(param);
    if (!checkNull) {
      candidate = (param as Formula.FieldParam).values.map(_value => ({
        ...param,
        value: _value,
      })).filter((_param, index) => {
        if (validateParamNull(_param)) {
          return false;
        }
        let calc: boolean | Promise<boolean> = false;
        try {
          const fields = Object.values(compiler.fields).map(field => {
            const temp = {
              value: field.value,
            };
            if (field.fieldType === Formula.FieldType.SUBLIST && sublistFieldIds.includes(field.id)) {
              temp.value = field.values[index];
            }
            return {
              ...field,
              ...temp,
            };
          });
          const res = internalCompiler.calculate(conditionFormula, fields, { onlyCondition: true });
          if (res instanceof Promise) {
            calc = res.then(val => typeof val.result === 'boolean' ? val.result : false);
          } else {
            calc = typeof res.result === 'boolean' ? res.result : false;
          }
        } catch (e) {
          console.log(e);
        }
        return calc;
      }).map(_param => formatParamValue(_param).value);
    }
    return {
      ...identifier.outputType,
      value: candidate.reduce((count, num) => num.plus(count), toDecimal(0)),
    };
  },
};

export default SUMIFS;
