
import LayerManager from './manager';
import Layer from './layer';
import { globalStorage } from '@lateinos/global-provider';
import { createHotkeys } from './hotkeys';
import * as LayerMixin from './mixin';

const layerManagerKey = '_LayerManager';

function getLayerManager () {
  let layerManager = globalStorage.get(layerManagerKey) as LayerManager;
  if (!layerManager) {
    layerManager = new LayerManager();
    globalStorage.set(layerManagerKey, layerManager);
  }
  return layerManager;
}

export {
  getLayerManager,
  LayerManager,
  Layer,
  LayerMixin,
  createHotkeys,
}
