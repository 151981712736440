import { Formula } from '../type';
import { validateEmpty } from '../utils';
import { generateError } from '../error';

const ISBLANK: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'ISBLANK',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.ANY,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.BOOL,
    sourceType: Formula.SourceType.EDIT,
  },
  validate (identifier, params, compiler) {
    const outputType = compiler._funcValidate(identifier, params);
    const [param] = params;
    if (param.sourceType !== Formula.SourceType.FIELD) {
      throw generateError(`函数 ${identifier.name}() 要求参数是字段`);
    }
    return outputType;
  },
  calculate: (identifier, params) => {
    const [param] = params;
    const value = validateEmpty(param.value);
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default ISBLANK;
