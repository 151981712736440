import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import FormView from '../views/form-view.vue';
import SubmitResult from '../views/submit-result.vue';
import { getRealProcessEnv } from '@lcos-kernel/chicken-library';

// const FormView = () => import(/* webpackChunkName: "pc" */'../views/form-view.vue');
// const PcBizdata = () => import(/* webpackChunkName: "pc" */'../views/bizdata-share.vue');
// const SubmitResult = () => import(/* webpackChunkName: "pc" */'../views/submit-result.vue');
const MobileForm = () => import(/* webpackChunkName: "mobile" */'../mobile/mobile-form-share.vue');
const MobileResult = () => import(/* webpackChunkName: "mobile" */'../mobile/mobile-form-share-result.vue');
const MobileBizdata = () => import(/* webpackChunkName: "mobile" */'../mobile/mobile-bizdata-share.vue');
const MobileSignature = () => import(/* webpackChunkName: "mobile" */'../mobile/mobile-signature.vue');
Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/form/share/:shortCode',
    name: 'FormShare',
    component: FormView
  },
  {
    path: '/form/result',
    name: 'SubmitResult',
    component: SubmitResult
  },
  {
    path: '/m/form/share/:shortCode',
    name: 'FormShareMobile',
    component: MobileForm
  },
  {
    path: '/m/form/result',
    name: 'MobileSubmitResult',
    component: MobileResult
  },
  {
    path: '/m/bizdata/:shortCode',
    name: 'FormShareMobile',
    component: MobileBizdata,
    props: route => ({ query: route.query.sign })
  },
  {
    path: '/m/signature/:taskId',
    name: 'FormShareMobile',
    component: MobileSignature,
  },
  // {
  //   path: '/bizdata/:shortCode',
  //   name: 'FormShareMobile',
  //   component: PcBizdata,
  //   props: route => ({ query: route.query.sign })
  // },
];

export default new VueRouter({
  mode: 'history',
  base: getRealProcessEnv(process.env.BASE_URL) || '/',
  routes,
});
