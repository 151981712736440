import { Formula } from '../type';
import { getNumberValue } from '../utils';

const TIME: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'TIME',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.NUMBER,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.DATE,
    sourceType: Formula.SourceType.EDIT,
  },
  calculate: (identifier, params) => {
    const [_param] = params;
    let value = new Date(getNumberValue(_param));
    if (value.toString() === 'Invalid Date') {
      value = null;
    }
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default TIME;
