export type ThemeColor =
  | 'LightPurple'
  | 'GoldenPurple'
  | 'DustRed'
  | 'VolcanoOrange'
  | 'GeekBlue'
  | 'Cyan'
  | 'BluishGreen'
  | 'DaybreakBlue'
  | 'Purple'
  | 'Amaranth'
  | 'Red'
  | 'Orange'
  | 'Blue'
  | 'LightGreen'
  | 'Grass'
  | 'Yellow';

export type MapStructure = {
  color: string;
  name: string;
  code: ThemeColorEnum;
  start: string;
  end: string; // 背景主题色
  iconBgFrom?: string;
  iconBgTo?: string; // icon展示色
  startTabBtn: string;
  endTabBtn: string; // 渐变主题tab按钮配色
};

export enum ThemeColorEnum {
  LightPurple,
  GoldenPurple,
  DustRed,
  VolcanoOrange,
  GeekBlue,
  Cyan,
  BluishGreen,
  DaybreakBlue,
  Purple,
  Amaranth,
  Red,
  Orange,
  Blue,
  LightGreen,
  Grass,
  Yellow
}

export const gradientColorsMap = {
  [ThemeColorEnum.LightPurple]: {
    code: 0,
    color: 'rgba(100, 123, 252, 1)',
    shadow: '0 2px 5px rgba(100, 123, 252, .7)',
    name: '藤萝蔓蔓',
    start: 'rgba(113, 83, 163, 1)',
    end: 'rgba(28, 77, 194, 1)',
    startTabBtn: 'rgb(112, 83, 164, 1)',
    endTabBtn: 'rgb(39, 78, 190, 1)',
    iconBgFrom: 'rgba(177, 148, 227, 1)',
    iconBgTo: 'rgba(30, 77, 194, 1)',
  },
  [ThemeColorEnum.GoldenPurple]: {
    code: 1,
    color: 'rgba(146, 102, 251, 1)',
    shadow: '0 2px 5px rgba(146, 102, 251, .7)',
    name: '紫露星云',
    start: 'rgba(138, 6, 98, 1)',
    end: 'rgba(79, 8, 132, 1)',
    startTabBtn: 'rgb(137, 7, 99, 1)',
    endTabBtn: 'rgb(86, 6, 128, 1)',
    iconBgFrom: 'rgba(182, 69, 149, 1)',
    iconBgTo: 'rgba(79, 8, 132, 1)',
  },
  [ThemeColorEnum.DustRed]: {
    code: 2,
    color: 'rgba(244, 92, 77, 1)',
    shadow: '0 2px 5px rgba(244, 92, 77, .7)',
    name: '桃之夭夭',
    start: 'rgba(238, 100, 154, 1)',
    end: 'rgba(240, 103, 103, 1)',
    startTabBtn: 'rgb(238, 100, 153, 1)',
    endTabBtn: 'rgb(240, 103, 109, 1)',
    iconBgFrom: 'rgba(255, 160, 196, 1)',
    iconBgTo: 'rgba(240, 72, 72, 1)',
  },
  [ThemeColorEnum.VolcanoOrange]: {
    code: 3,
    color: 'rgba(254, 176, 63, 1)',
    shadow: '0 2px 5px rgba(254, 176, 63, .7)',
    name: '麦穗之芒',
    start: 'rgba(240, 99, 99, 1)',
    end: 'rgba(255, 167, 49, 1)',
    startTabBtn: 'rgb(240, 100, 98, 1)',
    endTabBtn: 'rgb(253, 159, 58, 1)',
    iconBgFrom: 'rgba(255, 220, 172, 1)',
    iconBgTo: 'rgba(240, 100, 98, 1)',
  },
  [ThemeColorEnum.GeekBlue]: {
    code: 4,
    color: 'rgba(55, 172, 255, 1)',
    shadow: '0 2px 5px rgba(55, 172, 255, .7)',
    name: '天空之镜',
    start: 'rgba(76, 206, 222, 1)',
    end: 'rgba(110, 118, 255, 1)',
    startTabBtn: 'rgb(77, 204, 223, 1)',
    endTabBtn: 'rgb(108, 128, 251, 1)',
    iconBgFrom: 'rgba(142, 212, 255, 1)',
    iconBgTo: 'rgba(109, 119, 254, 1)',
  },
  [ThemeColorEnum.Cyan]: {
    code: 5,
    color: 'rgba(74, 180, 171, 1)',
    shadow: '0 2px 5px rgba(74, 180, 171, .7)',
    name: '千山一碧',
    start: 'rgba(83, 201, 123, 1)',
    end: 'rgba(62, 177, 192, 1)',
    startTabBtn: 'rgb(84, 200, 124, 1)',
    endTabBtn: 'rgb(64, 180, 185, 1)',
    iconBgFrom: 'rgba(177, 248, 202, 1)',
    iconBgTo: 'rgba(50, 171, 187, 1)',
  },
  [ThemeColorEnum.BluishGreen]: {
    code: 6,
    color: 'rgba(146, 102, 251, 1)',
    shadow: '0 2px 5px rgba(146, 102, 251, .7)',
    name: '芳华如梦',
    start: 'rgba(155, 147, 237, 1)',
    end: 'rgba(222, 84, 144, 1)',
    startTabBtn: 'rgb(157, 146, 236, 1)',
    endTabBtn: 'rgb(215, 93, 155, 1)',
    iconBgFrom: 'rgba(214, 210, 255, 1)',
    iconBgTo: 'rgba(221, 85, 145, 1)',
  },
  [ThemeColorEnum.DaybreakBlue]: {
    code: 7,
    color: 'rgba(55, 172, 255, 1)',
    shadow: '0 2px 5px rgba(55, 172, 255, .7)',
    name: '烟如沧海',
    start: 'rgba(0, 160, 138, 1)',
    end: 'rgba(0, 81, 207, 1)',
    startTabBtn: 'rgb(0, 151, 147, 1)',
    endTabBtn: 'rgb(0, 82, 206, 1)',
    iconBgFrom: 'rgba(70, 177, 232, 1)',
    iconBgTo: 'rgba(0, 82, 206, 1)',
  },
};

export const pureColorsMap = {
  [ThemeColorEnum.Purple]: {
    code: 8,
    color: 'rgba(100, 123, 252, 1)',
    shadow: '0 2px 5px rgba(100, 123, 252, .7)',
    name: '罗兰紫',
    start: 'rgba(100, 123, 252, 1)',
    end: 'rgba(100, 123, 252, 1)',
    startTabBtn: 'rgba(100, 123, 252, 1)',
    endTabBtn: 'rgba(100, 123, 252, 1)',
  },
  [ThemeColorEnum.Amaranth]: {
    code: 9,
    color: 'rgba(146, 102, 251, 1)',
    shadow: '0 2px 5px rgba(146, 102, 251, .7)',
    name: '紫嫣红',
    start: 'rgba(146, 102, 251, 1)',
    end: 'rgba(146, 102, 251, 1)',
    startTabBtn: 'rgba(146, 102, 251, 1)',
    endTabBtn: 'rgba(146, 102, 251, 1)',
  },
  [ThemeColorEnum.Red]: {
    code: 10,
    color: 'rgba(244, 92, 77, 1)',
    shadow: '0 2px 5px rgba(244, 92, 77, .7)',
    name: '松火红',
    start: 'rgba(244, 92, 77, 1)',
    end: 'rgba(244, 92, 77, 1)',
    startTabBtn: 'rgba(244, 92, 77, 1)',
    endTabBtn: 'rgba(244, 92, 77, 1)',
  },
  [ThemeColorEnum.Orange]: {
    code: 11,
    color: 'rgba(244, 111, 47, 1)',
    shadow: '0 2px 5px rgba(244, 111, 47, .7)',
    name: '落日橙',
    start: 'rgba(244, 111, 47, 1)',
    end: 'rgba(244, 111, 47, 1)',
    startTabBtn: 'rgba(244, 111, 47, 1)',
    endTabBtn: 'rgba(244, 111, 47, 1)',
  },
  [ThemeColorEnum.Blue]: {
    code: 12,
    color: 'rgba(55, 172, 255, 1)',
    shadow: '0 2px 5px rgba(55, 172, 255, .7)',
    name: '天青蓝',
    start: 'rgba(55, 172, 255, 1)',
    end: 'rgba(55, 172, 255, 1)',
    startTabBtn: 'rgba(55, 172, 255, 1)',
    endTabBtn: 'rgba(55, 172, 255, 1)',
  },
  [ThemeColorEnum.LightGreen]: {
    code: 13,
    color: 'rgba(74, 180, 171, 1)',
    shadow: '0 2px 5px rgba(74, 180, 171, .7)',
    name: '丹如绿',
    start: 'rgba(74, 180, 171, 1)',
    end: 'rgba(74, 180, 171, 1)',
    startTabBtn: 'rgba(74, 180, 171, 1)',
    endTabBtn: 'rgba(74, 180, 171, 1)',
  },
  [ThemeColorEnum.Grass]: {
    code: 14,
    color: 'rgba(73, 192, 109, 1)',
    shadow: '0 2px 5px rgba(73, 192, 109, .7)',
    name: '露草青',
    start: 'rgba(73, 192, 109, 1)',
    end: 'rgba(73, 192, 109, 1)',
    startTabBtn: 'rgba(73, 192, 109, 1)',
    endTabBtn: 'rgba(73, 192, 109, 1)',
  },
  [ThemeColorEnum.Yellow]: {
    code: 15,
    color: 'rgba(245, 159, 12, 1)',
    shadow: '0 2px 5px rgba(245, 159, 12, .7)',
    name: '蜜桔黄',
    start: 'rgba(245, 159, 12, 1)',
    end: 'rgba(245, 159, 12, 1)',
    startTabBtn: 'rgba(245, 159, 12, 1)',
    endTabBtn: 'rgba(245, 159, 12, 1)',
  },
};

export const colorsMap = Object.assign({}, gradientColorsMap, pureColorsMap);
