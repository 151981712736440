<style lang="less" scoped>
  .form-page-submited {
    margin: auto;
    background: #fff;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    .image-wrap {
      img {
        width: 230px;
      }
    }
    .msg {
      font-size: 24px;
      font-weight: 600;
      color: #121933;
    }
    .action {
      margin-top: 40px;
    }
  }
  .formview-subapp {
  position: relative;
  // top: 0;
  // right: 0;
  // left: 0;
  // bottom: 0;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f1f2f5;
  }
  .share-form {
    background-color: #fff;
    overflow-y: auto;
    padding-bottom: 30px;
  }
  .form-view-footer {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    image {
      height: 24px;
      width: 24px;
    }
    span {
      color: #707481;
      font-size: 12px;
    }
  }
</style>
<template>
  <div
    class="formview-subapp share-form"
    :style="{height: subappContentHeight}"
  >
    <div class="form-page-submited">
      <div v-if="negative" class="image-wrap">
        <img :src="SVGSadFatBoy" />
      </div>
      <div v-else class="image-wrap">
        <img :src="SVGHappyFatBoy" />
      </div>
      <div
        v-if="success === true"
        class="msg"
      >
        {{ successMsg }}
      </div>
      <div v-if="failed" class="msg">
        {{ '提交失败' }}
      </div>
      <div v-if="negative" class="msg">
        出现异常，请联系管理员！
      </div>
      <div v-if="multipleTimes || failed" class="action">
        <h3-button type="default" @click="toSubmitAgain">
          再次填写
        </h3-button>
      </div>
    </div>
    <div class="form-view-footer">
      <img :src="logoImage" :srcset="`${logoImage2X} 2x`" />
      <span>有格提供支持</span>
    </div>
  </div>
</template>
<script>
import { H3Button } from '@lateinos/awesome-ui';

export default {
  name: 'SubmitResult',
  components: {
    H3Button,
  },
  data () {
    return {
      SVGHappyFatBoy: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/happy-fat-boy.svg`,
      SVGSadFatBoy: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/illustration/formview/sad-fat-boy.svg`,
      logoImage: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/logo/logo@1x.png`,
      logoImage2X: `${window.__ENV_STATE__._H3_ASSETS_CDN_URL_}/common-resource/logo/logo@2x.png`,
      success: false,
      failed: false,
      negative: false,
      multipleTimes: false,
      subappContentHeight: 0,
      successMsg: null,
    };
  },
  computed: {
    isSuccess () {
      return this.resultCode === ''; // SUCCESS_CODE;
    },
    isFailed () {
      return this.resultCode === ''; // FAILED_CODE;
    },
    isNegative () {
      return this.resultCode === ''; // NEGATIVE_CODE;
    },
  },
  created () {
    // debugger;
    this.subappContentHeight = window.innerHeight + 'px';
    if (this.$route.query.res === 'true') {
      this.success = true;
    } else if (this.$route.query.res === 'false') {
      this.failed = true;
    }
    if (this.$route.query.isSubmitOnce === '0') {
      this.multipleTimes = true;
    }
    if (this.$route.query.negative === 'true') {
      this.negative = true;
    }
    if (this.$route.query.successMsg === null) {
      this.successMsg = '提交成功，感谢填写！';
    } else {
      this.successMsg = this.$route.query.successMsg;
    }
    this.$emit('load', true);
  },
  methods: {
    toSubmitAgain () {
      this.$router.push({ path: `/form/share/${this.$route.query.url}` });
    },
  },
};
</script>
