import toNumber from 'lodash/toNumber';
import { Formula } from '../type';
import { validateObjectType } from '../utils';
import { generateError } from '../error';

const CHAR: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'CHAR',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.NUMBER,
    sourceType: Formula.SourceType.EDIT,
  }],
  outputType: {
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.EDIT,
  },
  validate (identifier, params, compiler) {
    validateObjectType(identifier, params);
    const outputType = compiler._funcValidate(identifier, params);
    const [param] = params;
    if (!isNaN(param.value)) {
      const unary = param.unary || 1;
      const value = toNumber(param.value) * unary;
      if (![9, 10, 34, 39, 92].includes(value)) {
        throw generateError(`函数 ${identifier.name}() 第二个参数只支持9, 10, 34, 39, 92`);
      }
    }
    return outputType;
  },
  calculate: (identifier, params, compiler) => {
    const _params = compiler._pretreatment(identifier, params);
    const [_param] = _params;
    const _value = !isNaN(_param.value) ? toNumber(_param.value) * (_param.unary || 1) : _param.value;
    const value = String.fromCharCode(_value);
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default CHAR;
