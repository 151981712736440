import Stack from './stack';
import math from './function';
// import NP from 'number-precision';

const NP = {
  times: function (n, m) {
    return n * m;
  },
  minus: function (n, m) {
    return n - m;
  },
  plus: function (n, m) {
    return n + m;
  },
  divide: function (n, m) {
    return n / m;
  },
};
let $placeholderId = 1;
// 运算符
const reg = /(!==|!=|===|==|&&|\|\||<=|<|>=|>|\+|-|\*|%|\/|\(|\))/;
// 用于判断表达式是否包含函数计算
const funcReg = /\$\.fn\.(\w*?)\((.*)\)/;
const buildInFunc = { ...math };

function funcCallFactory (funcName, context, params) {
  let ret = '';
  try {
    if (funcName in buildInFunc) {
      ret = buildInFunc[funcName].call(context, ...params);
    }
  } catch (e) {
    console.warn(`调用函数： ${funcName} 出错；${JSON.stringify(e)}`);
  }
  return ret;
}
// 占位属性
function generatePropPlaceholder (ctx = {}) {
  while ('_' + $placeholderId + '_' in ctx) {
    $placeholderId++;
  }
  return '_' + $placeholderId + '_';
}
function operateLevel (optr) {
  switch (optr) {
    case '||':
      return 0;
    case '&&':
      return 1;
    case '>':
    case '>=':
    case '<':
    case '<=':
    case '==':
    case '===':
    case '!==':
    case '!=':
      return 2;
    case '+':
    case '-':
      return 3;
    case '*':
    case '%':
    case '/':
      return 4;
    default:
      return 0;
  }
}
// 拆分表达式
// 操作符(暂时支持二元操作符)：
// ||, &&, ==, ===, !=, !==,
//  <, <=, >, >=
// +, -, *, /, %
// 表达式规范：
// 3.双引号不能嵌套
function splitExpression (expressionString) {
  let expression = expressionString;
  // 字符串以其他操作符开头，暂时不考虑
  // 加减号开头算是正常情形
  // 抛弃掉，这里不考虑数字字符串转数字
  if (expression.startsWith('+')) {
    expression = expression.slice(1);
    // 第一个字符位减号，在前面添加一个 0
  } else if (expression.startsWith('-')) {
    expression = '0' + expression;
  }
  // 拆分表达式，并剔除多余的空项
  return expression
    .replace(new RegExp(reg, 'g'), ' $1 ')
    .split(/\s+/)
    .filter(item => {
      return item.length;
    });
}
// 替换双引号中的内容
// 被双引号包裹的内容为字符串
// 无法正确识别嵌套双引号
function replaceDoubleQuotesContent (expression, context) {
  const newExpression = expression.replace(/".*?"/g, function (matchedStr) {
    const key = generatePropPlaceholder(context);
    context[key] = matchedStr.slice(1, -1);
    return '{' + key + '}';
  });
  return newExpression;
}
function isEmpty (val) {
  return val === '' || val === null || val === undefined;
}
function evalExpression (op1, oprt, op2) {
  let num1, num2;
  // 前面为了聚合函数，参数传递的都是数组
  //
  if (op1 instanceof Array) {
    num1 = op1[0];
  } else {
    num1 = op1;
  }
  if (op2 instanceof Array) {
    num2 = op2[0];
  } else {
    num2 = op2;
  }
  try {
    switch (oprt) {
      case '||':
        return num1 || num2;
      case '&&':
        return num1 && num2;
      case '>':
        return num1 > num2;
      case '>=':
        return num1 >= num2;
      case '<':
        return num1 < num2;
      case '<=':
        return num1 <= num2;
      case '==': /* return num1 == num2; */
      case '===':
        return num1 === num2;
      case '!=': /* return num1 != num2; */
      case '!==':
        return num1 !== num2;
      case '+':
        if (typeof num1 === 'number' && typeof num2 === 'number') {
          return NP.plus(num1, num2);
        }
        return num1 + num2;
      case '-':
        // 两个都没有值，则不做计算
        // 如：阻止两个空串相减等于 0
        // 如果是数字控件，其初始值会被转化为 0，不会被这里阻止
        if (isEmpty(num1) && isEmpty(num2)) {
          return '';
        }
        return NP.minus(num1, num2);
      case '*':
        return NP.times(num1, num2);
      case '/':
        // 除数为空不做计算，并返回 0
        if (isEmpty(num2)) {
          return 0;
        }
        if (num2 === 0 || num2 === '0') {
          console.log('除数不能为0');
          return 0;
        }
        return NP.divide(num1, num2);
      case '%':
        return num1 % num2;
      default:
        return '';
    }
  } catch (e) {
    console.log(e);
    return '';
  }
}
function getValue (key, ctx = {}) {
  // 被大括号包裹的字符{adds}作为变量
  if (key.startsWith('{') && key.endsWith('}')) {
    const attr = key.slice(1, -1);
    return ctx[attr];
  } else {
    const num = Number(key);
    // 数字
    // 注意：数字控件没有区分实际值和展示值
    // 如果控件使用的是【千隔符】表示法，不能正确被识别
    // 科学计数可以被识别
    if (!isNaN(num)) {
      return num;
    } else if (key === 'true') {
      return true;
    } else if (key === 'false') {
      return false;
    } else if (key === 'null') {
      return '';
    } else {
      return key;
    }
  }
}
// 解析表达式;将表达式拆分，输出操作符后缀的数组
function parseExpression (expression) {
  const items = splitExpression(expression);
  const outItems = []; // 输出队列
  const stack = new Stack(); // 保存运算符的栈

  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (!reg.test(item)) {
      // 操作数直接输出
      outItems.push(item);
      continue;
    }
    if (item === '(') {
      // 左括号直接入栈
      stack.push(item);
    } else if (item === ')') {
      // 右括号弹出栈中运算符，直到遇到左括号
      while (stack.store.length > 0 && stack.store[stack.top - 1] !== '(') {
        outItems.push(stack.pop());
      }
      // 弹出左括号
      stack.pop();
    } else {
      const curLevel = operateLevel(item);
      // 不是左括号，且栈不为空，比较优先级
      // 如果栈顶运算符优先级大于等当前优先级
      // 先出栈再入栈
      if (stack.store.length !== 0 && stack.store[stack.top - 1] !== '(') {
        let topOprtLevel = operateLevel(stack.store[stack.top - 1]);
        while (stack.store.length > 0 && topOprtLevel >= curLevel) {
          outItems.push(stack.pop());
          topOprtLevel = operateLevel(stack.store[stack.top - 1]);
        }
      }
      // 等于0或者栈顶是左括号，直接入栈
      stack.push(item);
    }
  }
  while (stack.store.length > 0) {
    outItems.push(stack.pop());
  }
  return outItems;
}
// 拆分内置函数的参数
//  $.fn.max({a}, {b}, {c + d});
// 这里现在已经没有嵌套函数了
// 可以直接按逗号分隔 param.split(',')
function splitParams (param) {
  if (param.indexOf(',') === -1) {
    return [param];
  }
  const stack = [];
  let count = 0;
  let pointer = 0;
  let lastSplitPoint = 0;
  while (true) {
    if (pointer >= param.length) {
      break;
    }
    const letter = param[pointer];
    if (letter === '(') {
      count++;
    } else if (letter === ')') {
      count--;
    } else if (letter === ',' && count <= 0) {
      stack.push(param.slice(lastSplitPoint, pointer));
      lastSplitPoint = pointer + 1;
    }
    pointer++;
  }
  // 最后一段尾部参数
  stack.push(param.slice(lastSplitPoint));
  return stack;
}
function getFuncResult (funcName, params, ctx = {}) {
  const exprs = splitParams(params);
  const exprsResult = [];
  for (let i = 0; i < exprs.length; i++) {
    const calcResult = $$calcExpression(exprs[i], ctx);
    exprsResult.push(calcResult);
  }
  const key = generatePropPlaceholder(ctx);
  ctx[key] = funcCallFactory(funcName, ctx, exprsResult.flat());
  return '{' + key + '}';
}
function calculateFn (expression, context) {
  // 拆解函数
  // 例如：$.fn.AVG(1,2,3,4) + 1 + 2 + 3 + $.fn.MAX(4, 5, 6) + $.fn.SIN(1 + $.fn.COS($.fn.MIN()))
  // 循环从右往左、从里往外拆，直到拆完
  let expr = expression;
  // 这个正则表达式不是很完善。应该把下面这一段重写
  // ......[^$]*?......;(中括号里不够严谨)
  const funcSplitReg = /\$\.fn\.(\w*?)\(([^$]*?)\)/;
  let ret = null;
  let index = -1; // 匹配的子字符串起始位置
  let matchString = ''; // 匹配的子字符
  let funcName = ''; // 拆解出来的函数名称
  let params = ''; // 参数
  // 函数执行后，所存储的属性。
  // 例如：函数计算结果为 123; ctx['placeholder'] = 123; funcValue = '{placeholder}'
  // 用属性替换表达式中的函数
  let funcValue = '';
  while (true) {
    ret = funcSplitReg.exec(expr);
    if (ret === null) {
      break;
    }
    index = ret.index;
    matchString = ret[0];
    funcName = ret[1];
    params = ret[2];
    funcValue = getFuncResult(funcName, params, context);
    expr =
      expr.slice(0, index) + funcValue + expr.slice(index + matchString.length);
  }
  return expr;
}

// 求值表达式，不包含函数计算
function $$calcExpression (expression, ctx) {
  const items = parseExpression(expression);
  let firstNum = 0; // 第一个操作数
  let secondNum = 0; // 第二个操作数
  const stack = new Stack();
  for (let i = 0; i < items.length; i += 1) {
    if (!reg.test(items[i])) {
      stack.push(getValue(items[i], ctx));
    } else {
      secondNum = stack.pop();
      firstNum = stack.pop();
      stack.push(evalExpression(firstNum, items[i], secondNum));
    }
  }
  return stack.store[0];
}
// 计算表达式
function calcExpression (expressionString, ctx = {}) {
  // 替换双引号中的内容
  let expression = replaceDoubleQuotesContent(expressionString, ctx);
  // 对表达式中的函数求值，并用属性替换
  if (funcReg.test(expression)) {
    expression = calculateFn(expression, ctx);
  }
  return $$calcExpression(expression, ctx);
}
/**
 * @author 李周
 * @param
 * 计算表达式
 * 例如：1 + 1, 1 + 'a', 'a' + b, {asd} + 1
 * 包含在大括号中({})的字符作为 context(第二个参数) 的属性
 */
export default {
  calc: calcExpression,
};
