import { FormulaCompiler } from './compiler';
export namespace Formula {
  export type Identifier<T = IdentifierType | IdentifierType> = {
    name: string;
    type: T;
    inputType?: Param[];
    parameterless?: boolean;
    outputType?: Param;
    validate?: (identifier: Identifier, params: Param[], compiler: FormulaCompiler)
      => Param | FieldParam | KeywordParam;
    calculate: (identifier: Identifier, params: Param[], compiler: FormulaCompiler) => Param | Promise<Param>;
  };

  export interface Param {
    paramType: ParamType;
    sourceType: SourceType;
    value?: any;
    isNull?: boolean; // 字段默认值
    unary?: number;
    formulaFormat?: (value: any, type: ParamType) => any;
  };

  export interface KeywordParam extends Param {
    name: string;
  };

  export interface FieldParam extends Param {
    id: string;
    name: string;
    fieldType: FieldType;
    values?: any[];
    isCompatible?: boolean;
    formula?: string;
  };

  export interface ConditionParam extends Param {
    id: string;
    formula: string;
  };

  export interface ConstantParam extends Param {
    id: string;
  }

  export enum FieldType {
    BASIC = 'basic',
    SUBLIST = 'sublist',
    RELEVANCE = 'relevance',
    EXPRESSION = 'expression', // (LOOKUP、ROLLUP)
  }

  export enum SourceType {
    FIELD = 'field',
    EDIT = 'edit',
    CONDITION = 'condition',
    CONSTANT = 'constant',
    ANY = 'any',
  }

  export enum ConstType {
    NULL = 'null',
    TRUE = 'true',
    FALSE = 'false',
  }

  export enum ParamType {
    TEXT = 'text',
    MULTITEXT = 'multitext',
    NUMBER = 'number',
    DATE = 'date',
    BOOL = 'bool',
    ANY = 'any',
    VOID = 'void',
    ADDRESS = 'address',
    SINGLEUSER = 'singleuser',
    SIGNLEDEPT = 'singledept',
    MULTIUSER = 'multiuser',
    MULTIDEPT = 'multidept',
    MultiSelect = 'multiselect',
    Relationship = 'relationship',
    Attachment = 'attachment',
    // 构造复合类型
  }

  /**
   * 复合类型
   */
  export enum CompositeParamType {
    CONTAINS = 'text,multitext,address,singleuser,singledept,multiuser,multidept,multiselect',
    LOOKUP = 'text,number,date,address,singleuser,singledept,multiuser,multidept,multiselect,relationship',
    ROLLUP = 'text,number,date,address,singleuser,singledept,multiuser,multidept,multiselect,relationship',
  };

  export enum IdentifierType {
    FUNC = 'func',
    OPERATOR = 'operator',
  }

  export enum TokenType {
    KEYWORD = 'keyword',
    FIELD = 'field',
  }

  export interface TokenInfo {
    name: string;
    type: IdentifierType | TokenType;
  };
  export interface IdentifierTokenInfo extends TokenInfo {
    parameterless: boolean;
  };

  export interface FieldTokenInfo extends TokenInfo {
    id: string;
    paramType: ParamType;
    fieldType: FieldType;
  };

  export interface Field {
    id: string;
    name: string;
    sourceType: TokenType.FIELD;
    paramType: ParamType;
    fieldType: FieldType;
    value?: string;
    formula?: string;
    formulaFormat?(value: any, type: ParamType): any;
    // color?: string; // 字体颜色
    // bgColor?: string; // 背景颜色
  }

  // 人员部门类型
  export interface UnitType {
    id: string;
    name: string;
    avatar?: string;
  }

}
