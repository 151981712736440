import { generateError } from '../error';
import { Formula } from '../type';
import { uniq, toNumber } from 'lodash';
const USERDEPTS = {
    name: 'USERDEPTS',
    type: Formula.IdentifierType.FUNC,
    outputType: {
        paramType: Formula.ParamType.MULTIDEPT,
        sourceType: Formula.SourceType.ANY,
    },
    validate(identifier, params) {
        if (params.length < 1) {
            throw generateError(`函数 ${identifier.name}() 至少需要1个参数，但传入了${params.length}个参数`);
        }
        if (params.length > 2) {
            throw generateError(`函数 ${identifier.name}() 最多需要2个参数，但传入了${params.length}个参数`);
        }
        const [param, level] = params;
        if (![Formula.ParamType.SINGLEUSER, Formula.ParamType.MULTIUSER].includes(param.paramType)) {
            throw generateError(`函数 ${identifier.name}() 要求第一个参数是人员单选或人员多选`);
        }
        if (level && (level.paramType !== Formula.ParamType.NUMBER ||
            level.sourceType !== Formula.SourceType.EDIT ||
            isNaN(level.value) ||
            toNumber(level.value) * (level.unary || 1) < 0 ||
            (typeof level.value.isInteger === 'function' && !level.value.isInteger()))) {
            throw generateError(`函数 ${identifier.name}() 要求第二个参数是等于或大于0的整数`);
        }
        return identifier.outputType;
    },
    calculate: (identifier, params, compiler) => {
        const value = null;
        const [param, level] = params;
        const result = { ...identifier.outputType, value };
        const ids = Array.isArray(param.value) ? param.value.map((item) => item.id) : [param.value.id];
        const _level = level ? level.value?.toNumber() : level;
        const _ids = uniq(ids);
        if (_ids[0]) {
            return compiler._externalMethods.userDepts(_ids, _level).then((depts) => {
                if (!depts) {
                    return result;
                }
                result.value = depts;
                return result;
            });
        }
        return result;
    },
};
export default USERDEPTS;
