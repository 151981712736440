/**
 * @description 快捷键命名空间管理
 * @author 徐凌志 gss
 * @example
 * // 1. 先创建Shortcuts实例，可挂载到任何地方（Window、Vue组件等，但是得确保调用一致性）
 * window.shortcuts = new Shortcuts()
 * // 2. 使用 register 方法可以给对应的调用层绑定对应的调用函数， cancellation 可以解绑对应的调用层
 *  绑定的调用函数的形参 会有对应按键触发信息
 *  interface NsCallback {
      (namespace: string, keys: string, e: Event, throughSpaces: Array<string>):void
    }
 *  
 * // 3. needThroughSpaces 可以穿透对象的命名空间，来实现不同调用层按键共享的作用
 *
 * // 4. 如果不想影响其他页面，请在合适的时候销毁 Shortcuts 实例或者调用 destroy 解绑事件
 * window.shortcuts.destroy();
 * delete window.shortcuts;
 * // 如果进入了新的弹层，请务必将当前的层级调整至合适命名空间！
 * // 看各方法注释可了解更多，如发现bug可直接钉钉找我
 */

type namespaceT = string | symbol;

interface EventBinderFunc extends Function {
  excludeFunc?: Function;
}

interface NsCallback {
  (namespace: namespaceT, keys: string, e: Event, throughSpaces: Array<namespaceT>):void;
}

interface RegisterRN {
  throughToSpaces(namespaces: Array<namespaceT>): void;
  cancellation(): void;
}

export const SHORTCUTS_H3YUN = Symbol.for('_SHORTCUTS_H3YUN_');
let shortcuts: ShortcutsManager;

class ShortcutsManager {

  // 按键映射
  private shortcutsMap: {
    [keyName: string]: string;
  }

  // 调用层和处理函数之间的映射
  private namespaceMap: Map<namespaceT, NsCallback> = new Map();

  // 调用层与穿透空间的映射
  private throughSpaceMap: Map<namespaceT, Set<namespaceT>> = new Map();

  // 按下按键Set
  private pressKeyCodesSet: Set<string> = new Set<string>();

  // 功能按键 兼容苹果 
  private featKeyCodeSet: Set<string> = new Set<string>();

  // 处理过的功能按键
  private readonly unifyFeatKeys: string[] = ['ctrl', 'shift', 'alt'];

  // 不同调用层事件表
  private eventsMap: {
    [at: string]: {
      [eventName: string]: EventBinderFunc[];
    };
  } = {
    global: {},
  };

  // 阻止默认事件
  private prevent: boolean = false;

  // 开启console.log标识
  private logging: boolean = false;

  // 重置的快捷键的定时器
  private _resetTimer: any = null;

  // 调用层命名空间栈
  namespaceStack: namespaceT[] = [];

  // 当前命名空间
  get namespace (): namespaceT {
    const length = this.namespaceStack.length;
    return this.namespaceStack[length - 1];
  }
  // 获取穿透的命名空间
  get throughSpaces (): Array<namespaceT> {
    const throughSpace = this.throughSpaceMap.get(this.namespace);
    return throughSpace ? Array.from(throughSpace) : [];
  }

  constructor () {
    // 按键映射map
    this.shortcutsMap = this._isMacOS() ? macShortCutsMap : winShortCutsMap;
    Object.assign(this.shortcutsMap, globalShortCutsMap);

    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.keyUpHandler = this.keyUpHandler.bind(this);
    // 排除在mac系统下control组合件的问题
    // if (this._isMacOS()) {
    //   delete this.shortcutsMap.ControlLeft;
    //   delete this.shortcutsMap.ControlRight;
    // }

    this.bindingEvent();
  }

  /**
   * @description 绑定事件
   */
  private bindingEvent () {
    document.addEventListener('keydown', this.keyDownHandler, true);
    document.addEventListener('keyup', this.keyUpHandler, true);
  }

  /**
   * @description 获取元素类型
   * @param {any} obj 传入的元素
   * @returns {string} 元素类型
   */
  private _getType (obj): string {
    const class2type = {};
    for (let i = 0, len = typeNameStr.length; i < len; i++) {
      const item = typeNameStr[i];
      class2type['[object ' + item + ']'] = item.toLowerCase();
    }
    if (typeof obj === 'object' || typeof obj === 'function') {
      if (obj.nodeType && obj.nodeType === 1) {
        return 'html';
      } else {
        return class2type[Object.prototype.toString.call(obj)];
      }
    } else {
      return typeof obj;
    }
  }

  /**
   * @description 判断是否在mac系统下
   * @returns {boolean}
   */
  _isMacOS (): boolean {
    const av = window.navigator.appVersion;
    if (av.indexOf('Mac') > -1) {
      return true;
    }
    return false;
  }

  /**
   * @description 组合键功能按键过滤
   */

  private featKeyFilter (e, isWindows: boolean) {
    // 在业务中不考虑 mac 的 ctrl 键
    // 在 mac上 metaKey 与 ctrlKey 映射一致，固以下判断不适用
    // if (!e.ctrlKey && this.featKeyCodeSet.has(this.unifyFeatKeys[0])) {
    //   this.featKeyCodeSet.delete(this.unifyFeatKeys[0]);
    // }

    if (!e.shiftKey && this.featKeyCodeSet.has(this.unifyFeatKeys[1])) {
      this.featKeyCodeSet.delete(this.unifyFeatKeys[1]);
    }

    if (!e.altKey && this.featKeyCodeSet.has(this.unifyFeatKeys[2])) {
      this.featKeyCodeSet.delete(this.unifyFeatKeys[2]);
    }

    const diffKey = isWindows ? e.ctrlKey : e.metaKey;
    if (!diffKey && this.featKeyCodeSet.has(this.unifyFeatKeys[0])) {
      this.featKeyCodeSet.delete(this.unifyFeatKeys[0]);
    }

  }

  /**
   * @description 键盘按下处理函数
   */
  private keyDownHandler (e) {
    if (this.prevent) {
      e.preventDefault();
    }
    this._resetPressKeyCodesSetTimer();
    const eventObj = e;
    const { code } = eventObj;
    const isMac = this._isMacOS();
    const unifyKey = this.shortcutsMap[code] || code;
    if (this.unifyFeatKeys.includes(unifyKey)) {
      this.featKeyCodeSet.add(unifyKey);
    } else {
      if (isMac) {
        if (unifyKey === null) return; // mac ctrl 不合成
          this.pressKeyCodesSet.clear();
          this.pressKeyCodesSet.add(unifyKey);
      } else {
        this.pressKeyCodesSet.add(unifyKey);
      }
    }
    this.featKeyFilter(e, !isMac);
    this.pressKeyCodesSet = new Set(Array.from(this.featKeyCodeSet).concat(Array.from(this.pressKeyCodesSet)));

    if (this.logging) {
      console.log(
        `%c You have pressed:
        ${Array.from(this.pressKeyCodesSet)
          .map(k => k)
          .join(' ')}
      `,
        'font-size: 14px; color: green;',
      );
    }

    const keyCodes = Array.from(this.pressKeyCodesSet).join('|');
    
    this.namespaceMap.forEach((fn, key) => {
      if (this._getType(fn) === 'function') {
        fn(this.namespace, keyCodes, eventObj, this.throughSpaces)
      } else {
        if (this.logging) {
          console.error(
            '该调用层：'
            + key.toString()
            + '注册的并不是一个函数。'
          );
        }
      }
    });
    
  }

  /**
   * @description 定时清除所有要合成的快捷键 防止某些情况keyUp无法触发
   */
  private _resetPressKeyCodesSetTimer () {
    clearTimeout(this._resetTimer);
    this._resetTimer = setTimeout(() => {
      this.pressKeyCodesSet.clear();
    }, 1000);
  }

  /**
   * @description 键盘松开处理函数
   */
  private keyUpHandler (e) {
    this._resetPressKeyCodesSetTimer();
    const code = this.shortcutsMap[e.code] || e.code;
    const logCode = this._isMacOS() ? Array.from(this.pressKeyCodesSet) : code;
    if (this._isMacOS()) {
      this.pressKeyCodesSet.clear();
    } else {
      this.pressKeyCodesSet.delete(code);
    }
    if (this.logging) {
      console.log(
        `%c You have loosed:
        ${logCode}
      `,
        'font-size: 14px; color: red;',
      );
    }
  }

  /**
   * @description 注册对应的调用层
   * @params {string | symbol} namespace 调用层名称
   * @params {NsCallback} callback 回调内部可以自定义按键的处理程序
   */
  register (namespace: namespaceT, callback: NsCallback) {
    if (!this.namespaceMap.has(namespace)) {
      this.at(namespace);
      this.namespaceMap.set(namespace, callback);
    } else {
      if (this.logging) {
        console.warn(
         '该调用层：' + namespace.toString() + ' 已存在，无法重复注册！'
        );
      }
    }
  }

  /**
   * @description 解绑对应的调用层
   * @params {string | symbol} namespace 调用层名称
   */
  cancellation (namespace: namespaceT) {
    if (this.namespaceMap.has(namespace)) {
      this.removeNamespace(namespace);
      this.namespaceMap.delete(namespace);
      this.throughSpaceMap.delete(namespace);
    } else {
      console.warn(
        '该调用层：' + namespace.toString() + ' 并未注册！'
      )
    }
  }

  /**
   * @description 移除指定的namespace
   * @params {string | symbol} namespace 调用层名称
   */
  private removeNamespace (namespace: namespaceT) {
    const index = this.namespaceStack.indexOf(namespace);
    if (index > -1) {
      this.namespaceStack.splice(index, 1);
    }
  }

   /**
   * @description 穿透对应的调用层
   * @params {namespaceT[]} namespace 穿透的调用层名称
   */
  needThroughSpaces (namespaces: Array<namespaceT>) {
    const spaces = this.throughSpaceMap.get(this.namespace);
    if (spaces) {
      namespaces.forEach((name) => {
        spaces.add(name);    
      });
    } else {
      const set: Set<namespaceT> = new Set();
      namespaces.forEach((name) => {
        set.add(name);    
      });
      this.throughSpaceMap.set(this.namespace, set);
    }
  }

  
  /**
   * @description 将元素与对应的调用层绑定，便于同级调用层切换，解绑是挂在binding上的
   * @params {string} selector 元素选择器
   * @param {string} namespace 调用层名称
   * @param {boolean} isToggle 绑定时是否切换namespace
   * @param {string} eventName 元素绑定的对应事件
   * @returns {ShortcutsManager} 实例
   */
  // binding (selector: string, namespace: string, isToggle: boolean = true, eventName: string = 'mousedown') {
  //   if (isToggle && namespace) {
  //     this.at(namespace);
  //   }
  //   if (selector && namespace) {
  //     const el = document.querySelector(selector);
  //     if (el) {
  //       const fn = (e) => {
  //         if (this.prevent) {
  //           e.preventDefault();
  //         }
  //         this.at(namespace);
  //       }

  //       el.addEventListener(eventName, fn, true);
  //       (this.binding as any).unbind= () => {
  //         el.removeEventListener(eventName, fn)
  //       };
  //     }
  //   }
  //   return this;
  // }

  /**
   * @description 切换事件调用层，保留其他层级
   * @param {string | symbol} namespace 调用层名称
   * @returns {ShortcutsManager} 实例
   */
  at (namespace: namespaceT) {
    const index = this.namespaceStack.indexOf(namespace);
    if (index > -1) {
      const left = this.namespaceStack.splice(index, 1);
      this.namespaceStack.push(left[0]);
    } else {
      this.namespaceStack.push(namespace);
    }
    if (this.logging) {
      console.log(
        `快捷键当前处于：%c${this.namespace.toString()}`,
        'font-size: 14px; color: blue; font-weight: bold',
      );
    }
    return this;
  }

  /**
   * @description 开启console.log，在控制台打印信息
   * @param {boolean} status 调用状态，不传status多次调用相当于来回切换显示/隐藏
   * @returns {ShortcutsManager} 实例
   */
  log (status?: boolean) {
    if (status && this._getType(status) === 'boolean') {
      this.logging = status;
    } else {
      this.logging = !this.logging;
    }
    return this;
  }

  /**
   * @description 切换是否阻止默认事件
   * @param {boolean} status 调用状态，不传status多次调用相当于来回切换阻止/不阻止
   * @returns {ShortcutsManager} 实例
   */
  togglePrevent (status: boolean = false) {
    if (status && this._getType(status) === 'boolean') {
      this.prevent = status;
    } else {
      this.prevent = !this.prevent;
    }
    return this;
  }

  /**
   * @description 添加当前调用层的事件处理方法
   * @param {string | string[]} keys 触发的按键
   * @param {EventBinderFunc} callback 按键回调函数
   * @param {any} excludeFunc 排除默认事件的特殊方法，可根据 e 在外层决定调用默认事件(返回true)或者不调用（返回false）
   * @param {any} context 上下文
   * @returns {ShortcutsManager} 实例
   */
  
  /**
   * @description 解绑事件
   */
  removeEvent () {
    document.removeEventListener('keydown', this.keyDownHandler, true);
    document.removeEventListener('keyup', this.keyUpHandler, true);
  }

  /**
   * @description 强制销毁绑定信息
   */
  destroy () {
    this.namespaceMap.clear();
    this.throughSpaceMap.clear();
    this.pressKeyCodesSet.clear();
    this.namespaceStack = [];
  }

}

// 按键映射表（添加的按键映射以对象 value 值为准）
const globalShortCutsMap = {
  Control: 'ctrl',
  MetaLeft: 'ctrl',
  MetaRight: 'ctrl',
  Shift: 'shift',
  ShiftLeft: 'shift',
  ShiftRight: 'shift',
  Alt: 'alt',
  AltLeft: 'alt',
  AltRight: 'alt',
  Escape: 'esc',
  Enter: 'enter',
  NumpadEnter: 'enter',
  Tab: 'tab',
  Delete: 'del',
  Backspace: 'backspace',
  Space: 'space',
  ArrowUp: 'up',
  ArrowDown: 'down',
  ArrowLeft: 'left',
  ArrowRight: 'right',
  ContextMenu: 'contextmenu',
  Home: 'home',
  End: 'end',
  PageUp: 'pageup',
  PageDown: 'pagedown',
  Slash: '?',
  Comma: ',',
  Period: '.',
  Equal: '=',
  Minus: '-',
  KeyA: 'a',
  KeyB: 'b',
  KeyC: 'c',
  KeyD: 'd',
  KeyE: 'e',
  KeyF: 'f',
  KeyG: 'g',
  KeyH: 'h',
  KeyI: 'i',
  KeyJ: 'j',
  KeyK: 'k',
  KeyL: 'l',
  KeyM: 'm',
  KeyN: 'n',
  KeyO: 'o',
  KeyP: 'p',
  KeyQ: 'q',
  KeyR: 'r',
  KeyS: 's',
  KeyT: 'T',
  KeyU: 'u',
  KeyV: 'v',
  KeyW: 'w',
  KeyX: 'x',
  KeyY: 'y',
  KeyZ: 'z',
  Digit0: '0',
  Digit1: '1',
  Digit2: '2',
  Digit3: '3',
  Digit4: '4',
  Digit5: '5',
  Digit6: '6',
  Digit7: '7',
  Digit8: '8',
  Digit9: '9',
  F1: 'f1',
  F2: 'f2',
  F3: 'f3',
  F4: 'f4',
  F5: 'f5',
  F6: 'f6',
  F7: 'f7',
  F8: 'f8',
  F9: 'f9',
  F10: 'f10',
  F11: 'f11',
  F12: 'f12',
};
const macShortCutsMap = {
  MetaLeft: 'ctrl',
  MetaRight: 'ctrl',
  ControlLeft: null,
  ControlRight: null,
};
const winShortCutsMap = {
  ControlLeft: 'ctrl',
  ControlRight: 'ctrl',
};

// 元素类型数组
// eslint-disable-next-line
export const typeNameStr = 'Boolean Number String Function Array Date RegExp Object Error Null Undefined Symbol Window'.split(
  ' ',
);

if (typeof window !== 'undefined') {
  if (window[SHORTCUTS_H3YUN]) {
    shortcuts = window[SHORTCUTS_H3YUN]
  } else {
    shortcuts = new ShortcutsManager();
    window[SHORTCUTS_H3YUN] = shortcuts;
  }
}


export function register (namespace: namespaceT, callback: NsCallback): RegisterRN {
  shortcuts.register(namespace, callback);
  return {
    throughToSpaces: function (namespaces: Array<namespaceT>) {
      shortcuts.needThroughSpaces(namespaces);
    },
    cancellation: function () {
      shortcuts.cancellation(namespace);
    }
  };
}

export function cancellation (namespace: namespaceT) {
  return shortcuts.cancellation(namespace);
}

export function at(namespace: namespaceT) {
  return shortcuts.at(namespace);
}

export default shortcuts;
