export const enum ProcessCaseMapping {
  Going = 2, // 进行中
  Finished = 4, // 已完成
  Canceled = 5, // 已取消
  Error = 6, // 异常状态
  Terminate = 7, // 终止
}
const enum ActivityStatusMapping {
  Going = 0, // 运行中
  Finished = 1, // 已完成
  Canceled = 2, // 被取消
  Recalled = 3, // 被撤回
  Rejected = 4, // 被驳回
  Closed = 5, // 已关闭
}
export const enum TaskStatusMapping {
  Wait = 0, // 处理中
  Going = 1, // 处理中
  Approved = 2, // 同意
  Delivered = 3, // 已转交
  Recalled = 4, // 被撤回
  Rejected = 6, // 被驳回
  Votedown = 7, // 被否决
  Canceled = 8, // 被取消
  Invalid = 9, // 无效状态
  Revert = 11, // 撤回
  Terminate = 12, // 终止
}
const enum ActivityTypeMapping {
  Add = 0, // 新增
  Update = 1, // 更新
  Delete = 2, // 删除
}

export const ProcessCaseText = {
  [ProcessCaseMapping.Going]: '处理中',
  [ProcessCaseMapping.Finished]: '已完成',
  [ProcessCaseMapping.Canceled]: '已取消',
  [ProcessCaseMapping.Error]: '异常',
  [ProcessCaseMapping.Terminate]: '已终止',
};

export const ActivityStatusText = {
  [ActivityStatusMapping.Going]: '处理中',
  [ActivityStatusMapping.Finished]: '通过',
  [ActivityStatusMapping.Canceled]: '取消',
  [ActivityStatusMapping.Recalled]: '被驳回',
  [ActivityStatusMapping.Rejected]: '驳回',
  [ActivityStatusMapping.Closed]: '已关闭',
};

export const TaskStatusText = {
  [TaskStatusMapping.Wait]: '处理中',
  [TaskStatusMapping.Going]: '处理中',
  [TaskStatusMapping.Approved]: '通过',
  [TaskStatusMapping.Delivered]: '转交',
  [TaskStatusMapping.Recalled]: '被驳回',
  [TaskStatusMapping.Rejected]: '否决',
  [TaskStatusMapping.Votedown]: '否决',
  [TaskStatusMapping.Canceled]: '取消',
  [TaskStatusMapping.Invalid]: '无效',
  [TaskStatusMapping.Revert]: '撤回',
  [TaskStatusMapping.Terminate]: '终止',
};

export const ProcessCaseColor = {
  [ProcessCaseMapping.Going]: '#FF9D00',
  [ProcessCaseMapping.Finished]: '#0ABF5B',
  [ProcessCaseMapping.Canceled]: '#707481',
  [ProcessCaseMapping.Error]: '#FF3640',
  [ProcessCaseMapping.Terminate]: '#FF3640',
};

export const ActivityStatusColor = {
  [ActivityStatusMapping.Going]: '#FF9D00',
  [ActivityStatusMapping.Finished]: '#0ABF5B',
  [ActivityStatusMapping.Canceled]: '#707481',
  [ActivityStatusMapping.Recalled]: '#FF9D00',
  [ActivityStatusMapping.Rejected]: '#FF9D00',
  [ActivityStatusMapping.Closed]: '#FF3640',
};

export const TaskStatusColor = {
  [TaskStatusMapping.Wait]: '#FF9D00',
  [TaskStatusMapping.Going]: '#FF9D00',
  [TaskStatusMapping.Approved]: '#0ABF5B',
  [TaskStatusMapping.Delivered]: '#315EFB',
  [TaskStatusMapping.Recalled]: '#FF9D00',
  [TaskStatusMapping.Rejected]: '#FF9D00',
  [TaskStatusMapping.Votedown]: '#FF3640',
  [TaskStatusMapping.Canceled]: '#707481',
  [TaskStatusMapping.Invalid]: '#707481',
  [TaskStatusMapping.Revert]: '#707481',
  [TaskStatusMapping.Terminate]: '#FF3640',
};

export const TaskStatusBackColor = {
  [TaskStatusMapping.Wait]: '#FFF9E6',
  [TaskStatusMapping.Going]: '#FFF9E6',
  [TaskStatusMapping.Approved]: '#E6FFED',
  [TaskStatusMapping.Delivered]: '#F0F6FF',
  [TaskStatusMapping.Recalled]: '#FF9D00', //
  [TaskStatusMapping.Rejected]: '#FFF9E6', //
  [TaskStatusMapping.Votedown]: '#FFF1F0',
  [TaskStatusMapping.Canceled]: '#F4F5F7',
  [TaskStatusMapping.Invalid]: '#F4F5F7',
};

export const ActivityTypeText = {
  [ActivityTypeMapping.Add]: '新增数据',
  [ActivityTypeMapping.Update]: '更新数据',
  [ActivityTypeMapping.Delete]: '删除数据',
};
