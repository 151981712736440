export const FormSettingKeys = {
  formTitle: 'formTitle',
  formDescription: 'formDes',
  formViewCover: 'formViewCover',
  displayFields: 'displayFields', // 表单民示字段
  sortFields: 'layout', // 表单排序字段
};

// 表单视图背景图
export const CoverThumbnails: string[] = ['diannao', 'bi', 'shanshui', 'xingkong', 'yu'].reverse();

// 默认表单视图背景图
export const DefaultCoverThumbnail = 'diannao';

export const FormPermissionVisibleKey = 'Visible';
export const FormPermissionEditableKey = 'Editable';
