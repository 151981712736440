import _ from 'lodash';

export enum RuleMethod {
  EQUAL = 'equal',
  NOTEQUAL = 'notequal',
  ABOVE = 'above',
  NOTABOVE = 'notabove',
  BELOW = 'below',
  NOTBELOW = 'notbelow',
  CONTAINS = 'contains',
  NOTCONTAINS = 'notcontains',
  RANGE = 'range',
  IN = 'in',
  OUTOFRANGE = 'outofrange',
  NOTIN = 'notin',
  EMPTY = 'empty',
  NOTEMPTY = 'notempty',
  CONTAINSANY = 'containsany',
  NOTCONTAINSANY = 'notcontainsany',
  NUMRANGE = 'numrange',
  NUMOUTOFRANGE = 'numoutofrange',
}

export enum TimeMoments {
  YESTERDAY = 0,
  TODAY = 1,
  TOMORROW = 2,
  LASTWEEK = 3,
  THISWEEK = 4,
  NEXTWEEK = 5,
  LASTMONTH = 6,
  THISMONTH = 7,
  NEXTMONTH = 8,
  LASTQUARTER = 9,
  THISQUARTER = 10,
  NEXTQUARTER = 11,
  LASTYEAR = 12,
  THISYEAR = 13,
  NEXTYEAR = 14,
};

const toStr = Object.prototype.toString;
export function methodFactory (method: RuleMethod) {
  switch (method) {
    case RuleMethod.EQUAL: return equal;
    case RuleMethod.NOTEQUAL: return notequal;
    case RuleMethod.ABOVE: return greaterThen;
    case RuleMethod.NOTABOVE: return notGreaterThen;
    case RuleMethod.BELOW: return lessThen;
    case RuleMethod.NOTBELOW: return notLessThen;
    case RuleMethod.CONTAINS: return contains;
    case RuleMethod.NOTCONTAINS: return notContains;
    case RuleMethod.RANGE: return inRange;
    case RuleMethod.IN: return foundIn;
    case RuleMethod.OUTOFRANGE: return outRange;
    case RuleMethod.NOTIN: return notFound;
    case RuleMethod.EMPTY: return empty;
    case RuleMethod.NOTEMPTY: return notEmpty;
    case RuleMethod.CONTAINSANY: return containsAny;
    case RuleMethod.NOTCONTAINSANY: return notContainsAny;
    case RuleMethod.NUMRANGE: return inRange;
    case RuleMethod.NUMOUTOFRANGE: return outRange;
    default: return function () { return true; };
  }
}

// undefined, '', null, [], {}
function isEmpty (val) {
  if (val === undefined || val === null || val === '') {
    return true;
  }
  if (Array.isArray(val) && val.length === 0) {
    return true;
  }
  if (toStr.call(val) === '[object Object]' && Object.keys(val).length === 0) {
    return true;
  }
  return false;
}

// 不考虑元素的顺序
export function arrayEqual (sourceArr, targetArr) {
  const slen = sourceArr.length;
  const tlen = targetArr.length;
  if (slen !== tlen) {
    return false;
  }
  for (let i = 0; i < slen; i++) {
    let found = false;
    for (let j = targetArr.length - 1; j > -1; j--) {
      if (_.isEqual(sourceArr[i], targetArr[j])) {
        found = true;
        targetArr.splice(j, 1);
        break;
      }
    }
    if (found === false) {
      return false;
    }
  }
  return true;
}
// 等于.原始数据相等、数组是否相等(不考虑排序)
export function equal (sourceVal, targetVal, ...args) {
  if (Array.isArray(sourceVal)) {
    // eslint-disable-next-line no-param-reassign
    targetVal = [targetVal, ...args];
    return arrayEqual(sourceVal, targetVal);
  }
  return _.isEqual(sourceVal, targetVal);
}
// 不等于
export function notequal (sourceVal, targetVal, ...args) {
  return !equal(sourceVal, targetVal, ...args);
}
// 大于、晚于
export function greaterThen (sourceVal, ...targetVal:any) {
  if (isEmpty(sourceVal)) {
    return false;
  }
  return sourceVal > Math.max(targetVal);
}
// 大于等于
export function notLessThen (sourceVal, targetVal) {
  if (isEmpty(sourceVal)) {
    return false;
  }
  return !lessThen(sourceVal, targetVal);
}
// 小于、早于。时间控件计算：早于本周，传参是一个时间段
export function lessThen (sourceVal, ...targetVal:any) {
  if (isEmpty(sourceVal)) {
    return false;
  }
  return sourceVal < Math.min(targetVal);
}
// 小于等于
export function notGreaterThen (sourceVal, targetVal) {
  if (isEmpty(sourceVal)) {
    return false;
  }
  return !greaterThen(sourceVal, targetVal);
}
function uniqueProp (target) {
  return (target && target.id) || target;
}
// 包含、同时包含
// 1.数组包含
// 2.字符串包含
export function contains (sourceVal, targetVal, ...args) {
  // targetVal 为空直接返回
  if (isEmpty(targetVal)) {
    return false;
  }
  sourceVal = sourceVal || '';
  if (Array.isArray(sourceVal)) {
    /* eslint-disable */
    targetVal = [targetVal, ...args];
    sourceVal = sourceVal.map(uniqueProp);
    targetVal = targetVal.map(uniqueProp);
    /* eslint-enable */
    let matched = true;
    for (let i = 0; i < targetVal.length; i++) {
      matched = sourceVal.indexOf(targetVal[i]) !== -1;
      if (!matched) {
        break;
      }
    }
    return matched;
  } else {
    return typeof sourceVal === 'string' && sourceVal.indexOf(targetVal) !== -1;
  }
}
// 不包含
export function notContains (sourceVal, targetVal, ...args) {
  return !contains(sourceVal, targetVal, ...args);
}
// 包含于(等于任意一个)
export function foundIn (sourceVal, ...args) {
  const targetVal = [...args];
  return targetVal.some(function (t) {
    return equal(sourceVal, t);
  });
}

export function notFound (sourceVal, ...args) {
  return !foundIn(sourceVal, ...args);
}
// 在范围内
export function inRange (sourceVal, startVal, endVal) {
  return (sourceVal >= startVal && sourceVal <= endVal);
}
// 不在范围内
export function outRange (sourceVal, startVal, endVal) {
  return !inRange(sourceVal, startVal, endVal);
}

// 为空
export function empty (sourceVal) {
  // 兼容地址控件
  // if (sourceVal && isEmpty(sourceVal.detail)) {
  //   delete sourceVal.detail;
  // }
  return isEmpty(sourceVal);
}

// 不为空
export function notEmpty (sourceVal) {
  return !empty(sourceVal);
}
// 包含任意一个
export function containsAny (sourceVal, ...args) {
  const targetVal = [...args];
  return (sourceVal || []).some(function (s) {
    return targetVal.some(function (t) {
      return equal(s, t);
    });
  });
}

// 不包含任意一个
export function notContainsAny (sourceVal, ...args) {
  return !containsAny(sourceVal, ...args);
}

export function timeRange (momentType: string) {
  const millis = (new Date()).getTime();
  const index = TimeMoments[momentType.toUpperCase()];
  const params = [];
  let baseVal;
  let baseMonthNum;
  let lowVal;
  let topVal;
  const weekDayNum = 7;
  const dayMillis = 24 * 3600 * 1000;
  const firstDay = 1;
  let date;
  if (index <= 5) {
    date = new Date();
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
  }
  // (index - num) * baseVal 表示上一个/当前/下一个周期的时间偏移量
  // 日为单位
  if (index <= 2) {
    baseVal = dayMillis;
    lowVal = date.getTime() + (index - 1) * baseVal;
    topVal = lowVal + baseVal - 1000; // 减去一秒，代表当前时间段最后一秒
    // 周为单位
  } else if (index <= 5) {
    baseVal = weekDayNum * dayMillis;
    // 中国大陆地区星期一作为第一天
    const diffDay = (weekDayNum + date.getDay() - firstDay) % weekDayNum;
    date.setDate(date.getDate() - diffDay);
    lowVal = date.getTime() + (index - 4) * baseVal;
    topVal = lowVal + baseVal - 1000;
    // 月、季度、年为单位
  } else {
    // 1, 3, 12分别表示月、季度、年等包含的月份数
    baseMonthNum = [1, 3, 12][Math.floor((index - 6) / 3)];
    const values = monthRange(millis, (index % 3) - 1, baseMonthNum);
    ([lowVal, topVal] = values);
  }
  params.push(lowVal, topVal);
  return params;
}

export function monthRange (millis, factor, baseMonthNum) {
  const date = new Date(millis);
  date.setMilliseconds(0);
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(0);
  date.setDate(1);
  const month = date.getMonth();
  // const month = date.getMonth() + 1;
  // const mod = month % baseMonthNum;
  // const baseMonth = Math.floor((month / baseMonthNum)) * baseMonthNum -
  //   (mod === 0 ? baseMonthNum - 1 : mod);
  const offetMonth = factor * baseMonthNum;
  let startMonth;
  if (baseMonthNum === 1) {
    startMonth = month;
  } else if (baseMonthNum === 3) {
    startMonth = Math.floor(month / baseMonthNum) * baseMonthNum;
  } else if (baseMonthNum === 12) {
    startMonth = 0;
  }
  date.setMonth(startMonth + offetMonth);
  const lowVal = date.getTime();
  date.setMonth(date.getMonth() + baseMonthNum);
  const topVal = date.getTime();
  return [lowVal, topVal - 1000]; // 减去1秒
}
