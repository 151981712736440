import { formatMoney } from '../format';
import { Formula } from '../type';
const UPPERMONEY = {
    name: 'UPPERMONEY',
    type: Formula.IdentifierType.FUNC,
    inputType: [{
            paramType: Formula.ParamType.NUMBER,
            sourceType: Formula.SourceType.ANY,
        }],
    outputType: {
        paramType: Formula.ParamType.TEXT,
        sourceType: Formula.SourceType.EDIT,
    },
    calculate: (identifier, params, compiler) => {
        const _params = compiler._pretreatment(identifier, params);
        const [_param] = _params;
        const value = formatMoney(_param.value, true);
        return {
            ...identifier.outputType,
            value,
        };
    },
};
export default UPPERMONEY;
