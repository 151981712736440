
export enum AsyncTaskType {
  BulkEdit = 'bulk-edit',
  AddControl = 'add-control',
  DeleteControl = 'delete-control',
  UpdateControl = 'update-control',
  BulkCommit = 'bulk-submit',
  ConditionUpdate = 'condition-update',
  ConditionDelete = 'condition-delete',
  ConditionTerminate = 'bulk-terminate-workflow',
  ConditionChangeOwner = 'bulk-update-workflow-participant',
  ConditionReactive = 'bulk-reactive-workflow',
}
