import { Formula } from '../type';
import { validateObjectType } from '../utils';

const TOLOWER: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'TOLOWER',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.EDIT,
  },
  validate (identifier, params, compiler) {
    validateObjectType(identifier, params);
    const outputType = compiler._funcValidate(identifier, params);
    return outputType;
  },
  calculate: (identifier, params, compiler) => {
    const _params = compiler._pretreatment(identifier, params);
    const [_str] = _params;
    const value = _str.value.toLowerCase();
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default TOLOWER;
