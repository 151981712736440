import { RuleValueType, RelativeDateType, DateUnitType } from "@lcos-kernel/chicken-library";
import { ArithmeticOperatorTypes, ControlType, ControlSystem } from "@lcos-kernel/controls-core";
import { querySceneCtrlTypes, SceneType } from "@lcos-kernel/control-settings";
var FilterSceneType = /* @__PURE__ */ ((FilterSceneType2) => {
  FilterSceneType2["CreatDataWorkflowScene"] = "creatDataWorkflowScene";
  FilterSceneType2["CreatTimeWorkflowScene"] = "creatTimeWorkflowScene";
  FilterSceneType2["WorkflowBranchNodeScene"] = "workflowBranchNodeScene";
  FilterSceneType2["SubmitValidateScene"] = "submitValidateScene";
  FilterSceneType2["DisplayConditionScene"] = "displayConditionScene";
  FilterSceneType2["ColorConditionScene"] = "colorConditionScene";
  FilterSceneType2["RelationshipSelectionRangeScene"] = "relationshipSelectionRangeScene";
  FilterSceneType2["ToolbarFilterScene"] = "toolbarFilterScene";
  FilterSceneType2["RelationshipFilterScene"] = "relationshipFilterScene";
  FilterSceneType2["SearchReplaceScene"] = "searchReplaceScene";
  FilterSceneType2["RollupConditionScene"] = "rollupConditionScene";
  FilterSceneType2["RelationshipQuickFilterScene"] = "relationshipQuickFilterScene";
  FilterSceneType2["MemberDataRangeScene"] = "memberDataRangeScene";
  FilterSceneType2["SelectOptionsRelationScene"] = "selectOptionsRelationScene";
  FilterSceneType2["MemberCustomConditionScene"] = "memberCustomConditionScene";
  FilterSceneType2["AutofillConditionScene"] = "autofillConditionScene";
  return FilterSceneType2;
})(FilterSceneType || {});
var FiexdRenderT = /* @__PURE__ */ ((FiexdRenderT2) => {
  FiexdRenderT2["Input"] = "Input";
  FiexdRenderT2["Textarea"] = "Textarea";
  FiexdRenderT2["DatePicker"] = "DatePicker";
  FiexdRenderT2["InputNumber"] = "InputNumber";
  FiexdRenderT2["Select"] = "Select";
  FiexdRenderT2["Org"] = "Org";
  FiexdRenderT2["RelationshipSelect"] = "RelationshipSelect";
  FiexdRenderT2["Address"] = "Address";
  return FiexdRenderT2;
})(FiexdRenderT || {});
const DateRuleValueTypeList = [{
  label: "\u6307\u5B9A\u65E5\u671F",
  value: RuleValueType.Fixed
}, {
  label: "\u4ECA\u5929",
  value: RuleValueType.Today
}, {
  label: "\u6628\u5929",
  value: RuleValueType.Yesterday
}, {
  label: "\u660E\u5929",
  value: RuleValueType.Tomorrow
}, {
  label: "\u672C\u5468",
  value: RuleValueType.CurrentWeek
}, {
  label: "\u4E0A\u5468",
  value: RuleValueType.LastWeek
}, {
  label: "\u4E0B\u5468",
  value: RuleValueType.NextWeek
}, {
  label: "\u672C\u6708",
  value: RuleValueType.CurrentMonth
}, {
  label: "\u4E0A\u6708",
  value: RuleValueType.LastMonth
}, {
  label: "\u4E0B\u6708",
  value: RuleValueType.NextMonth
}, {
  label: "\u672C\u5B63\u5EA6",
  value: RuleValueType.CurrentQuarter
}, {
  label: "\u4E0A\u5B63\u5EA6",
  value: RuleValueType.LastQuarter
}, {
  label: "\u4E0B\u5B63\u5EA6",
  value: RuleValueType.NextQuarter
}];
const DynamicRangeOpertions = [ArithmeticOperatorTypes.Range, ArithmeticOperatorTypes.OutOfRange];
const DataRangeRuleValueTypeList = [{
  label: "\u6307\u5B9A\u65E5\u671F",
  value: RuleValueType.Fixed
}, {
  label: "\u52A8\u6001\u8303\u56F4",
  value: RuleValueType.DynamicRange
}];
const DynamicRangeTimeOptions = [{
  label: "\u5F53\u524D",
  value: RelativeDateType.Current
}, {
  label: "\u8FC7\u53BB",
  value: RelativeDateType.Past
}, {
  label: "\u672A\u6765",
  value: RelativeDateType.Future
}, {
  label: "\u4E0D\u9650",
  value: RelativeDateType.UnLimited
}];
const DynamicRangeUnitOptions = [{
  label: "\u5929",
  value: DateUnitType.Day
}, {
  label: "\u5468",
  value: DateUnitType.Week
}, {
  label: "\u6708",
  value: DateUnitType.Month
}, {
  label: "\u5B63",
  value: DateUnitType.Quarter
}, {
  label: "\u5E74",
  value: DateUnitType.Year
}];
const defaultValueTypes = [{
  label: "\u56FA\u5B9A\u503C",
  value: RuleValueType.Fixed
}, {
  label: "\u52A8\u6001\u503C",
  value: RuleValueType.Dynamic
}];
const statusValueTypes = [{
  label: "\u56FA\u5B9A\u503C",
  value: RuleValueType.Fixed
}];
const defaultValueTypeList = {
  [ControlType.Address]: defaultValueTypes,
  [ControlType.CreatedTime]: defaultValueTypes,
  [ControlType.Creater]: defaultValueTypes,
  [ControlType.DateTime]: defaultValueTypes,
  [ControlType.Email]: defaultValueTypes,
  [ControlType.Formula]: defaultValueTypes,
  [ControlType.IDNumber]: defaultValueTypes,
  [ControlType.Lookup]: defaultValueTypes,
  [ControlType.ModifiedTime]: defaultValueTypes,
  [ControlType.Modifier]: defaultValueTypes,
  [ControlType.MultiDept]: defaultValueTypes,
  [ControlType.MultilineText]: defaultValueTypes,
  [ControlType.MultiSelect]: defaultValueTypes,
  [ControlType.MultiUser]: defaultValueTypes,
  [ControlType.Number]: defaultValueTypes,
  [ControlType.ObjectId]: defaultValueTypes,
  [ControlType.Owner]: defaultValueTypes,
  [ControlType.OwnerDept]: defaultValueTypes,
  [ControlType.PhoneNumber]: defaultValueTypes,
  [ControlType.QRCode]: defaultValueTypes,
  [ControlType.Relationship]: defaultValueTypes,
  [ControlType.Rollup]: defaultValueTypes,
  [ControlType.SingleDept]: defaultValueTypes,
  [ControlType.SinglelineText]: defaultValueTypes,
  [ControlType.SingleSelect]: defaultValueTypes,
  [ControlType.SingleUser]: defaultValueTypes,
  [ControlType.SN]: defaultValueTypes,
  [ControlType.Status]: statusValueTypes,
  [ControlType.SubTable]: defaultValueTypes,
  [ControlType.WorkflowName]: defaultValueTypes,
  [ControlType.WorkflowActivity]: defaultValueTypes,
  [ControlType.WorkflowVersion]: defaultValueTypes,
  [ControlType.ActivityParticipants]: defaultValueTypes
};
const defaultOperationMap = {
  [ControlType.Address]: ControlSystem.Controls[ControlType.Address].getFixDefaultMethod(),
  [ControlType.Attachment]: ControlSystem.Controls[ControlType.Attachment].getFixDefaultMethod(),
  [ControlType.CreatedTime]: ControlSystem.Controls[ControlType.CreatedTime].getFixDefaultMethod(),
  [ControlType.Creater]: ControlSystem.Controls[ControlType.Creater].getFixDefaultMethod(),
  [ControlType.DateTime]: ControlSystem.Controls[ControlType.DateTime].getFixDefaultMethod(),
  [ControlType.Email]: ControlSystem.Controls[ControlType.Email].getFixDefaultMethod(),
  [ControlType.Formula]: ControlSystem.Controls[ControlType.Formula].getFixDefaultMethod(),
  [ControlType.IDNumber]: ControlSystem.Controls[ControlType.IDNumber].getFixDefaultMethod(),
  [ControlType.Lookup]: ControlSystem.Controls[ControlType.Lookup].getFixDefaultMethod(),
  [ControlType.ModifiedTime]: ControlSystem.Controls[ControlType.ModifiedTime].getFixDefaultMethod(),
  [ControlType.Modifier]: ControlSystem.Controls[ControlType.Modifier].getFixDefaultMethod(),
  [ControlType.MultiDept]: ControlSystem.Controls[ControlType.MultiDept].getFixDefaultMethod(),
  [ControlType.MultilineText]: ControlSystem.Controls[ControlType.MultilineText].getFixDefaultMethod(),
  [ControlType.MultiSelect]: ControlSystem.Controls[ControlType.MultiSelect].getFixDefaultMethod(),
  [ControlType.MultiUser]: ControlSystem.Controls[ControlType.MultiUser].getFixDefaultMethod(),
  [ControlType.Number]: ControlSystem.Controls[ControlType.Number].getFixDefaultMethod(),
  [ControlType.ObjectId]: ControlSystem.Controls[ControlType.ObjectId].getFixDefaultMethod(),
  [ControlType.Owner]: ControlSystem.Controls[ControlType.Owner].getFixDefaultMethod(),
  [ControlType.OwnerDept]: ControlSystem.Controls[ControlType.OwnerDept].getFixDefaultMethod(),
  [ControlType.PhoneNumber]: ControlSystem.Controls[ControlType.PhoneNumber].getFixDefaultMethod(),
  [ControlType.QRCode]: ControlSystem.Controls[ControlType.QRCode].getFixDefaultMethod(),
  [ControlType.Relationship]: ControlSystem.Controls[ControlType.Relationship].getFixDefaultMethod(),
  [ControlType.Rollup]: ControlSystem.Controls[ControlType.Rollup].getFixDefaultMethod(),
  [ControlType.SingleDept]: ControlSystem.Controls[ControlType.SingleDept].getFixDefaultMethod(),
  [ControlType.SinglelineText]: ControlSystem.Controls[ControlType.SinglelineText].getFixDefaultMethod(),
  [ControlType.SingleSelect]: ControlSystem.Controls[ControlType.SingleSelect].getFixDefaultMethod(),
  [ControlType.SingleUser]: ControlSystem.Controls[ControlType.SingleUser].getFixDefaultMethod(),
  [ControlType.SN]: ControlSystem.Controls[ControlType.SN].getFixDefaultMethod(),
  [ControlType.Status]: ControlSystem.Controls[ControlType.Status].getFixDefaultMethod(),
  [ControlType.SubTable]: ControlSystem.Controls[ControlType.SubTable].getFixDefaultMethod(),
  [ControlType.WorkflowName]: ControlSystem.Controls[ControlType.WorkflowName].getFixDefaultMethod(),
  [ControlType.WorkflowActivity]: ControlSystem.Controls[ControlType.WorkflowActivity].getFixDefaultMethod(),
  [ControlType.WorkflowVersion]: ControlSystem.Controls[ControlType.WorkflowVersion].getFixDefaultMethod(),
  [ControlType.ActivityParticipants]: ControlSystem.Controls[ControlType.ActivityParticipants].getFixDefaultMethod()
};
const defaultSupportedOperatorMap = {
  [ControlType.Address]: ControlSystem.Controls[ControlType.Address].options.expressions,
  [ControlType.Attachment]: ControlSystem.Controls[ControlType.Attachment].options.expressions,
  [ControlType.CreatedTime]: ControlSystem.Controls[ControlType.CreatedTime].options.expressions,
  [ControlType.Creater]: ControlSystem.Controls[ControlType.Creater].options.expressions,
  [ControlType.DateTime]: ControlSystem.Controls[ControlType.DateTime].options.expressions,
  [ControlType.Email]: ControlSystem.Controls[ControlType.Email].options.expressions,
  [ControlType.Formula]: ControlSystem.Controls[ControlType.Formula].options.expressions,
  [ControlType.IDNumber]: ControlSystem.Controls[ControlType.IDNumber].options.expressions,
  [ControlType.Lookup]: ControlSystem.Controls[ControlType.Lookup].options.expressions,
  [ControlType.ModifiedTime]: ControlSystem.Controls[ControlType.ModifiedTime].options.expressions,
  [ControlType.Modifier]: ControlSystem.Controls[ControlType.Modifier].options.expressions,
  [ControlType.MultiDept]: ControlSystem.Controls[ControlType.MultiDept].options.expressions,
  [ControlType.MultilineText]: ControlSystem.Controls[ControlType.MultilineText].options.expressions,
  [ControlType.MultiSelect]: ControlSystem.Controls[ControlType.MultiSelect].options.expressions,
  [ControlType.MultiUser]: ControlSystem.Controls[ControlType.MultiUser].options.expressions,
  [ControlType.Number]: ControlSystem.Controls[ControlType.Number].options.expressions,
  [ControlType.ObjectId]: ControlSystem.Controls[ControlType.ObjectId].options.expressions,
  [ControlType.Owner]: ControlSystem.Controls[ControlType.Owner].options.expressions,
  [ControlType.OwnerDept]: ControlSystem.Controls[ControlType.OwnerDept].options.expressions,
  [ControlType.PhoneNumber]: ControlSystem.Controls[ControlType.PhoneNumber].options.expressions,
  [ControlType.QRCode]: ControlSystem.Controls[ControlType.QRCode].options.expressions,
  [ControlType.Relationship]: ControlSystem.Controls[ControlType.Relationship].options.expressions,
  [ControlType.Rollup]: ControlSystem.Controls[ControlType.Rollup].options.expressions,
  [ControlType.SingleDept]: ControlSystem.Controls[ControlType.SingleDept].options.expressions,
  [ControlType.SinglelineText]: ControlSystem.Controls[ControlType.SinglelineText].options.expressions,
  [ControlType.SingleSelect]: ControlSystem.Controls[ControlType.SingleSelect].options.expressions,
  [ControlType.SingleUser]: ControlSystem.Controls[ControlType.SingleUser].options.expressions,
  [ControlType.SN]: ControlSystem.Controls[ControlType.SN].options.expressions,
  [ControlType.Status]: ControlSystem.Controls[ControlType.Status].options.expressions,
  [ControlType.SubTable]: ControlSystem.Controls[ControlType.SubTable].options.expressions,
  [ControlType.WorkflowName]: ControlSystem.Controls[ControlType.WorkflowName].options.expressions,
  [ControlType.WorkflowActivity]: ControlSystem.Controls[ControlType.WorkflowActivity].options.expressions,
  [ControlType.WorkflowVersion]: ControlSystem.Controls[ControlType.WorkflowVersion].options.expressions,
  [ControlType.ActivityParticipants]: ControlSystem.Controls[ControlType.ActivityParticipants].options.expressions
};
const defaultSupportedOperationMap = {
  [ControlType.Address]: {
    operation: ControlSystem.Controls[ControlType.Address].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Address].getFixDefaultMethod()
  },
  [ControlType.Attachment]: {
    operation: ControlSystem.Controls[ControlType.Attachment].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Attachment].getFixDefaultMethod()
  },
  [ControlType.CreatedTime]: {
    operation: ControlSystem.Controls[ControlType.CreatedTime].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.CreatedTime].getFixDefaultMethod()
  },
  [ControlType.Creater]: {
    operation: ControlSystem.Controls[ControlType.Creater].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Creater].getFixDefaultMethod()
  },
  [ControlType.DateTime]: {
    operation: ControlSystem.Controls[ControlType.DateTime].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.DateTime].getFixDefaultMethod()
  },
  [ControlType.Email]: {
    operation: ControlSystem.Controls[ControlType.Email].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Email].getFixDefaultMethod()
  },
  [ControlType.Formula]: {
    operation: ControlSystem.Controls[ControlType.Formula].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Formula].getFixDefaultMethod()
  },
  [ControlType.IDNumber]: {
    operation: ControlSystem.Controls[ControlType.IDNumber].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.IDNumber].getFixDefaultMethod()
  },
  [ControlType.Lookup]: {
    operation: ControlSystem.Controls[ControlType.Lookup].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Lookup].getFixDefaultMethod()
  },
  [ControlType.ModifiedTime]: {
    operation: ControlSystem.Controls[ControlType.ModifiedTime].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.ModifiedTime].getFixDefaultMethod()
  },
  [ControlType.Modifier]: {
    operation: ControlSystem.Controls[ControlType.Modifier].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Modifier].getFixDefaultMethod()
  },
  [ControlType.MultiDept]: {
    operation: ControlSystem.Controls[ControlType.MultiDept].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.MultiDept].getFixDefaultMethod()
  },
  [ControlType.MultilineText]: {
    operation: ControlSystem.Controls[ControlType.MultilineText].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.MultilineText].getFixDefaultMethod()
  },
  [ControlType.MultiSelect]: {
    operation: ControlSystem.Controls[ControlType.MultiSelect].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.MultiSelect].getFixDefaultMethod()
  },
  [ControlType.MultiUser]: {
    operation: ControlSystem.Controls[ControlType.MultiUser].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.MultiUser].getFixDefaultMethod()
  },
  [ControlType.Number]: {
    operation: ControlSystem.Controls[ControlType.Number].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Number].getFixDefaultMethod()
  },
  [ControlType.ObjectId]: {
    operation: ControlSystem.Controls[ControlType.ObjectId].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.ObjectId].getFixDefaultMethod()
  },
  [ControlType.Owner]: {
    operation: ControlSystem.Controls[ControlType.Owner].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Owner].getFixDefaultMethod()
  },
  [ControlType.OwnerDept]: {
    operation: ControlSystem.Controls[ControlType.OwnerDept].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.OwnerDept].getFixDefaultMethod()
  },
  [ControlType.PhoneNumber]: {
    operation: ControlSystem.Controls[ControlType.PhoneNumber].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.PhoneNumber].getFixDefaultMethod()
  },
  [ControlType.QRCode]: {
    operation: ControlSystem.Controls[ControlType.QRCode].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.QRCode].getFixDefaultMethod()
  },
  [ControlType.Relationship]: {
    operation: ControlSystem.Controls[ControlType.Relationship].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Relationship].getFixDefaultMethod()
  },
  [ControlType.Rollup]: {
    operation: ControlSystem.Controls[ControlType.Rollup].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Rollup].getFixDefaultMethod()
  },
  [ControlType.SingleDept]: {
    operation: ControlSystem.Controls[ControlType.SingleDept].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.SingleDept].getFixDefaultMethod()
  },
  [ControlType.SinglelineText]: {
    operation: ControlSystem.Controls[ControlType.SinglelineText].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.SinglelineText].getFixDefaultMethod()
  },
  [ControlType.SingleSelect]: {
    operation: ControlSystem.Controls[ControlType.SingleSelect].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.SingleSelect].getFixDefaultMethod()
  },
  [ControlType.SingleUser]: {
    operation: ControlSystem.Controls[ControlType.SingleUser].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.SingleUser].getFixDefaultMethod()
  },
  [ControlType.SN]: {
    operation: ControlSystem.Controls[ControlType.SN].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.SN].getFixDefaultMethod()
  },
  [ControlType.Status]: {
    operation: ControlSystem.Controls[ControlType.Status].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Status].getFixDefaultMethod()
  },
  [ControlType.SubTable]: {
    operation: ControlSystem.Controls[ControlType.SubTable].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.SubTable].getFixDefaultMethod()
  },
  [ControlType.WorkflowName]: {
    operation: ControlSystem.Controls[ControlType.WorkflowName].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.WorkflowName].getFixDefaultMethod()
  },
  [ControlType.WorkflowActivity]: {
    operation: ControlSystem.Controls[ControlType.WorkflowActivity].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.WorkflowActivity].getFixDefaultMethod()
  },
  [ControlType.WorkflowVersion]: {
    operation: ControlSystem.Controls[ControlType.WorkflowVersion].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.WorkflowVersion].getFixDefaultMethod()
  },
  [ControlType.ActivityParticipants]: {
    operation: ControlSystem.Controls[ControlType.ActivityParticipants].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.ActivityParticipants].getFixDefaultMethod()
  },
  [ControlType.Signature]: {
    operation: ControlSystem.Controls[ControlType.Signature].options.expressions,
    defaultMethod: ControlSystem.Controls[ControlType.Signature].getFixDefaultMethod()
  }
};
const NotRuleTypes$1 = [ControlType.SubTable, ControlType.QRCode];
const defaultFixedRenderTypeMap = {
  [ControlType.Address]: FiexdRenderT.Address,
  [ControlType.CreatedTime]: FiexdRenderT.DatePicker,
  [ControlType.Creater]: FiexdRenderT.Org,
  [ControlType.DateTime]: FiexdRenderT.DatePicker,
  [ControlType.Email]: FiexdRenderT.Input,
  [ControlType.IDNumber]: FiexdRenderT.Input,
  [ControlType.ModifiedTime]: FiexdRenderT.DatePicker,
  [ControlType.Modifier]: FiexdRenderT.Org,
  [ControlType.MultiDept]: FiexdRenderT.Org,
  [ControlType.MultilineText]: FiexdRenderT.Textarea,
  [ControlType.MultiSelect]: FiexdRenderT.Select,
  [ControlType.MultiUser]: FiexdRenderT.Org,
  [ControlType.Number]: FiexdRenderT.InputNumber,
  [ControlType.ObjectId]: FiexdRenderT.Input,
  [ControlType.Owner]: FiexdRenderT.Org,
  [ControlType.OwnerDept]: FiexdRenderT.Org,
  [ControlType.PhoneNumber]: FiexdRenderT.Input,
  [ControlType.Relationship]: FiexdRenderT.RelationshipSelect,
  [ControlType.SingleDept]: FiexdRenderT.Org,
  [ControlType.SinglelineText]: FiexdRenderT.Input,
  [ControlType.SingleSelect]: FiexdRenderT.Select,
  [ControlType.SingleUser]: FiexdRenderT.Org,
  [ControlType.SN]: FiexdRenderT.Input,
  [ControlType.Status]: FiexdRenderT.Select,
  [ControlType.WorkflowName]: FiexdRenderT.Input,
  [ControlType.WorkflowActivity]: FiexdRenderT.Input,
  [ControlType.WorkflowVersion]: FiexdRenderT.InputNumber,
  [ControlType.ActivityParticipants]: FiexdRenderT.Org
};
const getValueType = (type) => {
  return defaultValueTypeList[type];
};
const systemControl = [ControlType.OwnerDept, ControlType.Owner, ControlType.Creater, ControlType.CreatedTime, ControlType.Modifier, ControlType.ModifiedTime];
const CrossTableScenelist = [FilterSceneType.RelationshipSelectionRangeScene, FilterSceneType.RollupConditionScene];
function fieldDefines(type) {
  if (!type) {
    return;
  }
  return ControlSystem.Controls[type];
}
function getMethod(type, method) {
  if (!(type && method)) {
    return;
  }
  const {
    expressions,
    expressionsInMultiMode
  } = getExpressions(type);
  let _expressions = expressions;
  if (type === ControlType.Relationship && method === ArithmeticOperatorTypes.Contains) {
    _expressions = expressions.concat(expressionsInMultiMode);
  }
  return _expressions.find((option) => option.value === method);
}
function getExpressions(type) {
  if (!type) {
    return [];
  }
  const expressions = fieldDefines(type).options.expressions;
  const expressionsInMultiMode = fieldDefines(type).options.expressionsInMultiMode;
  return {
    expressions,
    expressionsInMultiMode
  };
}
function getValueTypeList(type, method) {
  var _a, _b;
  return (_b = (_a = getMethod(type, method)) == null ? void 0 : _a.expValueTypes) != null ? _b : [];
}
function getDynamicValueType(type, method) {
  var _a;
  const valueTypes = getValueTypeList(type, method).find((item) => item.value === RuleValueType.Dynamic);
  const controlTypes = (_a = valueTypes == null ? void 0 : valueTypes.controlTypes) != null ? _a : [];
  return [...new Set(controlTypes.concat([type, ControlType.Formula, ControlType.Lookup, ControlType.Rollup]))];
}
const getDefaultValueTypes = (type) => {
  return defaultValueTypeList[type];
};
const getDefaultValueFields = (dynamicSelectList, value, scene) => {
  const options = getDynamicValueType(value.controlType, value.method);
  const fieldList = dynamicSelectList;
  const isCrossTableScene = CrossTableScenelist.includes(scene);
  const list = fieldList.reduce((acc, cur) => {
    if (!isCrossTableScene) {
      const notRepeatCode = value.code !== cur.code || cur.type === ControlType.ObjectId;
      if (!notRepeatCode) {
        return acc;
      }
    }
    if (options.includes(cur.type)) {
      if (cur.type === ControlType.Formula || cur.type === ControlType.Lookup || cur.type === ControlType.Rollup) {
        if (options.includes(cur.properties.targetType) && value.controlType !== ControlType.Relationship) {
          acc.push({
            label: cur.displayName,
            value: cur.code
          });
        }
      } else if (cur.type === value.controlType || systemControl.includes(cur.type) || options.includes(cur.type)) {
        acc.push({
          label: cur.displayName,
          value: cur.code
        });
      }
    }
    return acc;
  }, []);
  return list;
};
const getDefaultOperationType = (type) => {
  return defaultSupportedOperationMap[type].defaultMethod;
};
const handleSpecialField = (methods, type, properties) => {
  if (type === ControlType.Relationship && (properties == null ? void 0 : properties.countLimit) !== 1) {
    const {
      expressionsInMultiMode
    } = getExpressions(type);
    return methods.concat(expressionsInMultiMode);
  }
  return methods;
};
const getDefaultOperations = (type, properties) => {
  if (type) {
    const methods = defaultSupportedOperationMap[type].operation;
    if (type === ControlType.Relationship || type === ControlType.DateTime) {
      return handleSpecialField(methods, type, properties);
    }
    return methods;
  } else {
    return [];
  }
};
const getDefaultConditionFields = (fieldList, disabledFieldTypes) => {
  return fieldList.filter((item) => {
    var _a;
    let realType = getRealType(item);
    if (item.type === ControlType.Lookup && ((_a = item.properties) == null ? void 0 : _a.rollupControlCode)) {
      realType = ControlType.Rollup;
    }
    return !disabledFieldTypes.concat(ControlType.Location).includes(realType);
  });
};
const getControlDefaultMethod = (type, properties) => {
  if (!type) {
    return null;
  }
  const defaultMethod = fieldDefines(type).getFixDefaultMethod(properties);
  return defaultMethod;
};
const getDefaultFixedRenderType = (type) => {
  return defaultFixedRenderTypeMap[type];
};
const getRealType = (field) => {
  var _a;
  if ([ControlType.Formula, ControlType.Lookup, ControlType.Rollup].includes(field.type)) {
    return (_a = field.properties) == null ? void 0 : _a.targetType;
  }
  return field.type;
};
({
  [ControlType.SinglelineText]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Contains]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotContains]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.MultilineText]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Contains]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotContains]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.Number]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Above]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotBelow]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Below]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotAbove]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.SingleSelect]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.In]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.MultiSelect]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.ContainsAny]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Contains]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.SingleUser]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.In]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.MultiUser]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.ContainsAny]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Contains]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.SingleDept]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.In]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.MultiDept]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.ContainsAny]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Contains]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.Owner]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.In]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.DateTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Equal,
      label: "\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.NotEqual,
      label: "\u4E0D\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Below]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Above]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.CreatedTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Equal,
      label: "\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.NotEqual,
      label: "\u4E0D\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Below]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Above]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.Creater]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.In]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.ModifiedTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Equal,
      label: "\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.NotEqual,
      label: "\u4E0D\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Below]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.Above]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.Modifier]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.In]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  },
  [ControlType.SN]: {
    valueType: {
      [ArithmeticOperatorTypes.Equal]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }],
      [ArithmeticOperatorTypes.NotEqual]: [{
        value: RuleValueType.Fixed,
        label: "\u56FA\u5B9A\u503C"
      }]
    }
  }
});
const workflowOperationMap$1 = {
  [ControlType.Relationship]: {
    operation: [{
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A",
      method: "checkValueEmpty",
      expects: true
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A",
      method: "checkValueEmpty",
      expects: false
    }],
    defaultMethod: ArithmeticOperatorTypes.Empty
  },
  [ControlType.DateTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Equal,
      label: "\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.NotEqual,
      label: "\u4E0D\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    defaultMethod: ArithmeticOperatorTypes.Equal
  },
  [ControlType.CreatedTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    defaultMethod: ArithmeticOperatorTypes.Below
  },
  [ControlType.ModifiedTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    defaultMethod: ArithmeticOperatorTypes.Below
  }
};
const getOperation$3 = (type, properties) => {
  var _a, _b;
  if (type) {
    const methods = ((_a = workflowOperationMap$1[type]) == null ? void 0 : _a.operation) || ((_b = defaultSupportedOperationMap[type]) == null ? void 0 : _b.operation);
    if (type === ControlType.DateTime) {
      return handleSpecialField(methods, type, properties);
    }
    if (type === ControlType.Number) {
      return methods.map((item) => {
        if (item.value === ArithmeticOperatorTypes.NumOutOfRange) {
          return {
            ...item,
            value: ArithmeticOperatorTypes.OutOfRange
          };
        }
        if (item.value === ArithmeticOperatorTypes.NumRange) {
          return {
            ...item,
            value: ArithmeticOperatorTypes.Range
          };
        }
        return item;
      });
    }
    return methods;
  } else {
    return [];
  }
};
const getDefaultOperation$4 = (type, properties) => {
  var _a;
  return ((_a = workflowOperationMap$1[type]) == null ? void 0 : _a.defaultMethod) || getControlDefaultMethod(type, properties);
};
const getValueTypes$f = (type) => {
  if (getDefaultValueTypes(type)) {
    return [getDefaultValueTypes(type)[0], {
      value: RuleValueType.Function,
      label: "\u8BA1\u7B97\u503C"
    }];
  } else {
    return [];
  }
};
const getConditionFields$f = (fieldList) => {
  const supportList = querySceneCtrlTypes(SceneType.WorkflowStartNodeDateFilterScene);
  return fieldList.filter((item) => supportList.includes(getRealType(item)));
};
const createTimeWorkflow = {
  supportAndOrOperation: true,
  showMethodSelect: true,
  showValueTypeSelect: true,
  allowDuplicate: false,
  disabledField: [ControlType.SubTable],
  getConditionFields: getConditionFields$f,
  getOperations: getOperation$3,
  getValueTypes: getValueTypes$f,
  getDefaultOperation: getDefaultOperation$4,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const theDefaultOperationMap$1 = {
  [ControlType.Relationship]: {
    operation: [{
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A",
      method: "checkValueEmpty",
      expects: true
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A",
      method: "checkValueEmpty",
      expects: false
    }],
    defaultMethod: ArithmeticOperatorTypes.Empty
  },
  [ControlType.DateTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Equal,
      label: "\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.NotEqual,
      label: "\u4E0D\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    defaultMethod: ArithmeticOperatorTypes.Equal
  },
  [ControlType.CreatedTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    defaultMethod: ArithmeticOperatorTypes.Below
  },
  [ControlType.ModifiedTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    defaultMethod: ArithmeticOperatorTypes.Below
  }
};
const dateTypes$c = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes$e = (type, method) => {
  if (dateTypes$c.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getConditionFields$e = (fieldList) => {
  const supportList = querySceneCtrlTypes(SceneType.WorkflowCreateSettingScene);
  return fieldList.filter((item) => supportList.includes(getRealType(item)));
};
const getOperation$2 = (type, properties) => {
  var _a, _b;
  if (type) {
    const methods = ((_a = theDefaultOperationMap$1[type]) == null ? void 0 : _a.operation) || ((_b = defaultSupportedOperationMap[type]) == null ? void 0 : _b.operation);
    if (type === ControlType.DateTime) {
      return handleSpecialField(methods, type, properties);
    }
    if (type === ControlType.Number) {
      return methods.map((item) => {
        if (item.value === ArithmeticOperatorTypes.NumOutOfRange) {
          return {
            ...item,
            value: ArithmeticOperatorTypes.OutOfRange
          };
        }
        if (item.value === ArithmeticOperatorTypes.NumRange) {
          return {
            ...item,
            value: ArithmeticOperatorTypes.Range
          };
        }
        return item;
      });
    }
    return methods;
  } else {
    return [];
  }
};
const getDefaultOperation$3 = (type, properties) => {
  var _a;
  return ((_a = theDefaultOperationMap$1[type]) == null ? void 0 : _a.defaultMethod) || getControlDefaultMethod(type, properties);
};
const createDataWorkflow = {
  supportAndOrOperation: true,
  showMethodSelect: true,
  showValueTypeSelect: true,
  allowDuplicate: false,
  disabledField: [ControlType.SubTable],
  getConditionFields: getConditionFields$e,
  getOperations: getOperation$2,
  getValueTypes: getValueTypes$e,
  getDefaultOperation: getDefaultOperation$3,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const dateTypes$b = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const workflowOperationMap = {
  [ControlType.DateTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Equal,
      label: "\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.NotEqual,
      label: "\u4E0D\u7B49\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    defaultMethod: ArithmeticOperatorTypes.Equal
  },
  [ControlType.CreatedTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    defaultMethod: ArithmeticOperatorTypes.Below
  },
  [ControlType.ModifiedTime]: {
    operation: [{
      value: ArithmeticOperatorTypes.Below,
      label: "\u65E9\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Above,
      label: "\u665A\u4E8E"
    }, {
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A"
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A"
    }],
    defaultMethod: ArithmeticOperatorTypes.Below
  },
  [ControlType.Relationship]: {
    operation: [{
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A",
      method: "checkValueEmpty",
      expects: true
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A",
      method: "checkValueEmpty",
      expects: false
    }],
    defaultMethod: ArithmeticOperatorTypes.Empty
  }
};
const getOperation$1 = (type, properties) => {
  var _a, _b;
  if (type) {
    const dirtyItem = {
      value: ArithmeticOperatorTypes.IsDirty,
      label: "\u6709\u53D8\u66F4"
    };
    const methods = ((_a = workflowOperationMap[type]) == null ? void 0 : _a.operation) || ((_b = defaultSupportedOperationMap[type]) == null ? void 0 : _b.operation);
    if (type === ControlType.DateTime) {
      return handleSpecialField(methods, type, properties).concat([dirtyItem]);
    }
    if (type === ControlType.Number) {
      return methods.map((item) => {
        if (item.value === ArithmeticOperatorTypes.NumOutOfRange) {
          return {
            ...item,
            value: ArithmeticOperatorTypes.OutOfRange
          };
        }
        if (item.value === ArithmeticOperatorTypes.NumRange) {
          return {
            ...item,
            value: ArithmeticOperatorTypes.Range
          };
        }
        return item;
      }).concat([dirtyItem]);
    }
    return methods.concat([dirtyItem]);
  } else {
    return [];
  }
};
const getDefaultOperation$2 = (type, properties) => {
  var _a;
  return ((_a = workflowOperationMap[type]) == null ? void 0 : _a.defaultMethod) || getControlDefaultMethod(type, properties);
};
const getValueTypes$d = (type, method) => {
  if (dateTypes$b.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getConditionFields$d = (fieldList) => {
  const notSupportTypes = querySceneCtrlTypes(SceneType.WorkflowFilterScene).concat(NotRuleTypes$1);
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const workflowBranchNodeScene = {
  supportAndOrOperation: true,
  showMethodSelect: true,
  showValueTypeSelect: true,
  allowDuplicate: false,
  getConditionFields: getConditionFields$d,
  getOperations: getOperation$1,
  getValueTypes: getValueTypes$d,
  getDefaultOperation: getDefaultOperation$2,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const dateTypes$a = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes$c = (type, method) => {
  if (dateTypes$a.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getConditionFields$c = (fieldList) => {
  const notSupportTypes = querySceneCtrlTypes(SceneType.SubmitValidateScene).concat(NotRuleTypes$1);
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const submitValidateScene = {
  supportAndOrOperation: true,
  showMethodSelect: true,
  showValueTypeSelect: true,
  allowDuplicate: false,
  getConditionFields: getConditionFields$c,
  getOperations: getDefaultOperations,
  getValueTypes: getValueTypes$c,
  getDefaultOperation: getControlDefaultMethod,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const dateTypes$9 = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes$b = (type, method) => {
  if (dateTypes$9.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getConditionFields$b = (fieldList) => {
  const notSupportTypes = querySceneCtrlTypes(SceneType.ControlPropertyDisplayScene).concat(NotRuleTypes$1);
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const displayConditionScene = {
  supportAndOrOperation: true,
  showMethodSelect: true,
  showValueTypeSelect: true,
  allowDuplicate: false,
  getConditionFields: getConditionFields$b,
  getOperations: getDefaultOperations,
  getValueTypes: getValueTypes$b,
  getDefaultOperation: getControlDefaultMethod,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const theDefaultOperationMap = {
  [ControlType.Relationship]: {
    operation: [{
      value: ArithmeticOperatorTypes.Empty,
      label: "\u4E3A\u7A7A",
      method: "checkValueEmpty",
      expects: true
    }, {
      value: ArithmeticOperatorTypes.NotEmpty,
      label: "\u4E0D\u4E3A\u7A7A",
      method: "checkValueEmpty",
      expects: false
    }],
    defaultMethod: ArithmeticOperatorTypes.Empty
  }
};
const dateTypes$8 = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes$a = (type, method) => {
  if (dateTypes$8.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getOperation = (type, properties) => {
  var _a, _b;
  if (type) {
    const methods = ((_a = theDefaultOperationMap[type]) == null ? void 0 : _a.operation) || ((_b = defaultSupportedOperationMap[type]) == null ? void 0 : _b.operation);
    if (type === ControlType.Relationship || type === ControlType.DateTime) {
      return handleSpecialField(methods, type, properties);
    }
    return methods;
  } else {
    return [];
  }
};
const getDefaultOperation$1 = (type, properties) => {
  var _a;
  return ((_a = theDefaultOperationMap[type]) == null ? void 0 : _a.defaultMethod) || getControlDefaultMethod(type, properties);
};
const getConditionFields$a = (fieldList) => {
  const notSupportTypes = NotRuleTypes$1;
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const colorConditionScene = {
  supportAndOrOperation: true,
  showMethodSelect: true,
  showValueTypeSelect: true,
  allowDuplicate: true,
  getConditionFields: getConditionFields$a,
  getOperations: getOperation,
  getValueTypes: getValueTypes$a,
  getDefaultOperation: getDefaultOperation$1,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const dateTypes$7 = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes$9 = (type, method) => {
  if (dateTypes$7.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getConditionFields$9 = (fieldList) => {
  const notSupportTypes = NotRuleTypes$1;
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const relationshipSelectionRangeScene = {
  supportAndOrOperation: true,
  showMethodSelect: true,
  showValueTypeSelect: true,
  allowDuplicate: true,
  disabledField: [ControlType.SubTable, ControlType.Attachment],
  getConditionFields: getConditionFields$9,
  getOperations: getDefaultOperations,
  getValueTypes: getValueTypes$9,
  getDefaultOperation: getControlDefaultMethod,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
function isDateType$1(type) {
  return [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime].includes(type);
}
const getValueTypes$8 = (type, method) => {
  if (!isDateType$1(type)) {
    return defaultValueTypeList[type];
  }
  if (DynamicRangeOpertions.includes(method)) {
    return DataRangeRuleValueTypeList;
  }
  return DateRuleValueTypeList;
};
const getConditionFields$8 = (fieldList) => {
  const notSupportTypes = NotRuleTypes$1;
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const toolbarFilterScene = {
  supportAndOrOperation: false,
  showMethodSelect: true,
  showValueTypeSelect: false,
  allowDuplicate: true,
  showDynamicType: true,
  personnelDynamicParams: true,
  getConditionFields: getConditionFields$8,
  getOperations: getDefaultOperations,
  getValueTypes: getValueTypes$8,
  getDefaultOperation: getControlDefaultMethod,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const dateTypes$6 = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes$7 = (type, method) => {
  if (dateTypes$6.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getConditionFields$7 = (fieldList) => {
  const notSupportTypes = NotRuleTypes$1;
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const relationshipFilterScene = {
  supportAndOrOperation: false,
  showMethodSelect: true,
  showValueTypeSelect: false,
  allowDuplicate: false,
  getConditionFields: getConditionFields$7,
  getOperations: getDefaultOperations,
  getValueTypes: getValueTypes$7,
  getDefaultOperation: getControlDefaultMethod,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
function isDateType(type) {
  return [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime].includes(type);
}
const getValueTypes$6 = (type, method) => {
  if (!isDateType(type)) {
    return defaultValueTypeList[type];
  }
  if (DynamicRangeOpertions.includes(method)) {
    return DataRangeRuleValueTypeList;
  }
  return DateRuleValueTypeList;
};
const getConditionFields$6 = (fieldList) => {
  const notSupportTypes = querySceneCtrlTypes(SceneType.BathcReplaceFilterScene).concat(NotRuleTypes$1);
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const searchReplaceScene = {
  supportAndOrOperation: false,
  showMethodSelect: true,
  showValueTypeSelect: false,
  allowDuplicate: true,
  getConditionFields: getConditionFields$6,
  getOperations: getDefaultOperations,
  getValueTypes: getValueTypes$6,
  getDefaultOperation: getControlDefaultMethod,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const NotRuleTypes = [ControlType.Address, ControlType.Relationship, ControlType.SubTable, ControlType.Attachment, ControlType.QRCode];
const dateTypes$5 = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes$5 = (type, method) => {
  if (dateTypes$5.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getConditionFields$5 = (fieldList) => {
  const notSupportTypes = NotRuleTypes;
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const getOperations$1 = (type, properties) => {
  const methods = getDefaultOperations(type, properties) || [];
  if ([ControlType.MultiSelect, ControlType.MultiUser, ControlType.MultiDept, ControlType.OwnerDept].includes(type)) {
    return methods.filter((item) => item.value !== ArithmeticOperatorTypes.NotContains);
  }
  return methods;
};
const rollupConditionScene = {
  supportAndOrOperation: true,
  showMethodSelect: true,
  showValueTypeSelect: false,
  allowDuplicate: false,
  getConditionFields: getConditionFields$5,
  getOperations: getOperations$1,
  getValueTypes: getValueTypes$5,
  getDefaultOperation: getControlDefaultMethod,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const NotFieldFilterTypes = [ControlType.SubTable, ControlType.Attachment, ControlType.QRCode, ControlType.Address];
const dateTypes$4 = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes$4 = (type, method) => {
  if (dateTypes$4.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getConditionFields$4 = (fieldList) => {
  return getDefaultConditionFields(fieldList, NotFieldFilterTypes);
};
const relationshipQuickFilterScene = {
  supportAndOrOperation: false,
  showMethodSelect: false,
  showValueTypeSelect: false,
  allowDuplicate: false,
  getConditionFields: getConditionFields$4,
  getOperations: getDefaultOperations,
  getValueTypes: getValueTypes$4,
  getDefaultOperation: getControlDefaultMethod,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const dateTypes$3 = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes$3 = (type, method) => {
  if (dateTypes$3.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getConditionFields$3 = (fieldList) => {
  const notSupportTypes = querySceneCtrlTypes(SceneType.MemberDataRangeScene).concat(NotRuleTypes$1);
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const memberDataRangeScene = {
  supportAndOrOperation: true,
  showMethodSelect: true,
  showValueTypeSelect: false,
  allowDuplicate: false,
  personnelDynamicParams: true,
  getConditionFields: getConditionFields$3,
  getOperations: getDefaultOperations,
  getValueTypes: getValueTypes$3,
  getDefaultOperation: getControlDefaultMethod,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const dateTypes$2 = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes$2 = (type, method) => {
  if (dateTypes$2.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getConditionFields$2 = (fieldList) => {
  const notSupportTypes = querySceneCtrlTypes(SceneType.SelectOptionsRelationScene).concat(NotRuleTypes$1);
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const selectOptionsRelationScene = {
  supportAndOrOperation: false,
  showMethodSelect: true,
  showValueTypeSelect: true,
  allowDuplicate: true,
  getConditionFields: getConditionFields$2,
  getOperations: getDefaultOperations,
  getValueTypes: getValueTypes$2,
  getDefaultOperation: getControlDefaultMethod,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const dateTypes$1 = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes$1 = (type, method) => {
  if (dateTypes$1.includes(type)) {
    return getValueTypeList(type, method);
  }
  return defaultValueTypeList[type];
};
const getConditionFields$1 = (fieldList) => {
  const notSupportTypes = querySceneCtrlTypes(SceneType.MemberDataRangeScene).concat(NotRuleTypes$1);
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
const memberCustomConditionScene = {
  supportAndOrOperation: false,
  showMethodSelect: true,
  showValueTypeSelect: false,
  allowDuplicate: false,
  personnelDynamicParams: true,
  getConditionFields: getConditionFields$1,
  getOperations: getDefaultOperations,
  getValueTypes: getValueTypes$1,
  getDefaultOperation: getControlDefaultMethod,
  getValueFields: getDefaultValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const dateTypes = [ControlType.DateTime, ControlType.CreatedTime, ControlType.ModifiedTime];
const getValueTypes = (type, method) => {
  let resultTypes = [];
  if (dateTypes.includes(type)) {
    resultTypes = getValueTypeList(type, method) || [];
  } else {
    resultTypes = defaultValueTypeList[type] || [];
  }
  const dynamicIndex = resultTypes.findIndex((item) => item.value === "dynamic");
  if (dynamicIndex > -1) {
    resultTypes = [...resultTypes];
    const dinamicItem = resultTypes.splice(dynamicIndex, 1);
    resultTypes.unshift(dinamicItem[0]);
  }
  return resultTypes;
};
const getConditionFields = (fieldList) => {
  const notSupportTypes = querySceneCtrlTypes(SceneType.AutofillConditionScene).concat(NotRuleTypes$1);
  return getDefaultConditionFields(fieldList, notSupportTypes);
};
function getDefaultOperation(type, properties) {
  const operations = getDefaultOperations(type, properties);
  const equalOperation = operations.find((item) => item.value === ArithmeticOperatorTypes.Equal);
  if (equalOperation) {
    return equalOperation;
  }
  return getControlDefaultMethod(type, properties);
}
function getOperations(type, properties) {
  return getDefaultOperations(type, properties);
}
function getValueFields(dynamicSelectList, value, scene) {
  const result = getDefaultValueFields(dynamicSelectList, value, scene);
  if (value.controlType === ControlType.Relationship) {
    return result.filter((item) => item.value !== "ObjectId");
  }
  return result;
}
const autofillConditionScene = {
  supportAndOrOperation: false,
  showMethodSelect: true,
  showValueTypeSelect: true,
  allowDuplicate: true,
  getConditionFields,
  getOperations,
  getValueTypes,
  getDefaultOperation,
  getValueFields,
  getFixedRenderType: getDefaultFixedRenderType
};
const SceneMap = {
  [FilterSceneType.CreatDataWorkflowScene]: createDataWorkflow,
  [FilterSceneType.CreatTimeWorkflowScene]: createTimeWorkflow,
  [FilterSceneType.WorkflowBranchNodeScene]: workflowBranchNodeScene,
  [FilterSceneType.SubmitValidateScene]: submitValidateScene,
  [FilterSceneType.DisplayConditionScene]: displayConditionScene,
  [FilterSceneType.ColorConditionScene]: colorConditionScene,
  [FilterSceneType.RelationshipSelectionRangeScene]: relationshipSelectionRangeScene,
  [FilterSceneType.ToolbarFilterScene]: toolbarFilterScene,
  [FilterSceneType.RelationshipFilterScene]: relationshipFilterScene,
  [FilterSceneType.SearchReplaceScene]: searchReplaceScene,
  [FilterSceneType.RollupConditionScene]: rollupConditionScene,
  [FilterSceneType.RelationshipQuickFilterScene]: relationshipQuickFilterScene,
  [FilterSceneType.MemberDataRangeScene]: memberDataRangeScene,
  [FilterSceneType.SelectOptionsRelationScene]: selectOptionsRelationScene,
  [FilterSceneType.MemberCustomConditionScene]: memberCustomConditionScene,
  [FilterSceneType.AutofillConditionScene]: autofillConditionScene
};
export {
  DataRangeRuleValueTypeList,
  DateRuleValueTypeList,
  DynamicRangeOpertions,
  DynamicRangeTimeOptions,
  DynamicRangeUnitOptions,
  FiexdRenderT,
  FilterSceneType,
  NotRuleTypes$1 as NotRuleTypes,
  SceneMap,
  defaultFixedRenderTypeMap,
  defaultOperationMap,
  defaultSupportedOperationMap,
  defaultSupportedOperatorMap,
  defaultValueTypeList,
  fieldDefines,
  getControlDefaultMethod,
  getDefaultConditionFields,
  getDefaultFixedRenderType,
  getDefaultOperationType,
  getDefaultOperations,
  getDefaultValueFields,
  getDefaultValueTypes,
  getDynamicValueType,
  getExpressions,
  getRealType,
  getValueType,
  getValueTypeList,
  handleSpecialField
};
