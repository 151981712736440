import { cloneDeep } from 'lodash';
import {
  RuleItemType, RuleValueType, RuleOperators, DateRuleValueType, RelativeDateType, DynamicRangeValueType, ParseDateFormatString
} from '../../../const/rule';
import { FieldType } from '../../../const';
import moment from 'moment';
// /** 在/不范围内 || 等/不于 || 早于 */
function Yesterday () {
  const list = [];
  list.push(moment().startOf('day').subtract(1, 'day').format(ParseDateFormatString));
  list.push(moment().endOf('day').subtract(1, 'day').format(ParseDateFormatString));
  return list;
};
/** 今天 */
function Today () {
  const list = [];
  list.push(moment().startOf('day').format(ParseDateFormatString));
  list.push(moment().endOf('day').format(ParseDateFormatString));
  return list;
};
/** 明天天 */
function Tomorrow () {
  const list = [];
  list.push(moment().startOf('day').add(1, 'day').format(ParseDateFormatString));
  list.push(moment().endOf('day').add(1, 'day').format(ParseDateFormatString));
  return list;
};
/** 本周 */
function CurrentWeek () {
  const list = [];
  list.push(moment().startOf('week').format(ParseDateFormatString));
  list.push(moment().endOf('week').format(ParseDateFormatString));
  return list;
};
/** 上周 */
function LastWeek () {
  const list = [];
  list.push(moment().startOf('week').subtract(1, 'week').format(ParseDateFormatString));
  list.push(moment().endOf('week').subtract(1, 'week').format(ParseDateFormatString));
  return list;
};
/** 下周  */
function NextWeek () {
  const list = [];
  list.push(moment().startOf('week').add(1, 'week').format(ParseDateFormatString));
  list.push(moment().endOf('week').add(1, 'week').format(ParseDateFormatString));
  return list;
};
/** 本月 */
function CurrentMonth () {
  const list = [];
  list.push(moment().startOf('month').format(ParseDateFormatString));
  list.push(moment().endOf('month').format(ParseDateFormatString));
  return list;
}; 
/** 上月 */
function LastMonth () {
  const list = [];
  list.push(moment().startOf('month').subtract(1, 'month').format(ParseDateFormatString));
  list.push(moment().subtract(1, 'month').endOf('month').format(ParseDateFormatString));
  return list;
};
/** 下月 */
function NextMonth () {
  const list = [];
  list.push(moment().startOf('month').add(1, 'month').format(ParseDateFormatString));
  list.push(moment().add(1, 'month').endOf('month').format(ParseDateFormatString));
  return list;
};
/** 本季度 */
function CurrentQuarter () {
  const list = [];
  list.push(moment().startOf('quarter').format(ParseDateFormatString));
  list.push(moment().endOf('quarter').format(ParseDateFormatString));
  return list;
};
/** 上季度 */
function LastQuarter () {
  const list = [];
  list.push(moment().startOf('quarter').subtract(1, 'quarter').format(ParseDateFormatString));
  list.push(moment().endOf('quarter').subtract(1, 'quarter').format(ParseDateFormatString));
  return list;
};
/** 下季度 */
function NextQuarter () {
  const list = [];
  list.push(moment().startOf('quarter').add(1, 'quarter').format(ParseDateFormatString));
  list.push(moment().endOf('quarter').add(1, 'quarter').format(ParseDateFormatString));
  return list;
};

const dateValueCommenMap: Record<string, Function> = {
  [RuleValueType.Yesterday]: Yesterday,
  [RuleValueType.Today]: Today,
  [RuleValueType.Tomorrow]: Tomorrow,
  [RuleValueType.CurrentWeek]: CurrentWeek,
  [RuleValueType.LastWeek]: LastWeek,
  [RuleValueType.NextWeek]: NextWeek,
  [RuleValueType.CurrentMonth]: CurrentMonth,
  [RuleValueType.LastMonth]: LastMonth,
  [RuleValueType.NextMonth]: NextMonth,
  [RuleValueType.CurrentQuarter]: CurrentQuarter,
  [RuleValueType.LastQuarter]: LastQuarter,
  [RuleValueType.NextQuarter]: NextQuarter,
};
// 快捷日期解析
function parseTime (quickDate: DateRuleValueType | string) {   
  return dateValueCommenMap[quickDate]();
}

// 获取相对值日期范围
function parseTimeByRelativeDate(param: DynamicRangeValueType) {
  const { time, num, unit } = param;
  const list = [];
  let date;
  switch (time) {
    case RelativeDateType.Current:
      date = moment();
      break;
    case RelativeDateType.Past:
      date = moment().add(-num, unit);
      break;
    case RelativeDateType.Future:
      date = moment().add(num, unit);
      break;
    default:
      break;
  }
  list.push(date.startOf(unit).format(ParseDateFormatString));
  list.push(date.endOf(unit).format(ParseDateFormatString));
  return list;
}

/**
 * 获取目标日期范围精准值
 * @param range [start, end]
 * @param type 
 * @param isQuickType 是否快捷日期类型，如今天，明天
 * @returns 
 */
function getExactRangeValue(range: string[], type: RuleOperators, isQuickType = false) {
  let date;
  switch (type) {
    case RuleOperators.Above:
      if (isQuickType) {
        // date = moment(range[1]).startOf('day').add(1, 'day').format(ParseDateFormatString);
        date = range[1];
      } else {
        date = moment(range[0]).endOf('day').add(-1, 'day').format(ParseDateFormatString);
      }
      break;
    case RuleOperators.Below:
      if (isQuickType) {
        // date = moment(range[0]).endOf('day').add(-1, 'day').format(ParseDateFormatString);
        date = range[0];
      } else {
        date = moment(range[1]).startOf('day').add(1, 'day').format(ParseDateFormatString);
      }
      break;
    default:
      break;
  }
  return date;
}

// 解析日期范围真实时间范围值
function parseDataRange(params: { start?: DynamicRangeValueType, end?: DynamicRangeValueType } ) {
  let min = null;
  let max = null;
  let range;
  const { start, end } = params;
  if (start) {
    range = parseTimeByRelativeDate(start);
    min = end ? range[0] : getExactRangeValue(range, RuleOperators.Above);
  }
  if (end) {
    range = parseTimeByRelativeDate(end);
    max = start ? range[1] : getExactRangeValue(range, RuleOperators.Below);
  }
  return min ? max ? [min,max] : [min] : [max];
}

// 快速查询日期类型解析
function parseRangeByQuickDate(type: RuleOperators, quickDate: DateRuleValueType) {
  const range = parseTime(quickDate);
  switch (type) {
    case RuleOperators.Above:
    case RuleOperators.Below:
      return [getExactRangeValue(range, type, true)];
    default:
      return range;
  }
}

// 转换规则操作符
function transformOperator(method: RuleOperators) {
  const rangeList = [
    RuleOperators.Range,
    RuleOperators.Equal,
  ];
  
  const outOfRangeList = [
    RuleOperators.OutRange,
    RuleOperators.NotEqual,
  ];
  if (rangeList.includes(method)) {
    return RuleOperators.Range;
  }
  if (outOfRangeList.includes(method)) {
    return RuleOperators.OutRange;
  }
  return method;
}

function transformOperatorByValueType(method: RuleOperators, value: DynamicRangeValueType[]) {
  const isBelow = value[0].time === RelativeDateType.UnLimited && value[1].time !== RelativeDateType.UnLimited;
  const isAbove = value[1].time === RelativeDateType.UnLimited && value[0].time !== RelativeDateType.UnLimited;
  if (isBelow) {
    return RuleOperators.Below;
  }
  // 晚于 终止时间value[1]为无限
  if (isAbove) {
    return RuleOperators.Above;
  }
  return method;
}

function isDateType(type: any) {
  return [
    FieldType.DateTime,
    FieldType.CreatedTime,
    FieldType.ModifiedTime,
  ].includes(type);
}

// 规则的解析
function transform(value: RuleItemType[][]) {
  if (!value) {
    return [];
  }
  const newValue = cloneDeep(value);
  const RuleValueTypes = [
    RuleValueType.Dynamic,
    RuleValueType.Fixed,
    RuleValueType.DynamicRange,
  ];
  const RangeMethodList = [
    RuleOperators.Range,
    RuleOperators.OutRange,
  ];
  // 需要剔除掉无意义的ruleitem
  const ret = [];
  newValue.forEach(el => {
    const childs = [];
    el.forEach((e) => {
      if (isDateType(e.controlType) && e.valueType) {
        if (RangeMethodList.includes(e.method) && e.valueType === RuleValueType.DynamicRange && e.value.length > 0) {
          e.method = transformOperatorByValueType(e.method, e.value);
          const values = e.value as DynamicRangeValueType[];
          const isMeaningless = values[1].time === RelativeDateType.UnLimited && values[0].time === RelativeDateType.UnLimited;
          if (isMeaningless) {
            return;
          }
          values.forEach(el => {
            el.num = el.num ? el.num : 1;
          });
          const start = values[0].time === RelativeDateType.UnLimited ? null : values[0];
          const end = values[1].time === RelativeDateType.UnLimited ? null : values[1];
          e.value = parseDataRange({ start, end });
        } else {
          if (!RuleValueTypes.includes(e.valueType)) {
            e.method = transformOperator(e.method);
            e.value = parseRangeByQuickDate(e.method, e.valueType as any);
          }
        }
      }
      if (e.value && e.value.length && e.value[0] || [RuleOperators.Empty,  RuleOperators.NotEmpty].includes(e.method)){
        childs.push(e);
      }
      
    });
    ret.push(childs);
  });
  return ret;
};

export {
  transform,
  parseTime,
};
