
import { Formula } from '../type';

const FILECOUNT: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'FILECOUNT',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.Attachment,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.NUMBER,
    sourceType: Formula.SourceType.EDIT,
  },
  calculate: (identifier, params, compiler) => {
    const _params = compiler._pretreatment(identifier, params);
    const [_param] = _params;
    let value = null;
    if (Array.isArray(_param.value)) {
      value = _param.value.length;
    }
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default FILECOUNT;
