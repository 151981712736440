import { reactive, defineComponent, ref, computed, provide, watch, onMounted } from "@vue/composition-api";
import { ruleExec } from "@lcos-kernel/chicken-library";
import DataLogic, { batchInit } from "@lcos-kernel/data-logic";
import { cloneDeep, isEqual, filter } from "lodash";
import { ControlType } from "@lcos-kernel/controls-core";
import Vue from "vue";
import moment from "moment";
function useDisplayCols({
  columns,
  rowData,
  permissions
}) {
  const displayControlState = {};
  columns.forEach(({
    code
  }) => {
    if (permissions[code]) {
      displayControlState[code] = permissions[code].Visible;
    }
  });
  const displayControl = reactive(displayControlState);
  async function initDisplayCols() {
    const titleField = columns.find((item) => item.titleFlag) || columns[0];
    for (let i = 0; i < columns.length; i++) {
      const control = columns[i];
      if (control === titleField) {
        continue;
      }
      const rules = control.displayRule;
      if (!rules || rules.length === 0) {
        continue;
      }
      const status = await ruleExec(rowData, rules);
      displayControl[control.code] = status;
    }
    console.log(displayControl);
  }
  return {
    displayControl,
    initDisplayCols
  };
}
function guid() {
  return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === "x" ? r : r & 3 | 8;
    return v.toString(16);
  });
}
const controlBaseProps = {
  appCode: {
    type: String,
    required: true
  },
  schemaCode: {
    type: String,
    required: true
  },
  viewCode: {
    type: String
  },
  bizObjectId: {
    type: String,
    required: true
  },
  parentSchemaCode: {
    type: String
  },
  parentFieldCode: {
    type: String
  },
  parentObjectId: {
    type: String
  },
  rootObject: {
    type: Object
  },
  rootColumns: {
    type: Array
  },
  appPermissions: {
    type: Object,
    require: true
  },
  columns: {
    type: Array,
    required: true
  },
  preference: {
    type: Object
  },
  orgTabPanes: {
    type: Array
  },
  workItemId: {
    type: String
  }
};
const baseProps = {
  ...controlBaseProps,
  isCreateMode: {
    type: Boolean,
    default: false
  },
  isInited: {
    type: Boolean,
    default: false
  },
  permissions: {
    type: Object,
    required: true
  },
  displayFields: {
    type: Array
  },
  operatePermission: {
    type: Object
  },
  isLoading: {
    type: Boolean
  },
  isMobileEnv: {
    type: Boolean,
    default: false
  }
};
const controlsProps = {
  ...baseProps,
  uowId: {
    type: String,
    required: false
  },
  rowValues: {
    type: Object
  },
  subTableValue: {
    type: Object
  },
  displayControl: {
    type: Object
  },
  errorsMsg: {
    type: Object
  }
};
const rFuncProps = {
  renderControls: {
    type: Function,
    required: true
  }
};
const ControlsWrapper = defineComponent({
  name: "ControlsWrapper",
  props: {
    ...controlsProps,
    ...rFuncProps
  },
  setup(props, context) {
    return () => {
      return props.renderControls({
        props,
        on: context.listeners
      });
    };
  }
});
const formProps = {
  ...baseProps,
  rowData: {
    type: Object
  },
  systemDefaultValue: {
    type: Object
  },
  formDefaultValue: {
    type: Object
  },
  formContext: {
    type: Object
  },
  enableDraft: Boolean,
  openByRelationship: {
    type: Object
  }
};
const formCoreProps = {
  ...formProps,
  ...rFuncProps
};
var DataType = /* @__PURE__ */ ((DataType2) => {
  DataType2[DataType2["Add"] = 1] = "Add";
  DataType2[DataType2["Update"] = 2] = "Update";
  DataType2[DataType2["Delete"] = 3] = "Delete";
  return DataType2;
})(DataType || {});
var DraftDataState = /* @__PURE__ */ ((DraftDataState2) => {
  DraftDataState2[DraftDataState2["Empty"] = 0] = "Empty";
  DraftDataState2[DraftDataState2["Changed"] = 1] = "Changed";
  return DraftDataState2;
})(DraftDataState || {});
var DraftStatus = /* @__PURE__ */ ((DraftStatus2) => {
  DraftStatus2[DraftStatus2["Editing"] = 1] = "Editing";
  DraftStatus2[DraftStatus2["Submiting"] = 2] = "Submiting";
  DraftStatus2[DraftStatus2["Exception"] = 3] = "Exception";
  DraftStatus2[DraftStatus2["Idle"] = 4] = "Idle";
  return DraftStatus2;
})(DraftStatus || {});
var ViewType = /* @__PURE__ */ ((ViewType2) => {
  ViewType2[ViewType2["Exception"] = 1] = "Exception";
  ViewType2[ViewType2["All"] = 2] = "All";
  return ViewType2;
})(ViewType || {});
var ListActionType = /* @__PURE__ */ ((ListActionType2) => {
  ListActionType2[ListActionType2["Add"] = 0] = "Add";
  ListActionType2[ListActionType2["Edit"] = 1] = "Edit";
  ListActionType2[ListActionType2["Delete"] = 2] = "Delete";
  return ListActionType2;
})(ListActionType || {});
class BaseFormDataManager {
  constructor(params) {
    this.formContext = null;
    this.values = {};
    this.preValues = {};
    this.columnMap = {};
    this.displayControl = {};
    this.fieldChangeCodes = [];
    this.errorsMap = {};
    this.uowId = "";
    this.isSubTableChange = false;
    this.controlsMethod = {};
    this.isSubmiting = false;
    this.subtableValue = {};
    this.state = Vue.observable({
      status: ""
    });
    this.processing = {
      value: false,
      resolve: () => {
      }
    };
    this.changeSubTable = params.changeSubTable;
    this.syncRecoverValues = params.syncRecoverValues;
    this.syncReplaceOriginValues = params.syncReplaceOriginValues;
    this.changeFieldCodes = params.changeFieldCodes;
    this.formContext = params.formContext;
  }
  async initData(params) {
    this.isCreateMode = params.isCreateMode;
    this.values = cloneDeep(params.row);
    this.columns = params.columns;
    this.columnMap = this.getColumnMap(this.columns);
    this.displayControl = params.displayControl;
    params.columns.forEach(({
      code
    }) => {
      this.errorsMap[code] = null;
    });
    this.preValues = cloneDeep(params.sourceRow);
    this.openByRelationship = params.openByRelationship;
  }
  onDataLogicValidate() {
    this.dataLogic.on("validate", (results) => {
      console.log("validate----", results);
      results.forEach((item) => {
        this.changeErrorMsg(item.code, item.errorMsg);
      });
    });
  }
  async initDataLogicBase(params) {
    this.dataLogic = new DataLogic({
      columns: params.columns,
      origin: this.values,
      config: {
        ...params.dataLogicConfig,
        uowId: this.uowId
      }
    });
    this.dataLogic.on("change", (changed) => {
      var _a, _b;
      console.log("\u503C\u53D8\u5316", changed, this.dataLogic);
      if (!this.isCreateMode) {
        Object.keys(changed).forEach((keyCode) => {
          this.collectFormChangedFields(keyCode, this.values[keyCode], this.preValues[keyCode]);
        });
        if (((_a = this.values.ModifiedBy) == null ? void 0 : _a.id) !== ((_b = this.dataLogic.config.defaultValues.OwnerId) == null ? void 0 : _b.id)) {
          this.updateValue("ModifiedBy", this.dataLogic.config.defaultValues.OwnerId);
        }
      }
    });
    this.dataLogic.on("visibleChange", (visibleChanged) => {
      console.log("\u5C55\u793A\u53D8\u5316", visibleChanged);
      Object.keys(visibleChanged).forEach((key) => {
        this.displayControl[key] = visibleChanged[key];
      });
    });
    this.dataLogic.on("processing", (val) => {
      this.processing.value = val;
      if (!val) {
        setTimeout(() => {
          if (typeof this.processing.resolve === "function") {
            this.processing.resolve();
          }
          this.processing.resolve = null;
        }, 20);
      }
    });
  }
  async waitForEdit() {
    await new Promise((resolve) => setTimeout(() => resolve(null), 50));
    if (this.processing.value) {
      this.state.status = "updating";
      await new Promise((resolve) => {
        this.processing.resolve = resolve;
      });
      this.state.status = "";
    }
    return true;
  }
  getRootColumnMap(rootSchemaCode, columns) {
    const columnMap = {};
    columns.forEach((column) => {
      columnMap[`${rootSchemaCode}.${column.code}`] = column;
    });
    return columnMap;
  }
  getColumnMap(columns) {
    const columnMap = {};
    columns.forEach((column) => {
      columnMap[column.code] = column;
    });
    return columnMap;
  }
  changeErrorMsg(code, msg) {
    this.errorsMap[code] = msg;
  }
  batchSetErrorMsg(errors) {
    errors.forEach((error) => {
      this.errorsMap[error.code] = error.errorMsg;
    });
  }
  updateValue(code, value) {
    var _a;
    if (((_a = this.columnMap[code]) == null ? void 0 : _a.type) === ControlType.SubTable) {
      this.dataLogic.setData(code, {
        ...this.values[code],
        ...value
      });
    } else {
      this.dataLogic.setData(code, value);
    }
    this.isCreateMode && this.collectFormChangedFields(code, value, this.preValues[code]);
    if (this.formContext) {
      if (this.formContext.controlOptions[code] && this.formContext.controlOptions[code].update) {
        this.formContext.controlOptions[code].update(value);
      }
    }
  }
  inited(code) {
    var _a;
    if (((_a = this.columnMap[code]) == null ? void 0 : _a.type) === ControlType.SubTable) {
      this.isSubTableChange = false;
      const index = this.fieldChangeCodes.indexOf(code);
      if (index > -1) {
        this.fieldChangeCodes.splice(index, 1);
        this.changeFieldCodes([...this.fieldChangeCodes]);
      }
    }
  }
  updateValues(code, action, value) {
    const values = this.values[code];
    const result = values !== null ? cloneDeep(values) : {
      records: [],
      total: 0
    };
    let idx = -1;
    let v;
    switch (action) {
      case ListActionType.Add:
        result.records.unshift(value);
        result.total += 1;
        break;
      case ListActionType.Edit:
        v = value;
        idx = result.records.findIndex((r) => r.ObjectId === v.ObjectId);
        if (idx > -1) {
          result.records.splice(idx, 1, v);
        }
        break;
      case ListActionType.Delete:
        v = value;
        idx = result.records.findIndex((r) => r.ObjectId === v);
        if (idx > -1) {
          result.records.splice(idx, 1);
        }
        result.total -= 1;
        break;
    }
    this.updateValue(code, result);
  }
  isControlsChange() {
    return this.fieldChangeCodes.length > 0 || this.isSubTableChange;
  }
  hasChange(col, a, b) {
    var _a;
    let isChanged = false;
    if ((col == null ? void 0 : col.type) === ControlType.SubTable) {
      isChanged = !!(a == null ? void 0 : a.records);
    } else if ((col == null ? void 0 : col.type) === ControlType.Relationship) {
      const calculateType = (_a = col == null ? void 0 : col.properties) == null ? void 0 : _a.relationAutoFillCalculateType;
      if (calculateType === "systemCalculated") {
        isChanged = false;
      } else if (calculateType === "autoFill" && (a == null ? void 0 : a.empty)) {
        isChanged = true;
      } else {
        const {
          Append = [],
          Delete = [],
          Update = []
        } = (a == null ? void 0 : a.increments) || {};
        const {
          Append: Append2 = [],
          Delete: Delete2 = [],
          Update: Update2 = []
        } = (b == null ? void 0 : b.increments) || {};
        const originHasIncrement = Append2.length > 0 || Delete2.length > 0 || Update2.length > 0;
        if (originHasIncrement) {
          isChanged = !isEqual(Append, Append2) || !isEqual(Delete, Delete2) || !isEqual(Update, Update2);
        } else {
          isChanged = Append.length > 0 || Delete.length > 0 || Update.length > 0;
        }
      }
    } else if ((col == null ? void 0 : col.type) === ControlType.Attachment) {
      const idsAfter = (a || []).map((file) => file.fileId);
      const idsBefore = (b || []).map((file) => file.fileId);
      isChanged = !isEqual(idsAfter, idsBefore);
    } else {
      isChanged = !isEqual(this.transferVal(col.type, a, col.code), this.transferVal(col.type, b, col.code));
    }
    return isChanged;
  }
  collectFormChangedFields(code, curVal, preVal) {
    let changed = true;
    const column = this.columns.find((col) => col.code === code);
    if (column) {
      changed = this.hasChange(column, curVal, preVal);
    }
    const index = this.fieldChangeCodes.indexOf(code);
    const isExist = index > -1;
    if (!isExist) {
      changed && this.fieldChangeCodes.push(code);
    } else {
      !changed && this.fieldChangeCodes.splice(index, 1);
    }
    this.changeFieldCodes([...this.fieldChangeCodes]);
  }
  validateControl(needValidateFields) {
    return this.dataLogic.validate(needValidateFields).then((resps) => {
      return resps.filter((resp) => !!resp);
    }).then((vRes) => vRes.filter((resp) => !resp.status)).then((errors) => {
      var _a, _b;
      if (errors.length) {
        this.batchSetErrorMsg(errors);
        ((_b = (_a = this.formContext) == null ? void 0 : _a.validation) == null ? void 0 : _b.errorHandler) && this.formContext.validation.errorHandler(errors);
        return false;
      }
      return true;
    });
  }
  getSubmitFiles(val, oldVal) {
    let sourceKeys = [];
    let newKeys = [];
    let toAttachFileIds = [];
    let toDeleteFileIds = [];
    if (oldVal) {
      sourceKeys = oldVal.map((file) => file.fileId);
    }
    if (val) {
      newKeys = val.map((file) => file.fileId);
    }
    toAttachFileIds = filter(newKeys, (key) => {
      return !sourceKeys.includes(key);
    });
    toDeleteFileIds = filter(sourceKeys, (key) => {
      return !newKeys.includes(key);
    });
    return {
      toAttachFileIds,
      toDeleteFileIds
    };
  }
  transferVal(type, val, code) {
    var _a, _b, _c;
    switch (type) {
      case ControlType.Attachment:
      case ControlType.Signature:
        return this.getSubmitFiles(val, this.preValues[code]);
      case ControlType.SingleUser:
      case ControlType.SingleDept:
      case ControlType.Owner:
      case ControlType.Creater:
      case ControlType.Modifier:
        return (val == null ? void 0 : val.id) || null;
      case ControlType.MultiUser:
      case ControlType.MultiDept:
      case ControlType.OwnerDept:
        return val ? val.map((o) => o.id) : [];
      case ControlType.Relationship:
        const col = this.columnMap[code];
        const calculateType = (_a = col == null ? void 0 : col.properties) == null ? void 0 : _a.relationAutoFillCalculateType;
        if (calculateType === "systemCalculated") {
          return void 0;
        }
        if (!val) {
          return {
            Append: [],
            Delete: []
          };
        }
        const Delete = [...(_b = val.increments) == null ? void 0 : _b.Delete];
        const Append = [...(_c = val.increments) == null ? void 0 : _c.Append];
        if (val == null ? void 0 : val.empty) {
          return {
            Set: Append
          };
        }
        if (Array.isArray(val._realDeleted)) {
          val._realDeleted.forEach((item) => {
            const index = Delete.indexOf(item);
            if (index > -1) {
              Delete.splice(index, 1);
            }
          });
        }
        return this.openByRelationshipFilter({
          Delete,
          Append
        }, code);
      default:
        return val || null;
    }
  }
  async submitSubtable() {
    var _a, _b;
    const subtables = [];
    Object.values(this.controlsMethod).forEach((controlInfo) => {
      var _a2;
      if (((_a2 = controlInfo == null ? void 0 : controlInfo.column) == null ? void 0 : _a2.type) === ControlType.SubTable) {
        subtables.push(controlInfo);
      }
    });
    for (let i = 0; i < subtables.length; i++) {
      const subtable = subtables[i];
      if (subtable) {
        const {
          method,
          column
        } = subtable;
        const ret = await method();
        if (!ret) {
          this.errorsMap[column.code] = "\u5B50\u8868\u5B58\u5728\u6821\u9A8C\u9519\u8BEF";
          const errors = [{
            code: column.code,
            errorMsg: "\u5B50\u8868\u5B58\u5728\u6821\u9A8C\u9519\u8BEF"
          }];
          this.batchSetErrorMsg(errors);
          ((_b = (_a = this.formContext) == null ? void 0 : _a.validation) == null ? void 0 : _b.errorHandler) && this.formContext.validation.errorHandler(errors);
          return false;
        }
        this.subtableValue[column.code] = ret;
      }
    }
  }
  async checkSubmit() {
    const self = this;
    await new Promise((resolve) => setTimeout(() => resolve(null), 100));
    return new Promise((resolve) => {
      function checkUpdating() {
        if (self.processing.value) {
          return true;
        }
        return false;
      }
      let timer;
      function loop() {
        if (checkUpdating()) {
          timer = setTimeout(loop, 200);
        } else {
          resolve(true);
          clearTimeout(timer);
        }
      }
      loop();
    });
  }
  isNotSubTable(type) {
    return type !== ControlType.SubTable;
  }
  recoverRelationshipCtrl() {
    Object.keys(this.values).forEach((val) => {
      if (this.values[val] && this.values[val].increments) {
        const ctrlVal = this.columns.find((column) => column.code === val);
        if (ctrlVal && ctrlVal.type === ControlType.Relationship) {
          const Append = this.values[val].increments.Append || [];
          this.values[val].empty = false;
          this.values[val].tempRecords = [];
          this.values[val].increments = {
            Append: [],
            Delete: []
          };
          if (this.openByRelationship && this.openByRelationship.relationControlCode === val) {
            const targetId = this.openByRelationship.record.ObjectId;
            if (Append.includes(targetId)) {
              this.values[val].increments.Append = [targetId];
            }
          }
        }
      }
    });
  }
  openByRelationshipFilter(val, code) {
    if (this.openByRelationship && this.openByRelationship.relationControlCode === code) {
      const targetId = this.openByRelationship.record.ObjectId;
      const newVal = cloneDeep(val);
      [newVal.Append, newVal.Delete, newVal.Update].forEach((item) => {
        if (item && Array.isArray(item)) {
          const index = item.indexOf(targetId);
          if (index > -1) {
            item.splice(index, 1);
          }
        }
      });
      return newVal;
    }
    return val;
  }
  recoverSubtableCtrl() {
    Object.keys(this.values).forEach((val) => {
      const ctrlVal = this.columns.find((column) => column.code === val);
      if (ctrlVal && ctrlVal.type === ControlType.SubTable) {
        this.values[val].empty = false;
      }
    });
  }
}
class FormDataManager extends BaseFormDataManager {
  async initDataLogic(params) {
    this.initDataLogicBase(params);
    this.dataLogic.on("subTableChange", async (changed) => {
      const keys = Object.keys(changed);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (Array.isArray(changed[key])) {
          const list = changed[key] || [];
          if (list.length === 0) {
            const newVal = {
              increments: {
                Append: [],
                Delete: []
              },
              total: 0,
              values: [],
              empty: true,
              records: []
            };
            this.dataLogic.setData(key, newVal);
            console.log(this.values[key]);
            await this.waitForEdit();
            this.changeSubTable(key, newVal);
            this.isSubTableChange = true;
            this.collectFormChangedFields(key, this.values[key], this.preValues[key]);
            continue;
          }
          const column = params.columns.find((col) => col.code === key);
          if (column) {
            const config = {
              schemaCode: column.properties.relationCode,
              appCode: params.dataLogicConfig.appCode,
              viewCode: params.dataLogicConfig.viewCode,
              rootColumnMap: this.getRootColumnMap(params.dataLogicConfig.schemaCode, params.columns),
              rootObject: this.values,
              defaultValues: this.dataLogic.config.defaultValues || {}
            };
            list.forEach((row) => {
              row.ObjectId = guid();
            });
            const newRows = await batchInit({
              config,
              columns: column.childrenControls,
              rows: list
            });
            const newVal = {
              increments: {
                Append: [],
                Delete: []
              },
              total: newRows.length,
              values: newRows.map((item) => {
                return {
                  id: item.ObjectId,
                  name: item.Name || ""
                };
              }),
              empty: true,
              records: newRows
            };
            this.dataLogic.setData(key, newVal);
            await this.waitForEdit();
            this.changeSubTable(key, newVal);
            this.isSubTableChange = true;
            this.collectFormChangedFields(key, this.values[key], this.preValues[key]);
          }
        } else {
          const {
            result,
            source
          } = changed[key];
          const newVal = {
            increments: {
              Append: [],
              Delete: []
            },
            total: result.length,
            values: result.slice(0, 5).map((row) => {
              return {
                id: row.ObjectId,
                name: row.Name || ""
              };
            }),
            empty: false,
            records: result
          };
          this.dataLogic.setData(key, newVal);
          await this.waitForEdit();
          this.changeSubTable(key, {
            new: result,
            source
          });
          this.isSubTableChange = true;
          this.collectFormChangedFields(key, this.values[key], this.preValues[key]);
        }
      }
    });
    if (params.isCreateMode && !params.isInited) {
      return await this.dataLogic.dataInit({});
    }
  }
  async init(params) {
    this.initData(params);
    await this.initDataLogic(params);
    this.onDataLogicValidate();
  }
  completeValues(all) {
    this.preValues = cloneDeep({
      ...all,
      ...this.preValues
    });
    this.values = this.dataLogic.origin = cloneDeep({
      ...all,
      ...this.values
    });
    this.syncRecoverValues && this.syncRecoverValues(this.values);
  }
  handleSubtableChange(code, action, value) {
    this.updateValues(code, action, value);
  }
  handleCollectionChange(column, action, value) {
    if (column.type === ControlType.SubTable) {
      this.handleSubtableChange(column.code, action, value);
    }
  }
  async getNeedFormData(columns) {
    var _a;
    const changedReally = {};
    for (const field of columns) {
      if (this.isNotSubTable(field.type)) {
        changedReally[field.code] = this.transferVal(field.type, this.values[field.code], field.code);
      } else {
        if (this.subtableValue[field.code]) {
          changedReally[field.code] = {
            data: this.subtableValue[field.code],
            empty: (_a = this.values[field.code]) == null ? void 0 : _a.empty
          };
        }
      }
    }
    return changedReally;
  }
  async getSubmitFormData(columns = null, isValidate = true) {
    if (isValidate) {
      const res = await this.submitSubtable();
      if (res === false) {
        return null;
      }
    }
    await this.checkSubmit();
    let cols = columns || this.columns;
    const displayControls = cols.filter(({
      code
    }) => this.displayControl[code]);
    if (!columns) {
      cols = cols.filter(({
        code
      }) => this.fieldChangeCodes.includes(code));
    }
    const formData = await this.getNeedFormData(cols);
    if (isValidate) {
      const validateResult = await this.validateControl(displayControls.map(({
        code
      }) => code));
      if (validateResult) {
        return formData;
      } else {
        return null;
      }
    }
    return Promise.resolve(formData);
  }
  async getSubmitFormAllData(isValidate = true) {
    return await this.getSubmitFormData(this.columns, isValidate);
  }
  recoverValues() {
    this.values = this.dataLogic.origin = cloneDeep(this.preValues);
    this.fieldChangeCodes = [];
    this.changeFieldCodes && this.changeFieldCodes(this.fieldChangeCodes);
    this.syncRecoverValues && this.syncRecoverValues(this.values);
  }
  replaceOriginValues() {
    this.recoverRelationshipCtrl();
    this.recoverSubtableCtrl();
    this.preValues = cloneDeep(this.values);
    this.fieldChangeCodes = [];
    this.changeFieldCodes && this.changeFieldCodes(this.fieldChangeCodes);
    this.syncReplaceOriginValues && this.syncReplaceOriginValues(this.fieldChangeCodes, this.uowId);
  }
}
async function initFormDataManager(params) {
  const instance = new FormDataManager(params);
  await instance.init(params);
  return instance;
}
function useSubmit(columns) {
  const getSubmitFiles = (newRow, sourceRow, code) => {
    let sourceKeys = [];
    let newKeys = [];
    let toAttachFileIds = [];
    let toDeleteFileIds = [];
    if (sourceRow[code]) {
      sourceKeys = sourceRow[code].map((file) => file.fileId);
    }
    if (newRow[code]) {
      newKeys = newRow[code].map((file) => file.fileId);
    }
    toAttachFileIds = filter(newKeys, (key) => {
      return !sourceKeys.includes(key);
    });
    toDeleteFileIds = filter(sourceKeys, (key) => {
      return !newKeys.includes(key);
    });
    return {
      toAttachFileIds,
      toDeleteFileIds
    };
  };
  const getColValue = (row, col, val) => {
    var _a, _b, _c;
    switch (col == null ? void 0 : col.type) {
      case ControlType.Attachment:
      case ControlType.Signature:
        return getSubmitFiles(row.data, row.status === DataType.Add ? {} : row.source, col.code);
      case ControlType.SingleUser:
      case ControlType.SingleDept:
      case ControlType.Owner:
      case ControlType.Creater:
      case ControlType.Modifier:
        return (val == null ? void 0 : val.id) || "";
      case ControlType.OwnerDept:
        return val && val.length > 0 ? val.map((u) => u.id) : [];
      case ControlType.MultiUser:
      case ControlType.MultiDept:
        return val ? val.map((o) => o.id) : [];
      case ControlType.Relationship:
        const calculateType = (_a = col == null ? void 0 : col.properties) == null ? void 0 : _a.relationAutoFillCalculateType;
        if (calculateType === "systemCalculated") {
          return void 0;
        }
        if (!val) {
          return {
            Append: [],
            Delete: []
          };
        }
        const Delete = [...(_b = val.increments) == null ? void 0 : _b.Delete];
        const Append = [...(_c = val.increments) == null ? void 0 : _c.Append];
        if (val == null ? void 0 : val.empty) {
          return {
            Set: Append
          };
        }
        if (Array.isArray(val._realDeleted)) {
          val._realDeleted.forEach((item) => {
            const index = Delete.indexOf(item);
            if (index > -1) {
              Delete.splice(index, 1);
            }
          });
        }
        return {
          Delete,
          Append
        };
      case ControlType.Address:
        return (val == null ? void 0 : val.province) ? val : null;
      default:
        return val || null;
    }
  };
  const getChangedContent = (row, cols) => {
    const changedCodes = [];
    Object.keys(row.data).forEach((key) => {
      var _a;
      const a = row.data[key];
      const b = row.source[key];
      const col = cols.find((item) => item.code === key);
      if (!col) {
        return;
      }
      let isChanged = false;
      if ((col == null ? void 0 : col.type) === ControlType.Relationship) {
        const calculateType = (_a = col == null ? void 0 : col.properties) == null ? void 0 : _a.relationAutoFillCalculateType;
        if (calculateType === "systemCalculated") {
          isChanged = false;
        } else if (calculateType === "autoFill" && (a == null ? void 0 : a.empty)) {
          isChanged = true;
        } else {
          const {
            Append,
            Delete,
            Update
          } = (a == null ? void 0 : a.increments) || {};
          if (Append && Append.length > 0 || Delete && Delete.length > 0 || Update && Update.length > 0) {
            isChanged = true;
          }
        }
      } else if ((col == null ? void 0 : col.type) === ControlType.Attachment) {
        const idsAfter = (a || []).map((file) => file.fileId);
        const idsBefore = (b || []).map((file) => file.fileId);
        isChanged = !isEqual(idsAfter, idsBefore);
      } else {
        isChanged = !isEqual(getColValue(row, col, a), getColValue(row, col, b));
      }
      if (isChanged) {
        changedCodes.push(key);
      }
    });
    const content = {};
    changedCodes.forEach((item) => {
      const column = cols.find((col) => col.code === item);
      if (column) {
        content[item] = getColValue(row, column, row.data[item]);
      }
    });
    return content;
  };
  const getAddContent = (row, cols) => {
    const obj = {};
    Object.keys(row.data).forEach((key) => {
      if (key === "ObjectId" || key === "CreatedTime" || key === "isNewRow")
        return;
      if (!row.data[key])
        return;
      const col = cols.find((item) => item.code === key);
      obj[key] = getColValue(row, col, row.data[key]);
    });
    return obj;
  };
  const getSubtableSubmitValue = (rows) => {
    const list = [];
    let sortKey = -1;
    rows.forEach((row) => {
      let obj;
      switch (row.status) {
        case DataType.Add:
          obj = {
            ObjectId: row.id,
            Action: row.status,
            Content: getAddContent(row, columns)
          };
          if (Object.keys(obj.Content).length > 0) {
            sortKey++;
            obj.SortKey = sortKey;
            list.push(obj);
          }
          break;
        case DataType.Update:
          obj = {
            ObjectId: row.id,
            Action: row.status,
            Content: getChangedContent(row, columns)
          };
          list.push(obj);
          break;
        case DataType.Delete:
          obj = {
            ObjectId: row.id,
            Action: row.status,
            Content: {}
          };
          if (row.id) {
            list.push(obj);
          }
          break;
      }
    });
    return list;
  };
  return {
    getSubtableSubmitValue
  };
}
class MobileFormDataManager extends BaseFormDataManager {
  constructor(params) {
    super(params);
    this.drafts = null;
    this.enableDraft = false;
    this.enableDraft = params.enableDraft || false;
  }
  async initDataLogic(params) {
    this.initDataLogicBase(params);
    this.dataLogic.on("subTableChange", async (changed) => {
      const keys = Object.keys(changed);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (Array.isArray(changed[key])) {
          const list = changed[key] || [];
          if (list.length === 0) {
            const newVal = {
              increments: {
                Append: [],
                Delete: []
              },
              total: 0,
              values: [],
              empty: true,
              records: []
            };
            this.dataLogic.setData(key, newVal);
            await this.waitForEdit();
            if (this.drafts[key]) {
              this.drafts[key].crossUpdate([]);
              this.formContext.subtables[key].syncDraftRows([]);
              this.formContext.subtables[key].syncState(true);
            } else {
              this.changeSubTable(key, newVal);
            }
            this.isSubTableChange = true;
            this.collectFormChangedFields(key, this.values[key], this.preValues[key]);
            continue;
          }
          const column = params.columns.find((col) => col.code === key);
          if (column) {
            const config = {
              schemaCode: column.properties.relationCode,
              appCode: params.dataLogicConfig.appCode,
              viewCode: params.dataLogicConfig.viewCode,
              rootColumnMap: this.getRootColumnMap(params.dataLogicConfig.schemaCode, params.columns),
              rootObject: this.values,
              defaultValues: this.dataLogic.config.defaultValues || {}
            };
            list.forEach((row) => {
              row.ObjectId = guid();
            });
            const newRows = await batchInit({
              config,
              columns: column.childrenControls,
              rows: list
            });
            const newVal = {
              increments: {
                Append: [],
                Delete: []
              },
              total: newRows.length,
              values: newRows.map((item) => {
                return {
                  id: item.ObjectId,
                  name: item.Name || ""
                };
              }),
              empty: true,
              records: newRows
            };
            this.dataLogic.setData(key, newVal);
            await this.waitForEdit();
            if (this.drafts[key]) {
              this.drafts[key].crossUpdate(newRows);
              this.formContext.subtables[key].syncDraftRows(this.drafts[key].getAll());
              this.formContext.subtables[key].syncState(true);
            } else {
              this.changeSubTable(key, newVal);
              this.isSubTableChange = true;
            }
            this.collectFormChangedFields(key, this.values[key], this.preValues[key]);
          }
        } else {
          const {
            result,
            source
          } = changed[key];
          const newVal = {
            increments: {
              Append: [],
              Delete: []
            },
            total: result.length,
            values: result.slice(0, 5).map((row) => {
              return {
                id: row.ObjectId,
                name: row.Name || ""
              };
            }),
            empty: false,
            records: result
          };
          this.dataLogic.setData(key, newVal);
          await this.waitForEdit();
          if (this.drafts[key]) {
            this.drafts[key].batchCalc(result, source);
            this.formContext.subtables[key].syncDraftRows(this.drafts[key].getAll());
          } else {
            this.changeSubTable(key, {
              new: result,
              source
            });
            this.isSubTableChange = true;
          }
          this.collectFormChangedFields(key, this.values[key], this.preValues[key]);
        }
      }
    });
    if (params.isCreateMode && !params.isInited) {
      return await this.dataLogic.dataInit({});
    }
  }
  async init(params) {
    this.initData(params);
    await this.initDataLogic(params);
    this.onDataLogicValidate();
  }
  updateDraft(code, action, value) {
    let rowData;
    let d;
    switch (action) {
      case ListActionType.Add:
        d = value;
        rowData = {
          data: {
            ObjectId: d.ObjectId
          },
          changed: d,
          status: DataType.Add
        };
        this.drafts[code].add(rowData);
        break;
      case ListActionType.Edit:
        d = value;
        const dr = this.drafts[code].get(d.ObjectId);
        if (dr) {
          rowData = {
            data: dr.data,
            changed: d,
            status: dr.status
          };
        } else {
          const oldValue = this.values[code].records.find((v) => v.ObjectId === d.ObjectId);
          if (oldValue) {
            rowData = {
              data: oldValue,
              changed: d,
              status: DataType.Update
            };
          } else {
            rowData = {
              data: {
                ObjectId: d.ObjectId
              },
              changed: d,
              status: DataType.Update
            };
          }
        }
        this.drafts[code].update(rowData);
        break;
      case ListActionType.Delete:
        rowData = {
          data: {
            ObjectId: value
          },
          status: DataType.Delete
        };
        this.drafts[code].remove(rowData);
        break;
    }
  }
  handleSubtableChange(code, action, value) {
    this.updateDraft(code, action, value);
    this.formContext.subtables[code].syncDraftRows(this.drafts[code].getAll());
    this.updateValues(code, action, value);
  }
  handleCollectionChange(column, action, value) {
    if (column.type === ControlType.SubTable) {
      this.handleSubtableChange(column.code, action, value);
    }
  }
  syncSubtableValues(code, rows) {
    this.values[code] = {
      records: rows,
      total: rows.length,
      values: [],
      empty: false
    };
  }
  async getNeedFormData(columns) {
    var _a;
    const changedReally = {};
    for (const field of columns) {
      if (this.isNotSubTable(field.type)) {
        changedReally[field.code] = this.transferVal(field.type, this.values[field.code], field.code);
      } else {
        if (this.enableDraft) {
          changedReally[field.code] = this.getSubtableValues(field);
        } else {
          if (this.subtableValue[field.code]) {
            changedReally[field.code] = {
              data: this.subtableValue[field.code],
              empty: (_a = this.values[field.code]) == null ? void 0 : _a.empty
            };
          }
        }
      }
    }
    return changedReally;
  }
  async validateSubtableSubmit(columns) {
    await this.checkSubmit();
    const cols = columns || this.columns;
    const displayControls = cols.filter(({
      code
    }) => this.displayControl[code]);
    const validateResult = await this.validateControl(displayControls.map(({
      code
    }) => code));
    if (validateResult) {
      return this.values;
    }
    return null;
  }
  async getSubmitFormData(columns = null, isValidate = true) {
    if (isValidate) {
      const res = await this.submitSubtable();
      if (res === false) {
        return null;
      }
    }
    await this.checkSubmit();
    let cols = columns || this.columns;
    const displayControls = cols.filter(({
      code
    }) => this.displayControl[code]);
    if (!columns) {
      cols = cols.filter(({
        code
      }) => this.fieldChangeCodes.includes(code));
    }
    const formData = await this.getNeedFormData(cols);
    if (isValidate) {
      const validateResult = await this.validateControl(displayControls.map(({
        code
      }) => code));
      if (validateResult) {
        return formData;
      } else {
        return null;
      }
    }
    return Promise.resolve(formData);
  }
  async getSubmitFormAllData(isValidate = true) {
    return await this.getSubmitFormData(this.columns, isValidate);
  }
  recoverValues() {
    this.values = this.dataLogic.origin;
    this.recoverRelationshipCtrl();
    this.recoverSubtableCtrl();
    this.preValues = cloneDeep(this.values);
    this.fieldChangeCodes = [];
    this.changeFieldCodes && this.changeFieldCodes(this.fieldChangeCodes);
    this.syncRecoverValues && this.syncRecoverValues(this.values);
    if (this.drafts) {
      Object.keys(this.drafts).forEach((code) => this.drafts[code].clear());
    }
  }
  getSubtableValues(column) {
    const {
      getSubtableSubmitValue
    } = useSubmit(column.childrenControls);
    const rows = this.drafts[column.code].getAll();
    const data = getSubtableSubmitValue(rows);
    return {
      empty: this.values[column.code].empty,
      data
    };
  }
}
async function initMobileFormDataManager(params) {
  const instance = new MobileFormDataManager(params);
  await instance.init(params);
  return instance;
}
class LocalTable {
  constructor() {
    this.rows = [];
  }
  put(data) {
    this.rows.push(data);
    return true;
  }
  bulkAdd(datas) {
    this.rows.push(...datas);
    return true;
  }
  remove(id) {
    const idx = this.rows.findIndex((row) => row.id === id);
    if (idx > -1) {
      this.rows.splice(idx, 1);
    }
    return true;
  }
  bulkDelete(ids) {
    for (let i = this.rows.length - 1; i >= 0; i--) {
      const row = this.rows[i];
      if (ids.includes(row.id)) {
        this.rows.splice(i, 1);
      }
    }
    return true;
  }
  update(id, data) {
    const idx = this.rows.findIndex((row) => row.id === id);
    if (idx > -1) {
      this.rows.splice(idx, 1, data);
    }
    return true;
  }
  bulkGet(ids) {
    const ret = this.rows.filter((row) => ids.includes(row.id));
    return ret;
  }
  get(id) {
    const row = this.rows.find((r) => r.id === id);
    if (row) {
      return row;
    }
    return null;
  }
  where(params) {
    const ret = this.rows.filter((row) => {
      let flag = true;
      for (const key of Object.keys(params)) {
        if (row[key] !== params[key]) {
          flag = false;
          break;
        }
      }
      return flag;
    });
    return ret;
  }
  empty() {
    this.rows.splice(0, this.rows.length);
    return true;
  }
  getAll() {
    return this.rows;
  }
  batchRemove(objectIds) {
    return this.bulkDelete(objectIds);
  }
  batchUpdate(rows, shouldEmpty = true) {
    if (shouldEmpty) {
      this.empty();
    }
    return this.bulkAdd(rows);
  }
}
class DBProxy {
  constructor() {
    this.table = new LocalTable();
  }
  add(row, parentId = "") {
    const d = {
      id: row.data.ObjectId,
      parentId,
      data: {
        ...row.data,
        ...row.changed || {}
      },
      source: row.data,
      status: row.status,
      time: new Date().getTime()
    };
    this.table.put(d);
    return d;
  }
  batchAdd(rows, parentId = "") {
    const d = rows.map((row, idx) => {
      return {
        id: row.data.ObjectId,
        parentId,
        data: {
          ...row.data,
          ...row.changed || {}
        },
        source: row.data,
        status: row.status,
        time: new Date().getTime() + idx
      };
    });
    return this.table.bulkAdd(d);
  }
  batchUpdate(rows, shouldEmpty = true) {
    if (shouldEmpty) {
      this.empty();
    }
    return this.table.bulkAdd(rows);
  }
  remove(objectId) {
    return this.table.remove(objectId);
  }
  batchRemove(objectIds) {
    return this.table.bulkDelete(objectIds);
  }
  empty() {
    return this.table.empty();
  }
  upsert(row, parentId = "") {
    const d = this.one(row.data.ObjectId);
    let ret;
    if (d) {
      const md = {
        ...d.data,
        ...row.changed || {}
      };
      ret = {
        ...d,
        data: md
      };
      this.table.update(row.data.ObjectId, ret);
    } else {
      ret = this.add(row, parentId);
    }
    return ret;
  }
  updateDraft(objectId, data) {
    return this.table.update(objectId, data);
  }
  update(objectId, data) {
    const d = this.one(objectId);
    const md = {
      ...d.data,
      ...data
    };
    return this.table.update(objectId, {
      ...d,
      data: md
    });
  }
  all() {
    return this.table.getAll();
  }
  queryByKeys(ids) {
    return this.table.bulkGet(ids);
  }
  queryByCondition(params) {
    const filters = {};
    Object.keys(params).forEach((key) => {
      filters[key] = params[key];
    });
    return this.table.where(filters);
  }
  one(objectId) {
    return this.table.get(objectId);
  }
}
function useDraftStore() {
  const addRows = ref([]);
  const changeRows = ref([]);
  const removedRows = ref([]);
  const dataStatus = ref(DraftDataState.Empty);
  const getRowMap = (rows) => {
    const result = {};
    rows.forEach((row) => {
      result[row.id] = row;
    });
    return result;
  };
  const addMap = computed(() => {
    return getRowMap(addRows.value);
  });
  const changeMap = computed(() => {
    return getRowMap(changeRows.value);
  });
  const removeMap = computed(() => {
    return getRowMap(removedRows.value);
  });
  const reset = () => {
    addRows.value = [];
    changeRows.value = [];
    removedRows.value = [];
    dataStatus.value = DraftDataState.Empty;
  };
  const delById = (rows, id) => {
    const idx = rows.findIndex((row) => row.id === id);
    if (idx > -1) {
      rows.splice(idx, 1);
    }
  };
  const remove = (id) => {
    if (addMap.value[id]) {
      delById(addRows.value, id);
    } else if (changeMap.value[id]) {
      delById(changeRows.value, id);
    }
  };
  return {
    addRows,
    changeRows,
    removedRows,
    addMap,
    changeMap,
    removeMap,
    dataStatus,
    reset,
    remove
  };
}
class DraftManager {
  constructor() {
    this.dbProxy = new DBProxy();
    this.dataStore = useDraftStore();
  }
  get(id) {
    return this.dbProxy.one(id);
  }
  add(row) {
    const d = this.dbProxy.upsert(row);
    if (!this.dataStore.addMap.value[row.data.ObjectId]) {
      this.dataStore.addRows.value.push(d);
    } else {
      const idx = this.dataStore.addRows.value.findIndex((r) => r.id === row.data.ObjectId);
      if (idx > -1) {
        this.dataStore.addRows.value.splice(idx, 1, d);
      }
    }
    this.dataStore.dataStatus.value = DraftDataState.Changed;
  }
  update(row) {
    if (this.dataStore.addMap.value[row.data.ObjectId]) {
      row.status = DataType.Add;
    }
    const d = this.dbProxy.upsert(row);
    if (!this.dataStore.changeMap.value[row.data.ObjectId]) {
      this.dataStore.changeRows.value.push(d);
    } else {
      const idx = this.dataStore.changeRows.value.findIndex((r) => r.id === row.data.ObjectId);
      if (idx > -1) {
        this.dataStore.changeRows.value.splice(idx, 1, d);
      }
    }
    this.dataStore.dataStatus.value = DraftDataState.Changed;
  }
  async remove(row) {
    const id = row.data.ObjectId;
    if (this.dataStore.addMap.value[id]) {
      this.dbProxy.remove(id);
      this.dataStore.remove(id);
      const {
        addRows,
        changeRows,
        removedRows
      } = this.dataStore;
      if (addRows.value.length === 0 && changeRows.value.length === 0 && removedRows.value.length === 0) {
        this.dataStore.dataStatus.value = DraftDataState.Empty;
      }
      return;
    }
    if (this.dataStore.changeMap.value[id]) {
      const d2 = this.dbProxy.one(id);
      if (d2) {
        d2.status = DataType.Delete;
        this.dbProxy.updateDraft(id, d2);
      }
      this.dataStore.remove(id);
    }
    const d = this.dbProxy.upsert(row);
    this.dataStore.removedRows.value.push(d);
    this.dataStore.dataStatus.value = DraftDataState.Changed;
  }
  async clear() {
    this.dataStore.reset();
    this.dbProxy.empty();
  }
  getAll() {
    return this.dbProxy.all();
  }
  async crossUpdate(records) {
    this.dbProxy.empty();
    this.dataStore.changeRows.value = [];
    this.dataStore.removedRows.value = [];
    if (records.length > 0) {
      const rows = [];
      records.forEach((row) => {
        const rowData = {
          status: DataType.Add,
          data: row,
          changed: row,
          id: row.ObjectId
        };
        rows.push(rowData);
      });
      this.dbProxy.batchAdd(rows);
      this.dataStore.addRows.value = rows;
    }
  }
  getRowMap(rows, key) {
    const result = {};
    rows.forEach((row) => {
      result[row[key]] = row;
    });
    return result;
  }
  async batchCalc(newRows, sourceRows) {
    const draftRows = this.dbProxy.all();
    const draftMap = this.getRowMap(draftRows, "id");
    const sourceMap = this.getRowMap(sourceRows, "ObjectId");
    const ret = [];
    newRows.forEach((newRow) => {
      let draftData = draftMap[newRow.ObjectId];
      if (draftData) {
        draftData.data = newRow;
      } else {
        draftData = {
          id: newRow.ObjectId,
          data: newRow,
          source: sourceMap[newRow.ObjectId],
          status: DataType.Update
        };
      }
      ret.push(draftData);
    });
    this.dbProxy.empty();
    this.dbProxy.batchUpdate(ret, false);
  }
}
function useStore({
  appCode,
  schemaCode,
  viewCode,
  columns,
  rowData,
  permissions,
  formDefaultValue,
  systemDefaultValue,
  isCreateMode,
  isInited,
  enableDraft,
  rootColumns,
  rootObject,
  parentFieldCode,
  parentSchemaCode,
  parentObjectId,
  formContext,
  isMobileEnv,
  displayControl,
  changeControlStatus,
  $ugContext,
  openByRelationship
}) {
  const rowValues = ref({});
  const subTableValue = ref({});
  const errorMap = ref({});
  const uowId = ref("");
  const defaultValue = formDefaultValue || {};
  const state = reactive({
    fieldChangeCodes: [...Object.keys(defaultValue)]
  });
  const initControlsMethod = {};
  provide("initControlsMethod", initControlsMethod);
  const rootColumnMap = computed(() => {
    const columnMap = {};
    (rootColumns || []).forEach((column) => {
      columnMap[`${parentSchemaCode}.${column.code}`] = column;
    });
    return columnMap;
  });
  let store = null;
  async function initStore() {
    var _a;
    const initSystemDefaultValue = async (params) => {
      const getSystemDefaultVal = async () => {
        var _a2, _b;
        const engineContext = params.$ugContext.getEngineContext();
        const userContext = params.$ugContext.getUserContext();
        const userInfo = userContext.getUserInfo();
        if (userInfo) {
          const memberInfo = await engineContext.getMemberInfo();
          const memberId = (_a2 = memberInfo.data) == null ? void 0 : _a2.objectId;
          const departments = (_b = memberInfo.data) == null ? void 0 : _b.departments;
          const deptData = departments && departments.map(({
            departmentId,
            department
          }) => ({
            id: departmentId,
            name: department
          }));
          const urInfo = {
            avatar: userInfo.avatarUrl,
            id: memberId,
            name: userInfo.userName
          };
          return {
            CreatedTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            CreatedBy: urInfo,
            OwnerId: urInfo,
            ModifiedBy: null,
            ModifiedTime: null,
            OwnerDeptId: deptData
          };
        }
        return {};
      };
      const defaultValues = !params.$ugContext ? {} : await getSystemDefaultVal();
      return defaultValues;
    };
    const initRow = {
      ...rowData,
      ...defaultValue
    };
    if (openByRelationship) {
      const {
        relationControlCode,
        record,
        type,
        inAppend
      } = openByRelationship;
      if (type === "add") {
        initRow[relationControlCode] = {
          total: 1,
          values: [{
            name: record.Name,
            id: record.ObjectId
          }],
          increments: {
            Append: [record.ObjectId],
            Delete: []
          }
        };
      } else {
        if (inAppend) {
          initRow[relationControlCode] = {
            total: (((_a = initRow[relationControlCode]) == null ? void 0 : _a.total) || 0) + 1,
            values: [{
              name: record.Name,
              id: record.ObjectId
            }],
            increments: {
              Append: [record.ObjectId],
              Delete: []
            }
          };
        }
      }
    }
    const initManagerParams = {
      dataLogicConfig: {
        appCode,
        schemaCode,
        viewCode,
        permission: permissions,
        defaultValues: (systemDefaultValue == null ? void 0 : systemDefaultValue.value) || await initSystemDefaultValue({
          $ugContext
        }),
        rootColumnMap: rootColumnMap.value,
        rootObject,
        parentFieldCode,
        parentObjectId
      },
      isCreateMode,
      isInited,
      sourceRow: {
        ...rowData
      },
      row: initRow,
      columns,
      enableDraft,
      formContext,
      displayControl,
      changeSubTable: (code, value) => {
        subTableValue.value = {
          ...subTableValue.value,
          [code]: value
        };
      },
      syncRecoverValues: (values) => {
        rowValues.value = values;
        uowId.value = store.uowId;
      },
      syncReplaceOriginValues: () => {
        uowId.value = store.uowId;
      },
      changeFieldCodes: (codes) => {
        state.fieldChangeCodes = codes;
      },
      openByRelationship
    };
    if (!isMobileEnv) {
      store = await initFormDataManager(initManagerParams);
    } else {
      store = await initMobileFormDataManager(initManagerParams);
      if (enableDraft) {
        store.drafts = {};
        columns.forEach((item) => {
          if (item.type === ControlType.SubTable) {
            store.drafts[item.code] = new DraftManager();
          }
        });
      }
    }
    uowId.value = store.uowId;
    rowValues.value = store.values;
    errorMap.value = store.errorsMap;
    store.fieldChangeCodes = state.fieldChangeCodes;
    store.controlsMethod = initControlsMethod;
  }
  function getSubmitFormAllData(isValidate) {
    return store && store.getSubmitFormAllData(isValidate);
  }
  function getSubmitFormData(isValidate) {
    return store && store.getSubmitFormData(null, isValidate);
  }
  function clearDraft() {
    store && store.drafts && Object.keys(store.drafts).forEach((code) => store.drafts[code].clear());
  }
  function getSubtableRow() {
    return store && store.validateSubtableSubmit(columns);
  }
  function updateValue(code, value) {
    store && store.updateValue(code, value);
  }
  function inited(code) {
    store && store.inited(code);
  }
  function getStore() {
    return store;
  }
  watch(() => state.fieldChangeCodes, (newVal) => {
    changeControlStatus(newVal.length > 0);
  }, {
    immediate: true
  });
  return {
    initStore,
    rowValues,
    subTableValue,
    uowId,
    errorMap,
    updateValue,
    inited,
    getSubmitFormAllData,
    getSubmitFormData,
    getSubtableRow,
    getStore,
    clearDraft
  };
}
const __vue2_script = defineComponent({
  name: "FormCore",
  components: {
    ControlsWrapper
  },
  props: formCoreProps,
  emits: {
    changeControlStatus: null,
    uploadingFileStatus: null,
    calculatingSubTableStatus: null
  },
  setup(props, context) {
    console.log(props);
    const {
      emit
    } = context;
    const isStoreLoading = ref(true);
    const {
      displayControl,
      initDisplayCols
    } = useDisplayCols({
      columns: props.columns,
      rowData: props.rowData,
      permissions: props.permissions
    });
    const {
      initStore,
      rowValues,
      subTableValue,
      uowId,
      errorMap,
      updateValue,
      inited,
      getSubmitFormAllData,
      getSubmitFormData,
      getSubtableRow,
      getStore,
      clearDraft
    } = useStore({
      appCode: props.appCode,
      schemaCode: props.schemaCode,
      viewCode: props.viewCode,
      columns: props.columns,
      rowData: props.rowData,
      permissions: props.permissions,
      formDefaultValue: props.formDefaultValue,
      systemDefaultValue: props.systemDefaultValue,
      isCreateMode: props.isCreateMode,
      isInited: props.isInited,
      enableDraft: props.enableDraft,
      rootColumns: props.rootColumns,
      rootObject: props.rootObject,
      parentFieldCode: props.parentFieldCode,
      parentSchemaCode: props.parentSchemaCode,
      parentObjectId: props.parentObjectId,
      formContext: props.formContext,
      isMobileEnv: props.isMobileEnv,
      displayControl,
      changeControlStatus,
      $ugContext: window.$ugContext,
      openByRelationship: props.openByRelationship
    });
    function valueUpdate(code, value) {
      console.log("valueUpdate---", code, value);
      if (code === props.columns[0].code) {
        emit("titleUpdate", value);
      }
      updateValue(code, value);
    }
    function changeControlStatus(status) {
      emit("changeControlStatus", status);
    }
    onMounted(async () => {
      await initDisplayCols();
      await initStore();
      isStoreLoading.value = false;
    });
    return {
      rowValues,
      displayControl,
      subTableValue,
      uowId,
      isStoreLoading,
      errorMap,
      valueUpdate,
      inited,
      getSubmitFormAllData,
      getSubmitFormData,
      getSubtableRow,
      getStore,
      clearDraft
    };
  }
});
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("controls-wrapper", _vm._g({
    attrs: {
      "isCreateMode": _vm.isCreateMode,
      "isLoading": _vm.isStoreLoading,
      "appCode": _vm.appCode,
      "schemaCode": _vm.schemaCode,
      "viewCode": _vm.viewCode,
      "bizObjectId": _vm.bizObjectId,
      "columns": _vm.columns,
      "rowValues": _vm.rowValues,
      "uowId": _vm.uowId,
      "subTableValue": _vm.subTableValue,
      "displayControl": _vm.displayControl,
      "displayFields": _vm.displayFields,
      "permissions": _vm.permissions,
      "appPermissions": _vm.appPermissions,
      "operatePermission": _vm.operatePermission,
      "preference": _vm.preference,
      "errorsMsg": _vm.errorMap,
      "workItemId": _vm.workItemId,
      "orgTabPanes": _vm.orgTabPanes,
      "renderControls": _vm.renderControls
    },
    on: {
      "valueUpdate": _vm.valueUpdate,
      "inited": _vm.inited
    }
  }, _vm.$listeners));
};
var staticRenderFns = [];
function normalizeComponent(scriptExports, render2, staticRenderFns2, functionalTemplate, injectStyles, scopeId, moduleIdentifier, shadowMode) {
  var options = typeof scriptExports === "function" ? scriptExports.options : scriptExports;
  if (render2) {
    options.render = render2;
    options.staticRenderFns = staticRenderFns2;
    options._compiled = true;
  }
  if (functionalTemplate) {
    options.functional = true;
  }
  if (scopeId) {
    options._scopeId = "data-v-" + scopeId;
  }
  var hook;
  if (moduleIdentifier) {
    hook = function(context) {
      context = context || this.$vnode && this.$vnode.ssrContext || this.parent && this.parent.$vnode && this.parent.$vnode.ssrContext;
      if (!context && typeof __VUE_SSR_CONTEXT__ !== "undefined") {
        context = __VUE_SSR_CONTEXT__;
      }
      if (injectStyles) {
        injectStyles.call(this, context);
      }
      if (context && context._registeredComponents) {
        context._registeredComponents.add(moduleIdentifier);
      }
    };
    options._ssrRegister = hook;
  } else if (injectStyles) {
    hook = shadowMode ? function() {
      injectStyles.call(
        this,
        (options.functional ? this.parent : this).$root.$options.shadowRoot
      );
    } : injectStyles;
  }
  if (hook) {
    if (options.functional) {
      options._injectStyles = hook;
      var originalRender = options.render;
      options.render = function renderWithStyleInjection(h, context) {
        hook.call(context);
        return originalRender(h, context);
      };
    } else {
      var existing = options.beforeCreate;
      options.beforeCreate = existing ? [].concat(existing, hook) : [hook];
    }
  }
  return {
    exports: scriptExports,
    options
  };
}
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
const FormCore = /* @__PURE__ */ function() {
  return __component__.exports;
}();
const components = [FormCore];
const install = (Vue2) => {
  if (install.installed)
    return;
  install.installed = true;
  components.forEach((component) => {
    var _a;
    Vue2.component(((_a = component.options) == null ? void 0 : _a.name) || component.name, component);
  });
};
if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}
export {
  DataType,
  DraftDataState,
  DraftStatus,
  FormDataManager,
  ListActionType,
  ViewType,
  controlBaseProps,
  controlsProps,
  FormCore as default,
  formProps
};
