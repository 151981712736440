import { Formula } from '../type';
const UUID = {
    name: 'UUID',
    type: Formula.IdentifierType.FUNC,
    inputType: [],
    parameterless: true,
    outputType: {
        paramType: Formula.ParamType.TEXT,
        sourceType: Formula.SourceType.EDIT,
    },
    calculate: (identifier) => {
        const value = guid();
        return {
            ...identifier.outputType,
            value,
        };
    },
};
export default UUID;
/**
 * @description 生成随机id
 */
function guid() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}
