
import {
  StrInfo,
  outSideStr,
  eatWhite,
  parseFormulaStrInfo,
  doubleQuoteMatch,
  bracketPair,
  extraRelevanceFuncs,
  extraSublistFuncs,
} from './utils';
import { generateError } from './error';
import { Formula } from './type';

export const CONDITION_KEY = 'MATCH';
export const CONDITION_CALLER = ['SUMIFS'];

export function checkConditionParamFromCaller (identifier:Formula.Identifier, params:Formula.Param[]) {
  const _params = params.filter(param => param.sourceType === Formula.SourceType.CONDITION);
  if (_params.length > 0 && !CONDITION_CALLER.includes(identifier.name)) {
    throw generateError(`函数 ${CONDITION_KEY}() 只允许在函数 ${
      CONDITION_CALLER.map(func => `${func}()`).join('，')
    } 中使用，不允许在${
      identifier.type === Formula.IdentifierType.FUNC ? `函数 ${identifier.name}() 中` : `操作符 "${identifier.name}" `
    }使用`);
  }
}

/*
  sumifs 等 match 条件处理步骤
  1. 判断是否包含 match
  2. 判断 () "" 是否匹配
  3. 解析公式中 match 函数，判断是否是存在 嵌套 sum avg max min sumifs match
  4. 做 match 函数 替换 #condition-id#
  5. 判断是否在 sumifs 函数中
  6. 在sumifs函数中判断match中公式是否合法, 返回值是否为 bool, 及是否同一个明细表的字段
*/
export type ConditionOption = {
  formula: string;
  conditions: Formula.ConditionParam[];
};
/* 1 2 3 4 */
export function conditionHandle (formula: string): ConditionOption {
  // (MATCH)[\s]*(?=[(])/gmi
  const conditionRegExp = new RegExp(`(${CONDITION_KEY})[\\s]*(?=[(])`, 'gmi');
  const conditions: Formula.ConditionParam[] = [];
  if (!conditionRegExp.test(formula) || !doubleQuoteMatch(formula)) {
    return {
      formula,
      conditions,
    };
  }
  const strInfo = parseFormulaStrInfo(formula);
  if (!bracketPair(formula, strInfo)) {
    return {
      formula,
      conditions,
    };
  }
  const funcTypes = parseFormulaFuncType(CONDITION_KEY, formula, strInfo);
  if (!validateConditionParam(funcTypes)) {
    throw generateError(`函数 ${CONDITION_KEY}() 中不能嵌套函数 ${
      [...extraRelevanceFuncs, ...extraSublistFuncs, CONDITION_KEY].map(item => `${item}()`).join(', ')
    } 等使用`);
  }
  return createConditionParam(funcTypes, formula);
}

export function extractionParameters (formula: string) {
  // /^(MATCH)[\s]*\(/mi;
  const prefixRegExp = new RegExp(`^(${CONDITION_KEY})[\\s]*\\(`, 'mi');
  const suffixRegExp = /\)$/m;
  return formula.replace(prefixRegExp, '').replace(suffixRegExp, '');
}

export function validateConditionParam (funcTypes: FuncType[]): boolean {
  const conditionRegExp = new RegExp(`(?<func>${CONDITION_KEY}|${
    [...extraRelevanceFuncs, ...extraSublistFuncs].join('|')
  })[\\s]*(?=[(])`, 'gmi');

  return funcTypes.every(funcType => {
    let match: RegExpExecArray;
    const strInfo = parseFormulaStrInfo(funcType.param);
    while ((match = conditionRegExp.exec(funcType.param))) {
      const { index } = match;
      if (!outSideStr(index, strInfo)) {
        continue;
      }
      return false;
    }
    return true;
  });
}

export function createConditionParam (funcTypes: FuncType[], formula: string): ConditionOption {
  let _formula = formula;
  const conditions:Formula.ConditionParam[] = funcTypes.map<Formula.ConditionParam>(funcType => {
    const id = Math.random().toString(36).slice(-8);
    _formula = _formula.replace(funcType.formula, `#${id}#`);
    return {
      id,
      paramType: Formula.ParamType.BOOL,
      sourceType: Formula.SourceType.CONDITION,
      formula: funcType.formula,
      value: funcType.param,
    };
  });

  return {
    formula: _formula,
    conditions,
  };
}

export type FuncType = {
  level : number;
  isFunc : boolean;
  formula: string;
  param: string;
  start: number;
  end: number;
  children: FuncType[];
  parent?: FuncType;
  nesting?: string[];

};

export function parseFormulaFuncType (funcKey: string, formula: string, strInfo: StrInfo[]): FuncType[] {
  const list: FuncType[] = [];
  const groups: FuncType[] = [];
  let level = 0;
  const _funcKey = funcKey.toLocaleUpperCase();
  const funcKeyLen = _funcKey.length;
  let isTop = false;
  let target: FuncType;
  function createTarget (subTarget: FuncType) {
    if (target) {
      subTarget.parent = target;
      target.children.push(subTarget);
      target = subTarget;
      return;
    }
    isTop && (target = subTarget);
  }
  for (let index = 0; index < formula.length; index++) {
    let char = formula[index];
    const originIndex = index;
    if (outSideStr(index, strInfo)) {
      if (char.toLocaleUpperCase() === _funcKey[0]) {
        const pending = formula.substr(index, funcKeyLen);
        if (pending.toLocaleUpperCase() === _funcKey) {
          const lastIndex = eatWhite(index + funcKeyLen - 1, formula);
          const nextChar = formula[lastIndex];
          if (nextChar === '(') {
            char = formula.substring(index, lastIndex + 1);
            index = lastIndex;
            level++;
            isTop = level === 1;
            createTarget({
              level,
              isFunc: true,
              formula: '',
              param: '',
              nesting: [],
              start: originIndex,
              end: originIndex,
              children: [],
            });
            list.push(target);
          }
        }
      } else if (/\s/.test(char)) {
        const lastIndex = eatWhite(index, formula);
        char = formula.substring(index, lastIndex);
        index = lastIndex - 1;
      } else if (char === '(' && target) {
        level++;
        createTarget({
          level,
          isFunc: false,
          formula: '',
          param: '',
          nesting: [],
          start: originIndex,
          end: originIndex,
          children: [],
        });
        target && list.push(target);
      } else if (char === ')' && target) {
        if (level > 0) {
          level--;
          if (list[level]) {
            list[level].nesting.push(char);
            list.splice(level, 1);
          }
          const subTarget = target;
          target = target.parent;
          subTarget.formula = subTarget.nesting.join('');
          subTarget.param = extractionParameters(subTarget.formula);
          subTarget.end = subTarget.start + subTarget.formula.length - 1;
          delete subTarget.nesting;
          delete subTarget.parent;
        }
      }
    }

    if (isTop && target) {
      groups.push(target);
      isTop = false;
    }

    if (level > 0) {
      for (let _level = 0; _level < level; _level++) {
        list[_level].nesting.push(char);
      }
    }
  }
  return groups;
}
