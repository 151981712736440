import { generateError } from '../error';
import { Formula } from '../type';
import { getParamTypeValue, inferSingleFromMultiType, getNumberValue } from '../utils';

const GETITEM: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'GETITEM',
  type: Formula.IdentifierType.FUNC,
  outputType: {
    paramType: Formula.ParamType.ANY,
    sourceType: Formula.SourceType.ANY,
  },
  validate (identifier, params) {
    if (params.length !== 2) {
      throw generateError(`函数 ${identifier.name}() 需要2个参数，但传入了${params.length}个参数`);
    }
    const [param, index] = params;
    const inputType = param.paramType;
    const outputType = inferSingleFromMultiType(inputType);
    if (!outputType) {
      throw generateError(
        `函数 ${identifier.name}() 的参数类型[${getParamTypeValue(inputType)}]不是数组类型，请输入类型[多选/人员多选/部门多选]`,
      );
    }
    if (!isNaN(index.value)) {
      const value = getNumberValue(index);
      if (value < 1 || value % 1 !== 0) {
        throw generateError(`函数 ${identifier.name}() 的第二个参数应该为正整数`);
      }
    }
    return {
      paramType: outputType,
      sourceType: Formula.SourceType.ANY,
    };
  },
  calculate: (identifier, params) => {
    const value = null;
    const [param, index] = params;
    const inputType = param.paramType;
    const outputType = inferSingleFromMultiType(inputType);
    if (!outputType) {
      throw generateError(
        `函数 ${identifier.name}() 的参数类型[${getParamTypeValue(inputType)}]不是数组类型，请输入类型[多选/人员多选/部门多选]`,
      );
    }
    const indexValue = getNumberValue(index);
    if (indexValue < 1 || indexValue % 1 !== 0) {
      throw generateError(`函数 ${identifier.name}() 的第二个参数应该为正整数`);
    }
    const result = { ...identifier.outputType, value, paramType: outputType };
    result.value = param.value[indexValue - 1] || null;
    return result;
  },
};

export default GETITEM;
