import { Formula } from '../type';
import {
  checkParam, getDateInstanceFromParam, getFieldName, getNumberValue,
} from '../utils';
import { generateError } from '../error';

// 获取一年的第一个周的开始
function getFirstWeekOfYear (fullYear: number, returnType: number) {
  // 默认ISO 8601
  if (!returnType || returnType === 21) {
    return system2(fullYear, getStartOfWeek(21));
  }
  const startOfWeek = getStartOfWeek(returnType);
  return system1(fullYear, startOfWeek);
}

function system1 (fullYear: number, startOfWeek: number) {
  // 包含1月1日的周为该年的第1周，其编号为1周。
  const firstWeek = new Date(fullYear, 0, 1);
  const day = firstWeek.getDay();
  // 距离第一天
  let offset = startOfWeek - day;
  if (offset > 0) {
    offset -= 7;
  }
  firstWeek.setDate(firstWeek.getDate() + offset);
  return firstWeek;
}

function system2 (fullYear: number, startOfWeek: number) {
  // ISO 8601（1月4号所在的周为第一周）
  const firstWeek = new Date(fullYear, 0, 4);
  const day = firstWeek.getDay();
  // 距离第一天
  let offset = startOfWeek - day;
  if (offset > 0) {
    offset -= 7;
  }
  firstWeek.setDate(firstWeek.getDate() + offset);
  return firstWeek;
}

// 通过type获取每周第一天
function getStartOfWeek (type: number) {
  if (type === 21) {
    // ISO默认星期一
    return 1;
  }
  let _type = type;
  if (_type === 1) {
    _type = 17;
  } else if (_type === 2) {
    _type = 11;
  }
  return (_type - 10) % 7;
}

const returnTypeArr = [1, 2, 11, 12, 13, 14, 15, 16, 17, 21];

const WEEKNUM: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'WEEKNUM',
  type: Formula.IdentifierType.FUNC,
  outputType: {
    paramType: Formula.ParamType.NUMBER,
    sourceType: Formula.SourceType.EDIT,
  },
  validate (identifier, params) {
    if (params.length < 1) {
      throw generateError(`函数 ${identifier.name}() 至少需要1个参数，但传入了${params.length}个参数`);
    }

    if (params.length > 2) {
      throw generateError(`函数 ${identifier.name}() 最多需要2个参数，但传入了${params.length}个参数`);
    }

    const result = [];
    params.forEach((incoming, index) => {
      let paramTypes = {
        paramType: Formula.ParamType.DATE,
        sourceType: Formula.SourceType.ANY,
      };
      if (index === 1) {
        paramTypes = {
          paramType: Formula.ParamType.NUMBER,
          sourceType: Formula.SourceType.EDIT,
        };

        const returnType = incoming && getNumberValue(incoming);
        if (!returnTypeArr.includes(returnType)) {
          throw generateError(`函数 ${identifier.name}() 第二个参数只允许是数字 1, 2, 11, 12, 13, 14, 15, 16, 17, 21`);
        }
      }
      const {
        paramType, paramTypeInfo, sourceType, sourceTypeInfo,
      } = checkParam(paramTypes, incoming);
      if (!paramType || !sourceType) {
        result.push(`第${index + 1}个参数要求是【${
            [paramTypeInfo[0], sourceTypeInfo[0]].filter(str => str).join('，')
          }】，传入的是${getFieldName(incoming)}【${
            [paramTypeInfo[1], sourceTypeInfo[1]].filter(str => str).join('，')
          }】`);
      }
    });
    if (result.length > 0) {
      throw generateError(`函数 ${identifier.name}() 参数错误：\n${result.join('\n')}`);
    }

    return identifier.outputType;
  },
  calculate: (identifier, params, compiler) => {
    const _params = compiler._pretreatment(identifier, params);
    const [_param, _returnType] = _params;
    const _date = getDateInstanceFromParam(_param.value);
    if (!_date) {
      throw generateError(`函数 ${identifier.name}() 中时间参数${getFieldName(_param)}转成时间时出现错误`);
    }
    const type = _returnType && getNumberValue(_returnType);
    if (_returnType && !returnTypeArr.includes(type)) {
      throw generateError(`函数 ${identifier.name}() 第二个参数只允许是数字 1, 2, 11, 12, 13, 14, 15, 16, 17, 21`);
    }
    // 第一周
    let firstWeek = getFirstWeekOfYear(_date.getFullYear(), type);
    // 比第一周的第一天小，则取上一年
    if (_date.getTime() < firstWeek.getTime()) {
      firstWeek = getFirstWeekOfYear(_date.getFullYear() - 1, type);
    }
    const times = _date.getTime() - firstWeek.getTime();
    const value = Math.floor(times / (24 * 3600 * 1000 * 7)) + 1;
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default WEEKNUM;
