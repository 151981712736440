import { Formula } from '../type';
import { generateError } from '../error';
import { dateFormat, getDateInstanceFromParam, toDecimal, extractNumber, getFieldName, getParamTypeValue, } from '../utils';
function checkParamType(param, result) {
    if (![Formula.ParamType.TEXT, Formula.ParamType.MULTITEXT, Formula.ParamType.DATE, Formula.ParamType.BOOL].includes(param.paramType)) {
        result.push(`参数要求类型是【文本、多行文本、日期、逻辑】，传入的是${getFieldName(param)}【${getParamTypeValue(param.paramType)}】`);
    }
}
const NUMBER = {
    name: 'NUMBER',
    type: Formula.IdentifierType.FUNC,
    inputType: [{
            paramType: Formula.ParamType.ANY,
            sourceType: Formula.SourceType.ANY,
        }],
    outputType: {
        paramType: Formula.ParamType.NUMBER,
        sourceType: Formula.SourceType.EDIT,
    },
    validate: (identifier, params, compiler) => {
        const { name, inputType, outputType } = identifier;
        if (inputType.length !== params.length) {
            throw generateError(`函数 ${name}() 需要${inputType.length}个参数，但传入了${params.length}个参数`);
        }
        const result = [];
        checkParamType(params[0], result);
        if (result.length > 0) {
            throw generateError(`函数 ${name}() 参数错误：\n${result.join('\n')}`);
        }
        return outputType;
    },
    calculate: (identifier, params, compiler) => {
        const _params = compiler._pretreatment(identifier, params);
        const [_param] = _params;
        let value = _param.value;
        switch (_param.paramType) {
            case Formula.ParamType.BOOL:
                value = value ? 1 : 0;
                break;
            case Formula.ParamType.TEXT:
                value = toDecimal(extractNumber(value));
                if (isNaN(value)) {
                    generateError(`函数 ${identifier.name}() 的文本参数转化数字错误`);
                }
                break;
            case Formula.ParamType.DATE:
                value = toDecimal(dateFormat(getDateInstanceFromParam(value), 'YYYYMMDD'));
                break;
            default:
                break;
        }
        return {
            ...identifier.outputType,
            value,
        };
    },
};
export default NUMBER;
