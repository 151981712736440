import { generateError } from '../error';
import { Formula } from '../type';

const GETID: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'GETID',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.ANY,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.ANY,
  },
  validate (identifier, params, compiler) {
    const outputType = compiler._funcValidate(identifier, params);
    const [param] = params;
    if (param.paramType !== Formula.ParamType.SINGLEUSER && param.paramType !== Formula.ParamType.SIGNLEDEPT) {
      throw generateError(`函数 ${identifier.name}() 要求参数是人员单选或部门单选`);
    }
    return outputType;
  },
  calculate: (identifier, params) => {
    const [_param] = params;
    const value = _param.value?.id || '';
    return {
      ...identifier.outputType,
      value,
    };
  },
};

export default GETID;
