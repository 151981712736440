import { Formula } from '../type';
import { toText, formatParamValue, validateParamNull } from '../utils';
const TEXT = {
    name: 'TEXT',
    type: Formula.IdentifierType.FUNC,
    inputType: [{
            paramType: Formula.ParamType.ANY,
            sourceType: Formula.SourceType.ANY,
        }],
    outputType: {
        paramType: Formula.ParamType.TEXT,
        sourceType: Formula.SourceType.EDIT,
    },
    calculate: (identifier, params) => {
        let [_param] = params;
        // 数字类型但是为null, 处理成'';
        if (_param.sourceType === Formula.SourceType.FIELD &&
            _param.paramType === Formula.ParamType.NUMBER &&
            validateParamNull(_param)) {
            _param.value = '';
        }
        else {
            _param = formatParamValue(_param);
        }
        const value = toText(_param).value;
        return {
            ...identifier.outputType,
            value,
        };
    },
};
export default TEXT;
