import { Formula } from '../type';

const IDTOUSER: Formula.Identifier<Formula.IdentifierType.FUNC> = {
  name: 'IDTOUSER',
  type: Formula.IdentifierType.FUNC,
  inputType: [{
    paramType: Formula.ParamType.TEXT,
    sourceType: Formula.SourceType.ANY,
  }],
  outputType: {
    paramType: Formula.ParamType.SINGLEUSER,
    sourceType: Formula.SourceType.ANY,
  },
  calculate: (identifier, params, compiler) => {
    const value = null;
    const [param] = params;
    const result = { ...identifier.outputType, value };
    if (param.value) {
      return compiler._externalMethods.idToUser(param.value).then((user: any) => {
        if (!user) {
          return result;
        }
        result.value = user;
        return result;
      });
    }
    return result;
  },
};

export default IDTOUSER;
